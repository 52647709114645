<template>
  <div class="pc-meal-plan-skeleton">
    <div class="gc-card-meal__wrapper">
      <div class="gc-card-meal__head">
        <div class="gc-card-meal gc-card-meal__without-calories">
        <div class="gc-card-meal__badge-wrapper">
          <AnimatedPlaceholder width="100px" height="31px" />
        </div>
        <div class="gc-card-meal__title rc-text-content">
          <AnimatedPlaceholder width="260px" height="24px" />
        </div>
      </div>
      </div>
      <div class="gc-meal-card__action-area">
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
     </div>
    </div>
    <div class="gc-card-meal__wrapper">
      <div class="gc-card-meal__head">
        <div class="gc-card-meal gc-card-meal__without-calories">
        <div class="gc-card-meal__badge-wrapper">
          <AnimatedPlaceholder width="100px" height="31px" />
        </div>
        <div class="gc-card-meal__title rc-text-content">
          <AnimatedPlaceholder width="260px" height="24px" />
        </div>
      </div>
      </div>
      <div class="gc-meal-card__action-area">
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
     </div>
    </div>
    <div class="gc-card-meal__wrapper">
      <div class="gc-card-meal__head">
        <div class="gc-card-meal gc-card-meal__without-calories">
        <div class="gc-card-meal__badge-wrapper">
          <AnimatedPlaceholder width="100px" height="31px" />
        </div>
        <div class="gc-card-meal__title rc-text-content">
          <AnimatedPlaceholder width="260px" height="24px" />
        </div>
      </div>
      </div>
      <div class="gc-meal-card__action-area">
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
     </div>
    </div>

  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";

export default {
  components: {
    AnimatedPlaceholder,
  },
};
</script>

<style lang="scss" scoped>
.pc-meal-plan-skeleton
  .gc-nutrition-details-bar__values-wrapper
  .gc-placeholder {
  margin-bottom: 8px;
}

.pc-meal-plan-skeleton
.gc-button-primary-forward__left-section
.gc-button-primary-forward__left-section-icon {
  margin-right: 10px;
}
.pc-meal-plan-skeleton {
  .gc-button-primary-forward__text-section {
    .gc-button-primary-forward__text-section-text {
      margin-bottom: 5px;
    }
  }

  .pc-meal-plan-day__cart-button {
    background-color: #fff;
  }

  .gc-card-meal__wrapper {
    display: block;
    margin-bottom: 24px;
  }
}
</style>

<template>
  <div class="pc-skeleton-meal-plan-log pc-popup-skeleton-meal-plan-view">
    <div class="pc-container-meal-plan-meal-log">
      <div class="pc-skeleton_nutrition-wrap--meal-info">
        <div class="pc-skeleton-img-wrap">
          <div class="pc-skeleton-nutrients">
            <AnimatedPlaceholder width="75px" height="75px" />
          </div>
        </div>
        <div class="pc-skeleton-text-wrap">
          <div class="pc-skeleton-nutrients">
            <AnimatedPlaceholder width="100%" height="20px" />
          </div>
          <div class="pc-skeleton-nutrients">
            <AnimatedPlaceholder width="50px" height="20px" />
          </div>
          <AnimatedPlaceholder width="100%" height="12px" />
        </div>
      </div>
      <div>
        <div class="pc-skeleton-meal-log-description">
          <AnimatedPlaceholder height="16px" width="100%" />

          <AnimatedPlaceholder height="16px" width="100%" />

          <AnimatedPlaceholder height="16px" width="100%" />

          <AnimatedPlaceholder height="16px" width="100%" />

          <AnimatedPlaceholder height="16px" width="10%" />
        </div>
        <div class="pc-meal-skeleton-date-slider">
          <div class="pc-skeleton-date">
            <AnimatedPlaceholder width="85px" height="50px" />
          </div>
          <div class="pc-skeleton-date">
            <AnimatedPlaceholder width="85px" height="50px" />
          </div>
          <div class="pc-skeleton-date">
            <AnimatedPlaceholder width="85px" height="50px" />
          </div>
          <div class="pc-skeleton-date">
            <AnimatedPlaceholder width="85px" height="50px" />
          </div>
          <div class="pc-skeleton-date">
            <AnimatedPlaceholder width="85px" height="50px" />
          </div>
        </div>

        <div class="pc-skeleton-card-list">
          <div
            class="pc-skeleton-card gc-card-meal"
            v-for="index in 3"
            :key="index"
          >
            <div class="pc-skeleton-card-top">
              <AnimatedPlaceholder width="200px" height="20px" />
            </div>

            <div class="pc-skeleton-card-bottom">
              <AnimatedPlaceholder height="16px" width="100%" />

              <AnimatedPlaceholder height="16px" width="100%" />

              <AnimatedPlaceholder height="16px" width="100%" />

              <AnimatedPlaceholder height="16px" width="100%" />

              <AnimatedPlaceholder height="16px" width="10%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder";

export default {
  components: {
    AnimatedPlaceholder,
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <popup
    :visible="visible"
    class="pc-popup-shopping-list-email"
    v-if="visible"
    size="md"
    @hide="closePopup()"
  >
    <popup-title>
      <popup-button-close @hide="closePopup($event)"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid}">
        <form
          @submit.prevent="handleSubmit(formSubmit)"
          class="pc-popup-shopping-list-email__form"
        >
          <popup-content>
            <popup-head>
              <popup-text-heading v-if="status!='loading'">
                {{ title ? title : '' }}
              </popup-text-heading>
              <div v-if="status==='loading'" class="text-center">
                <loading-buffer :height="'75'"></loading-buffer>
              </div>
            </popup-head>
            <icon-complete-filled
              v-if="status === 'success'"
              :size="'lg1'"
            ></icon-complete-filled>

            <div v-if="status === 'init'">
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <div>
                  <text-body-small
                    class="pc-popup-shopping-list-email__form-label"
                    :line-height="'multi'"
                  >{{ $i18n.t('message[\'settings.email\']') }}
                  </text-body-small
                  >
                  <text-input
                    class="pc-popup-shopping-list-email__form-input"
                    :placeholder="'Enter your email address'"
                    :error-message="errors[0]"
                    v-model="formData.email"
                  />
                </div>
              </ValidationProvider>
            </div>
          </popup-content>
          <popup-footer>
            <button-primary
              v-if="status === 'init'"
              class="pc-popup-shopping-list-email__form-button"
              :text="footerData.btnLabel"
              :disabled="invalid"
            ></button-primary>
          </popup-footer>
        </form>
      </ValidationObserver>
    </popup-body>
  </popup>
</template>

<script>
import DashBoardService from '../../../../../services/DashboardService'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import '../../../../../helpers/validation'
import {assets} from '../../../../../includes/AssetBinding'
import ShoppingListService from '../../../../../services/ShoppingListService'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import globalMixin from '../../../../../mixins/globalMixin'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import TextInput from '../../../../global/inputs/TextInput'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer.vue'

export default {
  name: 'PopupShoppingListEmail',
  props: ['visible', 'mealPlanID'],
  mixins: [popupAnimationMixin, globalMixin],
  components: {
    LoadingBuffer,
    ValidationProvider,
    ValidationObserver,
    Popup,
    PopupTitle,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupButtonClose,
    PopupBody,
    TextInput,
    TextBodySmall,
    ButtonPrimary,
    PopupFooter,
    IconCompleteFilled
  },
  mounted () {
    this.formData.meal_plan_id = this.mealPlanID
    const dashBoardService = new DashBoardService()
    this.formData.header_image = this.getStoreImage('email.pdf.logo')
    this.setCSS()
    dashBoardService.getUserData().then((data) => {
      this.formData.email = data.email
    })
  },
  data: function () {
    return {
      footerData: {
        btnLabel: this.$i18n.t('message["general.send"]'),
        active: true
      },
      submited: false,
      status: 'init',
      successImage: this.$appConfig.imagesUrl + 'swap-success.svg',
      systemErrors: [''],
      formData: {
        email: '',
        days: this.$store.getters['shoppingStore/days'],
        header_image: '',
        subject: this.$i18n.t('message[\'shopping-list.email-subject\']'),
        message: this.$i18n.t('message[\'shopping-list.email-message\']'),
        people: this.$store.getters['shoppingStore/peopleCount'],
        meal_plan_id: '',
        css: []
      },
      title: this.$i18n.t('message[\'shopping-list.email-send-title\']'),
      contentText: '',
      imagesUrl: this.$appConfig.imagesUrl.imagesUrl
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    formSubmit () {
      if (this.submited) {
        return
      }
      this.submited = true
      this.footerData.active = false
      this.status = 'loading'
      const shoppingListService = new ShoppingListService()
      shoppingListService
        .sendEmail(this.formData)
        .then((data) => {
          this.title = this.$i18n.t('message[\'shopping-list.check-inbox\']')
          this.status = 'success'
          this.logEvent('ShoppingList.Emailed', {}, false)
          setTimeout(() => {
            this.closePopup()
          }, 3000)
        })
        .catch((err) => {
          if (
            typeof err.response.data.type !== 'undefined' &&
            err.response.data.type === 'logic'
          ) {
            this.systemErrors = err.response.data.errors
          } else if (typeof err.response.data.errors !== 'undefined') {
            this.systemErrors = err.response.data.errors
          }
        })
        .finally(() => {
          this.submited = false
          this.footerData.active = true
        })
    },
    setCSS () {
      const cssList = assets.filter((asset) => {
        return asset.asset_type === 'style' && !asset.path.startsWith('/')
      })

      this.formData.css = cssList.map((css) => {
        return css.path
      })
    }
  }
}
</script>

<template>
  <div class="gc-slider-navigation gc-slider-navigation--v2">


    <Slider :slider-i-d="sliderId" :options="sliderOptions" v-if="links.length"  @click="clickLink">
      <slider-slide

      v-for="(item, index) in links"
      :key="index"

    >
      <div class="gc-slider-navigation__slide" :class="{'gc-slider-navigation__slide--active': item.id ===currentActiveLinkID}"   >
        <div class="gc-slider-navigation__content" :class="{'gc-slider-navigation__content--active': item.id ===currentActiveLinkID}">
          <icon-completed-filled class="gc-slider-navigation__slide-link-icon gc-slider-navigation__slide-link-icon-completed"
                                 v-if="item.completed"
                                 :size="'sm1'"></icon-completed-filled>
          <text-content class="gc-slider-navigation__slide-link"
                        :class="{'gc-slider-navigation__slide-link--active': item.id ===currentActiveLinkID}"
                        :line-height="'multi'" :size="'sm1'" :weight="'medium'">{{limitText(item.text)}}

          </text-content>
        </div>
      </div>
    </slider-slide>
    </Slider>
  </div>

</template>

<script>
import Slick from 'vue-slick'
import CalenderSliderCalDay from './calender-slider-sub-components/CalenderSliderCalDay'
import {slickMixin} from '@/mixins/slickMixin'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer.vue'
import TextContent from '../../root/TextContent'
import IconInfo from '../../root/icons/IconInfo'
import {mapMutations} from 'vuex'
import IconCompleted from '../../root/icons/IconComplete.vue'
import IconCompletedFilled from '../../root/icons/IconCompletedFilled'
import Slider from "@/components/global/sliders/Slider.vue";
import SliderSlide from "@/components/global/sliders/slider-sub-components/SliderSlide.vue";

export default {
  name: 'SliderNavigationV2',
  mixins: [slickMixin],
  components: {
    SliderSlide,
    Slider,
    IconCompletedFilled,
    IconCompleted,
    IconInfo,
    TextContent,
    LoadingBuffer,
    Slick,
    CalenderSliderCalDay
  },
  props: {
    sliderId: {
      type: String,
      default: ''
    },
    /**
     * array of links
     * [{text,id,info}]
     */
    links: {
      type: Array
    },
    slidesToShow: {
      default: 4.5
    },
    isWeekSlider: {
      default: false
    },
    /**
     * active link ID
     */
    activeLinkID: {}
  },
  created () {
    this.currentActiveLinkID = this.activeLinkID
    this.sliderOptions.start =  this.getCurrentActiveLinkIndex(this.activeLinkID)
  },
  data () {
    return {
      sliderOptions:{
        drag   : 'free',
        snap   : true,
        variableWidth: true,
        arrows: false,
        pagination: false,
        autoWidth: true,
        speed: 50,

      },
      show: false,
      currentActiveLinkID: true,
      currentSlide: null,
    }
  },
  watch: {

  },
  mounted () {
    this.show = true
    this.currentActiveLinkID = this.activeLinkID
  },

  methods: {
    ...mapMutations({
      setInstructionsPopupContent: 'instructionPopupStore/setContent',
      viewInstructionsPopup: 'instructionPopupStore/show'
    }),
    handleInit () {
      this.$nextTick(() => {
        this.goToActiveSlide()
      })
    },
    limitText (text, limit = 37) {
      if (typeof text !== 'string') {
        return text
      }
      return (text.length < limit) ? text : (text.substring(0, limit) + '...')
    },

    clickLink (event) {
      console.log('clickLink')
      this.currentActiveLinkID = this.links[event.index].id
      this.$emit('click-link', this.currentActiveLinkID)
    },


  },

}
</script>

<style scoped>

</style>

<template>
  <grid-row class="pc-dashboard__action-buttons pc-dashboard__action-buttons-style-1">
    <grid-col
      class="pc-dashboard__action-button__wrapper"
      v-for="(notification, id) in notifications"
      v-bind:key="'dashboard-notification' + id"
      :lg="notifications.length === 1 ? 12 : 6"
    >
      <button-progress-update
        v-if="!notification.action.url.includes(
                    'api/notifications/subscription/'
                  )"
        :text="notification.message"
        :action="notification.action"
      />
    </grid-col>
  </grid-row>
</template>

<script>
import GridCol from "../../../../../global/grid/GridCol.vue";
import ButtonProgressUpdate from "./../ButtonProgressUpdate.vue";
import GridRow from "@/components/global/grid/GridRow.vue";

export default {
  name: 'sectionDashboardProgressUpdateStyle1',
  components: {
    GridRow,
    GridCol,
    ButtonProgressUpdate
  },
  data: function () {
    return {
    }
  },
  props: {
    notifications: {
      default: ''
    },
  }
}
</script>

<template>
  <div>

    <div class="pc-container-photo-journey-history">
      <div v-if="history.length===0&&!loading" class="text-center gc-card-photo-journey-history">
        <text-content weight="medium" size="sm1" >No photos to show</text-content>
      </div>
      <!-- image History timeline-->
      <div v-else>
        <card-photo-journey-history
            class="pc-photo-journey-history__card"
            v-for="(data,index) in history"
            :key="'photo-journey-'+index"
            :galleryData="data"
            :isPublic="data.hasPublic"
            :date="data.date"
            :gallery-data="data"
            >
      </card-photo-journey-history>
      </div>
      <transition name="slow-fade">
        <div class="pc-photo-journey-history__card" v-if="loading&&!firstLoad">
          <div class=" pc-container-photo-journey-history-skeleton"v-for="n in 3" :key="n" >
            <ImagePhotoJourneyHistorySkeleton /><ImagePhotoJourneyHistorySkeleton /><ImagePhotoJourneyHistorySkeleton />
          </div>

        </div>
      </transition>




      <popup-confirmation v-if="visibleConfirmationPopup()" @confirm="deleteHistory"></popup-confirmation>
    </div>
  </div>

</template>

<script>

import cardPhotoJourneyHistory from './CardPhotoJourneyHistory'
import fitnessDiaryPhotoJourneyHistoryMixin
  from '../../../../../mixins/component-mixins/page/fitness-diary/fitnessDiaryPhotoJourneyHistoryMixin'
import PopupConfirmation from '../../../../global/popups/PopupConfirmation'
import { mapGetters } from 'vuex'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import moment from 'moment'
import TextContent from '../../../../root/TextContent.vue'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import ImagePhotoJourneyHistorySkeleton
  from "@/components/layout/template-1/fitness-diary/page-components/ImagePhotoJourneyHistorySkeleton.vue";
// import ContainerPhotoJourneyHistorySkeleton from './ContainerPhotoJourneyHistorySkeleton'

export default {
  name: 'ContainerPhotoJourneyHistory',
  mixins: [fitnessDiaryPhotoJourneyHistoryMixin],
  components: {
    ImagePhotoJourneyHistorySkeleton,
    LoadingBuffer,
    cardPhotoJourneyHistory,
    PopupConfirmation,
    TextBodyExtraSmall,
    TextContent,

  },
  data: function () {
    return {
      toBeDeleted: [],
      firstLoad: false,
    }
  },
  mounted () {
    // init
    this.resetHistoryData()
    this.load()

    // init scroll event listener
    window.addEventListener('scroll', this.scrollListener)
  },
  methods: {
    ...mapGetters({
      getToBeDeleted: 'fitnessDiaryStore/getToBeDeleted'
    }),
    /**
     * overrided the deleteHistory() method
     */
    async deleteHistory () {
      this.showLoading()

      // get IDs of toBeDeleted images from the vuex store
      this.toBeDeleted = this.getToBeDeleted()

      const lastId = this.toBeDeleted[(this.toBeDeleted.length - 1)]

      try {
        for (const id of this.toBeDeleted) {
          await this.service()
            .removeImageHistory(id)
            .finally(() => {
              if (lastId.toString() === id.toString()) {
                this.service().getDataFromAPI(true)
                  .finally(() => {
                    this.resetHistoryData()
                    this.load()
                    this.$emit('reInit')
                    this.hideLoading()
                  })
              }
            })
        }
      } catch (e) {
        this.resetHistoryData()
        this.load()
        this.$emit('reInit')
        this.hideLoading()
      }
    },
    /**
     * override changeDateFormat() method
     * @param {String} date
     */
    changeDateFormat (date) {
      const dateObj = moment(date)
      const today = moment(new Date())
      // if photo is uploaded on current date, it will display 'Today'
      if (dateObj.format('MMM DD, YYYY') === today.format('MMM DD, YYYY')) {
        return this.$i18n.t('message["general.today"]')
      } else if (dateObj.format('YYYY') === today.format('YYYY')) {
        // if photo is uploaded in current year, only month and date will be displayed. (Ex: 'Dec 24')
        return dateObj.format('MMM DD')
      } else {
        // if photo is uploaded in a past year, full date will be displayed. (Ex: 'Dec 01, 2019')
        return dateObj.format('MMM DD, YYYY')
      }
    }
  },
  beforeDestroy () {
    this.hideConfirmationPopup()
    // destroy scroll event listener
    window.removeEventListener('scroll', this.scrollListener)
  }
}
</script>

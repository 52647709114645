<template>
  <div class="pc-dashboard__card-food-diary" v-if="enabled">
    <!-- <loading-buffer
      v-if="state==='loading'"
      :height="180"/> -->
    <card-dashboard-step-tracker-skeleton v-if="state === 'loading'" />
    <card-food-diary-main
      v-if="state === 'loaded'"
      :under-maintenance="underMaintenance"
      :show-log-now-when-empty="true"
      :header-text="headerText"
      :header-icon="'forward'"
      :macro-nutrients="macroNutrients"
      :calories="calories"
      @log-now="goToLogPage()"
      @header-clicked="goToHomePage()"
    />
  </div>
</template>

<script>
import CardFoodDiaryMain from "../../../../global/cards/CardFoodDiaryMain";
import lodash from "lodash";
import moment from "moment";
import FoodDiaryService from "../../../../../services/FoodDiaryService";
import { showFoodDairy } from "../../../../../includes/TemplateSettings";
import LoadingBuffer from "../../global/widgets/LoadingBuffer";
import { mapGetters, mapMutations } from "vuex";
import NutritionService from "../../../../../services/nutrition/NutritionService";
import * as Sentry from "@sentry/browser";
import CardDashboardStepTrackerSkeleton from "./CardDashboardStepTrackerSkeleton";

export default {
  name: "CardFoodDiary",
  components: {
    CardFoodDiaryMain,
    LoadingBuffer,
    CardDashboardStepTrackerSkeleton,
  },
  data: function () {
    return {
      state: "loading",
      underMaintenance: false,
      enabled: false,
      headerText: this.$i18n.t('message["food-diary.main-card-title"]'),
      macroNutrients: [],
      mealPlan: null,
      calories: {},
      todayLog: {},
      logPopup: {
        visible: false,
      },
    };
  },
  watch: {
    todayLog: {
      deep: true,
      handler(value) {
        let loggedMeals = [];
        if (value) {
          loggedMeals = value.logged_meals || [];
        } else {
          return {};
        }

        let totalMacros =
          this.service.getCurrentNutrientTotalsOfMeals(loggedMeals);
        this.macroNutrients = [
          {
            name: this.$i18n.t("message['general.proteins']"),
            quantity: totalMacros.macronutrients.proteins,
            maxQuantity: value.required.macronutrients.proteins,
            unit: "%",
          },
          {
            name: this.$i18n.t("message['general.fats']"),
            quantity: totalMacros.macronutrients.fats,
            maxQuantity: value.required.macronutrients.fats,
            unit: "%",
          },
          {
            name: this.$i18n.t("message['general.carbs']"),
            quantity: totalMacros.macronutrients.carbohydrates,
            maxQuantity: value.required.macronutrients.carbohydrates,
            unit: "%",
          },
        ];
        if (this.todayLog.required.macronutrients.show_net_carbs) {
          this.macroNutrients.push({
            name: this.$i18n.t("message['general.carbs-net']"),
            quantity: totalMacros.macronutrients.net_carbohydrates,
            maxQuantity: value.required.macronutrients.net_carbohydrates,
            unit: "%",
          });
        }
        this.calories = {
          name: this.$i18n.tc("message['general.calories']", 2),
          quantity: totalMacros.calories,
          maxQuantity: value.required.calories,
          unit: this.$i18n.t("message['food-diary.unit-cal']"),
        };
      },
    },
  },
  mounted() {
    if (!showFoodDairy()) {
      return;
    }
    this.date = moment().locale("en").format("YYYY-MM-DD");
    this.service = new FoodDiaryService();
    let mealPlanService = new NutritionService();
    let latestMealPlan = mealPlanService.getLatestMealPlan();
    if (!latestMealPlan) {
      return;
    }
    latestMealPlan
      .getData()
      .then(() => {
        this.mealPlan = latestMealPlan;
        this.enabled = true;
        this.scrollToCard();

        this.days = latestMealPlan.getDays();
        this.getLatestData();
      })
      .catch((e) => {
        Sentry.captureException(e, { level: "debug" });
        Sentry.captureMessage(
          "Food Diary API Error Meal Plan :" + err.message,
          "debug"
        );
        this.state = "error";
      });
  },
  methods: {
    ...mapMutations({
      setRouteTransition: "setRouteTransition",
      resetStore: "foodDiaryStore/reset",
      setLastPage: "foodDiaryStore/setLastPage",
      setTodayLog: "foodDiaryStore/setTodayLog",
      setEditableMealLog: "foodDiaryStore/setEditableMealLog",
    }),
    ...mapGetters({
      getAddPopupShown: "foodDiaryStore/getAddPopupShown",
      getCurrentLogs: "foodDiaryStore/getCurrentLogs",
      getTodayLog: "foodDiaryStore/getTodayLog",
      getFoodDiaryPopupStatus: "featurePopupStore/visible",
      getIsPopupShown: "featurePopupStore/isPopupShown",
    }),
    scrollToCard() {
      if (location.hash === "#foodDairy") {
        this.$nextTick(() => {
          setTimeout(() => {
            let ele = document.querySelector(".pc-dashboard__card-food-diary");
            const top = ele.offsetTop;
            window.scrollTo(0, top - 30);
          }, 100);
        });
      }
    },
    goToLogPage() {
      document.body.style.backgroundColor =
        templateConfig.style_variables["$bg-infoPages"];
      this.setLastPage("dashboard");
      this.setRouteTransition({ name: "up-slide-fade", mode: "out-in" });
      this.setEditableMealLog(this.todayLog);
      this.$router.push({
        path: "/meal-plans",
        query: { page: "food-diary-log-meal", loading: "0" },
      });
    },
    showLogPopup() {
      this.logPopup.visible = true;
    },
    hideLogPopup() {
      this.logPopup.visible = false;
    },
    updateLog(log) {
      this.todayLog = log;
    },
    getLatestData() {
      this.todayLog = this.getTodayLog();
      if (this.getCurrentLogs.length > 0) {
        this.todayLog = this.getCurrentLogs.find((log) => {
          return log.logged_date === this.date;
        });
      }
      if (this.todayLog) {
        this.state = "loaded";
        return;
      }
      this.service
        .getTodayLogFromAPI()
        .then((log) => {
          this.todayLog = null;
          if (log) {
            this.todayLog = log;
            this.setTodayLog(this.todayLog);
          }
          this.$nextTick(() => {
            if (!this.todayLog) {
              this.todayLog = this.getEmptyLog();
              this.requiredNutrients = this.setRequiredNutrients();

              this.todayLog.required = this.requiredNutrients;
              this.setTodayLog(this.todayLog);
            } else {
              this.requiredNutrients = this.todayLog.required;
            }
          });
          this.state = "loaded";
        })
        .catch((err) => {
          this.state = "error";
          this.underMaintenance =
            err.response &&
            err.response.status &&
            (err.response.status === 504 || err.response.status === 503);
          if (this.underMaintenance) {
            this.state = "loaded";
          }
          Sentry.captureException(err, { level: "debug" });
          Sentry.captureMessage(
            "Food Diary API Error :" + err.message,
            "debug"
          );
        })
        .finally(() => {
          if (this.getAddPopupShown()) {
            this.showLogPopup();
          }
        });
    },
    getMinimumCalorieDay() {
      let sortedDays = lodash.sortBy(this.days, ["_calories"]);
      return sortedDays[0] || null;
    },
    setRequiredNutrients() {
      let latestMealPlan = this.mealPlan;
      if (!latestMealPlan) {
        return;
      }
      return {
        calories: latestMealPlan.getAverageCaloriesOfDays(),
        macronutrients: latestMealPlan.getAverageMacroNutrientsOfDays(),
      };
    },
    goToHomePage() {
      this.showLoading();
      this.resetStore();
      this.delayedRouterPush({ path: "/meal-plans?page=food-diary" });
    },

    getEmptyLog() {
      return this.service.emptyLog();
    },
  },
};
</script>

<style scoped></style>

<template>
  <popup
    :visible="visible"
    :no-close-on-backdrop="true"
    modal-class="gc-popup gc-popup-app-update"
    v-if="visible"
  >
    <popup-body class="text-center">
      <popup-content>
        <popup-head style="margin-top: 40px">
          <icon-download class="gc-popup__header-icon" :size="'lg1'"></icon-download>
          <popup-text-heading  class="gc-popup-app-update__title">{{$i18n.t("message['popup-app-update.header']")}}</popup-text-heading>
        </popup-head>
        <popup-text-body  class="gc-popup-app-update__description">
          <text-content :size="'sm2'" :lineHeight="'multi'">{{$i18n.t("message['popup-app-update.description']")}}</text-content>
        </popup-text-body>
        <div>
        </div>
      </popup-content>
      <popup-footer>
        <button-primary class="gc-popup__btn-wrapper" :text="$i18n.t('message[\'popup-app-update.action\']' )"  @click="gotoDownloadPage()" />
      </popup-footer>
    </popup-body>

  </popup>
</template>

<script>

import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import ButtonPrimary from '../../global/buttons/ButtonPrimary'
import PopupFooter from '../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../global/popups/popup-sub-components/PopupTextBody'
import PopupTextHeading from '../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../global/popups/popup-sub-components/PopupContent'
import PopupBody from '../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../global/popups/Popup'
import TextContent from '../../root/TextContent'
import ButtonSecondary from '../../global/buttons/ButtonSecondary'
import IconServerError from '../../root/icons/IconServerError'
import IconWarning from '../../root/icons/IconWarning'
import IconDownload from "@/components/root/icons/IconDownload.vue";
import NativeAppService from "@/services/NativeAppService";

export default {
  name: 'PopupNativeAppUpdate',
  mixins: [popupAnimationMixin],
  components: {
    IconDownload,
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextContent,
    ButtonSecondary,
    IconServerError,
    IconWarning
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.hideLoading()
      }
    }
  },
  computed: {
    visible () {
      return  this.$store.getters["nativeAppUpdateStore/visible"]
    },


  },
  methods: {
    gotoDownloadPage(){
      const na = new NativeAppService()
      na.showAppBrowser(this.$store.getters["nativeAppUpdateStore/storeUrl"])
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-workout-pdf-send-popup"
    ref="modal"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(formSubmit)">
          <popup-head class="text-center">
            <popup-text-heading>{{$i18n.t("message['popup.workout-send-pdf.title']")}}</popup-text-heading>
          </popup-head>
          <popup-content class="gc-workout-pdf-send-popup__content">
            <loading-buffer v-if="status === 'loading'" height="50"/>
            <div  class="text-center"  v-if="status==='success'">
              <icon-complete-filled class="text-center" :size="'lg3'"/>
            </div>

            <div  v-if="status === 'init'">
            <popup-text-body>
              <text-content class="gc-workout-pdf-send-popup__input-title" :size="'sm2'">{{$i18n.t("message['popup.workout-send-pdf.week-select-input.title']")}}</text-content>
              <div  class="gc-workout-pdf-send-popup__plan-type-input">
                <input-single-select :options="weeks" :sort="false" v-model="weekId"/>
              </div>
              <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                <text-content class="gc-workout-pdf-send-popup__input-title" :size="'sm2'">{{$i18n.t("message['popup.workout-send-pdf.email-input.title']")}}</text-content>
                <text-input :placeholder="placeholder" :type="'email'" :error-message="errors[0]" v-model="formData.email"/>
              </ValidationProvider>
            </popup-text-body>
            </div>
          </popup-content>
          <popup-footer>
            <button-primary
              v-if="status === 'init'"
              :text="$i18n.t('message[\'popup.workout-send-pdf.email-summary-popup-button-text\']')"/>
          </popup-footer>
        </form>
      </ValidationObserver>
    </popup-body>
  </popup>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import Popup from './Popup'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import TextInput from '../inputs/TextInput'
import TextContent from '../../root/TextContent'
import ButtonPrimary from '../buttons/ButtonPrimary'
import PopupFooter from './popup-sub-components/PopupFooter'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import WorkoutService from '../../../services/WorkoutService'
import InputSingleSelect from '../inputs/InputSingleSelect'
import DashBoardService from '../../../services/DashboardService'
import LanguageService from '../../../services/LanguageService'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
export default {
  name: 'PopupWorkoutPdfSend',
  components: {
    PopupFooter,
    ButtonPrimary,
    TextInput,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    IconCompleteFilled,
    PopupTitle,
    TextContent,
    ValidationProvider,
    ValidationObserver,
    LoadingBuffer,
    InputSingleSelect
  },
  mixins: [popupAnimationMixin, NativeAppMixin],
  props: {
    visible: { default: false },
    workoutId: {
      required: true
    },
    weeks: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      lang: '',
      weekId: '*',
      status: 'init',
      placeholder: this.$i18n.t('message["general.enter-your-email"]'),
      systemErrors: [],
      formData: {
        workoutId: this.workoutId,
        v: null,
        email: '',
        header_image: null,
      }
    }
  },
  mounted () {
    this.setDefaultEmail()
    this.setLang()
    this.formData.header_image = this.getStoreImage('email.pdf.logo')
  },
  methods: {
    setLang () {
      const languageService = new LanguageService()
      this.lang = languageService.getAppLang()
    },
    setDefaultEmail () {
      const dashboardService = new DashBoardService()
      this.formData.email = dashboardService.getUserEmail()
    },
    async formSubmit () {
      try {
        this.status = 'loading'
        let payload = {
          'email': this.formData.email,
          'subject': this.$i18n.t('message["workout-program.title"]'),
          'message': this.$i18n.t('message["workout-program.email-message"]'),
          'workout_program_id': this.workoutId,
          'lang': this.lang,
          'header_image': this.formData.header_image,
          'lang_lines': {
            'exercise': this.$i18n.t('message["general.exercise"]'),
            'setsRounds': this.$i18n.t('message["general.sets-rounds"]'),
            'time': this.$i18n.t('message["general.time"]'),
            'rest': this.$i18n.t('message["general.rest"]'),
            'sets': this.$i18n.t('message["general.sets"]'),
            'tempo': this.$i18n.t('message["workouts.tempo"]'),
            'minute': this.$i18n.t('message["general.minutes-short"]'),
            'second': this.$i18n.t('message["general.seconds-short"]'),
            'notAvailable': this.$i18n.t('message["general.not-available"]'),
            'page': this.$i18n.t('message["general.page"]'),
            'set_types': []
          },
          'group_set_types': [
            this.$i18n.t('message["workouts.set-type-super"]'),
            this.$i18n.t('message["workout.set-type-triset"]'),
            this.$i18n.t('message["workouts.set-type-giant"]'),
            this.$i18n.t('message["workout.mode-circuit"]')
          ]
        }

        if (this.weekId === '*') {
          payload.workout_program_week_id = null
        } else {
          payload.workout_program_week_id = this.weekId
        }
        const workoutService = new WorkoutService()
        await workoutService.sendWorkoutPdf(payload)

        this.status = 'success'
        setTimeout(() => {
          this.closeAnimatedPopup(() => {
            this.visible = false
            this.$emit('close')
            this.status = 'init'
          })
        }, 2000)
      } catch (err) {
        if (err.response.data.errors) {
          this.systemErrors = err.response.data.errors
        } else {
          this.systemErrors = [this.$i18n.t('message["general.server-error-description"]')]
        }

        this.status = 'init'
      }
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.visible = false
        this.$emit('close')
      })
    }
  }
}
</script>

<template>
  <div class="pc-smart-add">
    <page-container class="pc-smart-add__wrapper" v-if="mealGenerationState != 'generated'">
      <text-body-small :line-height="'multi'" class="pc-smart-add__description">{{ $t('message["food-diary.smart-add-description"]') }}</text-body-small>
      <text-area
        v-model="mealIngredients"
        :placeholder="$t('message[\'food-diary.smart-add-input-placeholder\']')"
        :errorMessage="error"
      />
      <page-fixed-footer>
        <button-primary
          :text="$tc('message[\'general.generate\']')"
          :disabled="!mealIngredients"
          @click="generate()"
        >
        </button-primary>
      </page-fixed-footer>
    </page-container>
    <page-container class="pc-smart-add__wrapper" v-if="mealGenerationState === 'generated'">
      <button-secondary-backward
        :text="$t('message[\'general.try-again-text\']')"
        @click="navigateBack"
        class="pc-smart-add__button-try-again"
        />
      <validation-observer
          v-slot="{ handleSubmit, invalid }"
          ref="form"
          tag="div"
      >
        <form
            id="custom-log-form"
            class="pc-container-smart-add-meal-log-form"
            @submit.prevent="handleSubmit(submitForm)"
        >

          <validation-provider
              v-slot="{ errors }"
              class="pc-container-smart-add-meal-log-form__input-name-wrapper"
              :name="$t('message[\'food-diary.enter-food-name\']')"
              tag="div"
              vid="name"
              rules="required|max:200"
          >
            <text-input
                v-model="formData.name"
                :placeholder="[[$t('message[\'food-diary.enter-food-name\']')]]"
                :error-message="errors[0]"
                class="pc-container-smart-add-meal-log-form__input"
            />
          </validation-provider>

          <card-nutrition-analysis
              v-if="showNutritionAnalysisCard"
              :calories="formData.calories"
              :ingredients="individualMacros"
          />

          <validation-provider
              v-slot="{ errors }"
              class="pc-container-smart-add-meal-log-form__input-wrapper"
              :name="firstLetterCaps($t('message[\'general.proteins\']'))"
              tag="div"
              vid="macronutrients.proteins"
              rules="required|min_value:0|between:0,1000"
          >
            <input-measurement
                v-model="formData.macronutrients.proteins"
                :max-value="9999"
                :step="0.01"
                :text="$t('message[\'general.proteins\']')"
                :unit="$t('message[\'general.g\']')"
                :error-message="errors[0]"
                class="pc-container-smart-add-meal-log-form__input"
                @change="setProteinsValue"
            />
          </validation-provider>

          <validation-provider
              v-slot="{ errors }"
              class="pc-container-smart-add-meal-log-form__input-wrapper"
              :name="firstLetterCaps($t('message[\'general.fats\']'))"
              tag="div"
              vid="macronutrients.fats"
              rules="required|min_value:0|between:0,1000"
          >
            <input-measurement
                v-model="formData.macronutrients.fats"
                :max-value="9999"
                :step="0.01"
                :text="$t('message[\'general.fats\']')"
                :unit="$t('message[\'general.g\']')"
                :error-message="errors[0]"
                class="pc-container-smart-add-meal-log-form__input"
                @change="setFatsValue"
            />
          </validation-provider>

          <validation-provider
              v-slot="{ errors }"
              class="pc-container-smart-add-meal-log-form__input-wrapper"
              :name="firstLetterCaps($t('message[\'general.carbs\']'))"
              tag="div"
              vid="macronutrients.carbohydrates"
              rules="required|min_value:0|between:0,1000"
          >
            <input-measurement
                v-model="formData.macronutrients.carbohydrates"
                :max-value="9999"
                :step="0.01"
                :text="firstLetterCaps($t('message[\'general.carbs\']'))"
                :unit="$t('message[\'general.g\']')"
                :error-message="errors[0]"
                class="pc-container-smart-add-meal-log-form__input"
                @change="setCarbohydratesValue"
            />
          </validation-provider>

          <validation-provider
              v-slot="{ errors }"
              class="pc-container-smart-add-meal-log-form__input-wrapper"
              :name="firstLetterCaps($t('message[\'general.carbs-net\']'))"
              tag="div"
              vid="macronutrients.net_carbohydrates"
              rules="min_value:0|between:0,1000"
          >
            <input-measurement
                v-model="formData.macronutrients.net_carbohydrates"
                :max-value="9999"
                :step="0.01"
                :text="firstLetterCaps($t('message[\'general.carbs-net\']')) + ' '  + optionalText"
                :unit="$t('message[\'general.g\']')"
                :error-message="errors[0]"
                class="pc-container-smart-add-meal-log-form__input"
                @change="setNetCarbohydratesValue"
            />
          </validation-provider>

          <validation-provider
              v-show="!hideCalories"
              v-slot="{ errors }"
              :name="firstLetterCaps($tc('message[\'general.calories\']',2))"
              tag="div"
              vid="calories"
              rules="required|min_value:0|between:0,10000"
          >
            <input-measurement
                v-model="formData.calories"
                :max-value="99999"
                :step="0.01"
                :text="firstLetterCaps($tc('message[\'general.calories\']', 2))"
                :unit="$t('message[\'food-diary.unit-cal\']')"
                :error-message="errors[0]"
                class="pc-container-smart-add-meal-log-form__input"
                name="calories"
                @change="setCaloriesValue"
            />
          </validation-provider>

          <div class="pc-container-smart-add-meal-log-form__saved-area">
            <input-check-box-squared
                :id="1"
                v-model="saved"
            />
            <text-body-small line-height="lh-lg">
              {{ $t('message["food-diary.save-this-meal"]') }}
            </text-body-small>
          </div>

          <p
              v-if="error && !showSmartAdd"
              class="app-error-msg"
          >
            {{ error }}
          </p>
          <page-fixed-footer v-if="isActiveTab">
            <button-primary
                :text="state === 'submitting' ? '' : $tc('message[\'food-diary.log-meal-submit\']', 0, { count: 0 })"
                :disabled="state === 'submitting'||invalid"
                form="custom-log-form"
                type="submit"
            >
              <loading-buffer-custom v-if="state === 'submitting'"/>
            </button-primary>
          </page-fixed-footer>
        </form>
      </validation-observer>
    </page-container>
    <popup-food-dairy-submitting
        :visible="showPopupLogSubmitting"
        @close="showPopupLogSubmitting=false"
    />
  </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import FoodDiaryService from '../../../../../services/FoodDiaryService'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import ButtonSecondaryBackward from '../../../../global/buttons/ButtonSecondaryBackward'
import FoodDiaryDaySlider from './FoodDiaryDaySlider'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import TextInput from '../../../../global/inputs/TextInput'
import InputMeasurement from '../../../../global/inputs/InputMeasurement'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import logCustomMealMixin from '../../../../../mixins/component-mixins/page/food-diary/logCustomMealMixin'
import FoodDiaryImageInput from './FoodDiaryImageInput'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import NutritionDetailsBar from '../../../../global/bars/BarNutritionDetails'
import NavigationMealsAdd from './NavigationMealsAdd'
import PageFixedFooter from '../../../../global/pages/PageFixedFooter'
import InputCheckBoxSquared from '../../../../global/inputs/InputCheckBoxSquared'
import GridRow from '../../../../global/grid/GridRow'
import GridCol from '../../../../global/grid/GridCol'
import LoadingBufferCustom from '../../global/widgets/LoadingBufferCustom'
import { getVisibilityOfCaloriesInNutrition } from '@/includes/TemplateSettings'
import ButtonPrimaryForwardChip from '../../../../global/buttons/ButtonPrimaryForwardChip'
import PopupTextToMeal from './PopupTextToMeal'
import PopupFoodDairySubmitting from './PopupFoodDairySubmitting.vue'
import CardNutritionAnalysis from './CardNutritionAnalysis'
import ChipPrimary from '../../../../global/chips/ChipPrimary'
import {enableSmartAdd} from '../../../../../includes/TemplateSettings'
import TextArea from "@/components/global/inputs/TextArea.vue";

export default {
  name: 'ContainerCustomMealLog',
  components: {
    TextArea,
    PopupFoodDairySubmitting,
    LoadingBufferCustom,
    GridCol,
    GridRow,
    InputCheckBoxSquared,
    PageFixedFooter,
    NavigationMealsAdd,
    PageContainer,
    NutritionDetailsBar,
    ButtonPrimary,
    TextBodySmall,
    TextBodyExtraSmall,
    ButtonSecondaryBackward,
    FoodDiaryDaySlider,
    LoadingBuffer,
    FoodDiaryImageInput,
    ValidationProvider,
    ValidationObserver,
    TextInput,
    InputMeasurement,
    ButtonPrimaryForwardChip,
    PopupTextToMeal,
    CardNutritionAnalysis,
    ChipPrimary
  },
  mixins: [logCustomMealMixin],
  props: {
    isActiveTab: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      mealIngredients: '',
      error: '',
      state: 'init',
      hideCalories: getVisibilityOfCaloriesInNutrition(),
      saved: [1],
      showPopupLogSubmitting: false
    }
  },
  computed: {
    optionalText: function () {
      if(this.$t('message[\'general.form.optional-with-brackets\']') !== '(Optional)'){
        return this.$t('message[\'general.form.optional-with-brackets\']')
      }

      return '('+this.$t('message[\'general.form.optional\']')+')'
    },
    macroNutrients: function () {
      let macroNutrients = []
      // to insert data to nutrition array
      const protein = this.mealLog.required.macronutrients.proteins
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.proteins\']'),
        qut: protein
      })
      const fat = this.mealLog.required.macronutrients.fats
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.fats\']'),
        qut: fat
      })
      const carbs = this.mealLog.required.macronutrients.carbohydrates
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.carbs\']'),
        qut: carbs
      })
      if (this.mealLog.required.macronutrients.show_net_carbs === true) {
        const netCarbs = this.mealLog.required.macronutrients.net_carbohydrates
        macroNutrients.push({
          unit: this.$i18n.t('message[\'general.g\']'),
          name: this.$i18n.t('message[\'general.carbs-net\']'),
          qut: netCarbs
        })
      }

      return macroNutrients
    },
    calories () {
      return [{
        unit: '',
        name: this.$i18n.tc('message[\'general.calories\']', this.mealLog.required.calories),
        qut: this.mealLog.required.calories
      }]
    }
  },
  watch: {
    mealIngredients(newVal, oldVal) {
      if (this.error && newVal !== oldVal) {
        this.clearError();
      }
    },
    saved: function () {
      this.formData.saved = this.saved.includes(1)
    },
    mealGenerationState: function (newVal) {
      if (newVal === 'init') {
        if (!this.error) {
          this.mealIngredients = ''
        }
      } else if (newVal === 'submitting') {
        this.showPopupLogSubmitting = true
      } else {
        this.showPopupLogSubmitting = false
      }
    }
  },
  mounted () {
    this.setMealPlanIDAndDays()
    this.newMealText = this.$i18n.t('message[\'food-diary.new-meal\']')
    this.formData.macronutrients.show_net_carbs = this.supportNetCarbs
    if (!this.supportNetCarbs) {
      this.formData.macronutrients.net_carbohydrates = 0
    }
    this.service = new FoodDiaryService()
  },
  methods: {
    changeTab (name) {
      this.$emit('change-tab', name)
    },
    navigateBack () {
      this.state = 'init'
      this.mealGenerationState = 'init'
    },
    generate () {
      this.state = 'generating'
      this.getNutritionAnalysis(this.mealIngredients).then(() => {
        this.state = 'generated'
      }).catch((error) => {
        this.error = error
      })
    },
    clearError() {
      this.error = ''
    },
    setSuccess () {
      this.$emit('set-success', true)
    }
  }

}
</script>

<template>
  <page-secondary class="macroapp--page-habits-tracker-manager" :header="headerOptions">
    <PageHabitsTrackerManagerSkeleton  v-if="loading"/>
    <page-container class="container" v-else>
      <div class="gc-habits-tracker-manager">
        <div class="pc-habits-tracker-manager__habits-header">
          <text-body-small2 :weight="'extra-bold'">
            {{
              $t('message["habit-tracker.page-habits-tracker-manager-title"]')
            }}
          </text-body-small2>
          <div class="pc-habits-tracker-manager__habits-header-button">
            <text-body-extra-small
              :weight="'extra-bold'"
              v-on:click="handleAddNewHabit"
            >
              {{
                $t(
                  'message["habit-tracker.page-habits-tracker-manager-create-new"]'
                )
              }}
            </text-body-extra-small>
          </div>
        </div>
        <div class="pc-habits-tracker-manager__habits-list">
          <div
            v-if="habitsList.length === 0"
            class="pc-habits-tracker-manager__habits-list-empty"
          >
            <text-body-small>
              {{
                $t(
                  'message["habit-tracker.page-habits-tracker-manager-empty-list"]'
                )
              }}
            </text-body-small>
          </div>

          <div v-else class="pc-habits-tracker-manager__habits-list-wraper">
            <div
              class="pc-habits-tracker-manager__habits-list-item"
              v-for="(habit, habitIndex) in habitsList"
              :key="habitIndex"
            >
              <text-body-small>{{ habit.name }}</text-body-small>
              <div
                class="pc-habits-tracker-manager__habits-list-item-option actions"
                v-on:click="handleShowHabitOptionsMenu($event, habit)"
                style="cursor: pointer; position: relative"
              >
                <IconMoreHorizontal style="pointer-events: none" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="suggestionsList.length > 0"
        class="pc-habits-tracker-manager__list-recommended"
      >
        <div class="pc-habits-tracker-manager__list-recommended-header">
          <text-body-small2 :weight="'extra-bold'">
            {{
              $t(
                'message["habit-tracker.page-habits-tracker-manager-recommended-title"]'
              )
            }}
          </text-body-small2>
        </div>
        <div
          class="pc-habits-tracker-manager__list-recommended-item"
          v-for="(
            suggestion, habitIndex
          ) in habitTracker.getRecommendedHabits()"
          :key="habitIndex"
        >
          <div
            class="pc-habits-tracker-manager__list-recommended-item-add actions"
            v-on:click="handleAcceptRecommendedHabit(suggestion)"
          >
            <icon-add-more />
          </div>
          <div>
            <text-body-small>{{ suggestion.habit }}</text-body-small>
          </div>
        </div>
      </div>
      <page-fixed-footer class="gc-habits-tracker-manager">
        <!-- <button-primary
          class="gc-habits-tracker-manager-button-save"
          type="button"
          :text="$t('message[\'workout-log.save-text\']')"
          @click="handleSaveHabits()"
        /> -->
      </page-fixed-footer>
    </page-container>
    <popup-habits-add
      v-if="popupsState.add"
      :visible="popupsState.add"
      :habitsList="habitsList"
      @close="closePopup"
      @onAddCb="onAddSuccess"
    />
    <popup-habits-edit
      v-if="popupsState.edit"
      :visible="popupsState.edit"
      :passedHabit="popupHabit"
      :habitsList="habitsList"
      @close="closePopup"
      @onEditCb="onEditSuccess"
    />
    <popup-habits-delete
      v-if="popupsState.delete"
      :visible="popupsState.delete"
      :habitId="popupHabit"
      @close="closePopup"
      @onDeleteCb="onDeleteSuccess"
    />
    <popup-habits-accept-suggestion
      v-if="popupsState.accept"
      :visible="popupsState.accept"
      :suggestion="popupHabit"
      @close="closePopup"
      @onSuccessAcceptCb="onAcceptSuccess"
    />
    <popup-habit-options-menu
      v-if="!!optionsMenu.habit"
      :parent="optionsMenu.parent"
      :habit="optionsMenu.habit"
      @onClose="hideOptionsMenu"
      @onEdit="handleEditHabit"
      @onDelete="handleDeleteHabit"
    />
  </page-secondary>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import DashboardService from "@/services/DashboardService";
import { pageReadyEvent } from "@/helpers/dom/events/customEvents";
import pageMixin from "../../../../mixins/pageMixin";
import HabitsService from "../../../../services/habits-tracker/HabitsTrackerService";
import BaseComponent from "../global/base/BaseComponent";
import ButtonPrimary from "../../../global/buttons/ButtonPrimary";
import PageSecondary from "../../../global/pages/PageSecondary";
import PageContainer from "../../../global/pages/page-sub-components/PageContainer";
import PageContainerFluid from "../../../global/pages/page-sub-components/PageContainerFluid";
import CardHabitsList from "../../../global/cards/CardHabitsList";
import headerEventBus from "@/event-buses/headerEventBus";
import TextBodySmall2 from "../../../global/typography/TextBodySmall2";
import TextBodyExtraSmall from "../../../global/typography/TextBodyExtraSmall.vue";
import TextBodySmall from "../../../global/typography/TextBodySmall";
import IconMoreHorizontal from "../../../root/icons/IconMoreHorizontal";
import IconAddMore from "../../../root/icons/IconAddMore";
import PopupHabitsAdd from "../habits-tracker/page-components/PopupHabitsAdd.vue";
import PopupHabitsEdit from "../habits-tracker/page-components/PopupHabitsEdit.vue";
import PopupHabitOptionsMenu from "../habits-tracker/page-components/PopupHabitOptionsMenu.vue";
import PopupHabitsDelete from "../habits-tracker/page-components/PopupHabitsDelete.vue";
import PopupHabitsAcceptSuggestion from "../habits-tracker/page-components/PopupHabitsAcceptSuggestion.vue";
import PageFixedFooter from "@/components/global/pages/PageFixedFooter";
import DashBoardService from "@/services/DashboardService";
import PageHabitsTrackerManagerSkeleton from "./PageHabitsTrackerManagerSkeleton.vue";

export default {
  name: "PageHabitsTrackerManager",
  components: {
    CardHabitsList,
    PageContainerFluid,
    PageContainer,
    PageSecondary,
    ButtonPrimary,
    TextBodySmall2,
    TextBodyExtraSmall,
    TextBodySmall,
    IconMoreHorizontal,
    IconAddMore,
    PopupHabitsAdd,
    PopupHabitsEdit,
    PopupHabitsDelete,
    PopupHabitOptionsMenu,
    PopupHabitsAcceptSuggestion,
    PageFixedFooter,
    PageHabitsTrackerManagerSkeleton
  },
  extends: BaseComponent,
  mixins: [pageMixin],
  data() {
    return {
      loading: true,
      headerOptions: {
        show: true,
        left: "",
        right: "close",
        rightDisabled: false,
        modifiers: ["with-bg-color", "small-title"],
        header: {
          //HT-TODO add i18n title for manage page
          mainTitle: this.$i18n.t('message["habit-tracker.header-manage"]'),
          subTitle: "",
        },
        mainTitle: this.$i18n.t('message["habit-tracker.header-manage"]'),
      },
      popupsState: {
        add: false,
        edit: false,
        delete: false,
        accept: false,
      },
      /** either a habit for editing | accepting or habitId for deleteing */
      popupHabit: null,
      optionsMenu: {
        habit: null,
        parent: null,
      },
      pageClass: ["macroapp--page-habits-tracker-manager"],
      habitTracker: new HabitsService(),
    };
  },
  computed: {
    ...mapGetters({
      headerBackButtonClick: "pageStore/getBackButtonClick",
    }),
    habitsList() {
      return this.habitTracker.getHabits();
    },
    suggestionsList() {
      return this.habitTracker.getRecommendedHabits();
    },
  },
  watch: {
    headerBackButtonClick: function () {
      this.navigatePrevious();
    },
  },
  created() {
    this.setPageTitle(this.$t('message["habit-tracker.header-manage"]'));
  },
  beforeMount() {
    if (!this.isEnabled()) {
      this.$router.replace({ path: "/dashboard" });
    }
    this.showLoading();
  },
  mounted() {
    headerEventBus.$on("close-button-click", this.handleCloseManager);
    this.headerOptions.header.mainTitle = this.$i18n.t(
      'message["habit-tracker.header-manage"]'
    );
    this.setPageClass();
    Promise.all([this.setHabits(), this.setRecommendedHabits()]).finally(
      () => {
        this.loading = false;
        this.hideLoading();
      }
    );

    this.setHeader(this.headerOptions);
    pageReadyEvent();
  },
  beforeDestroy() {
    headerEventBus.$off("close-button-click", this.handleCloseManager);
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: "showSubscriptionExpiredPopup",
    }),
    /** Load recommended habits to a service */
    setRecommendedHabits() {
      return this.habitTracker.setRecommendedHabits()
    },
    /** Load member's habits to a service */
    setHabits() {
      return  this.habitTracker.setHabits()
    },
    isEnabled() {
      const dashboard = new DashboardService();
      return dashboard.hasHabitTrackerEnabled();
    },
    navigatePrevious() {
      this.showLoading();
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.habitsTracker,
      });
    },
    /** Open a popup by changing corresponding state, opens 3 popups: add, edit, delete, 2 of which require habit id
     * @param {'add' | 'edit' | 'delete' | 'accept' } type of a popup to open
     * @param {(Habit | String) =} habitInfo is a Habit in case of type = "edit | accept" and habitId in case of type = "delete"
     */
    openPopup(type, habitInfo) {
      if (habitInfo.habitSource === "creator")
        return console.error(`Cannot edit creator's habits`);

      if (Object.keys(this.popupsState).some((type) => this.popupsState[type]))
        return console.error(`Some popup is already open`);
      if (this.popupsState[type])
        return console.error(`Popup ${type} is already open`);
      if (type !== "add" && !habitInfo)
        return console.error(`Habit is required for ${type} popup`);

      console.log("open popup => " + type, habitInfo);
      this.popupHabit = habitInfo ?? null;
      this.popupsState[type] = true;
      console.log("open popup => " + type, " ...done");
    },
    /** Reset all popups state, i.e. close any popup */
    closePopup() {
      this.popupHabit = null;
      this.popupsState = {
        add: false,
        edit: false,
        delete: false,
        accept: false,
      };
    },
    checkAndRemoveSuggestionCopy(habitName) {
      const suggestionsHasCopy = this.suggestionsList.some(
        (suggestion) =>
          suggestion.habit.trim().toLowerCase() ===
          habitName.trim().toLowerCase()
      );
      if (!suggestionsHasCopy) return;
      this.habitTracker.deleteSuggestedHabitInService(habitName);
    },
    /** Callback for a corresponging popup to update local state */
    onAddSuccess(newHabit) {
      this.habitTracker.addNewHabitToService(newHabit);
      this.checkAndRemoveSuggestionCopy(newHabit.name);
      // Trigger the HabitTracker.HabitCreated event
      this.logEvent("HabitTracker.HabitCreated", {
        type: "custom",
        context: "manage-habits",
      });
    },
    /** Callback for a corresponging popup to update local state */
    onEditSuccess(newHabit) {
      this.habitTracker.updateHabitInService(this.popupHabit.id, newHabit);
      this.checkAndRemoveSuggestionCopy(newHabit.name);
    },
    /** Callback for a corresponging popup to update local state */
    onDeleteSuccess(habitId) {
      this.habitTracker.deleteHabitInService(habitId);
    },
    onAcceptSuccess(newHabit) {
      this.habitTracker.addNewHabitToService(newHabit);
      this.habitTracker.deleteSuggestedHabitInService(newHabit.name);

      // Trigger the HabitTracker.HabitCreated event for a suggestion
      this.logEvent("HabitTracker.HabitCreated", {
        type: "suggestion",
        context: "manage-habits",
      });
    },
    // onProgressCheckInSuccess(habit) {
    //   // Trigger the HabitTracker.HabitCreated event for progress-check-in
    //   this.logEvent("HabitTracker.HabitCreated", {
    //     type: habit.isCustom ? "custom" : "suggestion",
    //     context: "progress-check-in",
    //   });
    // },
    /** Show habit optios menu by changing its state and setting up parent reference */
    showOptionsMenu(event, habit) {
      this.optionsMenu.isOpened = true;
      this.optionsMenu.habit = habit;
      this.optionsMenu.parent = event.target;
    },
    /** Reset options menu state to close it */
    hideOptionsMenu() {
      this.optionsMenu.isOpened = false;
      this.optionsMenu.habit = null;
      this.optionsMenu.parent = null;
    },
    /**
     *
     * BUTTONS HANDLERS
     *
     */
    handleAddNewHabit() {
      let dashboard = new DashBoardService();
      if(!dashboard.isEntriesEditable()) {
        this.showSubscriptionExpiredPopup("habitTracker_select_status");
        return console.log("Entries are not editable");
      }
      this.openPopup("add", "member");
    },
    handleEditHabit() {
      let dashboard = new DashBoardService();
      if(!dashboard.isEntriesEditable()) {
        this.showSubscriptionExpiredPopup("habitTracker_select_status");
        return console.log("Entries are not editable");
      }
      this.openPopup("edit", this.optionsMenu.habit);
    },
    handleDeleteHabit() {
      let dashboard = new DashBoardService();
      if(!dashboard.isEntriesEditable()) {
        this.showSubscriptionExpiredPopup("habitTracker_select_status");
        return console.log("Entries are not editable");
      }
      this.openPopup("delete", this.optionsMenu.habit.id);
    },
    handleAcceptRecommendedHabit(suggestion) {
      let dashboard = new DashBoardService();
      if(!dashboard.isEntriesEditable()) {
        this.showSubscriptionExpiredPopup("habitTracker_select_status");
        return console.log("Entries are not editable");
      }
      console.log("SUGG => ", this.habitTracker.getRecommendedHabits());
      this.openPopup("accept", suggestion);
    },
    handleShowHabitOptionsMenu(event, habit) {
      this.showOptionsMenu(event, habit);
    },
    handleCloseManager() {
      this.showLoading();
      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: "/dashboard",
        query: { page: "habit-tracker" },
      });
    },
  },
};
</script>

<template>
  <page-secondary
    :header="headerOptions"
    class="pc-food-dairy-log-meals"
  >
    <div v-if="!mealLog">
      <PageFoodDiarySkeleton />
    </div>
    <page-container
      v-if="mealLog"
      class="pc-container-meal-plan-meal__custom-meal-top-container custom-meal pc-container__remaining-ntr">
      <text-body-extra-small class="pc-food-dairy-log-meals__remaining-ntr">
        {{ $t('message[\'food-diary.remaining-for-the-day\']') }}
      </text-body-extra-small>

      <nutrition-details-bar
        :calories="remainingMacros.calories"
        :macro-nutrients="remainingMacros.macronutrients"
      />
    </page-container>
    <transition name="fade">
      <navigation-meals-add
        :active-tab="currentTab"
        @change-tab="changeTab($event)"
      />
    </transition>

    <container-meal-plan-meal-log
      v-show="currentTab === 'meal-plan'"
      v-if="mealLog"
      :is-active-tab="currentTab === 'meal-plan'"
      :meal-log="mealLog"
      @log="emitLog"
      @success="setSuccess"
      @change-tab="changeTab($event)"
    />
    <container-smart-add
      v-show="currentTab === 'smart-add'"
      v-if="mealLog"
      :is-active-tab="currentTab === 'smart-add'"
      :support-net-carbs="showNetCarbs"
      @success="setSuccess($event)"
      :meal-log="mealLog"
      @change-tab="changeTab($event)"
    />
    <container-custom-meal-log
      v-show="currentTab === 'new-meal'"
      v-if="mealLog"
      :is-active-tab="currentTab === 'new-meal'"
      :support-net-carbs="showNetCarbs"
      :meal-log="mealLog"
      @success="setSuccess($event)"
      @log="emitLog($event)"
      @change-tab="changeTab($event)"
    />
    <container-dine-out-meal-log
      v-show="currentTab === 'dine-out'"
      v-if="mealLog"
      :is-active-tab="currentTab === 'dine-out'"
      :support-net-carbs="showNetCarbs"
      :meal-log="mealLog"
      @success="setSuccess($event)"
      @log="emitLog($event)"
      @change-tab="changeTab($event)"
      @meal-erased="dineOutMealSaved"
      @meal-saved="dineOutMealSaved"
    />
    <container-saved-meals
      v-show="currentTab === 'saved-meals'"
      v-if="enableSavedMeals && mealLog"
      :is-active-tab="currentTab === 'saved-meals'"
      :meal-log="mealLog"
      @log="emitLog($event)"
      @success="setSuccess($event)"
      @change-tab="changeTab($event)"
    />
    <popup-food-dairy-success-save
      v-if="popupLogSuccess.visible"
      :visible="popupLogSuccess.visible"
      :saved="popupLogSuccess.saved"
      @close="mealLogSuccess"
    />

  </page-secondary>
</template>

<script>
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import ContainerCustomMealLog from './page-components/ContainerCustomMealLog'
import ContainerMealPlanMealLog from './page-components/ContainerMealPlanMealLog'
import ContainerSavedMeals from './page-components/ContainerSavedMeals'
import PopupFoodDairySuccessSave from './page-components/PopupFoodDairySuccessSave'
import pageLogMealsMixin from '../../../../mixins/component-mixins/page/food-diary/pageLogMealsMixin'
import ContainerDineOutMealLog from './page-components/ContainerDinningOutMealLog'
import NavigationMealsAdd from './page-components/NavigationMealsAdd.vue'
import ContainerSmartAdd from './page-components/ContainerSmartAdd.vue'
import NutritionDetailsBar from "@/components/global/bars/BarNutritionDetails.vue";
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall.vue";
import PageFoodDiarySkeleton from "./PageFoodDiarySkeleton.vue";

export default {
  name: "PageLogMeals",
  components: {
    TextBodyExtraSmall,
    NutritionDetailsBar,
    ContainerDineOutMealLog,
    NavigationMealsAdd,
    ContainerSmartAdd,
    PopupFoodDairySuccessSave,
    ContainerSavedMeals,
    PageContainer,
    PageSecondary,
    ContainerMealPlanMealLog,
    ContainerCustomMealLog,
    PageFoodDiarySkeleton,
  },
  mixins: [pageLogMealsMixin],
};
</script>

import DashBoardService from '../../../../services/DashboardService'
import SignUpService from '@/services/sign-up/SignUpService'
import * as Sentry from "@sentry/browser";
import LanguageService from '../../../../services/LanguageService'
export default {
  data: function () {
    return {
      headerOptions: {
        show: false
      },
      popupPaymentProcessing: {
        show: false
      },
      redirected: false,
      subscriptionID: null,
      userData: null,
      userName: '',
      introDescriptionText: '',
      needSubscriptionSetup: true,
      upsellDetails: [],
      pricingPlanUuid: null,
      plan: {},
      isLoadedUpsellForm: false,
      isError: false,
      finalRedirect: '',
      loadingState: 'init',
      mainPlanDetails: {}
    }
  },
  methods: {
    async confirmPayment(clientSecret, paymentMethodId) {
      let lang = new LanguageService();
      const stripKey = sessionStorage.getItem('publishable_key')
      const accountID = sessionStorage.getItem('account_id')

      window.onbeforeunload = null;
      window.removeEventListener('beforeunload', this.beforeunload)

      let stripe = Stripe(stripKey, {
        locale: lang.getLangForStripe(),
        stripeAccount: accountID
      });

      stripe
        .confirmPayment({
          clientSecret,
          confirmParams: {
            return_url: `${window.location.origin}${this.$router.resolve({
                path: this.$appConfig.appUrlList.upsellConfirmPayment.replace(':sid', this.subscriptionID),
                query: {
                    plan: this.plan.pricing_plan_uuid,
                    redirect: this.finalRedirect
                }
            }).href}`
          }
        })
        .then(function(result) {
          if (result.error) {
            console.error("Payment failed:", result.error)
          } else {
            console.log("Payment successful:", result.paymentIntent);
            window.onbeforeunload = null;
            window.removeEventListener('beforeunload', this.beforeunload)
            this.logEvent('Checkout.OneTimeOfferPurchased', {
              'pricing_plan_id': this.mainPlanDetails.plan_id,
              'pricing_plan_name': this.mainPlanDetails.plan_name,
              'price': this.upsellDetails.currency + ' ' +this.upsellDetails.price
            })
            this.$router.replace({ path: this.$appConfig.appUrlList.upsellConfirmPayment.replace(':sid', this.subscriptionID)})
          }
        }).finally(() => {
          this.loadingState = 'init';
        });
    },



    validateConfirmedPayment (paymentIntentID) {
      // send success payment indent id to API
      this.service.confirmPaymentViaStripe(this.subscriptionID, paymentIntentID).then(() => {
        // success
        this.goToThankYouPage()
      }).catch(err => {
        console.log(err)
      })
    },
    async payment () {
      try {
        this.loadingState = 'submitting'
        this.isError =  false
        let payload = {
          'payment_method_ref': sessionStorage.getItem('payment_method_id')
        }
        const signUpService = new SignUpService(this.plan.pricing_plan_uuid)
        await signUpService.payUpsellProduct(this.upsellDetails.plan_uuid, payload)

        sessionStorage.removeItem('payment_method_id')
        this.logEvent('Checkout.OneTimeOfferPurchased', {
          'pricing_plan_id': this.mainPlanDetails.plan_id,
          'pricing_plan_name': this.mainPlanDetails.plan_name,
          'price': this.upsellDetails.currency + ' ' +this.upsellDetails.price
        })
        this.withoutLoadUpsellRedirectionToNext()
        this.loadingState = 'init'
      } catch (err) {
        if (err.response.status === 402) {
          if(this.beforeunload){
            window.removeEventListener('beforeunload', this.beforeunload)
          }

          this.confirmPayment(err.response.data.client_secret, sessionStorage.getItem('payment_method_id'))
          sessionStorage.setItem('successful_payment_for_event_log', JSON.stringify({
            pricing_plan_id: this.mainPlanDetails.plan_id,
            pricing_plan_name: this.mainPlanDetails.plan_name,
            price: this.upsellDetails.currency + ' ' + this.upsellDetails.price
          }))
        } else {
          this.isError =  true
          Sentry.captureMessage('Upsell API Error :' + err.response.data.error, 'debug')
          this.loadingState = 'init'
        }
      }
    },
    async getUpsellingDetails () {
      try {
        const paymentType = sessionStorage.getItem('payment_type')
        const paymentTypeFromStripe = sessionStorage.getItem('supports_direct_purchase') === 'true'
        const paymentMethodId = sessionStorage.getItem('payment_method_id')

        if (!paymentMethodId) {
          this.withoutLoadUpsellRedirectionToNext();
          return;
        }

        if (paymentType === 'sepa_debit' || !paymentTypeFromStripe) {
          this.withoutLoadUpsellRedirectionToNext();
          return;
        }

        const signUpService = new SignUpService(this.plan.pricing_plan_uuid)
        const response = await signUpService.getUpsellingDetails()


        if (paymentMethodId && response.data.upsells.length > 0) {
          const signUpService = new SignUpService(response.data.upsells[0].plan_uuid)
          const upsellresponse = await signUpService.getUpsellingDetails()

          this.mainPlanDetails = response.data
          this.upsellDetails = response.data.upsells[0]
          this.upsellDetails.price = upsellresponse.data.price
          this.upsellDetails.currency = response.data.currency
          this.isLoadedUpsellForm = true

          this.logEvent('Checkout.OneTimeOfferSeen', {
            'pricing_plan_id': this.mainPlanDetails.plan_id,
            'pricing_plan_name': this.mainPlanDetails.plan_name,
            'price': this.upsellDetails.currency + ' ' +this.upsellDetails.price
          })
        } else {
          this.withoutLoadUpsellRedirectionToNext()
        }
      }
      catch(err) {
        Sentry.captureMessage('Upsell API Error :' + err.message, 'debug')
      }
      finally {
        this.hideLoading()
      }


    },
    withoutLoadUpsellRedirectionToNext () {
      const selectedPlan = this.userData.subscriptions.find(plan => {
        return plan.id === this.subscriptionID
      })
      if (selectedPlan && (selectedPlan.has_workout_programs || selectedPlan.has_meal_plans)) {
        this.$router.replace({ path: this.$appConfig.appUrlList.subscriptionSettingsStep.replace(':subscription', this.subscriptionID).replace(':form', 1), query: { from: 'thank-you' } })
      } else {
        this.$router.replace({ path: this.$appConfig.appUrlList.dashboard })
      }
    },
    addFinalRedirect (selectedPlan) {
      if (selectedPlan && (selectedPlan.has_workout_programs || selectedPlan.has_meal_plans)) {
        this.finalRedirect = 'on-boarding'
      } else {
        this.finalRedirect = 'dashboard'
      }
    },
    async loadUserData () {
      let dashBoardService = new DashBoardService()
      this.userData = await dashBoardService.getUserData(true)
      this.userName = this.userData.first_name
      const selectedPlan = this.userData.subscriptions.find(plan => {
        return plan.id === this.subscriptionID
      })
      console.log('selectedPlan', selectedPlan)

      this.addFinalRedirect(selectedPlan)
      this.needSubscriptionSetup= (selectedPlan && (selectedPlan.has_workout_programs || selectedPlan.has_meal_plans))
      console.log('needSubscriptionSetup', this.needSubscriptionSetup)
      this.plan = selectedPlan

      if (this.plan) {
        await this.getUpsellingDetails();
      } else {
        setTimeout(() => {
          this.loadUserData()
        }, 3000)
      }

    },
    async goToNextPage () {
      try {
        this.showLoading()
        await this.loadUserData()
        const selectedPlan = this.userData.subscriptions.find(plan => {
          return plan.id === this.subscriptionID
        })
        if (selectedPlan && (selectedPlan.has_workout_programs || selectedPlan.has_meal_plans)) {
          this.$router.replace({ path: this.$appConfig.appUrlList.subscriptionSettingsStep.replace(':subscription', this.subscriptionID).replace(':form', 1), query: { from: 'thank-you' } })
        } else {
          this.$router.replace({ path: this.$appConfig.appUrlList.dashboard })
        }
      } catch (error) {

      } finally {
        this.hideLoading()
      }

    }
  }

}

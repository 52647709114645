import FoodDiaryAPI from './api/meal-plan/FoodDiaryAPI'
import NutritionAnalysisApi from './api/meal-plan/NutritionAnalysisAPI'
import { showFoodDairy } from '../includes/TemplateSettings'
import moment from 'moment'
import APIErrorHandler from './api-handler/APIErrorHandler'
import UserEventsService from './UserEventsService'

export default class FoodDiaryService {
  _api = {}
  _nutritionAnalysisApi= {}

  constructor () {
    if (!FoodDiaryService.instance) {
      FoodDiaryService.instance = this
      this._api = new FoodDiaryAPI()
      this._nutritionAnalysisApi = new NutritionAnalysisApi()
    }
    return FoodDiaryService.instance
  }

  checkEnabled () {
    return showFoodDairy()
  }

  getPaginateData (data) {
    return this._api.getLogPaginatedData(data)
  }

  getTodayLogFromAPI () {
    return this._api.searchLog({ date: moment().locale('en').format('YYYY-MM-DD') })
      .then(data => data.data)
      .then(data => data.log)
  }

  getLogByID (id) {
    return this._api.getLogByID(id)
  }

  saveRecipe (payload) {
    return new Promise((resolve, reject) => {
      this._api.saveRecipe(payload).then(data => {
        resolve(data.data)
      }).catch(err => {
        let sentryEventID = APIErrorHandler.logSentry(err)
        APIErrorHandler.handleInternalServerError(err.response, sentryEventID)
        reject(err)
      })
    })
  }

  saveMeal (payload) {
    return new Promise((resolve, reject) => {
      this._api.saveMeal(payload).then(data => {
        resolve(data.data)
      }).catch(err => {
        let sentryEventID = APIErrorHandler.logSentry(err)
        APIErrorHandler.handleInternalServerError(err.response, sentryEventID)
        reject(err)
      })
    })
  }

  removeMealFromLog (logID, mealID) {
    return new Promise((resolve, reject) => {
      this._api.removeMealFromLog(logID, mealID).then(data => {
        resolve(data.data)
      }).catch(err => {
        let sentryEventID = APIErrorHandler.logSentry(err)
        APIErrorHandler.handleInternalServerError(err.response, sentryEventID)
        reject(err)
      })
    })
  }

  saveMealLog (payload) {
    payload.date = payload.logged_date
    payload.custom_meals = []
    payload.dine_out_meals = []
    payload.meals = []
    payload.logged_meals.forEach(meal => {
      if (meal.meal_type === 'dine-out' || meal.mealType === 'dine-out') {
        payload.dine_out_meals.push(meal)
      } else if (meal.meal_type === 'custom' || meal.mealType === 'custom') {
        payload.custom_meals.push({id: meal.id, time: meal.time, date: meal.date})
      } else if (meal.meal_type === 'platform' || meal.mealType === 'platform') {
        payload.meals.push(meal)
      }
    })
    return new Promise((resolve, reject) => {
      this._api.saveLog(payload).then(data => {
        UserEventsService.foodDairyFeedBackPopupView(payload.logged_date)
        resolve(data.data)
      }).catch(err => {
        if (err.response.status !== 401) {
          let sentryEventID = APIErrorHandler.logSentry(err)
          APIErrorHandler.showErrorPopup(sentryEventID)
        }
        let sentryEventID = APIErrorHandler.logSentry(err)
        APIErrorHandler.handleInternalServerError(err.response, sentryEventID)
        reject(err)
      })
    })
  }

  getCurrentNutrientTotalsOfMeals (meals) {
    let emptyNutrients = {
      'calories': 0,
      'macronutrients': {
        'fats': 0,
        'carbohydrates': 0,
        'show_net_carbs': false,
        'proteins': 0,
        'net_carbohydrates': 0

      }
    }
    return meals.reduce((accumulator, currentValue) => {
      let totals = null
      if (currentValue.meal_type === 'custom' || currentValue.mealType === 'custom' || currentValue.mealType === 'dine-out') {
        if (!currentValue.recipes) {
          totals = emptyNutrients
        } else {
          totals = currentValue.recipes.reduce((subAccumulator, subCurrentValue) => {
            return this.addNutrients(subAccumulator, subCurrentValue)
          }, emptyNutrients)
        }
      } else {
        totals = {
          'calories': currentValue.calories,
          'macronutrients': currentValue.macronutrients
        }
      }

      return this.addNutrients(accumulator, totals)
    }, emptyNutrients)
  }

  getRemainingNutrientTotalsOfMeals(required,current){
    const diff = {};

    for (const key in required) {
      if (typeof required[key] === 'object' && typeof current[key] === 'object') {
        const nestedDiff = this.getRemainingNutrientTotalsOfMeals(required[key], current[key]);
        if (Object.keys(nestedDiff).length > 0) {
          diff[key] = nestedDiff;
        }
      } else if (required[key] !== current[key]) {
        diff[key] = required[key] - current[key];
      }
    }

    return diff;

  }
  addNutrientsOfRecipes (recipes) {
    let emptyNutrients = {
      'calories': 0,
      'macronutrients': {
        'fats': 0,
        'carbohydrates': 0,
        'show_net_carbs': false,
        'proteins': 0,
        'net_carbohydrates': 0

      }
    }
    return recipes.reduce((subAccumulator, subCurrentValue) => {
      return this.addNutrients(subAccumulator, subCurrentValue)
    }, emptyNutrients)
  }

  addNutrients (first, second) {
    return {
      'calories': (first.calories + second.calories),
      'macronutrients': {
        'fats': (first.macronutrients.fats + second.macronutrients.fats),
        'show_net_carbs': (first.macronutrients.show_net_carbs && second.macronutrients.show_net_carbs),
        'carbohydrates': (first.macronutrients.carbohydrates + second.macronutrients.carbohydrates),
        'proteins': (first.macronutrients.proteins + second.macronutrients.proteins),
        'net_carbohydrates': (first.macronutrients.net_carbohydrates + second.macronutrients.net_carbohydrates)
      }
    }
  }

  emptyLog () {
    return {
      '_id': null,
      'logged_date': moment().locale('en').format('YYYY-MM-DD'),
      'required': {
        'calories': 0,
        'macronutrients': {
          'fats': 0,
          'carbohydrates': 0,
          'show_net_carbs': false,
          'proteins': 0,
          'net_carbohydrates': 0
        }
      },

      'logged_meals': []
    }
  }

  getNutritionAnalysis (payload) {
    return new Promise((resolve, reject) => {
      this._nutritionAnalysisApi.getNutritionAnalysis(payload).then(data => {
        resolve(data.data)
      }).catch(err => {
        //let sentryEventID = APIErrorHandler.logSentry(err)
       // APIErrorHandler.handleInternalServerError(err.response, sentryEventID)
        reject(err)
      })
    })
  }
}

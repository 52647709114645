<template>
  <div class="gc-chart-line" style="display: flex;    align-items: center;  flex-direction: row-reverse;">



    <div style="display: flex;position: relative;  ">
      <div style="transform: translateX(-8px) translateY(-0px);">
        <LineChart
          :chart-options="chartOptions2"
          :chart-data="chartData2"
          :chart-id="chartId+'axis'"
          :dataset-id-key="datasetIdKey"
          :css-classes="cssClasses"
          :width="40"
          :height="height+2"
        />
      </div>
    </div>
    <div ref="wrapper" style="overflow-x: auto; ">
      <LineChart
        style="z-index: 99"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :css-classes="cssClasses"
        :width="1200"
        :height="height"
      />
    </div>

    </div>

</template>

<script>
import { Line as LineChart } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from "moment";


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

export default {
  name: 'ChartLine',
  components: {LineChart},
  props: {
    measurement:{
      type: String,
      default: ''
    },
    dataSets: {type: Array, default: () => []},
    chartId: {type: String, default: 'line-chart'},
    datasetIdKey: {type: String, default: 'time'},
    width: {type: Number, default: 400},
    height: {type: Number, default: 400},
    cssClasses: {type: String, default: ''},
    styles: {type: Object, default: () => ({})}
  },
  watch:{
    dataSets:{
      handler(){
        this.chartData.datasets = [];
        this.chartData2.datasets=[]
        this.dataSets.forEach(set=>{
          this.chartData.datasets.push(set)
          this.chartData2.datasets.push({...set , borderColor: 'transparent',backgroundColor: 'transparent',borderWidth:0})
        })

      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            backgroundColor: '#f87979',
            borderColor: '#f87979',
            data: [
            ]
          }
        ]
      },      chartData2: {
        datasets: [
          {
            backgroundColor: '#f87979',
            borderColor: '#f87979',
            data: [
            ]
          }
        ]
      },
      chartOptions2: {
        layout:{
          padding:{
            bottom : 30,
            left : 0,
            right : 0
          }
        },
        scales: {
          x: {
            ticks: {
              display: false,
              color: templateConfig.style_variables['$text-secondary-on-bg'],
            },
            grid: {
              display: false,
              drawTicks: false,
              color: templateConfig.style_variables['$text-secondary-on-bg'],
              borderColor: templateConfig.style_variables['$text-secondary-on-bg'],
              tickColor: templateConfig.style_variables['$text-secondary-on-bg']
            }
          },
          y: {
            grid:{
              display: false,
              drawBorder  : false,
              tickColor: templateConfig.style_variables['$text-secondary-on-bg'],
            },
            afterFit:(ctx)=>{
                ctx.width = 40
              const element = this.$refs['wrapper'];
              if (element) {
                element.scrollTo({
                  left: element.scrollWidth,
                  behavior: "smooth"
                });
                this.hideLoading();
              }
            },
            ticks: {
              stepSize: 5,
              color: templateConfig.style_variables['$text-secondary-on-bg'],
            },

            position : 'right',
          }
        },
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: this.customTooltip
          },
        },
      },

      chartOptions: {
        pointBackgroundColor:templateConfig.style_variables['$selected-state'],
        pointBorderColor:templateConfig.style_variables['$selected-state'],
        borderColor:templateConfig.style_variables['$selected-state'],

        yAxisID: 'y',
        layout:{
          padding:{
          }
        },
        scales: {
          x: {
            ticks:{
              color: templateConfig.style_variables['$text-secondary-on-bg'],
            },
            grid: {
              color: templateConfig.style_variables['$text-secondary-on-bg'],
              borderColor: templateConfig.style_variables['$text-secondary-on-bg'],
              tickColor: templateConfig.style_variables['$text-secondary-on-bg']
            },
            type: 'time',
            time: {
              unit: 'month'
            }
          },
          y: {
            grid: {
              color: templateConfig.style_variables['$text-secondary-on-bg'],
              borderColor: templateConfig.style_variables['$text-secondary-on-bg'],
              tickColor: templateConfig.style_variables['$text-secondary-on-bg']
            },
            ticks: {
              stepSize: 5,
              color: templateConfig.style_variables['$text-secondary-on-bg'],
              display: false,
            },
            position : 'right',
          }
        },
       responsive: false,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: this.customTooltip
          },
        },
      }
    };
  },
  methods: {
    customTooltip(context) {
      const {chart, tooltip} = context;
      let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.classList.add('tooltip');
        tooltipEl.style.background = templateConfig.style_variables['$text-area-bg-fill'];
        tooltipEl.style.color = templateConfig.style_variables['$text-secondary-on-bg'];
        tooltipEl.style.padding = '8px';
        tooltipEl.style.borderRadius = '4px';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.zIndex = '999';
        tooltipEl.style.transition = 'opacity 0.2s ease';
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }/**/
      const dateValue = moment(tooltip.dataPoints[0].raw.x).format('ll');
      const yValue = tooltip.dataPoints[0].formattedValue;
      const measure = this.measurement;
      const pbg = templateConfig.style_variables['$text-secondary-on-bg']
      tooltipEl.innerHTML = `
      <div>
      <div style="color:var(--text-primary-on-bg, #AFAFAF);font-size: 30px; font-weight: 700;">${yValue}  <span style="font-size: 18px; font-weight: 700;">${measure}</span></div>
      <div style="color:var(--text-secondary-on-bg, #AFAFAF); font-size: 12px; font-weight: 400;">${dateValue}</div>
      </div>
      `;

      const position = chart.canvas.getBoundingClientRect();

      tooltipEl.style.opacity = 1;
      console.log(tooltip)
      tooltipEl.style.left = (position.left + window.pageXOffset + tooltip.caretX) + 'px';
      if(screen.width/2<(position.left + window.pageXOffset + tooltip.caretX)){
        tooltipEl.style.left = (position.left + window.pageXOffset + tooltip.caretX - (tooltip.width/2) ) + 'px';
      }
      tooltipEl.style.top = position.top + window.pageYOffset + tooltip.caretY + 'px';
    }
  }
};
</script>

<style>
.tooltip {
  font-size: 12px;
  text-align: center;
}
</style>

<template>
  <!--   page background -->

  <page-secondary :header="headerOptions" class="pc-profile">
    <div>
      <!--   page wrapper -->
      <page-container class="pc-profile__page-container">
        <grid-row align-content="center">
          <grid-col>
            <div class="pc-profile__image-wrap">
              <div
                v-if="!profileImage"
                class="pc-profile__image"
                alt="Profile-image"
              >
                <icon-profile />
              </div>

              <image-base
                v-if="profileImage"
                :src="profileImage"
                class="pc-profile__image"
                alt="Profile-image"
              />
              <form enctype="multipart/form-data">
                <input
                  type="file"
                  ref="image-uploader"
                  style="display: none"
                  name="image"
                />
              </form>
              <text-body-extra-small class="pc-profile__input-error-message">
                {{ imageError }}
              </text-body-extra-small>
            </div>
          </grid-col>
        </grid-row>
        <grid-row>
          <grid-col md="6">
            <button-secondary-forward
              v-on:click="navigate('account-settings')"
              :text="$t('message[\'profile.account-settings\']')"
              class="pc-profile__navigation-settings"
            />
          </grid-col>
          <grid-col md="6">
            <button-secondary-forward
              :text="$t('message[\'form.change-password\']')"
              v-on:click="navigate('change-password')"
              class="pc-profile__navigation-password"
            />
          </grid-col>
          <grid-col
            md="6"
            v-show="enablePaymentUpdate"
            class="pc-profile__col-navigation-payment"
          >
            <button-secondary-forward
              v-on:click="navigate('payment-settings')"
              :text="paymentPageHeader"
              class="pc-profile__navigation-payment"
            />
          </grid-col>
          <grid-col
            md="6"
            v-if="mealPlansPairEnabled && myConnectionEnabledByPlan"
          >
            <button-secondary-forward
              v-on:click="navigate('my-connections')"
              :text="$t('message[\'profile.my-connections\']')"
              class="pc-profile__navigation-connections"
            />
          </grid-col>
          <grid-col md="6" v-if="showDietarySection">
            <button-secondary-forward
              v-on:click="navigate('dietary-preferences')"
              :text="$t('message[\'profile.dietary-preferences\']')"
              class="pc-profile__navigation-dietary"
            />
          </grid-col>
          <grid-col md="6" v-if="showFitnessSection">
            <button-secondary-forward
              v-on:click="navigate('fitness-preferences')"
              :text="$t('message[\'profile.fitness-preferences\']')"
              class="pc-profile__navigation-fitness"
            />
          </grid-col>
          <grid-col md="6" v-if="isShowWidgets">
            <button-secondary-forward
              v-if="isShowWidgets"
              v-on:click="navigate('manage-widgets')"
              :text="$t('message[\'profile.manage-widgets\']')"
              class="pc-profile__navigation-fitness"
            />
          </grid-col>
          <grid-col md="6" v-show="showPushNotifications">
            <button-secondary-forward
              v-on:click="navigateToPushNotifications()"
              :text="$t('message[\'push-notifications.title\']')"
              class="pc-profile__navigation-push-notifications"
            />
          </grid-col>

          <grid-col md="6" v-show="showConnectWearables">
            <button-secondary-forward
              :text="$t('message[\'connect-wearables.title\']')"
              class="pc-profile__navigation-wearables"
              v-on:click="navigateToConnectWearablesScreen()"
            />
          </grid-col>
        </grid-row>
      </page-container>
      <theme-change-popup
        :visible="themeChangePopupVisibility"
        v-on:close="themeChangePopupVisibility = false"
      />
      <popup-switch-dashboard-layout
        :visible="dashboardLayoutChangePopupVisibility"
        v-on:close="dashboardLayoutChangePopupVisibility = false"
      />
    </div>
  </page-secondary>
</template>

<script>
import BaseComponent from "../global/base/BaseComponent";
import ProfileService from "../../../../services/ProfileService";
import ThemeChangePopup from "../global/widgets/PopupThemeChange";
import PopupSwitchDashboardLayout from "../../../global/popups/PopupSwitchDashboardLayout";
import { isMobileNativeApp } from "../../../../includes/NativeAppCommon";
import PageSecondary from "../../../global/pages/PageSecondary";
import PageContainer from "../../../global/pages/page-sub-components/PageContainer.vue";
import IconProfile from "../../../root/icons/IconProfile";
import ImageBase from "../../../root/Image";
import ButtonSecondaryForward from "../../../global/buttons/ButtonSecondaryForward";
import TextBodyExtraSmall from "../../../global/typography/TextBodyExtraSmall";
import {
  enableMealDislikesFlag, enablePushNotificationCustomization,
  enableStepTracker,
  newDashboardLayout,
  showNewDashboardLayout
} from '@/includes/TemplateSettings'
import DashBoardService from '../../../../services/DashboardService'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import {pageReadyEvent} from '@/helpers/dom/events/customEvents'
import LoadingBuffer from "../global/widgets/LoadingBuffer";
import NativeAppService from "@/services/NativeAppService";
import PushNotificationsService from "@/services/PushNotificationsService";
import {isPWA} from "@/helpers/pwa";

export default {
  name: "Profile",
  extends: BaseComponent,
  mounted() {
    this.setHeader(this.headerOptions);
    this.setFitnessSectionVisibility();
    this.setDietarySectionVisibility();
    this.setProfileImage();
    this.setPageClass();
    this.setThemeChangeEnabled();
    this.setMealPlansPairEnabled();
    this.setMyConnectionEnabledByPlan();
    pageReadyEvent();
    this.checkWidgetsAvaiable();
  },
  components: {
    GridCol,
    GridRow,
    ThemeChangePopup,
    PageSecondary,
    PageContainer,
    IconProfile,
    ImageBase,
    ButtonSecondaryForward,
    TextBodyExtraSmall,
    PopupSwitchDashboardLayout,
    LoadingBuffer,
  },
  computed: {
    showPushNotifications: function () {
      const nativeApp = new NativeAppService()
      return this.hasPushNotificationGroups && ((isMobileNativeApp() && nativeApp.canSendPushNotifications() && nativeApp.deepLinking) || isPWA())
    },
    showBillingPage: function () {
      return true;
    },
    paymentPageHeader: function () {
      if (this.showBillingPage) {
        return this.$i18n.t("message['billing-info.page-header']");
      }
      return this.$i18n.t("message['profile.payment-settings']");
    },
    switchDashboardLayoutText: function () {
      if (this.isNewDashboardEnabled) {
        return this.$i18n.t("message['general.old-dashboard']");
      }
      return this.$i18n.t("message['general.new-dashboard']");
    },
    showDashboardLayoutChangeButton: function () {
      return newDashboardLayout();
    },
    showConnectWearables: function () {
      return enableStepTracker()
    },
    isNewDashboardEnabled: function () {
      return showNewDashboardLayout();
    },
  },
  data: function () {
    return {
      hasPushNotificationGroups: false,
      isShowWidgets: false,
      showManageWidgetsBuffer: false,
      themeChangePopupVisibility: false,
      dashboardLayoutChangePopupVisibility: false,
      imageError: "",
      profileImage: "",
      imageUrl: this.$appConfig.imagesUrl,
      pageClass: ["macroapp--page-profile"],
      themeChangeEnabled: false,
      showFitnessSection: false,
      showDietarySection: false,
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: "menu",
        right: "",
        rightBlink: false,
        modifiers: ["small-title"],
        mainTitle: this.$t('message["profile.title"]'),
        subTitle: "",
      },
      enablePaymentUpdate: true,
      mealPlansPairEnabled: false,
      myConnectionEnabledByPlan: false,
    };
  },
  created() {
    this.setPageTitle(this.$t('message["profile.title"]'));
    const pushService = new PushNotificationsService()
    pushService.getNotificationGroups().then(data => {
      const notificationGroups = data.groups

      this.hasPushNotificationGroups = notificationGroups.some((group) => {
        return !!group.enabled_notifications_count
      })

    })
  },
  methods: {
    async checkWidgetsAvaiable() {
      //Special Note: We only checked the period tracker for now. This setup can handle new widgets in the future.
      try {
        this.showManageWidgetsBuffer = true;
        const dashBoardService = new DashBoardService();
        const response = await dashBoardService.getUserConfig(true);
        await dashBoardService.getUserData();
        const isPeriodTrackerfeatureFlagEnabled =
          dashBoardService.isPeriodTrackEnabledByFeatureFlag();
        const isFemale =
          dashBoardService.userData.gender &&
          dashBoardService.userData.gender === "f";
        this.isShowWidgets = isPeriodTrackerfeatureFlagEnabled && isFemale;
      } catch (error) {
        console.log(error);
      } finally {
        this.showManageWidgetsBuffer = false;
      }
    },
    setMyConnectionEnabledByPlan() {
      const dashBoardService = new DashBoardService();
      this.myConnectionEnabledByPlan = dashBoardService.hasActivePlan();
    },
    setMealPlansPairEnabled() {
      let dashBoardService = new DashBoardService();
      dashBoardService.getUserData().then(() => {
        this.mealPlansPairEnabled = dashBoardService.isMealPlanPairEnabled();
      });
    },
    setFitnessSectionVisibility() {
      let dashBoardService = new DashBoardService();
      this.showFitnessSection = !!dashBoardService.isInjuriesSelectionEnabled();
    },
    setDietarySectionVisibility() {
      let dashBoardService = new DashBoardService();
      this.showDietarySection =
        !!enableMealDislikesFlag() ||
        dashBoardService.isIngredientDislikesEnabled();
    },
    setThemeChangeEnabled() {
      if (typeof $("meta[name=ma-app-mode]").attr("content") !== "undefined") {
        if ($("meta[name=ma-app-mode]").attr("content") === "spa") {
          this.themeChangeEnabled = false;
          return;
        }
      }
      if (this.checkNativeApp()) {
        this.themeChangeEnabled = false;
      } else if (
        templateConfig.instance_config &&
        "new_theme_welcome_popup" in templateConfig.instance_config
      ) {
        this.themeChangeEnabled =
          templateConfig.instance_config.new_theme_welcome_popup || false;
      } else {
        this.themeChangeEnabled = true;
      }
    },
    checkNativeApp() {
      return isMobileNativeApp();
    },
    showThemeChangePopup() {
      this.themeChangePopupVisibility = true;
    },
    navigateToConnectWearablesScreen () {
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.connectWearable
      })
    },
    switchDashboardTheme () {
      this.dashboardLayoutChangePopupVisibility = true;
    },
    navigate(page, section = null) {
      let query = {};
      if (section) {
        query = { page: page, section: section };
      } else {
        query = { page: page };
      }
      this.showLoading();
      let self = this;
      setTimeout(function () {
        self.$router.push({
          path: self.$route.path,
          query: query,
        });
      }, 100);
    },
    navigateToPushNotifications() {
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.pagePushNotifications,
      });
    },

    setProfileImage() {
      const profileService = new ProfileService();
      profileService
        .setProfileData()
        .then((data) => {
          this.profileImage = data.data.avatar.reference;
        })
        .finally((res) => {
          this.hideLoading();
        });
    },
    //depricated
    // setIAPMember: function () {
    //   if (checkSpecialUserTag()) {
    //     // check instance config for iap enabled
    //     this.enablePaymentUpdate = false
    //   }
    //   if (!this.showBillingPage) {
    //     if (this.checkNativeApp()) {
    //       this.enablePaymentUpdate = false
    //     }
    //   }
    // }
  },
  destroyed() {
    this.resetHeader();
    this.resetBackground();
  },
};
</script>

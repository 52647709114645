<template>
    <div></div>
</template>
<script>
import SignUpService from '@/services/sign-up/SignUpService'
export default {
    data() {
        return {
            isLoading: true
        }
    },
    mounted () {
        this.checkPaymentStatus()
    },
    methods: {
        async checkPaymentStatus(attempt = 1) {
            try {
                this.showLoading();
                const plan = this.$route.query.plan;
                const subscriptionId = this.$route.params.sid;
                const redirect = this.$route.query.redirect

                const signUpService = new SignUpService(plan);
                const response = await signUpService.checkSubscriptionStatus(subscriptionId);

                console.log(`Attempt ${attempt}:`, response);

                // Check if response.ready is true
                if (response.ready) {
                    let paymentData = sessionStorage.getItem('successful_payment_for_event_log');
                    if (paymentData) {
                        paymentData = JSON.parse(paymentData);
                        this.logEvent('Checkout.OneTimeOfferPurchased', paymentData);

                        sessionStorage.removeItem('successful_payment_for_event_log');
                    }
                    console.log("Payment is ready!");
                    this.hideLoading()
                    if (redirect === 'on-boarding') {
                        this.$router.replace({ path: this.$appConfig.appUrlList.subscriptionSettingsStep.replace(':subscription', subscriptionId).replace(':form', 1), query: { from: 'thank-you' } })
                    } else {
                        this.$router.replace({ path: this.$appConfig.appUrlList.dashboard })
                    }
                    return; // Stop further retries
                }

                // Retry up to 5 times
                if (attempt < 5) {
                    console.log(`Retrying in 2 seconds... (Attempt ${attempt + 1})`);
                    setTimeout(() => {
                        this.checkPaymentStatus(attempt + 1);
                    }, 2000); // 2-second delay
                } else {
                    console.log("Payment is still not ready after 5 attempts. Stopping.");
                    this.hideLoading()
                }
            } catch (error) {
                console.error("Error checking payment status:", error);
                this.hideLoading()
            }
        }
    }
}
</script>
<template>
    <page-secondary
      :header="headerOptions"
      :desktop-side-bar="false"
      class="pc-sign-up-complete"
    >
  
      <grid-row :no-gutters="true">
        <grid-col
          md="12"
          lg="12"
          class="pc-sign-up-complete__content-col"
        >
          <page-container class="pc-upsell__container">
            <text-content class="pc-popup-upsell__heading" :size="'md3'" :weight="'extra-bold'">{{ upsellDetails.title }}</text-content>
            <div class="pc-popup-upsell__card" v-if="isLoadedUpsellForm">
                <div class="pc-popup-upsell__image-container">
                    <img :src="upsellDetails.image">
                </div>
                <div class="pc-popup-upsell__description">
                    <text-content :size="'sm4'" :weight="'extra-bold'" :lineHeight="'multi'" class="pc-popup-upsell__sub-heading">{{ upsellDetails.subtitle }}</text-content>
                    <text-content :size="'sm2'" v-html="upsellDetails.description">
                    </text-content>
                    <hr>
                    <div class="pc-popup-upsell__pricing">
                        <text-content :size="'sm2'" :weight="'extra-bold'" :lineHeight="'multi'">{{upsellDetails.currency}} {{ formattedPrice }}&nbsp;</text-content>
                        <text-content :size="'sm1'" :lineHeight="'multi'">({{ $t('message["sign-up.plan-card.one-off.type"]') }})</text-content>
                    </div>
                    <button-primary
                        :text="$t('message[\'upsell.accept\']')"
                        :disabled="loadingState==='submitting'"
                        @click="payment()"
                    >
                    <icon-loading-buffer
                      v-if="loadingState==='submitting'"
                      class="pc-sign-up-payment__footer-button--spinner"
                      size="md1"
                    />
                    </button-primary>

                    <text-content v-if="loadingState!=='submitting'" class="pc-popup-upsell__reject" :weight="'extra-bold'" :size="'sm1'" :decoration="'underline'" @click="withoutLoadUpsellRedirectionToNext">{{ $t('message["upsell.reject"]') }}</text-content>
                    <text-content v-if="isError" class="pc-popup-upsell__error" :weight="'extra-bold'" :size="'sm3'">{{ $t('message["upsell.purchase.error"]') }}</text-content>    
                </div>
            </div>
          </page-container>
        </grid-col>
      </grid-row>
    </page-secondary>
  </template>
  
  <script>
  import CardSubscriptionCoverImage from '../../../global/cards/card-subscription-sub-components/CardSubscriptionCoverImage'
  import HeaderSubscription from '../../../global/headers/HeaderSubscription'
  import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
  import PageSecondary from '../../../global/pages/PageSecondary'
  import TextBodyRegular from '../../../global/typography/TextBodyRegular'
  import TextBodySmall from '../../../global/typography/TextBodySmall'
  import globalSignUpMixin from '../../../../mixins/component-mixins/page/sign-up/globalSignUpMixin'
  import PageFooter from '../../../global/pages/PageFixedFooter'
  import pageSignUpComplete from '../../../../mixins/component-mixins/page/sign-up/pageSignUpCompleteMixin'
  import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
  import GridRow from '../../../global/grid/GridRow'
  import GridCol from '../../../global/grid/GridCol'
  import { thankYouPageLoadedEvent } from '@/helpers/dom/events/customEvents'
  import { setMAApplicationData } from '@/helpers/dom/header'
  import AppService from '@/services/AppService'
  import { injectHTMLToBody } from '@/helpers/dom/body'
  import TextContent from '../../../root/TextContent'
  import IconLoadingBuffer from '@/components/root/icons/IconLoadingBuffer.vue'
  
  export default {
    name: 'PageSignUpComplete',
    components: {
      GridCol,
      GridRow,
      ButtonPrimary,
      PageFooter,
      PageSecondary,
      HeaderSubscription,
      CardSubscriptionCoverImage,
      PageContainer,
      TextBodyRegular,
      TextBodySmall,
      TextContent,
      IconLoadingBuffer
    },
    mixins: [globalSignUpMixin, pageSignUpComplete],
    data: function () {
      return {
        showFooter: false,
        showUpsellPopup: false
      }
    },
    async beforeMount () {
      this.introDescriptionText = this.$i18n.t('message["sign-up.tank-you.description"]')
      this.setImages()
      await this.loadUserData()
      
      this.subscriptionID = parseInt(this.$route.params.subscription)
      window.addEventListener('beforeunload', this.beforeunload)
      this.$nextTick(() => {
        this.showFooter = true
      })
  
      this.dispatchDOMContentLoaded()
      thankYouPageLoadedEvent()
      setMAApplicationData('subscription-id',this.subscriptionID)
  
  
      const app= new AppService()
      app.getScripts().then(() => {
        if(app.getThankYouPageScript()){
          injectHTMLToBody(app.getThankYouPageScript())
        }
  
      })
      const userData = await this.loadUserData()
      await this.delay(700)
    },
  
    mounted () {
      // This code will prevent the user from navigating back using the browser's back button
      history.pushState(null, null, document.URL)
      history.pushState(null, null, document.URL)
  
      this.stepsData[0].status = 'completed'
      this.stepsData[1].status = 'completed'
    },
    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeunload)
  
    },
    computed: {
      formattedPrice() {
        return this.upsellDetails?.price ? this.upsellDetails.price.toFixed(2) : "0.00";
      }
    },
    methods: {
      delay (time) {
        return new Promise(resolve => setTimeout(resolve, time))
      },
      beforeunload (event) {
        event.preventDefault()
        event.returnValue = '' // For older browsers
  
        // Custom confirmation message
        const confirmationMessage = 'Are you sure you want to leave this page?'
  
        // Display the confirmation dialog
        return confirmationMessage
      }
    }
  
  }
  </script>
  
<template>
  <div style="display: flex; width: 100%; overflow-x: auto">

      <div ref="wrapper" :style="{position: 'initial',  width: '100%' , 'minWidth': (chartData.datasets[0].data.length *40)+'px' }">

        <BarChart
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :css-classes="cssClasses"
          :width="2000"
          :height="height"
        />
      </div>


    </div>

</template>

<script>
import { Bar as BarChart } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  TimeScale, BarElement
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import moment from "moment";



ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,TimeScale)
export default {
  name: 'ChartBar',
  components: {BarChart},
  props: {
    measurement:{
      type: String,
      default: ''
    },
    dataSets: {type: Array, default: () => []},
    chartId: {type: String, default: 'line-chart'},
    datasetIdKey: {type: String, default: 'time'},
    width: {type: Number, default: 400},
    height: {type: Number, default: 400},
    cssClasses: {type: String, default: ''},
    styles: {type: Object, default: () => ({})}
  },
  watch:{
    dataSets:{
      handler(){
        this.chartData = this.dataSets

      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            backgroundColor: '#5d5d5d',
            borderColor: '#494949',
            data: [
            ]
          }
        ]
      },
      chartData2: {
        datasets: [
          {
            backgroundColor: '#606060',
            borderColor: '#676767',
            data: [
            ]
          }
        ]
      },


      chartOptions: {
        borderRadius:4,
        barThickness: 26,
        layout:{
          padding:{
          }
        },
        scales: {
          x: {
            grid: {
              color: templateConfig.style_variables['$text-secondary-on-bg'],
              borderColor: templateConfig.style_variables['$text-secondary-on-bg'],
              tickColor: templateConfig.style_variables['$text-secondary-on-bg']
            },
            ticks: {
              color: templateConfig.style_variables['$text-secondary-on-bg'],
            }
          },
          y: {
            steps: 5,
            position : 'right',
            grid: {
              color: templateConfig.style_variables['$text-secondary-on-bg'],
              borderColor: templateConfig.style_variables['$text-secondary-on-bg'],
              tickColor: templateConfig.style_variables['$text-secondary-on-bg']
            },
            ticks: {
              color: templateConfig.style_variables['$text-secondary-on-bg'],
            }
          }
        },
       responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: this.customTooltip
          },
        },
      }
    };
  },
  methods: {
    customTooltip(context) {
      const {chart, tooltip} = context;
      let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.classList.add('tooltip');
        tooltipEl.style.background = templateConfig.style_variables['$text-area-bg-fill'];
        tooltipEl.style.color = templateConfig.style_variables['$text-secondary-on-bg'];
        tooltipEl.style.padding = '8px';
        tooltipEl.style.borderRadius = '4px';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.transition = 'opacity 0.2s ease';
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      console.log(tooltip.dataPoints[0])
      const yValue = tooltip.dataPoints[0].formattedValue;
      tooltipEl.innerHTML = `
      <div>
      <div style="font-size: 16px; font-weight: 700;">${yValue} </div>

      </div>
      `;

      const position = chart.canvas.getBoundingClientRect();
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = position.left + window.pageXOffset + tooltip.caretX + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset + tooltip.caretY + 'px';
    }
  }
};
</script>

<style>
.tooltip {
  font-size: 12px;
  text-align: center;
}
</style>

<template>
  <div class="pc-card-meal-remove-with-macros">
    <card-meal
      :type="type.name"
      :title="title "
      :macro-nutrients="macroNutrientsArray"
      :calories="caloriesObj"
      :images-src="images"
      :is-new-meal="false"
    >
      <template class="gc-card-meal__action-area--one-action">
        <card-meal-action-remove
          :state="actionRemoveState"
          @click="setRemove()"
        />
        <card-meal-action-view-meal
          v-show="(mealType==='platform')"
          :state="(mealType!=='platform')?'disabled': ''"
          @click="view()"
        />
      </template>
    </card-meal>
    <popup-remove-meal
      v-if="mealRemovePopup.visible"
      :visible="mealRemovePopup.visible"
      :meal-i-d="id"
      :log-i-d="logID"
      :meal-name="name"
      :date="getDayText()"
      @close="closePopup()"
      @error="setError"
      @deleting="setRemoving()"
      @success="setRemoved()"
    />
    <popup-food-dairy-view-meal-plan-meal
      v-if="viewMealPopup.visible"
      :visible="viewMealPopup.visible"
      :sub-header="localizedDate"
      :header="meal.type.name"
      :meal="meal"
      @close="viewMealPopup.visible =false"
    />
  </div>
</template>
<script>
import CardMeal from '../../../../global/cards/CardMeal'
import CardMealActionViewMeal from '../../../../global/cards/card-meal-sub-components/CardMealActionViewMeal'
import CardMealActionLogMeal from '../../../../global/cards/card-meal-sub-components/CardMealActionLogMeal'
import CardMealActionRemove from '../../../../global/cards/card-meal-sub-components/CardMealActionRemove'
import PopupRemoveMeal from './PopupRemoveMeal'
import PopupFoodDairyViewMealPlanMeal from './PopupFoodDiaryViewMealPlanMeal'
import MealPlanService from '../../../../../services/MealPlanService'
import DashBoardService from '../../../../../services/DashboardService'
import {mapGetters, mapMutations} from 'vuex'
import moment from 'moment'

export default {
  name: 'CardMealRemoveWithMacros',
  components: {
    PopupRemoveMeal,
    PopupFoodDairyViewMealPlanMeal,
    CardMealActionRemove,
    CardMealActionLogMeal,
    CardMealActionViewMeal,
    CardMeal
  },
  props: {
    name: {
      required: true,
      type: String
    },
    id: {
      type: Number,
      required: true
    },
    logID: {
      type: String,
      required: true
    },
    date: {
      required: false,
      type: String,
      default: ''
    },
    mealType: {
      type: String,
      required: true
    },
    type: {
      type: Object,
      required: true
    },
    recipes: {
      type: Array,
      required: true
    },
    macroNutrients: {
      type: Object,
      required: true
    },
    calories: {
      type: Number,
      required: true
    },
    restaurantName: {
      type: String,
      required: true
    },
    showNetCarbs: {
      type: Boolean,
      default: false
    },
    meal: {
      default: null
    }
  },
  data: function () {
    return {
      currentLog: false,
      title: '',
      images: [],
      states: {
        remove: 'pending'
      },
      viewMealPopup: {
        visible: false
      },
      isRemoved: false,
      mealRemovePopup: {
        visible: false
      },
      mealPlanId: null,
      dayId: null

    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    }),
    localizedDate: function () {
      if (!this.date) {
        return ''
      }
      return moment(this.date).format('ll')
    },
    actionRemoveState: function () {
      switch (this.states.remove) {
        case 'pending':
          return ''
        case 'remove' :
          return 'onTap'
        case 'removing':
          return 'active'
        default:
          return ''
      }
    },
    showViewMeal: function () {
      if (this.mealType === 'custom') {
        return false
      }
      return (this.mealPlanId && this.dayId)
    },
    macroNutrientsArray: function () {
      if (!this.macroNutrients) {
        return
      }
      let macros = [
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.proteins),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t('message[\'general.proteins\']'))
        },
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.fats),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t('message[\'general.fats\']'))
        },
        {
          qut: this.roundupMacroNutrients(this.macroNutrients.carbohydrates),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t('message[\'general.carbs\']'))
        }
      ]
      if (this.showNetCarbs) {
        macros.push({
          qut: this.roundupMacroNutrients(this.macroNutrients.net_carbohydrates),
          unit: this.$i18n.t('message["food-diary.unit-grams"]'),
          name: this.firstLetterCaps(this.$i18n.t('message[\'general.carbs-net\']'))
        })
      }

      return macros
    },
    caloriesObj: function () {
      return [
        {
          qut: this.calories,
          name: this.$i18n.tc('message[\'general.calories\']', this.roundupMacroNutrients(this.calories))
        }
      ]
    }
  },
  mounted () {
    this.setMealTitle()
    this.setImages()
    if (this.mealType !== 'custom') {
      this.haveMealForView()
    }
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    setRemoving () {
      this.states.remove = 'removing'
    },
    setError () {
      this.states.remove = ''
    },
    haveMealForView () {
      let mealPlanService = new MealPlanService()
      let navData = mealPlanService.getMealPlanIDAndDayIDByMealID(this.id)

      this.mealPlanId = navData.mealPlanID
      this.dayId = navData.dayID
    },
    setRemoved () {
      this.isRemoved = true
      setTimeout(() => {
        this.$emit('meal-removed')
      }, 500)

    },
    requestRemoveMeal () {
      this.mealRemovePopup.visible = true
    },
    closePopup () {
      this.mealRemovePopup.visible = false
      this.states.remove = 'pending'
    },
    setRemove () {
      let dashboard = new DashBoardService()
      if (!(dashboard.isEntriesEditable())) {
        if (!(this.getPlanExpiredPopupShow)) {
          this.showSubscriptionExpiredPopup('foodDiary_delete_entry')
        }
      } else {
        if (this.states.remove === 'pending') {
          this.states.remove = 'remove'
          this.requestRemoveMeal()
        }
      }
    },
    view () {
      if (this.mealType !== 'platform') {
        return
      }
      this.viewMealPopup.visible = true
      this.$emit('meal-view')
    },
    showImage (index) {
      this.images[index].state = 'loaded'
    },
    getDateString () {
      return moment(this.date).format('ll')
    },
    getDayText () {
      if (this.getDateString() === moment().format('ll')) {
        return this.$i18n.t('message["general.today"]') + ' (' + this.getDateString() + ')'
      } else {
        return this.getDateString()
      }
    },
    preLoadImages () {
      this.images.forEach(image => {
        let img = new Image()
        img.src = image.url
      })
    },
    setMealTitle () {
      let recipes = this.recipes.map(recipe => {
        return recipe.name
      })
      this.title = (this.restaurantName ? this.restaurantName + ' • ' : '') + recipes.join(' | ')
    },
    clickedView () {
      this.delayedRouterPush({
        path: [this.$appConfig.appUrlList.mealPlanBase, this.mealPlanId].join('/'),
        query: {day: this.dayId, meal: this.id}
      })
    },
    setImages () {
      let images = []
      images = this.recipes.map(recipe => {
        if (this.mealType === 'custom' || this.mealType === 'dine-out') {
          return recipe.image
        } else {
          return recipe.images[0] || ''
        }
      })

      images = images.filter(image => {
        return image
      })
      this.preLoadImages()
      this.images = images.slice(0, 3)
    },
    roundupMacroNutrients (val) {
      const intVal = parseInt(val)
      if (isNaN(intVal)) {
        return val
      } else {
        return intVal
      }
    }
  }
}
</script>

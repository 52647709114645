<script>
import PageSecondary from "@/components/global/pages/PageSecondary.vue";
import VideoPlayerCustom from "@/components/global/players/VideoPlayerCustom.vue";

export default {
  name: "PageVideoTest",
  components: {VideoPlayerCustom, PageSecondary},
  data:function (){
    return {
      videoUrl: 'https://player.vimeo.com/external/1059698550.mpd?s=ea5acce4f100f0726d5aac22be00292fe6440664&logging=false',
      showPLayer: true,
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''

      },
    }
  },
  mounted() {
    this.hideLoading()
  },
  watch: {
    videoUrl: function () {
      this.showPLayer = false
      this.$nextTick(() => {
        this.showPLayer = true
      })
    },
  }
}
</script>

<template>
  <page-secondary :header="header">
    <button @click="videoUrl='https://player.vimeo.com/external/1059689896.mpd?s=e7bd789c75e5fb0089de800f342970de104575b0&logging=false'">190-201  Dumbbell Stiff Leg Deadlift</button>
    <button @click="videoUrl='https://player.vimeo.com/external/1059698550.mpd?s=ea5acce4f100f0726d5aac22be00292fe6440664&logging=false'">190-201  Barbell Upright Row</button>
    <button @click="videoUrl='https://player.vimeo.com/external/1053013051.mpd?s=11c8805e146b50da727e36bf7ce3c7c33a25e603&logging=false'">198-43  Single Arm Kettlebell Swing</button>
    <button @click="videoUrl='https://player.vimeo.com/external/1069266071.mpd?s=73729b5bcd6d989c48d7928ab6c367131c386add&logging=false'">198-43 dumbbell front raises.mp4</button>

    <input type="text" v-model="videoUrl">
    <video-player-custom v-if="showPLayer" :src="videoUrl"/>



  </page-secondary>
</template>

<style scoped>

</style>

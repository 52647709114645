<template>
  <div class="pc-card-photo-journey-compare-photo-skeleton gc-card-photo-journey-compare__images">
  
          <AnimatedPlaceholder width="100%" height="100%" />

  </div>
</template>

<script>
import AnimatedPlaceholder from "../animators/AnimatedPlaceholder";

export default {
  name: "pc-card-compare-photo-skeleton",
  components: { AnimatedPlaceholder },
};
</script>

<style >

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-secondary',{staticClass:"gc-page-connect-wearables",attrs:{"header":_vm.headerOptions}},[(_vm.loading)?_c('PageConnectWearablesSkeleton'):_c('page-container',{staticClass:"container"},[_c('text-body-small',{staticClass:"gc-page-connect-wearables__description"},[_vm._v(" "+_vm._s(_vm.$t("message['connect-wearables.description']"))+" ")]),(
        (_vm.isWearableSupportForNativeApps &&
          (_vm.isIOSNative || _vm.isAndroidNative)) ||
        _vm.isAppleHealthConnected ||
        _vm.isHealthConnectConnected
      )?_c('div',{staticClass:"gc-page-connect-wearables__native-links"},[(_vm.isIOSNative || _vm.isAppleHealthConnected)?_c('button-primary-forward-icon',{class:_vm.isAppleHealthConnected
            ? 'gc-page-connect-wearables__button gc-page-connect-wearables__button__connected'
            : 'gc-page-connect-wearables__button',attrs:{"text":_vm.$t('message[\'general.apple-health\']'),"sub-text":_vm.isAppleHealthConnected
            ? _vm.$t('message[\'general.connected\']')
            : _vm.$t('message[\'step-tracker.first-time-card-button-text\']')},on:{"click":function($event){return _vm.showNativeConnectPopup('Apple Health', _vm.isAppleHealthConnected)}}},[_c('icon-apple-health',{attrs:{"size":'md4'}})],1):_vm._e(),(_vm.isAndroidNative || _vm.isHealthConnectConnected)?_c('button-primary-forward-icon',{class:_vm.isHealthConnectConnected
            ? 'gc-page-connect-wearables__button gc-page-connect-wearables__button__connected'
            : 'gc-page-connect-wearables__button',attrs:{"text":_vm.$t('message[\'general.health-connect\']'),"sub-text":_vm.isHealthConnectConnected
            ? _vm.$t('message[\'general.connected\']')
            : _vm.$t('message[\'step-tracker.first-time-card-button-text\']')},on:{"click":function($event){return _vm.showNativeConnectPopup('Health Connect', _vm.isHealthConnectConnected)}}},[_c('icon-health-connect',{attrs:{"size":'md4'}})],1):_vm._e(),(!_vm.isHealthConnectConnected && _vm.isAndroidNative)?_c('text-body-extra-small',{staticClass:"gc-page-connect-wearables__native-links__description"},[_vm._v(" "+_vm._s(_vm.$t("message['connect-wearables.native-app.warning']")))]):_vm._e()],1):_vm._e(),_vm._l((_vm.dataSources),function(source){return _c('div',{key:source.name},[_c('button-primary-forward-icon',{class:source.connected
            ? 'gc-page-connect-wearables__button gc-page-connect-wearables__button__connected'
            : 'gc-page-connect-wearables__button',attrs:{"text":source.name,"sub-text":source.connected
            ? _vm.$t('message[\'general.connected\']')
            : _vm.$t('message[\'step-tracker.first-time-card-button-text\']')},on:{"click":function($event){return _vm.showPopup(source)}}},[_c('image-component',{attrs:{"src":source.image}})],1)],1)})],2),(_vm.connectPopupVisibility)?_c('popup-confirm-wearable-connect',{attrs:{"source":_vm.popupData,"visible":_vm.connectPopupVisibility},on:{"close":function($event){return _vm.closePopup()},"success":function($event){return _vm.reload()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
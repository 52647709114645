<template>
  <div class="gc-card-photo-journey-history--tab" v-if="image&&show">
    <!-- <loading-buffer height="160" v-show="state==='loading'"/> -->
     <ImagePhotoJourneyHistorySkeleton v-if="state==='loading'"/>
    <img
      :src="image"
      alt=""
      class="gc-card-photo-journey-history__images"
      v-on:click="showImagePopup (image) "
      v-on:error="loadedImage()"
      v-on:load="loadedImage()"
      v-show="state!=='loading'"
    />
<!--    <custom-popup-image-->
<!--        :image="imagePopup.image"-->
<!--        v-if="imagePopup.show"-->
<!--        v-on:close="hideImagePopup()">-->
<!--    </custom-popup-image>-->
    <popup-custom-media v-if="imagePopup.show" :type="'image'" :url="imagePopup.image" @close="hideImagePopup"/>
  </div>
</template>
<script>
import CustomPopupImage from '../widgets/CustomPopupImage'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import PopupCustomMedia from '../../../../global/popups/PopupCustomMedia.vue'
import ImagePhotoJourneyHistorySkeleton from './ImagePhotoJourneyHistorySkeleton.vue'

export default {
  name: 'ImagePhotoJourneyHistory',
  components: { PopupCustomMedia, CustomPopupImage, LoadingBuffer, ImagePhotoJourneyHistorySkeleton },
  props: {
    /**
     * image src
     * @example
     * image: '//s3-us-west-1.amazonaws.com/s3b-usw-1-alpha/117/2/media-diary-97a868221faf44e09c212ede55a7712e.jpg'
     */
    image: {
      default: ''
    },
    /**
     * image type
     * @values front, side, back
     */
    type: {
      default: ''
    }
  },
  data: function () {
    return {
      state: 'loading',
      show: true,
      imagePopup: {
        show: false,
        image: ''
      }
    }
  },
  methods: {
    loadedImage () {
      setTimeout(() => {
    this.state = 'loaded';
    // console.log('Image loaded, changing state');
  }, 1000); // Delay of 1 second to ensure skeleton is visible
      
      // this.state = 'loaded';
    },
    showImagePopup (image) {
      this.logEvent('Journey.EnlargedPhoto', { type: this.type }, false)
      this.imagePopup.image = image
      this.imagePopup.show = true
    },
    hideImagePopup () {
      this.imagePopup.image = ''
      this.imagePopup.show = false
    },
    hideImage () {
      this.show = false
      this.$emit('error')
    }
  }
}
</script>
<style>

</style>

<template>
  <div class="pc-container-meal-plan-meal-log">
    <!-- Image Checkboxes -->
    <page-container-fluid>
      <food-diary-day-slider
        v-if="days.length>0 && isActiveTab &&showSlider"
        :meal-plan-i-d="mealPlanID"
        :days="days"
        @changed="contentChanged()"
        @day-select="setDay($event)"
      />
    </page-container-fluid>
    <page-container>
      <grid-row
        v-if="currentDay&&currentDay.getMeals()"
        class="pc-container-meal-plan-meal-log__meals"
      >
        <card-meal-log
          v-for="meal in currentDay.getMeals()"
          :id="meal.getId()"
          :key="'meal-log-meal-card-log-'+meal.getId()"
          :name="meal.getName()"
          :type="meal.getType()"
          :recipes="meal.getRecipes()"
          :meal-plan-id="mealPlanID"
          :day-id="currentDayID"
          :logged="isSelected(meal.getId(),meal.getReferenceMealId())"
          :macro-nutrients="meal.getMacroNutrients()"
          :calories="meal.getCalories()"
          class="pc-container-meal-plan-meal-log__meal"
          @logged="setSelected($event,meal)"
        />
      </grid-row>
      <page-fixed-footer v-if="isActiveTab">
        <button-primary
          :disabled="state==='submitting'|| (!isLoggedMealsChanged)"
          :text="state === 'submitting' ? '' : $tc('message[\'food-diary.log-meal-submit\']', newMealsCount, { count: newMealsCount })"
          @click="submitData()"
        >
          <loading-buffer-custom v-if="state === 'submitting'" />
        </button-primary>
      </page-fixed-footer>
    </page-container>
  </div>
</template>
<script>
import FoodDiaryDaySlider from './FoodDiaryDaySlider'
import FoodDiaryService from '../../../../../services/FoodDiaryService'
import CardMealLog from './CardMealLog'
import AlertWarning from '../../../../global/alerts/AlertWarning'
import IconMeal from '../../../../root/icons/IconMeal'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupFloatingFooter from '../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import logMealPlanMealMixin from '../../../../../mixins/component-mixins/page/food-diary/logMealPlanMealMixin'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import NutritionDetailsBar from '../../../../global/bars/BarNutritionDetails'
import NavigationMealsAdd from './NavigationMealsAdd'
import PageFixedFooter from '../../../../global/pages/PageFixedFooter'
import GridRow from '../../../../global/grid/GridRow'
import LoadingBufferCustom from '../../global/widgets/LoadingBufferCustom'
import PageContainerFluid from '../../../../global/pages/page-sub-components/PageContainerFluid'
import NutritionService from '../../../../../services/nutrition/NutritionService'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'

export default {
  name: 'ContainerMealPlanMealLog',
  components: {
    TextBodyExtraSmall,
    PageContainerFluid,
    LoadingBufferCustom,
    GridRow,
    PageFixedFooter,
    NavigationMealsAdd,
    PageContainer,
    NutritionDetailsBar,
    TextBodySmall,
    IconMeal,
    ButtonPrimary,
    AlertWarning,
    CardMealLog,
    FoodDiaryDaySlider,
    PopupFloatingFooter
  },
  mixins: [logMealPlanMealMixin],
  props: {
    isActiveTab: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      showSlider: true,
      pageLoading: false
    }
  },
  watch: {
    isActiveTab: function () {
      this.currentLog = Object.assign(this.mealLog, {})
    },
    loggedMeals: {
      deep: true,
      handler: function () {
        return ''
      }
    }
  },
  mounted () {
    this.pageLoading = true
    this.currentLog = Object.assign(this.mealLog, {})
    this.currentLog.logged_meals.forEach((meal) => {
      this.loggedMeals.push(meal)
    })
    this.previousLoggedMeals = this.loggedMeals
    if (Array.isArray(this.lastSelectedMeals)) {
      this.loggedMeals = this.lastSelectedMeals
    }
    this.service = new FoodDiaryService()

    const service = new NutritionService()

    let latestMealPlan = service.getLatestMealPlan()

    latestMealPlan.getData().then(() => {
      this.mealPlanID = latestMealPlan.getId()
      this.days = latestMealPlan.getDays()
      this.setSelectedOptionDayText()
      this.pageLoading = false
    })
    window.addEventListener('resize', this.reInit)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reInit)
  },
  methods: {
    reInit () {
      this.showSlider = false
      this.$nextTick(() => {
        this.showSlider = true
      })
    },
    setDay (day) {
      if (this.currentDayID === day.getId()) {
        return
      }
      let dayID = day.getId()
      this.currentDay = null

      this.$nextTick(() => {
        this.currentDayID = dayID
        this.currentDay = this.days.find((day) => {
          return day.getId() === this.currentDayID
        })
        this.$emit('meal-log-required-info-changed', this.currentLog)
      })
    },
    changeTab (name) {
      this.$emit('change-tab', name)
    }
  }
}
</script>

import axios from 'axios'
import BaseAPI from '../BaseAPI'
import DefineInclude from '@/DefineInclude'

export default class StepTrackerApi extends BaseAPI {
  constructor () {
    super()
    this._stepTrackerBaseUrl = DefineInclude.stepTrackerAPIUrl
  }

  async getDataSources (redirectUrl) {
    return await axios.get(this._stepTrackerBaseUrl + 'datasources/available', {
      params: {
        redirectUrl: redirectUrl
      }
    })
  }

  async getConnectedDataSources () {
    return await axios.get(this._stepTrackerBaseUrl + 'datasources/connected')
  }

  async getStats () {
    return await axios.get(this._stepTrackerBaseUrl + 'plot/stats')
  }

  async getStepCount (date) {
    return await axios.get(this._stepTrackerBaseUrl + 'plot/steps', {
      params: { date }
    })
  }

  async getUserExistence (memberId) {
    return await axios.get(this._stepTrackerBaseUrl + 'member/' + memberId + '/exists')
  }

  async setNewConnectedDataSource (data) {
    console.log(222222222222222)
    return await axios.post(this._stepTrackerBaseUrl + 'member/notify/datasource-connect', {
      'dataSource': data
    })
  }

  async setNewRevokedDataSource (data) {
    return await axios.post(this._stepTrackerBaseUrl + 'member/notify/datasource-revoke', {
      'dataSource': data
    })
  }

  async disconnectDataSource (datasource) {
    return await axios.post(this._stepTrackerBaseUrl + 'datasources/' + datasource + '/revoke')
  }

  async getGraphData (from, to, type) {
    return await axios.post(this._stepTrackerBaseUrl + 'plot/get',  {
      from: from,
      to: to,
      type: type})
  }

}

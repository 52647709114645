export default {
  methods:{
    getConfigByPageId(pageName){
      const defaultConfig = {
        "ver": "1.0",
        "header": { "variation": "style1" },
        "widgetOrder": [],
        "widgets": [
          { "code": "descriptionSelection", "variation": "style1" },
          { "code": "progressUpdateSection", "variation": "style1" }
        ]
      };
      const requiredWidgets = defaultConfig.widgets.map(widget => widget.code);

      let config = templateConfig.page_config|| {}

      const mergedConfig = Object.assign(defaultConfig,config[pageName])

      const existingWidgets = mergedConfig.widgets || [];
      const missingWidgets = requiredWidgets.filter(reqCode =>
        !existingWidgets.some(widget => widget.code === reqCode)
      );

      if (missingWidgets.length > 0) {
        mergedConfig.widgets = [...existingWidgets,
          ...missingWidgets.map(code => ({ code, variation: "style1" }))
        ];
      }

      return mergedConfig;

    }
  }

}

<template>
  <div id="app">

    <transition name="preloader-fade" mode="in-out">
      <page-loader />
    </transition>
    <transition name="preloader-fade" mode="in-out">
      <logging-loader />
    </transition>
    <transition :name="routeTransition.name" >
      <router-view :key="routePath"/>
    </transition>

    <app-banner-list/>
    <transition name="static-fade" mode="in-out">
      <navigator-bottom-bar v-if="showBottomNavigation"/>
    </transition>
    <popup-network-error />
    <popup-plan-inactive
      v-if="planExpiredPopupShow"
      :visible="planExpiredPopupShow"
    />
    <server-error-popup></server-error-popup>
    <popup-food-diary-reminder/>
    <popup-feed-back :flag="feedbackPopupEventName" :text-place-holder="feedbackPopupPlaceHolderText"
                     :title="feedbackPopupTitle" :visible="feedbackPopupVisible" v-if="feedbackPopupVisible"/>
    <popup-not-found-error v-if="$store.state.notFoundPopupShow" @close="goToDashboard()"/>
    <instructions/>
    <popup-feature-tour v-if="foodDiaryFeaturevisible"/>
    <popup-dynamic-message v-if="showDynamicContentPopup"/>
    <popup-custom-meal-log v-if="customMealLogPopupVisibility &&canShowCustomMealLogPopup "/>
    <popup-native-app-update />

  </div>
</template>

<script>
import store from './store/index'
import ServerErrorPopup from './components/global/popups/PopupServerError'
import PopupFoodDiaryReminder from './components/global/popups/PopupFoodDiaryReminder'
import PopupPlanInactive from './components/global/popups/PopupPlanInactive'
import Instructions from './components/global/popups/PopupInstructions'
import AppBannerList from './components/layout/template-1/global/widgets/AppBannerList'
import ActivityTracker from './services/ActivityTracker'
import {checkIsPWA, isMobileNativeApp} from './includes/NativeAppCommon'
import NativeAppService from './services/NativeAppService'
import PopupNetworkError from './components/global/popups/PopupNetworkError'
import PopupNotFoundError from './components/global/popups/PopupNotFoundError'
import PopupFeedBack from './components/global/popups/PopupFeedBack'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import PopupFeatureTour from './components/global/popups/PopupFeatureTour'
import ProfileService from './services/ProfileService'
import PopupDynamicMessage from './components/global/popups/PopupDynamicMessage'
import dynamiContentPopupWapperMixin from './mixins/dynamiContentPopupWapperMixin'
import foodDiaryGobalCustomMealLogWapperMixin from './mixins/foodDiaryGobalCustomMealLogWapperMixin'
import nativeAppUpdateCheckAppMixin from './mixins/nativeAppUpdateCheckAppMixin'
import PopupCustomMealLog from './components/layout/template-1/food-diary/page-components/PopupCustomMealLog'
import {checkIsMember} from './helpers/user'
import NavigatorBottomBar from './components/global/navigators/NavigatorBottomBar'
import {showBottomNavigationBar} from './includes/TemplateSettings'
import {browserVersion, mobileModel, osVersion} from 'mobile-device-detect'
import PageLoader from "@/components/global/loaders/PageLoader.vue";
import LoggingLoader from "@/components/global/loaders/LoggingLoader.vue";
import PageContainerSkeleton from "@/components/global/pages/page-sub-components/PageContainerSkeleton.vue";
import PopupNativeAppUpdate from "@/components/global/popups/PopupNativeAppUpdate.vue";

export default {
  name: "App",
  mixins: [
    dynamiContentPopupWapperMixin,
    nativeAppUpdateCheckAppMixin,
    foodDiaryGobalCustomMealLogWapperMixin,
  ],
  components: {
    PopupNativeAppUpdate,
    PageLoader,
    NavigatorBottomBar,
    PopupCustomMealLog,
    PopupFeedBack,
    PopupNotFoundError,
    PopupNetworkError,
    AppBannerList,
    Instructions,
    ServerErrorPopup,
    PopupPlanInactive,
    PopupFeatureTour,
    PopupDynamicMessage,
    PopupFoodDiaryReminder,
    PageContainerSkeleton,
    LoggingLoader
  },
  store,
  data: function () {
    return {
      routePath: "0",
      assertUrl: this.$appConfig.communityAssetURL,
      imagesUrl: this.$appConfig.imagesUrl,
      notificationsCheckTimer: null,
      lastRouteChangeTime: Date.now(),
      pollingInterval: 0, // 1 hour in milliseconds
      notificationTimeOut: null,
    };
  },
  computed: {

    ...mapState({
      loadingState: "loading",
    }),
    ...mapGetters({
      routeTransition: "getRouteTransition",
      getActiveCommunities: "communityStore/getActiveCommunities",
      feedbackPopupTitle: "feedBackPopupStore/title",
      planExpiredPopupShow: "getPlanExpiredPopupShow",
      feedbackPopupPlaceHolderText: "feedBackPopupStore/placeHolderText",
      feedbackPopupFlag: "feedBackPopupStore/flag",
      feedbackPopupEventName: "feedBackPopupStore/eventName",
      feedbackPopupVisible: "feedBackPopupStore/visible",
      foodDiaryCurrentLogs: "foodDiaryStore/addPopupShown",
      foodDiaryFeaturevisible: "featurePopupStore/visible",
    }),
    showBottomNavigation() {
      return showBottomNavigationBar();
    },
  },
  watch: {
    loadingState: {
      immediate: true,
      handler: function () {
        if (this.loadingState) {
          document.body.classList.add("ma-loading");
        } else {
          document.body.classList.remove("ma-loading");
        }
      },
    },
    $route(to, from) {
      this.routePath = window.location.pathname + window.location.search;
      if (
        typeof to.meta.requiresAuth !== "undefined" &&
        to.meta.requiresAuth &&
        !this.trainerBrand
      ) {
        if (!this.notificationsCheckTimer) {
          setTimeout(() => {
            this.initCommunityNotificationDetails();
          }, 1000);

          // notification status will be updated every 30 seconds
          this.handleNotificationRouteChange(to.name);
          this.notificationTimeOut = setTimeout(
            this.handleNotificationRouteChange,
            16 * 60 * 1000
          );
        }

        if (!checkIsMember()) {
          return;
        }
        let profileService = new ProfileService();
        profileService.setProfileData().then(() => {
          this.setTrainerDetails(profileService.getTrainerBrand());
        });
      }
    },
  },
  created() {
    this.setVHCSS();
    if (isMobileNativeApp()) {
      // init native related business (listeners, etc)
      const nativeAppService = new NativeAppService();
      nativeAppService.sendWhatCanDoEvent();
    }
    this.$store.commit("setImages");
    setTimeout(()=>{

      if(isMobileNativeApp()){
        const nativeAppService = new NativeAppService()
        if(nativeAppService.initialSplash && !nativeAppService.isNLAFAppLoadedResponseReceived){
          nativeAppService.appLoaded()

        }
      }
      this.$store.commit("hideLoading");
    },4000)

  },
  mounted() {
    this.addEventListenerDynamicContentEvent();
    this.addEventListenerNativeAppUpdateEvent();
    this.addEventListenerCustomMealLogEvent();
    const resolution =
      window.screen.width * window.devicePixelRatio +
      'x' +
      window.screen.height * window.devicePixelRatio
    this.setUserProperties('DeviceResolution', resolution, false)
    this.setOrientation()
    window.addEventListener('resize', this.setOrientation)
    this.setAmplitudeEvents()
    this.setAmplitudeUserProperties()
    sessionStorage.setItem('session' ,Math.random().toString())
  },
  methods: {
    ...mapMutations({
      setTrainerDetails: "pageStore/setTrainer",
    }),
    ...mapActions({
      initCommunityNotificationDetails:
        "communityStore/initNotificationDetails",
    }),
    startNotificationPolling() {
      if (this.notificationsCheckTimer) {
        clearInterval(this.notificationsCheckTimer);
      }
      this.notificationsCheckTimer = setInterval(
        this.initCommunityNotificationDetails,
        this.pollingInterval
      );
    },
    handleNotificationRouteChange(route = "") {
      const now = Date.now();
      const timeSinceLastChange = now - this.lastRouteChangeTime;
      const sec = 1000;
      if (route === "Home" || route === "DashboardMain") {
        if (timeSinceLastChange >= 120 * sec) {
          // 15 mins in milliseconds
          if (this.pollingInterval !== 3600 * sec) {
            this.pollingInterval = 3600 * sec; // Switch to 1 hour interval
            this.startNotificationPolling();
          }
        } else if (
          this.pollingInterval > 120 * sec ||
          this.pollingInterval === 0
        ) {
          this.pollingInterval = 3 * 120 * sec; // 2 mins in milliseconds
          this.startNotificationPolling();
        }
      } else {
        this.pollingInterval = 3600 * sec; // 1 hour interval for other routes
        this.startNotificationPolling();
      }

      this.lastRouteChangeTime = now;
    },
    setAmplitudeUserProperties() {
      const activity = new ActivityTracker();
      activity.setUserProperties("AppMode", "spa", false);

      let env = "web";
      if (checkIsPWA()) {
        env = "pwa";
      } else if (isMobileNativeApp()) {
        env = "native";
      }
      activity.setUserProperties("AppSubMode", env, false);
    },
    setOrientation() {
      this.setVHCSS();
      this.$store.commit("setOrientation", {
        height: window.innerHeight,
        width: window.innerWidth,
      });
      let appMode = "mobile";

      if (window.matchMedia("(min-width: 768px)").matches) {
        appMode = "tablet";
      }
      if (window.matchMedia("(min-width: 992px)").matches) {
        appMode = "desktop";
      }
      this.$store.commit("setAppMode", appMode);
    },
    setVHCSS() {
      document
        .querySelector(":root")
        .style.setProperty("--vh", window.innerHeight / 100 + "px");
    },
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    setAmplitudeEvents() {
      this.setUserProperties("Tenant", location.host, false);
      if (this.$resellerID && this.$trainerID) {
        this.setUserProperties("ResellerId", this.$resellerID, false);
        this.setUserProperties("TrainerId", this.$trainerID, false);
      }
      try {
        this.setUserProperties("OSVersion", osVersion, false);
        this.setUserProperties("BrowserVersion", browserVersion, false);
        this.setUserProperties("MobileModel", mobileModel, false);
      } catch (e) {
        console.error("Error setting amplitude events", e);
      }
    },
  },
  beforeDestroy() {
    if (isMobileNativeApp()) {
      // remove native related business (listeners, etc)
      const nativeAppService = new NativeAppService();
      nativeAppService.removeListeners();
    }
    this.removeEventListenerDynamicContentEvent();
    this.removeEventListenerNativeAppUpdateEvent();
    this.removeEventListenerCustomMealLogEvent();
    window.removeEventListener("resize", this.setOrientation);
    if (this.notificationsCheckTimer) {
      clearInterval(this.notificationsCheckTimer);
    }
    if (this.notificationTimeOut) {
      clearTimeout(this.notificationTimeOut);
    }
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({class:{'pc-container-style-editor':_vm.editable && _vm.mode==='edit' , 'pc-container-style-editor--selected': _vm.selected},style:({
      position: (_vm.selected || _vm.header)?'relative':'inherit'
})},_vm.$listeners),[(_vm.editable && _vm.mode === 'edit' && !_vm.header)?_c('icon-drag',{class:_vm.header ? 'pc-container-style-editor__header-drag-icon' : 'pc-container-style-editor__drag-icon'}):_vm._e(),_vm._t("default"),(_vm.editable && _vm.mode==='edit' && _vm.selected)?_c('div',{staticClass:"pc-container-style-editor-actions"},[_c('icon-up-arrow-filled',{staticClass:"pc-container-style-editor-actions__back",class:{
      'pc-container-style-editor-actions__back--disabled': _vm.selectedVariationIndex === 0
    },attrs:{"size":'md1'},on:{"click":_vm.back}}),_c('div',[_c('text-body-extra-small',{attrs:{"line-height":'lh-xl'}},[_vm._v(" "+_vm._s(_vm.selectedComponent)+" ")]),_c('text-body-small',{staticClass:"pc-container-style-editor-actions__title",attrs:{"line-height":'multi',"weight":'extra-bold'}},[_vm._v(_vm._s(_vm.selectedVariation.text)+" ")])],1),_c('icon-up-arrow-filled',{staticClass:"pc-container-style-editor-actions__next",class:{
      'pc-container-style-editor-actions__next--disabled': _vm.selectedVariationIndex === _vm.variations.length - 1
      },attrs:{"size":'md1'},on:{"click":_vm.next}})],1):_vm._e(),(false)?_c('div',{staticClass:"pc-container-style-editor__section-cover"},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M3.33333 9.66668C4.0697 9.66668 4.66667 10.2636 4.66667 11C4.66667 11.7364 4.0697 12.3333 3.33333 12.3333C2.59695 12.3333 2 11.7364 2 11C2 10.2636 2.59695 9.66668 3.33333 9.66668ZM9.33333 11C9.33333 10.2636 8.7364 9.66668 8 9.66668C7.26367 9.66668 6.66667 10.2636 6.66667 11C6.66667 11.7364 7.26367 12.3333 8 12.3333C8.7364 12.3333 9.33333 11.7364 9.33333 11ZM14 11C14 10.2636 13.403 9.66668 12.6667 9.66668C11.9303 9.66668 11.3333 10.2636 11.3333 11C11.3333 11.7364 11.9303 12.3333 12.6667 12.3333C13.403 12.3333 14 11.7364 14 11Z","fill":"white"}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M3.33333 3.66668C4.0697 3.66668 4.66667 4.26361 4.66667 5.00001C4.66667 5.73641 4.0697 6.33334 3.33333 6.33334C2.59695 6.33334 2 5.73641 2 5.00001C2 4.26361 2.59695 3.66668 3.33333 3.66668ZM9.33333 5.00001C9.33333 4.26361 8.7364 3.66668 8 3.66668C7.26367 3.66668 6.66667 4.26361 6.66667 5.00001C6.66667 5.73641 7.26367 6.33334 8 6.33334C8.7364 6.33334 9.33333 5.73641 9.33333 5.00001ZM14 5.00001C14 4.26361 13.403 3.66668 12.6667 3.66668C11.9303 3.66668 11.3333 4.26361 11.3333 5.00001C11.3333 5.73641 11.9303 6.33334 12.6667 6.33334C13.403 6.33334 14 5.73641 14 5.00001Z","fill":"white"}})])]):_vm._e(),(_vm.editable && _vm.mode === 'edit' && _vm.header)?_c('icon-drag',{class:_vm.header ? 'pc-container-style-editor__header-drag-icon' : 'pc-container-style-editor__drag-icon'}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
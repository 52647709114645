<template>
  <div class="pc-page-my-journey-measument-chart-box-skeleton">
    <div class="container">
          <AnimatedPlaceholder width="100%" height="400px" border-radius="0"/>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "../../../global/animators/AnimatedPlaceholder";

export default {
  name: "pc-page-my-journey-measument-chart-box-skeleton",
  components: { AnimatedPlaceholder },
};
</script>

<style lang="scss" scoped>

</style>

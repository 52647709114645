<template>
  <div class="gc-popup-text-skeleton">
    <div class="gc-popup-text-skeleton__head">
      <AnimatedPlaceholder width="150px" height="40px" />
    </div>
    <div class="gc-popup-text-skeleton__body">
      <AnimatedPlaceholder width="100%" height="20px" />
      <AnimatedPlaceholder width="20%" height="20px" />
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder";

export default {
  components: {
    AnimatedPlaceholder,
  },
};
</script>

<style lang="scss" scoped></style>

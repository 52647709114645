import ProgressAPI from '../../api/progress-check-in/ProgressAPI'
import MealPlanAPI from '../../api/meal-plan/MealPlanAPI'
import WorkoutApi from '../../api/workout/WorkoutApi'
import InjuriesAPI from '../../api/common/InjuriesAPI'
import NutritionCategoryAPI from '../../api/subscription/NutritionCategoryAPI'
import { i18n } from '../../../helpers/localization/i18n'
import FormOptionHelper from '../../helpers/FormOptionHelper'
import DashboardAPI from '../../api/dashboard/DashboardAPI'
import SubscriptionService from './SubscriptionService'
import FitnessDiaryAPI from '../../api/progress-check-in/FitnessDiaryAPI'
import ActivityTracker from '../../ActivityTracker'
import TrainerFeedService from '../../TrainerFeedService'
import IngredientService from '../../IngredientService'
import { enableGroupedIngredients } from '../../../includes/TemplateSettings'
import DashBoardService from '../../DashboardService'
import PreferredMealsApi from '../../api/profile/PreferredMealsApi'
import ProfileService from '../../ProfileService'
import store from "@/store";

export default class ProgressCheckInService {
  measurements = {
    shoulders: '',
    arms: '',
    chest: '',
    waist: '',
    hips: '',
    legs: '',
    calves: ''
  }
  _config = {
    showIngredientDislikes: true,
    showInjuries: true,
    showPreferredRecipes: false
  }

  suggestedHabits = []

  dietary = {
    mpd: '',
    ntr_goal: '',
    category: '7',
    days_per_mealplan: 7,
    vege: '',
    excluded_ingredients: [],
    recipes: [],
    cuisines: []
  }
  plan={
    follow: '',
    result: ''
  }
  bodyStat = {
    weight: { preference: 'imperial', lbs: '', kg: '' },
    height: '',
    body_fat: '',
    activity_level: ''
  }

  fitness = {
    injuries: []
  }

  programs = []
  account = null
  user = {
    gender: '',
    measurementSystem: ''
  }
  summaryData = null
  progressData = null
  mealPlanData = null
  dietTypeList = null
  workoutData = null
  injuriesList = []
  ingredientsList = []
  errors = {}
  recipes = []

  DietCategoryOptions = {
    mpd: [],
    vegetarian: [],
    dGoals: []
  }

  getDataFromAPI (payload) {
    const apiCalls = [this.getProgressUpdate(), this.getFitnessDiarySummary(), this.getMyInfoFromAPI(), this.getConfig(), this.getRecipesList()]

    const type = payload.type
    if (type === 'diet') {
      apiCalls.push(this.getMealPlanUpdate())
      apiCalls.push(this.getAccountData())
      apiCalls.push(this.getDietTypeList())
      apiCalls.push(this.getAllergies())
    } else if (type === 'fitness') {
      apiCalls.push(this.getWorkoutUpdate())
      apiCalls.push(this.getInjuriesList())
    } else {
      throw (new Error('Invalid Progress type'))
    }
    return new Promise((resolve, reject) => {
      Promise.all(apiCalls).then((values) => {
        resolve()
      }).catch((error) => {
        reject(error.response)
      })
    })
  }

  /**
   * load the configurations of the progress update flow
   * @returns {Promise}
   */
  getConfig () {
    return new Promise((resolve, reject) => {
      const dashBoardService = new DashBoardService()
      dashBoardService.getUserConfig().then(() => {
        this._config.showIngredientDislikes = dashBoardService.isIngredientDislikesEnabled()
        this._config.showInjuries = dashBoardService.isInjuriesSelectionEnabled()
        this._config.showFatQuestion = dashBoardService.isShowFatQuestionEnabled()
        this._config.showPreferredRecipes = dashBoardService.isPreferredRecipesEnabled()
        resolve(this._config)
      }).catch(() => {

        reject(new Error('no data'))
      })
    })
  }

  getRecipesList () {
    const api = new PreferredMealsApi()
    return new Promise((resolve, reject) => {
      api.getList().then(res => {
        this.recipes = res.data.recipes
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
  /**
   * get Allergies and map them in options
   * @returns {Promise<unknown>}
   */
  getAllergies () {
    const service = new IngredientService()
    return new Promise((resolve) => {
      service.setIngredients().then(() => {
        if (!enableGroupedIngredients()) {
          this.ingredientsList = Object.values(service.getIngredients()).map(option => {
            const groups = option.groups || []
            return { id: option.id, text: option.value, groups: groups }
          })
        } else {
          this.ingredientsList = service.getGroupedIngredients()
        }
        resolve(this.ingredientsList)
      })
    })
  }

  /**
   * get Nutritional category data by the `nutritional_category` ID
   * @returns {Promise<unknown>}
   */
  setNutritionalPrefData () {
    const nutritionalPrefID = this.dietary.category
    return new Promise((resolve, reject) => {
      const service = new NutritionCategoryAPI()
      service.get(nutritionalPrefID).then(data => {
        this.DietCategoryOptions.days_per_mealplan = { ...data.data.days_per_mealplan }
        this.DietCategoryOptions.mpd[nutritionalPrefID] = data.data.mpd
        this.DietCategoryOptions.vegetarian[nutritionalPrefID] = data.data.vegetarian
        this.DietCategoryOptions.dGoals[nutritionalPrefID] = data.data.goals
        console.log('llllllllllll', this.DietCategoryOptions)
        resolve(data.data)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  getMyInfoFromAPI () {
    return new Promise((resolve, reject) => {
      const service = new DashboardAPI()
      service.getUserData().then(data => {
        this.user.gender = data.data.gender
        this.user.measurementSystem = data.data.measurement_system
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getProgressUpdateFromAPI () {
    const api = new ProgressAPI()
    return api.getUpdateData()
  }

  getMealPlanUpdateFromAPI () {
    const api = new MealPlanAPI()
    return api.getUpdateData()
  }

  getWorkoutUpdateFromAPI () {
    const api = new WorkoutApi()
    return api.getUpdateData()
  }

  getFitnessDiarySummary () {
    let diaryAPI = new FitnessDiaryAPI()
    return new Promise((resolve, reject) => {
      if (this.summaryData === null) {
        diaryAPI.getSummary().then(res => {
          this.summaryData = res.data
          this.attemptSettingHeightFromSummaryData()
          this.attemptSettingWeightFromSummaryData()
          resolve(this.summaryData)
        }).catch(err => {
          if (err.response.status === 500) {
            store.commit('showServerErrorPopup')
            store.commit('setServerErrorPage', 'my-journey')
          }
          reject(err)
        })
      } else {
        resolve(this.summaryData)
      }
    })
  }
  getProgressUpdate () {
    return new Promise((resolve, reject) => {
      if (this.progressData === null) {
        this.getProgressUpdateFromAPI().then(res => {
          this.progressData = res.data
          this.setProgressData()
          resolve(this.progressData)
        }).catch(err => {
          reject(err)
        })
      } else {
        resolve(this.progressData)
      }
    })
  }

  getProgressContent (key) {
    return this.progressData.contents[key]
  }

  getDietContent (key) {
    return this.mealPlanData.contents[key]
  }

  getFitnessContent (key) {
    return this.workoutData.contents[key]
  }

  setProgressData () {
    // set values
    if (this._config.showFatQuestion) {
      this.bodyStat.body_fat = this.getStatisticsValue('body_fats')
    }
    this.bodyStat.height = this.getSummaryValue('height') || this.getStatisticsValue('heights')
    // dairy summary endpoint and progress update endpoint API calls are sending parallel. So this added because we can't set which API response comes first
    this.bodyStat.activity_level = this.getStatisticsValue('activity') || null
    this.bodyStat.weight = this.getSummaryValue('weight') || this.getStatisticsValue('weight')


    if(this.progressData && this.progressData.suggested_habits){
      this.suggestedHabits = this.progressData.suggested_habits

    }
  }

  attemptSettingHeightFromSummaryData () {
    let height = this.getSummaryValue('height')
    if (height) {
      this.bodyStat.height = this.getSummaryValue('height')
    }
  }

  attemptSettingWeightFromSummaryData () {
    let weight = this.getSummaryValue('weight')
    if (weight) {
      this.bodyStat.weight = this.getSummaryValue('weight')
    }
  }

  getSummaryValue (field) {
    if (!this.summaryData) {
      return 0
    }
    let entry = this.summaryData.entries.find(summaryEntry => {
      return summaryEntry.field === field
    })
    if (entry && entry.latest && entry.latest.value) {
      return parseFloat(entry.latest.value) || 0
    }
    return 0
  }

  setDietaryValues () {
    this.dietary.category = this.getDietaryValue('category')[0] || this.dietary.category
    this.dietary.ntr_goal = this.getDietaryValue('ntr_goal')[0] || ''
    this.dietary.mpd = this.getDietaryValue('mpd')[0] || ''
    this.dietary.meals_per_day = this.getDietaryValue('mpd')[0] || ''
    this.dietary.vege = this.getDietaryValue('vegetarian_days')[0] || 0
    this.dietary.excluded_ingredients = this.getDietaryValue('excluded_ingredients') || []
  }

  getWorkoutUpdate () {
    return new Promise((resolve, reject) => {
      if (this.workoutData === null) {
        this.getWorkoutUpdateFromAPI().then(res => {
          this.workoutData = res.data
          this.setFitnessAndWorkoutValues()
          resolve(this.workoutData)
        }).catch(err => {
          reject(err)
        })
      } else {
        resolve(this.workoutData)
      }
    })
  }

  setFitnessAndWorkoutValues () {
    this.fitness = {
      injuries: this.getFitnessValue('injuries') || []
    }

    this.workoutData.workout_preferences.programs.forEach((item, index) => {
      this.getWorkoutPreviousValues(item.id)
      this.programs[index] = this.getWorkoutPreviousValues(item.id)
    })
  }

  getWorkoutPreviousValues (workoutID) {
    const program = this.workoutData.workout_preferences.programs.find(selectedProgram => {
      return selectedProgram.id === workoutID
    })

    return {
      ...program.previous,
      id: workoutID,
      goal: program.previous.goal_id || ''

    }
  }

  setIngredientsSelected (values) {
    values = values.map((value) => {
      return parseInt(value)
    })
    const index = this.mealPlanData.dietary.fields.findIndex(field => {
      return field.key === 'excluded_ingredients'
    })
    this.mealPlanData.dietary.fields[index].options = this.mealPlanData.dietary.fields[index].options.map(option => {
      option.selected = values.includes(parseInt(option.id))
      return option
    })
  }

  getStatisticsValue (key) {
    const nonOptionKeys = ['body_fats', 'heights', 'weight']
    if (nonOptionKeys.includes(key)) {
      const val = this.getStatisticsObj(key).value || ''
      if (val) {
        return val
      }
    }
    const value = this.getStatisticsOptions(key).find(option => {
      return option.selected || false
    })
    if (typeof value === 'undefined') {
      return 0
    }
    return value.value || 0
  }

  getInitialStatisticsValue (key) {
    return this.getStatisticsValue(key)
  }

  getInitialMeasurementValue (key) {
    let measurementData = this.progressData.measurements.fields.find(measurement => {
      return measurement.key === key
    })
    if (measurementData) {
      return measurementData.value
    }
    return null
  }

  getStatisticsOptions (key) {
    const field = this.progressData.statistics.fields.find(field => {
      return field.key === key
    })

    return field.options || []
  }

  getStatisticsObj (key) {
    return this.progressData.statistics.fields.find(field => {
      return field.key === key
    })
  }

  getDietaryValue (key) {
    const values = this.getDietaryOptions(key).filter(option => {
      return option.selected
    })

    return values.map(value => {
      return value.id
    })
  }

  getDietaryOptions (key) {
    console.log('sssssssssssss', this.mealPlanData)
    const field = this.mealPlanData.dietary.fields.find(field => {
      return field.key === key
    })
    return field.options
  }

  getFitnessValue (key) {
    const values = this.getFitnessOptions(key).filter(option => {
      return option.selected
    })
    return values.map(value => {
      return value.key
    })
  }

  getFitnessOptions (key) {
    const field = this.workoutData.fitness.fields.find(subField => {
      return subField.key === key
    })
    return field.options || []
  }

  getFitnessField (key) {
    const field = this.workoutData.fitness.fields.find(subField => {
      return subField.key === key
    })
    return field
  }

  getWorkoutPreferencesValue (workout, key) {
    const program = this.workoutData.workout_preferences.programs.find(program => {
      return program.id === workout
    })

    const field = program.fields.find(field => {
      return field.key === key
    })
    let values = field.options.filter(option => {
      return option.selected
    })
    values = values || []
    return values.map(value => {
      return value.key
    })
  }

  getMealPlanUpdate () {
    return new Promise((resolve, reject) => {
      if (this.mealPlanData === null) {
        this.getMealPlanUpdateFromAPI().then(res => {
          this.mealPlanData = res.data
          this.setDietaryValues()
          resolve(this.mealPlanData)
        }).catch(err => {
          reject(err)
        })
      } else {
        resolve(this.mealPlanData)
      }
    })
  }

  getAccountData () {
    return new Promise((resolve) => {
      let profile = new ProfileService()
      profile.setProfileData().then(() => {
        this.account = profile

        this.dietary.recipes = this.account.getSelectedRecipes()
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  getInjuriesList () {
    return new Promise((resolve, reject) => {
      const service = new InjuriesAPI()
      service.getList().then(data => {
        this.injuriesList = data.data.injuries
        resolve(data.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getDietTypeList () {
    return new Promise((resolve, reject) => {
      const service = new NutritionCategoryAPI()
      service.getList().then(data => {
        this.dietTypeList = data.data.categories
        console.log('this.dietTypeList', this.dietTypeList)
        resolve(data.data.categories)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getUniqueGoalsByType (categories) {
    const allGoals = categories.flatMap(category => category.goals)
    const uniqueGoals = Array.from(new Map(allGoals.map(goal => [goal.id, goal])).values())
    return uniqueGoals
  }

  _setHeightValue () {
    this.bodyStat.height = this.getSummaryValue('height')
  }

  _makeWeightForRequest () {
    if (this.bodyStat.weight.preference === 'metric') {
      this.bodyStat.weight = this.bodyStat.weight.kg
    } else {
      this.bodyStat.weight = this.bodyStat.weight.lbs
    }
    this.bodyStat.activity_multiplier = this.bodyStat.activity_level
    this.bodyStat.lbm = this.bodyStat.body_fat
  }

  _makeBodyFatForRequest () {
    const service = new SubscriptionService()
    if (this._config.showFatQuestion) {
      this.bodyStat.body_fat = service.mapData(parseInt(this.bodyStat.body_fat), 'bodyFat')
    } else {
      delete this.bodyStat.body_fat
    }
  }

  _modifyDataToRequest () {
    this._setHeightValue()
    this._makeWeightForRequest()
    this._makeBodyFatForRequest()
  }

  makeWorkoutUpdateRequestData () {
    if (this.getStatisticsValue('body_fats').length === 0) {
      this.bodyStat.body_fat = 10
    }
    if (this.getStatisticsValue('activity').length === 0) {
      this.bodyStat.activity_level = 1
    }
    this._modifyDataToRequest()
    this.fitness.injuries = this.arrayToIntArray(this.fitness.injuries)
    let measurements = {}
    for (const [mkey, mvalue] of Object.entries(this.measurements)) {
      if (mvalue) {
        measurements[mkey] = mvalue
      }
    }
    this.bodyStat.weight = this.getSummaryValue('weight') || this.getStatisticsValue('weight')
    const data = { ...this.bodyStat, ...measurements, ...this.fitness }

    data.body_fat = data.lbm || data.body_fat

    delete data.activity_level
    delete data.activity_multiplier
    data.programs = this.programs
    return data
  }

  makeMealPlanUpdateRequest () {
    this._modifyDataToRequest()
    if (this.dietary.meals_per_day) {
      this.dietary.mpd = this.dietary.meals_per_day
    }
    const planData = { }
    if (this.plan.result === 'yes') {
      planData['claims_no_results'] = false
      planData['results_achieved'] = true
      planData['followed_plan'] = true
    } else if (this.plan.follow === 'yes') {
      planData['claims_no_results'] = true
      planData['results_achieved'] = (this.plan.result === 'yes')
      planData['followed_plan'] = true
    } else if (this.plan.follow === 'no') {
      planData['claims_no_results'] = (this.plan.result !== 'yes')
      planData['results_achieved'] = (this.plan.result === 'yes')
      planData['followed_plan'] = false
    }
    this.dietary.excluded_ingredients = this.arrayToIntArray(this.dietary.excluded_ingredients)
    this.dietary.recipes = this.arrayToIntArray(this.dietary.recipes)
    let measurements = {}
    for (const [mkey, mvalue] of Object.entries(this.measurements)) {
      if (mvalue) {
        measurements[mkey] = mvalue
      }
    }

    let res = { ...this.bodyStat, ...measurements, ...this.dietary }

    if ('claims_no_results' in planData) {
      delete res.activity_level
      delete res.activity_multiplier
      return {...res, ...planData}
    }
    return res
  }

  updateMealPlan () {
    const api = new MealPlanAPI()

    return new Promise((resolve, reject) => {
      let data = this.makeMealPlanUpdateRequest()
      api.updateData(data).then(res => {
        let activityTracker = new ActivityTracker()

        activityTracker.addEvent('Journey.AddedMeasurement.Weight', { value: this.getWeightInMetric(data.weight), context: 'meal-progress-update' })
        activityTracker.addEvent('Journey.AddedMeasurement.BodyFat', { value: data.body_fat, context: 'meal-progress-update' }, false)
        try {
          this.logMealPlanUpdate()
          this.logProgressUpdate()
        } catch (e) {
          // ignore issue
        }

        resolve(res)
      }).catch(err => {
        if (err.response.status === 422) {
          this.errors = err.response.data.errors
        }
        reject(err.response)
      })
    })
  }

  getMealPlanCategoryName (id) {
    const selected = this.dietTypeList.find(cat => {
      return cat.id === id
    })
    return (selected) ? selected.name : id
  }
  getDietaryGoalName (goal, category) {
    const selected = this.DietCategoryOptions.dGoals[this.dietary.category].find(cat => {
      return goal === cat.id
    })

    return (selected) ? selected.name : goal
  }
  logMealPlanUpdate () {
    try {
      let payload = {
        'meal_plan':
          {
            'body_fat': (this.bodyStat.body_fat),
            'lbm': (this.bodyStat.body_fat) * 100,
            'ntr_goal': {id: this.dietary.ntr_goal, label: this.getDietaryGoalName(this.dietary.ntr_goal, this.dietary.category)},
            'meals_per_day': this.dietary.mpd,
            'category': {id: this.dietary.category, label: this.getMealPlanCategoryName(this.dietary.category)}
          }
      }
      if (this.plan.result) {
        const smartCheckin = {
          'results_achieved': (this.plan.result === 'yes'),
          'followed_the_program': (this.plan.follow !== 'no')
        }
        payload['smart_checkin'] = smartCheckin
      }
      let activityTracker = new ActivityTracker()

      activityTracker.addEvent('MealPlan.ProgressCheckInComplete', payload)
    } catch (e) {
      console.log(e)
    }
  }
  logProgressUpdate () {
    let entryData = {
      'measurement_system': this.user.measurementSystem
    }
    for (const [mKey, mValue] of Object.entries(this.measurements)) {
      let initialMeasurementValue = this.getInitialMeasurementValue(mKey)
      if (parseFloat(initialMeasurementValue) !== parseFloat(mValue) && parseFloat(mValue)) {
        entryData[mKey] = parseFloat(mValue)
      }
    }

    entryData['weight'] = parseFloat(this.bodyStat.weight)
    entryData['body_fat'] = parseFloat(this.bodyStat.lbm)

    let trainerFeed = new TrainerFeedService()
    trainerFeed.logProgressUpdate(entryData)
  }

  updateWorkout () {
    const api = new WorkoutApi()
    return new Promise((resolve, reject) => {
      let data = this.makeWorkoutUpdateRequestData()
      api.updateData(data).then(res => {
        let activityTracker = new ActivityTracker()

        activityTracker.addEvent('Journey.AddedMeasurement.Weight', { value: this.getWeightInMetric(data.weight), context: 'workout-progress-update' })
        activityTracker.addEvent('Journey.AddedMeasurement.BodyFat', { value: data.body_fat, context: 'workout-progress-update' }, false)
        try {
          this.logWorkoutProgramUpdate()
          this.logProgressUpdate()
        } catch (e) {
          // ignore issue
        }
        resolve(res)
      }).catch(err => {
        if (err.response.status === 422) {
          this.errors = err.response.data.errors
        }
        reject(err.response.data)
      })
    })
  }

  logWorkoutProgramUpdate () {
    try {
      const programs = this.programs
      const payload = { workout_programs: [] }
      programs.forEach((program, index) => {
        const exLvl = this.getFitnessField('experience-level').options.find(lvl => lvl.key === program.level)
        const location = this.getFitnessField('exercise_location_preference').options.find(loc => loc.key === program.location)
        if (this.getWorkoutFormData(index)) {
          payload.workout_programs.push(
            {
              'id': program.id,
              'goal': {
                id: program.goal,
                label: this.getWorkoutFormData(index).options.workoutGoal.find(goal => goal.id === program.goal).text
              },
              'days_per_week': program.days_per_week,
              'session_duration': {
                id: program.session_duration,
                label: this.getWorkoutFormData(index).options.duration.find(option => option.id === program.session_duration).text
              },
              'location': (location.value || program.location).toLowerCase(),
              'experience_level': (exLvl.value || program.level).toLowerCase()
            }
          )
        }
      })
      const activityTracker = new ActivityTracker()
      activityTracker.addEvent('WorkoutProgram.ProgressCheckInComplete', payload)
    } catch (e) {
      console.log(e)
    }
  }
  getBodyFat () {
    const helper = new FormOptionHelper()
    return helper.getBodyFats()[this.user.gender || 'm']
  }

  getBodyFatTextEnabled () {
    const service = new FormOptionHelper()
    const bodyFatRanges = service.getBodyFats()

    if (typeof bodyFatRanges.isTextLabel !== 'undefined') {
      return bodyFatRanges.isTextLabel;
    } else {
      return false;
    }
  }

  getBodyFatInputFieldEnabled () {
    const service = new FormOptionHelper()
    const bodyFatRanges = service.getBodyFats()
    if (typeof bodyFatRanges.showInput !== 'undefined') {
      return bodyFatRanges.showInput;
    } else {
      return true;
    }
  }

  getEmptyFormData () {
    return {
      title: {
        main: '',
        sub: ''
      },
      errors: [],
      inputKey: '',
      inputValue: '',
      options: []
    }
  }

  /**
   * Generate Weight Form Data
   * @returns {{inputValue: (*|{preference: string, lbs: string, kg: string}), options: [], inputKey: string, title: {sub: string, main: *}, errors: {}}}
   */
  getWeightFormData () {
    let inputValue = {}
    if (typeof this.bodyStat.weight === 'object') {
      inputValue = this.bodyStat.weight
    } else {
      inputValue.preference = this.progressData.measurement_system
      if (this.progressData.measurement_system !== 'metric') {
        inputValue.lbs = this.bodyStat.weight || this.getStatisticsValue('weight')
      } else {
        inputValue.kg = this.bodyStat.weight || this.getStatisticsValue('weight')
      }
    }
    return {
      title: {
        main: i18n.t("message['progress.weight']"),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'weight',
      switchPreference: false,
      inputValue: inputValue,
      options: []
    }
  }

  /**
   * Generate BodyFat Form Data
   * @returns {{inputValue: *, options: *, inputKey: string, title: {sub: string, main: *}, errors: {bodyFat: (*|*[])}}}
   */
  getBodyFatFormData () {
    let weight = ''
    if (this.bodyStat.weight.preference === 'metric') {
      weight = this.bodyStat.weight.kg
    } else if (this.bodyStat.weight.preference === 'imperial') {
      weight = this.bodyStat.weight.lbs
    } else {
      weight = this.bodyStat.weight
    }
    return {
      title: {
        main: this.getProgressContent('body-fat'),
        sub: ''
      },
      errors: { bodyFat: this.errors.body_fats || [] },
      inputKey: 'body_fat',
      inputValue: parseFloat(this.bodyStat.body_fat) * 100,
      options: this.getBodyFat(),
      extraData: {
        weight: parseFloat(weight),
        height: parseFloat(this.bodyStat.height),
        system: this.user.measurementSystem,
        gender: this.user.gender,
        isTextLableEnabled: this.getBodyFatTextEnabled(),
        showBodyFatInputField: this.getBodyFatInputFieldEnabled()
      }
    }
  }

  /**
   * Generate Activity Level Form Data
   * @returns {{infoText: (*|string), inputValue: *, options: *, inputKey: string, title: {sub: VueI18n.TranslateResult, main: VueI18n.TranslateResult}, errors: {}}}
   */
  getActivityLevelFormData () {
    return {
      title: {
        main: this.getProgressContent('activity-level'),
        sub: i18n.t("message['form-sub-title.activity-level']")
      },
      infoText: this.getProgressContent('activity-level-info-txt'),
      errors: this.errors,
      inputKey: 'activity_level',
      inputValue: this.bodyStat.activity_level,
      options: this.getStatisticsOptions('activity').map(option => {
        return { key: option.value, value: option.label }
      })
    }
  }

  getCheckResultFormData () {
    return {
      title: {
        main: i18n.t("message['form.check-past-meal-plan-has-produced-result']"),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'result',
      inputValue: '',
      options: [
        { key: 'yes', value: i18n.t("message['general.form.answer.yes']") },
        { key: 'no', value: i18n.t("message['general.form.answer.no']") }
      ]

    }
  }
  getCheckFollowPlanFormData () {
    return {
      title: {
        main: i18n.t("message['form.check-meal-plan-is-followed']"),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'follow',
      inputValue: '',
      options: [
        { key: 'yes', value: i18n.t("message['general.form.answer.yes']") },
        { key: 'no', value: i18n.t("message['general.form.answer.no']") }
      ]

    }
  }

  /**
   *  Generate Diet Type Form Data
   * @returns {{inputValue: *, options: (string|[]|[{value: string, key: string}, {value: string, key: string}, {value: string, key: string}]|{value: string, key: string}), inputKey: string, title: {sub: string, main: (*|string)}, errors: {nutritional_category: (value.data.nutritionalPref|*[])}}}
   */
  getDietTypeFormData () {
    return {
      title: {
        main: this.getDietContent('nutritional-prefs') || i18n.t("message['subscriptions.nutrition-preferences']"),
        sub: ''
      },
      inputKey: 'category',
      inputValue: this.dietary.category,
      options: this.getFilteredNutritionalPrefs(this.dietTypeList, this.dietary.ntr_goal)
    }
  }

  getFilteredNutritionalPrefs(nutritionalPrefs, selectedGoalId) {
    return nutritionalPrefs
      .map(item => ({
        ...item,
        goals: item.goals.filter(goal => goal.id === selectedGoalId) // Keep only matching goals
      }))
      .filter(item => item.goals.length > 0); // Remove items with empty goals array
  }

  /**
   * Generate Allergies Form Data
   * @returns {{inputValue: *, options: ([]|*[]), inputKey: string, title: {sub: string, main: *}, errors: {}}}
   */
  getAllergiesFormData () {
    return {
      title: {
        main: this.getDietContent('blacklisted-ingredients'),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'excluded_ingredients',
      inputValue: this.getDietaryValue('excluded_ingredients'),
      options: this.ingredientsList
    }
  }

  getMealPlanDaysFormData () {
    let inputValue = (this.dietary['days_per_mealplan'])?this.dietary['days_per_mealplan']:null
    inputValue = (inputValue)?inputValue:((Array.isArray(this.getDietaryValue('days_per_mealplan'))) ? this.getDietaryValue('days_per_mealplan')[0] : this.getDietaryValue('days_per_mealplan'))

    return {
      title: {
        main: i18n.t("message['form-title.meal-plan-days']"),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'days_per_mealplan',
      inputValue: inputValue,
      options: Object.entries(this.DietCategoryOptions.days_per_mealplan).map(([key, value]) => {
        return { key: key, value: value };
      })
    }
  }

  getPagePreferredRecipesData () {
    return {
      title: {
        main: i18n.t("message['form-title.preferred-recipe']"),
        sub: ''
      },
      errors: this.errors,
      inputKey: 'recipes',
      inputValue: this.dietary.recipes,
      options: this.recipes.map(option => {
        return { id: option.id, text: option.name }
      })
    }
  }
  /**
   * Generate Vegetarian Days Form Data
   * @returns {{inputValue: *, options: *, inputKey: string, title: {sub: string, main: string | VueI18n.LocaleMessages | string}, errors: {}}}
   */
  getVegetarianDaysFormData () {
    return {
      title: {
        main: this.getDietContent('vegetarian-days'),
        sub: ''
      },
      inputKey: 'vege',
      inputValue: this.dietary.vege,
      options: this.DietCategoryOptions.vegetarian[this.dietary.category].map(option => this._mapVegetarianDaysOptions(option))
    }
  }

  /**
   * map the vegetarian day options
   * @param option
   * @returns {function(*=): {value: string, key: *}}
   * @private
   */
  _mapVegetarianDaysOptions (option) {
    let optionInInt = parseInt(option)
    return {
      value: i18n.tc('message["progress.vegetarian-day-count"]', optionInInt, { days: optionInInt }),
      key: option
    }
  }

  /**
   * Generate Dietary Goal Form Data
   * @returns {{inputValue: *, options: *, inputKey: string, title: {sub: string, main: (*|string)}, errors: {nutrition_goals: (value.data.dGoal|*[])}}}
   */
  getDietaryGoalFormData () {
    return {
      title: {
        main: this.getDietContent('nutrition-goal') || '',
        sub: ''
      },
      errors: { nutrition_goals: (this.errors.ntr_goal || []) },
      inputKey: 'ntr_goal',
      inputValue: this.dietary.ntr_goal,
      options: this.getUniqueGoalsByType(this.dietTypeList)
    }
  }

  getUniqueGoalsByType (categories) {
    const allGoals = categories.flatMap(category => category.goals)
    const uniqueGoals = Array.from(new Map(allGoals.map(goal => [goal.id, goal])).values())
    return uniqueGoals
  }


  isSelectedGoalMultiplierOne(){

    const selectedGoal = this.DietCategoryOptions.dGoals[this.dietary.category].find(goal=>goal.id===this.dietary.ntr_goal)
    return selectedGoal && selectedGoal.type==='maintain-weight'
  }

  /**
   * Generate Dietary Meals Pre Day Form Data
   * @returns {{inputValue: *, options: {value: string, key: string}[], inputKey: string, title: {sub: string, main: *}, errors: {meals_per_day: (value.data.mealsPerDay|*[])}}}
   */
  getMealsPreDayFormData () {
    return {
      title: {
        main: this.getDietContent('nutrition-meals-number'),
        sub: ''
      },
      errors: { mpd: (this.errors.mpd || []) },
      inputKey: 'meals_per_day',
      inputValue: this.dietary.meals_per_day || this.dietary.mpd,
      options: Object.keys(this.DietCategoryOptions.mpd[this.dietary.category]).map(option => {
        return { key: option, value: option }
      })
    }
  }

  /**
   * returns a empty valued workout object
   * @param id
   * @deprecated @param weeks
   * @returns {{workout_goal: string, duration: string, weeks: number, days_per_week: string, workout_days: string, id: *}}
   */
  setEmptyWorkout (id, weeks = null) {
    return {
      id: id,
      workout_goal: '',
      workout_days: '',
      days_per_week: '',
      duration: ''
    }
  }

  /**
   * get the workout option by index
   * @param index
   * @returns {*}
   */
  getWorkout (index) {
    return this.workoutData.workout_preferences.programs[index]
  }

  /**
   * returns the workouts count
   * @returns {number}
   */
  getWorkoutCount () {
    return this.workoutData.workout_preferences.programs.length
  }

  /**
   *  returns the workout goal values by the index
   * @param workoutIndex
   * @returns {*}
   */
  getWorkoutGoals (workoutIndex = 0) {
    const gender = this.getValue('gender')
    return this.options.workouts[workoutIndex].workout_goals[gender].map(option => {
      return { id: option.id, text: option.value }
    })
  }

  /**
   *  returns the workout session duration  values by the index
   * @param workoutIndex
   * @returns {*}
   */
  getSessionDuration (workoutIndex = 0) {
    const gender = this.getValue('gender')
    return this.options.workouts[workoutIndex].durations[gender].map(option => {
      return { id: option.id, text: option.value }
    })
  }

  /**
   *  returns the workout days values by the index
   * @param workoutIndex
   * @returns {*}
   */
  getDaysPerWeek (workoutIndex = 0) {
    const gender = this.getValue('gender')
    return this.options.workouts[workoutIndex].days[gender].map(option => {
      const newop = {}
      newop.text = i18n.tc('message["subscriptions.days-a-week"]', parseInt(option.value), [parseInt(option.value)])
      newop.id = option.value
      return newop
    })
  }

  getWorkoutProgramByIndex (workoutIndex) {
    return this.workoutData.workout_preferences.programs[workoutIndex]
  }

  getWorkoutsFormData () {
    const workouts = []
    let workoutIndex = 0
    for (workoutIndex = 0; workoutIndex < this.getWorkoutCount(); workoutIndex++) {
      workouts.push(this.getWorkoutFormData(workoutIndex))
    }
    const formData = this.getEmptyFormData()
    formData.options = workouts
    return formData
  }

  /**
   * Generate Workout Form Data
   * @param workoutIndex
   * @returns {{inputValue: *, options: {duration: *, daysPreWeek: *, workoutGoal: *}, inputKey: string, title: {duration: *, image: (*|string), sub: string, dayPreWeek: *, main: *, workoutGoal: *}, errors: {duration: (*|*[]), workoutGoal: (*|*[]), daysPerWeek: (*|*[])}}}
   */
  getWorkoutFormData (workoutIndex) {
    const daysPerWeekField = this.getWorkoutProgramByIndex(workoutIndex).fields.find(field => field.key === 'days_per_week')
    const sessionField = this.getWorkoutProgramByIndex(workoutIndex).fields.find(field => field.key === 'session_duration')
    const goalField = this.getWorkoutProgramByIndex(workoutIndex).fields.find(field => field.key === 'goal')
    const options = {}

    const exerciseLocation = this.getFitnessField('exercise_location_preference')
    options.exerciseLocation = exerciseLocation.options.map(option => {
      return {
        id: option.key,
        text: option.value
      }
    })
    const experienceLevel = this.getFitnessField('experience-level')
    options.experienceLevel = experienceLevel.options.map(option => {
      return {
        id: option.key,
        text: option.value
      }
    })
    /**
     * map workout goal options
     */
    options.workoutGoal = goalField.options.map(data => {
      return { id: data.key, text: data.value }
    })

    /**
     * map duration options
     * days per we is depend on the goals
     */
    options.duration = sessionField.options.map(data => {
      let supportedGoals = data.supported_goals || []
      return { id: data.key, text: data.value, supportedGoals: supportedGoals }
    })

    /**
     * map days per week options
     * days per week is depend on the session_durations
     */
    options.daysPreWeek = daysPerWeekField.options.map(data => {
      let supports = data.supports || []
      supports = supports.map(supportOption => {
        if (!supportOption.goals && supportOption.goal) {
          supportOption.goals = [supportOption.goal]
        }
        return supportOption
      })
      return { id: data.key, text: data.value, supports }
    })

    options.supports = this.getWorkoutProgramByIndex(workoutIndex).supports
    const title = {}
    title.main = this.getWorkoutProgramByIndex(workoutIndex).name
    title.image = this.getWorkoutProgramByIndex(workoutIndex).image
    title.dayPreWeek = daysPerWeekField.name
    title.duration = sessionField.name
    title.workoutGoal = goalField.name
    title.experienceLevel = experienceLevel.name
    title.exerciseLocation = exerciseLocation.name

    const errors = {
      daysPerWeek: (this.errors['programs.' + workoutIndex + '.days_per_week'] || []),
      duration: (this.errors['programs.' + workoutIndex + '.session_duration'] || []),
      workoutGoal: (this.errors['programs.' + workoutIndex + '.goal'] || [])
    }
    return {
      title,
      inputKey: 'workout',
      errors: errors,
      inputValue: this.mapWorkoutData(this.programs[workoutIndex]),
      validationRules: 'required',
      options: options
    }
  }

  mapWorkoutData (workout) {
    return {
      id: workout.id,
      workout_goal: workout.goal,
      workout_days: workout.day_per_week,
      days_per_week: workout.day_per_week,
      duration: workout.session_id,
      level: workout.level,
      location: workout.location
    }
  }

  saveWorkouts (workouts) {
    let index = 0
    for (index = 0; index < this.getWorkoutCount(); index++) {
      this.programs[index] = this.mapReverseWorkoutData(workouts[index])
    }
  }

  mapReverseWorkoutData (workout) {
    return {
      id: workout.id,
      goal: workout.workout_goal,
      days_per_week: workout.days_per_week,
      session_duration: workout.duration,
      level: workout.level,
      location: workout.location,
      exercise_location_preference: workout.location,
      experience_level: workout.level
    }
  }

  /**
   * Generate  Injuries Form Data
   * @returns {{inputValue: *, options: ([]|*[]), inputKey: string, title: {sub: *, main: VueI18n.TranslateResult}, errors: {}}}
   */
  getInjuriesFormData () {
    const field = this.getFitnessField('injuries')
    return {
      title: {
        main: field.name,
        sub: ''
      },
      errors: this.errors,
      inputKey: 'injuriesPrvtExc',
      inputValue: this.fitness.injuries,
      options: this.injuriesList.map(option => {
        return { id: option.id, text: option.value }
      })
    }
  }

  /**
   * Generate Experience Level Form Data
   * @returns {{inputValue: *, options: ([]|*[]), inputKey: string, title: {sub: string, main: *}, errors: {level: (*|*[])}}}
   */
  getExperienceLevelFormData () {
    const field = this.getFitnessField('experience-level')

    return {
      title: {
        main: field.name,
        sub: ''
      },
      errors: this.errors,
      inputKey: 'experience_level',
      inputValue: this.fitness.experience_level,
      options: field.options
    }
  }

  /**
   * Generate Exercise Location Form Data
   * @returns {{inputValue: *, options: ([]|*[]), inputKey: string, title: {sub: string, main: *}, errors: {location: (*|*[])}}}
   */
  getExerciseLocationFormData () {
    const field = this.getFitnessField('exercise_location_preference')
    return {
      title: {
        main: field.name || 'Exercise Location Preference',
        sub: ''
      },
      errors: this.errors,
      inputKey: 'exercise_location_preference',
      inputValue: this.fitness.exercise_location_preference,
      options: field.options
    }
  }

  getWeightInMetric (value) {
    if (this.user.measurementSystem === 'metric') {
      return Number.parseFloat(value)
    }
    return Math.round(Math.round(value * 0.453592 * 100) / 100)
  }

  arrayToIntArray (array) {
    if (array) {
      let mappedArray = array.map(item => {
        return parseInt(item) || 0
      })

      return mappedArray.filter(item => {
        return !!item
      })
    }
    return []
  }

  showIngredientDislikes () {
    return this._config.showIngredientDislikes
  }
  showInjuries () {
    return this._config.showInjuries
  }

  getHeight () {
    return this.bodyStat.height || 0
  }

  getErrors () {
    return this.errors || {}
  }

  showPreferredRecipes () {
    return this._config.showPreferredRecipes
  }


  /**
   * check suggested habits exists
   * @returns {boolean}
   */
  hasSuggestedHabits(){
    return this.suggestedHabits.length>0
  }

  getProductUUID(){
    const dashboard = new DashBoardService()
    const subscription =  dashboard.userData.subscriptions.find(sub => {
      return sub.id === this.subscriptionID
    })

    if(subscription && subscription.product_plan_uuid){
      return subscription.product_plan_uuid
    }
    return ''
  }

  /**
   * get suggested habits
   * @returns {*[]}
   */
  getSuggestedHabits () {
    const productUUID = this.getProductUUID()
    return this.suggestedHabits.map(habit=>{
      return {
        id:habit,
        name:habit,
        habit:habit,
        productId: productUUID
      }
    })
  }

}

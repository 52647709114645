import {setMAApplicationData} from '@/helpers/dom/header'
import AuthService from "@/services/AuthService";
import DefineInclude from "@/DefineInclude";
export default {
  beforeRouteEnter(to, from, next) {
    let auth = new AuthService()
    if (auth.isLoggedIn()) {
      // go to payment page
      next({
        path: DefineInclude.appUrlList.signUpPay.replace(':plan', to.params.plan),
      })
    } else {
      next()
    }

  },
  data: function () {
    return {
      state: 'init',
      headerOptions: {
        show: false
      },
      serverError: '',
      showStatementTextError: false,
      countries: [],
      regions: [],
      terms: [],
      hasExtraField: false,
      hasActivationField: false,
      extraFieldTitle: '',
      plan: {
        title: '',
        period: '-',
        price: 0.0,
        type: 'subscription',
        currency: '',
        status: 'loading',
        trialPeriod: 0,
        discountedPrice: 0,
        description: '',
        checkoutType: ''
      },
      isPostCodeRequired: false,
      isStripeCheckout: false,
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        region_name: '',
        password: '',
        plan_uuid: '',
        postcode: '',

      },
      service: null,
      introDescriptionText: '',
      passwordStrengthMessage: '',
      passwordStrength: '',
      showLegalDisclaimerPopup: false,
      showPrivacyPolicyPopup: false,
      isUserRegistered: false,
    }
  },
  watch: {
    'formData.country': function () {
      this.formData.region_name = ''
      this.setRegionsByCountry()

      this.isPostCodeRequired = this.service.isPostalCodeRequired(this.formData.country)
    }
  },
  mounted () {
    const paymentMethodId = sessionStorage.getItem('payment_method_id');
    if (paymentMethodId) {
      sessionStorage.removeItem('payment_method_id');
    }
  },
  computed: {
    submitButtonText: function () {
      if (this.state === 'submitted') {
        return ''
      }
      return this.$i18n.t('message[\'sign-up.register.submit\']')
    },
    statementText: function () {
      const textPP = this.$i18n.t('message[\'settings.privacy-policy\']')
      const textLD = this.$i18n.t('message[\'settings.legal-disclaimer\']')
      const ppHTML = `<span class="privacy-text pc-sign-up-register__registration-form-privacy-text-bold">${textPP}</span>`
      const ldHTML = `<span class="terms-text pc-sign-up-register__registration-form-disclaimer-text-bold">${textLD}</span>`
      let data = {
        privacyPolicy: ppHTML,
        termsAndConditions: ldHTML
      }
      return this.$i18n.t('message["sign-up.register.form-agree-privacy-policy-and-terms"]', data)
    }

  },
  methods: {
    setRegionsByCountry() {
      this.regions = this.service.getRegionsByCountry(this.formData.country).map(region => {
        return {
          id: region.name,
          text: region.name
        }
      })
    },
    setCountries() {
      this.countries = this.service.getCountries().map(country => {
        return {
          id: country.id,
          text: country.name
        }
      })
    },
    async setPlanData() {
      let data = await this.service.getPlanDetails()
      this.formData.plan_uuid = this.service.getPlanUUID()
      this.plan.currency = data.currency
      this.plan.period = data.billing_period
      this.plan.price = data.price
      this.plan.title = data.plan_name
      this.plan.type = data.plan_type
      this.plan.description = data.plan_description
      this.plan.checkoutType = data.checkout_type
      this.plan.trialPeriod = data.trial_period
      this.plan.activationCodeRequired = data.activation_code_required
      this.plan.status = 'default'
      if (typeof data.plan_product_description !== 'undefined') {
        this.plan.description = data.plan_product_description
      } else if (typeof data.contents['page-content'] !== 'undefined') {
        this.plan.description = data.contents['page-content']
      }
      setMAApplicationData('plan-id', this.plan.title)
      setMAApplicationData('plan-price', this.plan.price)
      setMAApplicationData('plan-currency', this.plan.currency)
      sessionStorage.setItem('plan-id', this.plan.title)
      sessionStorage.setItem('plan-price', this.plan.price)
      sessionStorage.setItem('plan-currency', this.plan.currency)
    },
    checkTerms() {
      if (this.terms.length === 0) {
        this.showStatementTextError = (this.terms.length === 0)
      } else {
        this.showStatementTextError = false
      }

      this.$refs.form.validate()
    },
    async submit() {
      if (this.terms.length === 0) {
        this.showStatementTextError = (this.terms.length === 0)
      }
      this.serverError = ''
      const activationCodeCheck = await this.checkActivationCode()
      if (!activationCodeCheck) {
        this.$refs.form.setErrors({
          activation_code: [
            this.$i18n.t('message.validation.messages.invalid').replace('{_field_}', this.$i18n.t('message[\'sign-up.form.activation-code-field\']'))
          ]
        })
        return false
      }

      if (this.passwordStrength === 'weak') {
        return false
      }
      if (this.terms.length === 0 || this.state === 'submitted') {
        return false
      }
      this.state = 'submitted'
      this.triggerSignUpCompleted()
      this.service.registerUser(this.formData).then(async () => {

        this.isUserRegistered = true
        await this.service.getPlanDetails({forceAPI: true})

        this.goToPaymentPage()
      }).catch(err => {
        if (err.error === 'invalid-address') {
          this.isStripeCheckout = false
          this.isPostCodeRequired = true
          this.$refs.form.setErrors({
            postcode: [
              this.$i18n.t("message['validation.invalid-postal-code']")
            ]
          })
          const postcodeInput = document.querySelector('.pc-sign-up-payment__registration-form-input-postcode');
          if (postcodeInput) {
            postcodeInput.scrollIntoView({behavior: 'smooth'});
          }
        } else if (err.name === 'UserAlreadyRegisteredError') {
          let loginHTML = '<span class="login rc-text-content--style-underline rc-text-content--weight-extra-bold">' + this.$i18n.t("message['sign-up.register.login']") + '</span>'
          this.$refs.form.setErrors({
            email: [
              this.$i18n.t("message['sign-up.form.email-already-registered-error']", {login: loginHTML})
            ]
          })
          const emailInput = document.querySelector('.pc-sign-up-register__registration__email');
          if (emailInput) {
            emailInput.scrollIntoView({behavior: 'smooth'});
          }
        } else if (err.name === 'InputDataValidationError') {
          console.log(err.errors)
          this.$refs.form.setErrors(err.errors)
        } else {
          this.serverError = err.message
        }
      }).finally(() => {
        this.state = 'init'
      })
    },
    triggerSignUpCompleted() {

      try {
        const frequency = parseInt(this.plan.period.split(' ')[0], 10) || 0

        const period = this.plan.period.split(' ')[1].replace('s', '') || ''
        const eventData = {
          first_name: this.formData.first_name,
          last_name: this.formData.last_name,
          email: this.formData.email,
          plan: this.plan.title,
          plan_period: period,
          plan_period_text: this.plan.period,
          plan_currency: this.plan.currency,
          plan_frequency: frequency,
          plan_price: this.plan.price,
          plan_type: this.plan.type,
          trial_period: this.plan.trialPeriod,
        };

        const event = new CustomEvent('signUpCompleted', {detail: eventData});
        window.dispatchEvent(event);
      } catch (e) {
        console.log('Error in triggerSignUpCompleted', e)
      }
    },
    goToPaymentPage() {
      this.setService(this.service)

      const page = document.querySelector('.pc-sign-up-register')
      if (page) {
        page.style.display = "none";
      }
      this.$router.push({
        path: this.$appConfig.appUrlList.signUpPay.replace(':plan', this.$route.params.plan),
        query: {loading: 0}
      })
    },
    passwordIsStrong() {
      this.passwordStrength = 'strong'
    },
    passwordIsWeak() {
      this.passwordStrength = 'weak'
    },
    async checkActivationCode() {
      if (this.plan.activationCodeRequired) {
        return this.service.checkActivationCode(this.formData.activation_code)
      }
      return true
    },
    privacyPolicyClicked() {
      this.showPrivacyPolicyPopup = true
    },
    legalDisclaimerClicked() {
      this.showLegalDisclaimerPopup = true
    },
    closePrivacyPolicyPopup() {
      this.showPrivacyPolicyPopup = false
    },
    closeLegalDisclaimerPopup() {
      this.showLegalDisclaimerPopup = false
    },
    statementTextClicked(e) {
      if (e.srcElement.classList.contains('privacy-text')) {
        this.privacyPolicyClicked()
      } else if (e.srcElement.classList.contains('terms-text')) {
        this.legalDisclaimerClicked()
      }
    },
    emailErrorClick(e) {
      if (e.srcElement.classList.contains('login')) {
        this.goToLogin()
      }
    }
  }

}

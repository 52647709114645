<template>
  <div class="pc-habit-tracker-manger-skeleton container gc-page__container container">
    <div class="pc-habit-tracker-manger-skeleton_section-one">
      <AnimatedPlaceholder width="100%" height="24px" v-for="index in 7"
      :key="index"/>
    </div>
    <div class="pc-habit-tracker-manger-skeleton_section-two">
      <div class="pc-habit-tracker-manger-skeleton_section-two-item" v-for="index in 7"
      :key="index">
        <AnimatedPlaceholder width="24px" height="24px" border-radius="50%" />
        <AnimatedPlaceholder width="150px" height="24px" />
      </div>
     
    </div>
  </div>
</template>

<script >
import AnimatedPlaceholder from '@/components/global/animators/AnimatedPlaceholder.vue';

export default {
    components: {
        AnimatedPlaceholder
    }
}
</script>

<style>


</style>
<template>
  <div>
    <div class="gc-card-food-diary-main" v-if="underMaintenance">
      <!-- Header Section -->
      <header-simple
        class="gc-card-food-diary-main__simple-header"
        :header-text="headerText"
        v-if="headerText"
      />
      <icon-info class="gc-card-food-diary-main__info-icon" />
      <div class="gc-card-food-diary-main__first-time-with-header">
        <text-body-extra-small
          class="gc-card-food-diary-main__first-time-with-header-text"
        >
          {{
            $t('message["feature.maintenance-mode"]', {
              featureName: $t('message["food-diary.title"]'),
            })
          }}
        </text-body-extra-small>
      </div>
    </div>
    <div v-else :class="componentClasses">
      <!-- Header Section -->
      <div class="gc-card-food-diary-main__simple-header_wraper">
        <header-simple
          class="gc-card-food-diary-main__simple-header"
          :header-text="headerText"
          :icon="headerIcon"
          :show-info-icon="true"
          v-if="headerText"
          @info-icon-click="showInfoPopup()"
          @click="emitGoToFoodDiary()"
        />
      </div>

      <!-- Macro Stats Section -->
      <div class="gc-card-food-diary-main__macro-section" v-if="hasValues">
        <div
          v-for="macro in macroNutrients"
          :key="macro.id"
          class="gc-card-food-diary-main__macro-item"
        >
          <div class="gc-card-food-diary-main__macro-name-box">
            <text-body-extra-small class="gc-card-food-diary-main__macro-name"
              >{{ firstLetterCaps(macro.name) }}
            </text-body-extra-small>
            <icon-warning
              class="gc-card-food-diary-main__warning-icon"
              v-if="
                macroNutrientsPercentage(macro.quantity, macro.maxQuantity) >
                100
              "
            />
          </div>
          <div class="gc-card-food-diary-main__macro-percentage-wrapper">
            <text-body-regular class="gc-card-food-diary-main__macro-percentage"
              >{{ roundNumber(macro.quantity) }}
            </text-body-regular>
            <text-body-regular
              class="gc-card-food-diary-main__macro-percentage"
            >
              {{ $t("message['food-diary.unit-grams']") }}
            </text-body-regular>
          </div>
        </div>
      </div>
      <!-- Calories Section -->
      <div class="gc-card-food-diary-main__calories-section" v-if="hasValues">
        <div class="gc-card-food-diary-main__calories-header">
          <div
            class="gc-card-food-diary-main__macro-name-box"
            v-show="!hideCalories"
          >
            <text-body-extra-small
              class="gc-card-food-diary-main__calories-name"
              >{{ firstLetterCaps(calories.name) }}
            </text-body-extra-small>
            <icon-warning
              class="gc-card-food-diary-main__warning-icon"
              v-if="
                macroNutrientsPercentage(
                  calories.quantity,
                  calories.maxQuantity
                ) > 100
              "
            />
          </div>
          <div
            dir="ltr"
            class="gc-card-food-diary-main__calories-count-wrapper"
            v-show="!hideCalories"
          >
            <text-body-small class="gc-card-food-diary-main__calories-count">
              <div>{{ roundNumber(calories.quantity.toFixed(0)) }}</div>
              <div>{{ calories.unit }}</div>
              <div>&nbsp;/&nbsp;</div>
              <div>{{ roundNumber(calories.maxQuantity.toFixed(0)) }}</div>
              <div>{{ calories.unit }}</div>
            </text-body-small>
          </div>
        </div>
        <progress-bar
          v-show="!hideCalories"
          :progress="
            caloriesPercentage(calories.quantity, calories.maxQuantity)
          "
        />
        <text-body-extra-small
          v-if="logNow"
          class="gc-card-food-diary-main__button-log-now"
          :weight="'extra-bold'"
          :decoration="'underline'"
          :line-height="'multi'"
          @click="emitLogNow()"
        >
          {{ $t('message["food-diary.log-card-log-now"]') }}
        </text-body-extra-small>
      </div>
      <!-- Instruction Texts For First Time User (with simple header) -->
      <div
        class="gc-card-food-diary-main__first-time-with-header"
        v-if="!hasValues && showLogNowWhenEmpty"
      >
        <text-body-extra-small
          class="gc-card-food-diary-main__first-time-with-header-text"
        >
          {{ emptyStateDescription }}
        </text-body-extra-small>
        <text-body-extra-small
          v-if="logNow"
          class="gc-card-food-diary-main__first-time-with-header-button"
          @click="emitLogNow()"
        >
          {{ $t('message["food-diary.log-card-log-now"]') }}
        </text-body-extra-small>
      </div>
      <!-- Instruction Texts For First Time User (without simple header) -->
      <div
        class="gc-card-food-diary-main__first-time"
        v-if="!hasValues && !showLogNowWhenEmpty"
      >
        <text-body-extra-small class="gc-card-food-diary-main__first-time-text">
          {{
            $t('message["food-diary.log-card-description-without-log-area"]')
          }}
        </text-body-extra-small>
      </div>
    </div>
  </div>
</template>

<script>
import IconWarning from "../../root/icons/IconWarning";
import IconInfo from "../../root/icons/IconInfo";
import HeaderSimple from "../headers/HeaderSimple";
import ProgressBar from "../progress-bar/ProgressBar";
import TextBodyExtraSmall from "../typography/TextBodyExtraSmall";
import TextBodyRegular from "../typography/TextBodyRegular";
import TextBodySmall from "../typography/TextBodySmall";
import CardContent from "./CardContent";
import DashBoardService from "../../../services/DashboardService";
import { mapGetters, mapMutations } from "vuex";
import { getVisibilityOfCaloriesInNutrition } from "@/includes/TemplateSettings";

export default {
  name: "CardFoodDiaryMain",
  components: {
    HeaderSimple,
    ProgressBar,
    TextBodyExtraSmall,
    TextBodyRegular,
    TextBodySmall,
    IconWarning,
    CardContent,
    IconInfo,
  },
  data: function () {
    return {
      hideCalories: getVisibilityOfCaloriesInNutrition(),
    };
  },
  props: {
    headerText: {
      type: String,
      default: "",
    },
    logNow: {
      type: Boolean,
      default: true,
    },
    headerIcon: {
      type: String,
      default: "",
    },
    calories: {
      type: Object,
      default: function () {
        return {
          name: "calories",
          quantity: 0,
          maxQuantity: 0,
          unit: "cal",
        };
      },
    },
    showLogNowWhenEmpty: {
      default: false,
      type: Boolean,
    },
    macroNutrients: {
      type: Array,
      default: function () {
        return [
          {
            name: "Protien",
            quantity: 0,
            maxQuantity: 0,
            unit: "%",
          },
          {
            name: "Fat",
            quantity: 0,
            maxQuantity: 0,
            unit: "%",
          },
          {
            name: "Carb",
            quantity: 0,
            maxQuantity: 0,
            unit: "%",
          },
          {
            name: "Net Carb",
            quantity: 0,
            maxQuantity: 0,
            unit: "%",
          },
        ];
      },
    },
    underMaintenance: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: "getPlanExpiredPopupShow",
    }),

    emptyStateDescription: function () {
      if (getVisibilityOfCaloriesInNutrition()) {
        return this.$t(
          'message["food-diary.log-card-description-no-calories"]'
        );
      }
      return this.$t('message["food-diary.log-card-description"]');
    },
    hasValues: function () {
      return (
        Boolean(this.calories.quantity) ||
        this.macroNutrients.some((macro) => {
          return Boolean(macro.quantity);
        })
      );
    },
    componentClasses: function () {
      return {
        "gc-card-food-diary-main": true,
        "gc-card-food-diary-main-empty":
          !this.hasValues && !this.showLogNowWhenEmpty,
      };
    },
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: "showSubscriptionExpiredPopup",
      showFoodDiaryInfoPopup: "showFoodDiaryInfoPopup",
    }),
    emitLogNow() {
      let dashboard = new DashBoardService();
      if (!dashboard.isEntriesEditable()) {
        if (!this.getPlanExpiredPopupShow) {
          this.showSubscriptionExpiredPopup("foodDiary_add_entry");
        }
      } else {
        this.$emit("log-now");
      }
    },
    showInfoPopup() {
      this.showFoodDiaryInfoPopup();
    },
    emitGoToFoodDiary() {
      this.$emit("header-clicked");
    },
    macroNutrientsPercentage(quantity, maxQuantity) {
      return Math.round((quantity / maxQuantity) * 100);
    },
    caloriesPercentage(quantity, maxQuantity) {
      return (quantity / maxQuantity) * 100;
    },
    roundNumber(num) {
      return Math.round(num) || 0;
    },
  },
};
</script>

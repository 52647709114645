<template>
  <div class="pc-wearable-skeleton">
    <div class="container">
      <div class="pc-wearable-skeleton__section-one">
        <AnimatedPlaceholder width="60%" height="16px" />
      </div>
      <div
        class="pc-wearable-skeleton__link gc-page-connect-wearables__button pc-wearable-skeleton__link-first"
      >
        <div class="pc-wearable-skeleton__link-section-one">
          <div class="pc-wearable-skeleton__link-icon">
            <AnimatedPlaceholder
              width="35px"
              height="35px"
              border-radius="50%"
            />
          </div>
          <div class="pc-wearable-skeleton__link-info">
            <AnimatedPlaceholder width="150px" height="20px" />
            <AnimatedPlaceholder width="80px" height="12px" />
          </div>
        </div>

        <div class="pc-wearable-skeleton__link-arrow">
          <AnimatedPlaceholder width="24px" height="36px" />
        </div>
      </div>
      <div
        class="pc-wearable-skeleton__link gc-page-connect-wearables__button"
        v-for="index in 5"
        :key="index"
      >
        <div class="pc-wearable-skeleton__link-section-one">
          <div class="pc-wearable-skeleton__link-icon">
            <AnimatedPlaceholder
              width="35px"
              height="35px"
              border-radius="50%"
            />
          </div>
          <div class="pc-wearable-skeleton__link-info">
            <AnimatedPlaceholder width="150px" height="20px" />
            <AnimatedPlaceholder width="80px" height="12px" />
          </div>
        </div>

        <div class="pc-wearable-skeleton__link-arrow">
          <AnimatedPlaceholder width="24px" height="36px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "../animators/AnimatedPlaceholder";

export default {
  name: "page-step-tracker-skeleton",
  components: { AnimatedPlaceholder },
};
</script>

<style scoped></style>

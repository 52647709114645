<template>
  <popup
    v-if="visible"
    ref="mealSwapModal"
    :visible="visible"
    class="gc-popup-meal-swap"
    :no-close-on-backdrop="noCloseOnBackdrop"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close
        :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }"
        @hide="closePopup()"
      />
    </popup-title>
    <popup-body
      class="gc-popup-meal-swap__body text-center"
    >
      <popup-head class="text-center">
        <popup-text-heading>{{ title }}</popup-text-heading>
        <div
          v-if="status==='loading'"
          class="text-center"
        >
          <popup-text-heading>{{ $t('message["general.swapping"]') + '...' }}</popup-text-heading>
          <loading-buffer :height="'75'" />
        </div>
      </popup-head>
      <popup-content>
        <popup-text-body
          v-if="status==='init'"
          class="text-center info-modal__subtitle mb-2"
        >
          {{ $t('message["messages.swap-meal-popup"]') }}
        </popup-text-body>
        <icon-complete-filled
          v-if="status==='success'"
          :size="'lg3'"
        />

        <div v-if="status==='error'">
          <div class="text-center" />

          <p class="text-center info-modal__subtitle mb-2">
            {{ errorMessage }}
          </p>
        </div>
      </popup-content>
      <popup-footer v-if="status==='init'">
        <loading-buffer
          v-if="apiLoading"
          :height="'75'"
        />
        <div
          v-if="isFavouritesAvailable && !apiLoading && status !== 'loading' && status !== 'success'"
          class="gc-popup-meal-swap__favourite-check"
        >
          <input-check-box-squared
            :id="true"
            v-model="isCheckedFavouriteMeals"
          />
          <text-content
            :size="'sm2'"
            class="gc-popup-meal-swap__favourite-text"
          >
            {{ $t('message["swap.request-try-with-favourite-meal"]') }}
          </text-content>
        </div>
        <button-primary
          v-if="!apiLoading"
          :text="swapConfirmButtonText"
          @click="swapMeal()"
        />
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import MealPlanService from '../../../services/MealPlanService'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import mealSwapMixin from '../../../mixins/component-mixins/global/mealSwapMixin'
import DefineInclude from '../../../DefineInclude'
import Popup from './Popup.vue'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFloatingFooter from './popup-sub-components/PopupFloatingFooter'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import ProfileService from '../../../services/ProfileService'
import InputCheckBoxSquared from '../../global/inputs/InputCheckBoxSquared'
import TextContent from '../../root/TextContent'

export default {
  name: 'SwapMeal',
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFloatingFooter,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary,
    IconCompleteFilled,
    InputCheckBoxSquared,
    TextContent
  },
  extends: BaseComponent,
  mixins: [popupAnimationMixin, mealSwapMixin],
  props: {
    meal: {
      required: true
    },
    data: {
      default: function () {
        return {}
      },
      type: Object
    },
    visible: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      title: this.$i18n.t('message["swap.meal-popup-title"]'),
      status: 'loading',
      loading: true,
      confirmButtonText: this.$i18n.t('message["swap.meal-popup-button-text"]'),
      swapConfirmButtonText: this.$i18n.t('message["swap.confirm-button-text"]'),
      successMsg: 'success',
      errorMessage: 'Error',
      ingredientsToSwap: [],
      isCheckedFavouriteMeals: [],
      isFavouritesAvailable: false,
      apiLoading: true
    }
  },
  computed: {
    noCloseOnBackdrop: function () {
      return this.status === 'success' || this.status === 'loading'
    }
  },
  created () {
    this.status = 'init'
    const service = new MealPlanService()
    service.getMealPlan(this.$route.params.mid).then(response => {
      this.confirmButtonText = response.content['request-meal-substitute']
      this.successMsg = response.content['meal-substitute-requested']
    })
    this.checkFavouriteMealAvailable()
  },
  methods: {
    checkFavouriteMealAvailable() {
      const service = new MealPlanService()
      this.apiLoading = true
      const payload = {
        'meal_type_id': this.meal.type.id,
        'meal_plan_id': this.$route.params.mid
      }
      service.isFavouriteMealAvaiable(payload).then(response => {
        this.isFavouritesAvailable = response.data.favourites_available
        this.apiLoading = false
      })
    },
    async swapMeal () {
      this.status = 'loading'
      this.title = ''
      await this.setIngredients()
    },
    async setIngredients () {
      const promises = []
      this.meal.getRecipes().forEach(recipe => {
        promises.push(this.getRepeatIngredientSwap(recipe, this.$route.params.mid))
      })
      let results = await Promise.all(promises)

      results.forEach((repeatIngredientSwap) => {
        this.ingredientsToSwap = this.ingredientsToSwap.concat(repeatIngredientSwap)
      })

      let profileService = new ProfileService()
      await profileService.setProfileData()
      let ingredients = this.ingredientsToSwap
      let excludedIngredients = profileService.getExcludedIngrediants()
      let ingredientsArray = ingredients.map(x => x.id)
      let commonIngredients = ingredientsArray.filter(e => excludedIngredients.indexOf(e) !== -1)
      if (commonIngredients.length > 0) {
        for (let i = 0; i < commonIngredients.length;) {
          const idxObj = ingredients.findIndex(object => {
            return object.id === commonIngredients[i]
          })
          ingredients.splice(idxObj, 1)
          i++
        }
      }
      this.sendSwapRequest(ingredients)
    },
    sendSwapRequest (repeatIngredientSwap) {
      let isFavouriteSelected = false;
      if (this.isCheckedFavouriteMeals.length > 0 && this.isCheckedFavouriteMeals[0] === true) {
        isFavouriteSelected = true;
      }

      this.meal.swap(isFavouriteSelected)
        .then(() => {
          this.status = 'success'
          this.title = this.$i18n.t('message["meal-plan.swapped"]')
          this.updateMealSwapStatus([{
            mid: this.data.mealID,
            rmid: this.data.refMealID
          }])
          const recipeseArray = []
          this.meal.getRecipes().forEach(recipe => {
            recipeseArray.push(recipe.getName())
          })
          this.$emit('meal-swap-success')
          setTimeout(() => {
            this.logEvent('MealPlan.SwappedMeal', {
              day: this.data.mealTitle,
              recipes: recipeseArray.join(',')
            })
            this.closePopup(null, () => {
              if (repeatIngredientSwap.length > 0) {
                this.showUpdateDislikesPopup(repeatIngredientSwap, this.data.mealID)
              } else {
                if (this.$route.query.meal) {
                  this.$router.push({
                    path:
                      [DefineInclude.appUrlList.mealPlanBase, this.$route.params.mid].join('/'),
                    query: {
                      day: this.$route.query.day
                    }
                  })
                }
              }
            })
          }, 2000)
        }).catch(() => {
          this.status = 'error'
          if (!this.$i18n) {
            return
          }
          this.title = this.$i18n.t('message[\'swap.meal-requested-title-error\']')
          this.errorMessage = this.$i18n.t('message[\'swap.meal-requested-error\']')
        })
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hide-swap-popup')
        if (callback) {
          callback()
        }
      })
    }

  }
}
</script>

<template>
  <popup
    :visible="visible"
    modal-class="pc-popup-day-recipe-email"
    v-if="visible"
    size="md"
    @hide="closePopup()"
  >
    <popup-title>
      <popup-button-close @hide="closePopup($event)"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(formSubmit)"
          class="pc-popup-day-recipe-email__form"
        >
          <popup-content>
            <popup-head>
              <popup-text-heading>
                {{ title ? title : "" }}
              </popup-text-heading>
            </popup-head>
            <icon-complete-filled
              v-if="status === 'success'"
              :size="'lg1'"
            ></icon-complete-filled>
            <div v-if="status === 'init'">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                          <form @submit.prevent="handleSubmit(formSubmit)">
                            <ValidationProvider name="email" rules="required|email" v-slot="{ errors , invalid, validated }">
                              <div class="form-group ma-form">
                                <text-body-small
                                  class="pc-popup-day-recipe-email__form-label"
                                  :line-height="'multi'"
                                >{{ $i18n.t("message['settings.email']") }}</text-body-small>
                                <text-input
                                  class="pc-popup-day-recipe-email__form-input"
                                  :placeholder="$t('message[\'meal-plan.enter-your-email\']')"
                                  :error-message="errors[0]"
                                  v-model="formData.email"
                                />
                              </div>
                            </ValidationProvider>
                          </form>
                        </ValidationObserver>
            </div>
          </popup-content>
          <popup-footer>
            <button-primary
              v-if="status === 'init'"
              class="pc-popup-day-recipe-email__form-button"
              :text="footerData.btnLabel"
            ></button-primary>
          </popup-footer>
        </form>
      </ValidationObserver>
    </popup-body>
  </popup>
</template>

<script>
import DashBoardService from '../../../../../services/DashboardService'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '../../../../../helpers/validation'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import MealPlanService from '../../../../../services/MealPlanService'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import TextInput from '../../../../global/inputs/TextInput'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'

export default {
  name: 'day-recipe-email-popup',
  props: ['visible', 'mealPlanID', 'dayID', 'dayName', 'calories', 'macronutrients', 'mealPlanDay'],
  mixins: [popupAnimationMixin],
  components: {
    LoadingBuffer,
    ValidationProvider,
    ValidationObserver,
    Popup,
    PopupTitle,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupButtonClose,
    PopupBody,
    TextInput,
    TextBodySmall,
    ButtonPrimary,
    PopupFooter,
    IconCompleteFilled
  },
  mounted () {
    this.title = this.$i18n.t("message['meal-plan.single-day-recipe-email-send-title']", [this.dayName])
    this.formData.meal_plan_id = this.mealPlanID
    this.formData.message = this.$i18n.t("message['meal-plan.single-day-recipe-email-message']", [this.dayName.toLowerCase()])
    const dashBoardService = new DashBoardService()
    this.formData.header_image = this.getStoreImage('email.pdf.logo')
    this.setCSS()
    this.formData.day_name = this.dayName
    dashBoardService.getUserData().then(data => {
      this.formData.email = data.email
    })
    this.formData.macronutrients = this.macronutrients
    this.formData.calories = this.calories
  },
  data: function () {
    return {
      footerData: {
        btnLabel: this.$i18n.t('message["push-notifications.send"]'),
        active: true

      },
      submited: false,
      status: 'init',
      successImage: this.$appConfig.imagesUrl + 'swap-success.svg',
      systemErrors: [],
      formData: {
        macronutrients: {},
        calories: '',
        email: '',
        days: this.$store.getters['shoppingStore/days'],
        header_image: '',
        subject: this.$i18n.t("message['meal-plan.single-day-recipe-email-subject']"),
        message: '',
        people: this.$store.getters['shoppingStore/peopleCount'],
        meal_plan_id: '',
        day_name: '',
        data: {},
        css: []
      },
      title: '',
      contentText: '',
      imagesUrl: this.$appConfig.imagesUrl,
      meals: []
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.status = 'init'
        this.$emit('close')
      })
    },
    formSubmit () {
      if (this.submited) {
        return
      }
      this.submited = true
      this.footerData.active = false
      this.showLoading()
      this.status = 'loading'
      const meals = this.mealPlanDay.getMeals()
      const mealRequests = []
      this.meals = meals.map((meal, index) => {
        mealRequests.push(this.makeMeal(meal, index))
        let recipes = []
        meal.getRecipes().forEach(() => {
          recipes.push({})
        })
        return {
          id: meal.getId(),
          name: meal.getName(),
          reference_meal_id: meal.getReferenceMealId(),
          type: meal.getType(),
          macronutrients: meal.getMacroNutrients(),
          calories: meal.getCalories(),
          recipes: recipes
        }
      })
      Promise.all(mealRequests).then(() => {
        this.sendEmail()
      }).catch((err) => {
        console.log(err)
        this.status = 'init'
        this.systemErrors = [err.toString()]
        this.submited = false
        this.footerData.active = true
      })
    },
    makeMeal (meal, index) {
      const recipeRequests = []
      meal.getRecipes().forEach((recipe, recipeIndex) => {
        recipeRequests.push(this.makeRecipes(recipe, recipeIndex, meal, index))
      })
      return new Promise((resolve, reject) => {
        Promise.all(recipeRequests).then((values) => {
          resolve()
        }).catch((err) => {
          console.log(err)
          reject(new Error('unable to fetch recipes'))
        })
      })
    },

    makeRecipes (recipe, index, meal, mealIndex) {
      return new Promise((resolve, reject) => {
        recipe.getDetailedInfo().then(data => {
          this.meals[mealIndex].recipes[index] = recipe.getAPIResponse()
          resolve(data)
        }).catch((err) => {
          console.log(err)
          reject(new Error('unable to fetch recipe data'))
        })
      })
    },
    sendEmail () {
      this.formData.data = this.meals
      const service = new MealPlanService()
      this.logEvent('MealPlan.EmailSingleDayMeals', { 'day ': this.formData.day_name }, false)
      service.sendRecipeEmail(this.formData).then((data) => {
        this.title = this.$i18n.t("message['shopping-list.check-inbox']")
        this.hideLoading()
        this.status = 'success'
        setTimeout(() => {
          this.closePopup()
        }, 3000)
      }).catch(err => {
        this.hideLoading()
        if (typeof err.response.data.type !== 'undefined' && err.response.data.type === 'logic') {
          this.systemErrors = err.response.data.errors
        } else if (typeof err.response.data.errors !== 'undefined') {
          this.systemErrors = err.response.data.errors
        } else {
          this.systemErrors = [this.$i18n.t('message["general.server-error-description"]')]
        }
        this.status = 'init'
      }).finally(() => {
        this.submited = false
        this.footerData.active = true
      })
    },
    setCSS () {
      this.formData.css = []
    }

  }
}
</script>

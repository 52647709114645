<template>
  <div class="video-container"  style="max-width: 100%">
    <!--    <div>-->
    <!--      <img :src="imageSrc" />-->
    <!--    </div>-->
    <video ref="videoPlayer" class="dash-video" playsinline  style="width: 100%"></video>
    <div>
      {{time}}/{{totalTime}}
      seeking : {{seeking}}
      quality : {{quality}}
      <input type="range" v-model="progress" min="0" max="100" style="width: 100%"
             @touchstart="seeking=true" @touchend="seeking=false"
             @mousedown="seeking=true" @mouseup="seeking=false"
      >
    </div>
    <div class="controls">
      <button @click="play" v-if="!playing">Play</button>
      <button @click="pause" v-else>Pause</button>
      <button @click="stop" v-if="startedPlaying">Stop</button>
    </div>
    <div v-html="logs">

    </div>
  </div>
</template>

<script>


export default {
  name: "VideoPlayerCustom",
  props: {
    imageSrc: {
      type: String,
      required: false,
      default: 'https://picsum.photos/536/354',
    },
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      logs: '',
      quality: null,
      seeking: false,
      playing: false,
      player: null,
      startedPlaying: false,
      time: 0,
      totalTime: 0,
      progress: 0,
    };
  },
  watch: {
    seeking(newVal,oldVal) {

      if (!newVal&&oldVal) {
        this.player.seek(this.totalTime * (this.progress / 100));
      }
    },
  },
  mounted() {
    this.initDashPlayer();
  },
  methods: {
    initDashPlayer() {
      console.log(dashjs)
      if (this.src) {
        this.player = dashjs.MediaPlayer().create();
        this.player.updateSettings({
          debug: {
            logLevel:5,
            dispatchEvent: true
          },

          streaming: {
            buffer:{
             // initialBufferLevel: 1,
              fastSwitchEnabled: true,
              flushBufferAtTrackSwitch:true
            },
            abr: {
              usePixelRatioInLimitBitrateByPortal: true,
              minBitrate: {
                video : 1000,
              },initialBitrate: {
                video : 5846,
              },
              autoSwitchBitrate: {
                video: false, // Disable auto bitrate switching for video
              }
            }
          }
        })
        console.log(this.src)
        this.player.initialize(this.$refs.videoPlayer, this.src, false);

        this.player.on(dashjs.MediaPlayer.events.PLAYBACK_STARTED, () => {
          this.log('PLAYBACK_STARTED')

          this.startedPlaying = true;
          this.playing = true;
          this.player.seek(0);
        });
        this.player.on(dashjs.MediaPlayer.events.PLAYBACK_PAUSED, () => {
          this.log('PLAYBACK_PAUSED')
          this.playing = false;
        });
      }

      this.player.on(dashjs.MediaPlayer.events.PLAYBACK_ENDED, () => {
        this.log('PLAYBACK_ENDED')
        this.playing = false;
        this.startedPlaying = false;
      });
      this.player.on(dashjs.MediaPlayer.events.PLAYBACK_PROGRESS, (e) => {
        console.log('PLAYBACK_PROGRESS',e)
      });

      this.player.on(dashjs.MediaPlayer.events.STREAM_INITIALIZING, (e) => {

        this.log('STREAM_INITIALIZING')
        console.log('STREAM_INITIALIZING',e)

      });
      this.player.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED, (e) => {
      console.log('QUALITY_CHANGE_RENDERED',this.player.getCurrentRepresentationForType('video').width)
        this.log('QUALITY_CHANGE_RENDERED '+ this.player.getCurrentRepresentationForType('video').width)
      });
      this.player.on(dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, (e) => {

       this.quality = this.player.getCurrentRepresentationForType('video').width

        console.log('PLAYBACK_TIME_UPDATED',e)
        if(this.seeking){
          return
        }
        this.time = e.time
        this.progress = (e.time / this.totalTime) * 100

      });

      this.player.on('LOG', function (event) {
       this.log(`[LOG] ${event}`);
      });
      this.player.on('ERROR', function (event) {
       this.log(`[ERROR] ${event.message}`, JSON.stringify(event));
      });

      this.player.on(dashjs.MediaPlayer.events.CAN_PLAY, (e) => {

        this.log(this.player.getRepresentationsByType('video'))
        console.log('bitrateList',this.player.getRepresentationsByType('video')  )
      //  this.player.setRepresentationForTypeByIndex('video',(this.player.getRepresentationsByType('video').length -1)  ,true);
        this.player.preload();
        // this.player.seek(0);
        this.totalTime = this.player.duration()
        console.log('CAN_PLAY',this.player.duration())
      });

    },
    play() {
      this.player.play();
    },
    pause() {
      this.player.pause();
    },
    stop() {
      this.$refs.videoPlayer.pause();
      this.$refs.videoPlayer.currentTime = 0;
    },
    log(log){
      this.logs = this.logs + '<br>' + log
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>

<style scoped>
.video-container {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.dash-video {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
}

.controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

button {
  margin: 5px;
  padding: 8px 12px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}


/**************************************************/
/*Control bar*/
/**************************************************/
.hide {
  display: none;
}

.disable {
  pointer-events: none;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:-webkit-full-screen {
  width: 100%;
  top: 0;
  z-index: -100;
  background: none;
}

:-ms-fullscreen {
  width: auto;
  height: auto;
  margin: auto;
}

.time-display,
.duration-display {
  padding: 11px;
  color: white;
  font-weight: normal;
  font-size: .9em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.time-display {
  float: left;
  min-width: 72px;
}

.duration-display {
  float: right;
}

.live-icon {
  cursor: pointer;
}

.live-icon.live {
  color: red !important;
  pointer-events: none;
  cursor: default;
}

.btn-play-pause {
  float: left;
  padding: 9px 10px;
  cursor: pointer;
}

.control-icon-layout {
  float: right;
  padding: 9px 10px;
  cursor: pointer;
}

.btn-fullscreen {
  margin-right: 10px;
}

.volumebar {
  float: right;
  width: 70px;
}

.video-controller {
  min-height: 35px;
  z-index: 2147483646;
}

.video-controller-fullscreen {
  position: fixed;
  z-index: 2147483647;
  width: 100%;
  bottom: 0;
  left: 0;
}

.menu,
.video-controller {
  background-color: black;
}

.menu-item-unselected,
.menu-item-selected {
  font-weight: normal;
  font-size: .9em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.menu-item-unselected {
  color: white;
}

.menu-item-over,
.menu-item-selected {
  background-color: white;
  color: black;
}

.menu-sub-menu-title {
  background-color: #191919;
  padding-left: 2px;
  font-weight: bold;
  font-size: 1.0em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.menu-item-selected {
  opacity: .7;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu li {
  padding: 0 10px;
  cursor: pointer;
}

.menu {
  position: absolute;
}

#bitrateMenu .menu-sub-menu-title {
  min-width: 150px;
}

@font-face {
  font-weight: normal;
  font-style: normal;
}

.icon-play,
.icon-pause,
.icon-caption,
.icon-mute-off,
.icon-mute-on,
.icon-fullscreen-enter,
.icon-fullscreen-exit,
.icon-tracks,
.icon-bitrate {

  font-size: 20px;
  color: white;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
}

.icon-fullscreen-enter:before {
  content: "\e90b";
}

.icon-fullscreen-exit:before {
  content: "\e90c";
}

.icon-play:before {
  content: "\e910";
}

.icon-pause:before {
  content: "\e911";
}

.icon-mute-on:before {
  content: "\e909";
}

.icon-mute-off:before {
  content: "\e918";
}

.icon-caption:before {
  content: "\e901";
}

.icon-bitrate:before {
  content: "\e905";
}

.icon-tracks:before {
  content: "\e90a";
}

.seekContainer {
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}

input[type="range"] {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  height: 14px;
  border: none;
  margin: 12px 5px;
  padding: 1px 2px;
  border-radius: 5px;
  background: #232528;
  box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
  -webkit-box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
  outline: none; /* no focus outline */
}

input[type=range]::-moz-focus-outer {
  border: 0;
}

input[type="range"]::-moz-range-track {
  border: inherit;
  background: transparent;
}

input[type="range"]::-ms-track {
  border: inherit;
  color: transparent; /* don't drawn vertical reference line */
  background: transparent;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  background: transparent;
}

input[type="range"]::-ms-tooltip {
  display: none;
}

/* thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 8px;
  border: none;
  border-radius: 2px;
  background-color: rgb(0, 150, 215);
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 8px;
  border: none;
  border-radius: 2px;
  background-color: rgb(0, 150, 215);
}

input[type="range"]::-ms-thumb {
  width: 15px;
  height: 8px;
  border: none;
  border-radius: 2px;
  background-color: rgb(0, 150, 215);
}

.thumbnail-container {
  position: absolute;
  text-align: center;
}

.thumbnail-elem {
  position: relative;
  box-shadow: 0px 0px 0.9em #000000;
  transform-origin: center bottom;
}

.thumbnail-time-label {
  position: relative;
  bottom: 1.8em;
  display: table;
  margin: 0 auto;
  padding: 2px 5px 2px 5px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.seekbar-complete {
  width: 100%;
  height: 7px;
  margin-top: 16px;
  background: #999a99;
  position: relative;
  overflow: hidden;
}

.seekbar-buffer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0%;
  height: 7px;
  background: lightgray;
}

.seekbar-play {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0%;
  height: 7px;
  background: rgb(0, 150, 215);
}

</style>

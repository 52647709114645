<template>
    <div class="pc-image-photo-journey-skeleton">
        <AnimatedPlaceholder height="180px" width="100%" border-radius="10px" />
    </div>
</template>

<script >
import AnimatedPlaceholder from '@/components/global/animators/AnimatedPlaceholder';

export default {
  name: "image-photo-journey-history-skeleton",
  components: { AnimatedPlaceholder },
};


</script>

<style>

</style>
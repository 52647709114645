import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import {mapGetters, mapMutations} from 'vuex'
import FirstLoadService from '../../../../services/FirstLoadService'
import pageConfigMixin from '../../../pageConfigMixin'
import NutritionService from '../../../../services/nutrition/NutritionService'
import APIErrorHandler from "@/services/api-handler/APIErrorHandler";

export default {
  extends: BaseComponent,
  mixins: [pageConfigMixin],
  data: function () {
    return {
      title: '',
      description: '',
      backgroundImage: '',
      isPaired: false,
      headerOptions: {
        show: true,
        left: 'menu',
        right: 'help',
        rightBlink: true,
        modifiers: ['with-img'],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      },
      content: {},
      days: [],
      instructionsPopup: {
        firstTimeKey: 'mealPlanView',
        instructions: '',
        title: '',
        visible: false
      },
      firstTime: false,
      mealPlanID: 0,
      mealPlan: null
    }
  },
  beforeMount: function () {
    this.setImages()
    this.mealPlanID = this.$route.params.mid
    this.getMealPlanOptionData(this.mealPlanID)
  //  this.setSliderTypeAndID('mealPlan', this.mealPlanID)
  },
  computed: {},
  methods: {
    ...mapGetters({
      instructionsPopupVisibility: 'instructionPopupStore/visible',

    }),
    ...mapMutations({
      setRouteTransition: 'setRouteTransition'
    }),
    getMealPlanOptionData(mealPlanID, force = false) {
      const service = new NutritionService()

      let plan = service.getMealPlan(parseInt(mealPlanID))
      if (!plan) {
        this.$router.replace({path: '/404'})
        return
      }
      this.mealPlan = plan
      if (!force&&plan.isLoaded()) {
        this.setPlanData(plan)
        this.afterGetMealPlanOptionData(plan)
        this.hideLoading()
        return
      }
      this.title = plan.getName()
      plan.getDataFromAPI(force).then(response => {
        this.setPlanData(plan)
        this.setPageTitle(this.title)
        this.afterGetMealPlanOptionData(plan)
      }).catch(err => {
        this.afterGetMealPlanOptionDataError(err.response)
      }).finally(() => {
        this.hideLoading()
      })
    },
    setPlanData(mealPlan) {
      this.title = mealPlan.getName()
      this.isPaired = mealPlan.isPairedMealPlan() || false
      this.instructionsPopup.instructions = [mealPlan.getDescription()]
      this.instructionsPopup.notes = mealPlan.getNotes() || ''
      this.instructionsPopup.instructionType = 'mealPlanHome'
      // calling to setContent function in the pageStore
      this.setInstructionsPopupContent(this.instructionsPopup)

      this.days = mealPlan.getDays()

      this.setImages()
    },

    afterGetMealPlanOptionData(response) {
    },
    afterGetMealPlanOptionDataError(response) {
      this.handleServerError(response)
    },
    handleServerError(response) {
      let sentryEventID = APIErrorHandler.logSentry(response)
      APIErrorHandler.handleError(response)
    },
    getDescription() {
      return this.$i18n.t("message['meal-plan.shopping-list-description']")
    },
    setFirstLoadStatus() {
      // first load check
      const firstLoadService = new FirstLoadService()
      this.firstTime = firstLoadService.getMealPlanViewFirstTimeStatus()
    },
    setImages() {
      this.setBackgroundImage(this.getStoreImage('mealPlan.background'))
      this.backgroundImage = this.getStoreImage('mealPlan.background')
    },
    navigateNext(day) {
      this.setRouteTransition({name: 'zero-fade', mode: 'in-out'})
      this.$router.push({
        path: [this.$appConfig.appUrlList.mealPlanBase, this.mealPlanID].join('/'),
        query: {day: day, loading: '0'}
      })
    },
    getAllMealOfDay(item) {
      const meals = item.meals.map((meal) => {
        const recipes = meal.recipes.map((recipe) => {
          return recipe.name
        })
        return recipes.join(', ')
      })
      const mealStr = meals.join(', ')
      return mealStr.length > 45
        ? (mealStr.substring(0, 45 - 3).trim()).replace(/-$/, '') + '... '
        : mealStr
    },
    navigateToShop() {
      this.showLoading()
      this.$store.commit('shoppingStore/days', {days: [1, 2, 3, 4, 5, 6, 7]})

      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.shoppingList.replace(
          ':mid',
          this.mealPlanID
        ),
        query: {loading: 0}
      })
    }
  }
}

<template>
  <div v-if="widgetsData.length > 0">
    <div
      v-for="widget in widgetsData"
      :key="widget.selector"
    >
      <button-primary-forward
        :key="widget.selector"
        :text="widget.label"
        @click="triggerAccounceKit(widget)"
      />
    </div>
  </div>
</template>
<script>
import ButtonPrimaryForward from '../../../../global/buttons/ButtonPrimaryForward'
export default {
    name: 'AnnounceKitContainer',
    components: {
        ButtonPrimaryForward
    },
    props: {
        widgetsData: {
            type: Array,
            default: () => []
        },
        announceKitConfigs: {
            type: Object,
            default: () => ({})
        }

    },
    mounted () {
        this.addAnnounceKitScript()
        .then(() => {
            this.getAnnounceConfig()
        })
        .catch(error => {
            console.error('Error loading AnnounceKit script:', error)
        })
    },
    methods: {
        addAnnounceKitScript() {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script')
                script.src = 'https://cdn.announcekit.app/widget-v2.js'
                script.async = true
                script.onload = () => resolve()
                script.onerror = () => reject(new Error('Failed to load AnnounceKit script.'))
                document.head.appendChild(script)
            })
        },
        getAnnounceConfig () {
            this.widgetsData.forEach(widget => {
                window.announcekit.push({
                    widget: "https://announcekit.co/widgets/v2/" + widget.widget,
                    name: widget.widget,
                    lang: "en",
                    user: this.announceKitConfigs.user,
                    data: this.announceKitConfigs.data
                });
            })

            
        },
        triggerAccounceKit (widgetData) {
            window.announcekit[`widget$${widgetData.widget}`].open()
            
            const iframe1 = document.querySelector('iframe[name="ank_widget_plwvm"]');
            if (iframe1) {
                iframe1.style.top = '30px';
            }

            const iframe2 = document.querySelector('iframe[name="ank_widget_n6VR6"]');
            if (iframe2) {
                iframe2.style.top = '30px';
            }
        },
    }
}
</script>
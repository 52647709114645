<template>
  <popup
    v-if="visible"
    ref="mealRemoveModal"
    :visible="visible"
    modal-class="pc-popup-remove-meal"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close @hide="closePopup" />
    </popup-title>
    <popup-body class="gc-popup-meal-swap__body">
      <popup-head class="text-center">
        <popup-text-heading>{{ titleText }}</popup-text-heading>
      </popup-head>
      <popup-content class="text-center">
        <icon-complete-filled size="lg3" />
      </popup-content>
    </popup-body>
  </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'

export default {
  name: 'PopupFoodDairySuccessSave',
  components: {
    IconCompleteFilled,
    ButtonPrimary,
    PopupTextBody,
    PopupContent,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup

  },
  mixins: [popupAnimationMixin],
  props: ['saved', 'visible'],
  computed: {
    titleText: function () {
      return (this.saved) ? this.$i18n.t('message["food-diary.meal-saved-and-logged-success"]') : this.$i18n.t('message["food-diary.meal-logged-success"]')
    }
  },
  mounted () {
    setTimeout(() => {
      this.closePopup()
    }, 2000)
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }

  }
}
</script>

import Vue from 'vue'
import App from './App'
import router from './router/templateOne'
import {
  CollapsePlugin,
  DropdownPlugin,
  ListGroupPlugin,
  ModalPlugin,
  LayoutPlugin
} from 'bootstrap-vue'
import jQuery from 'jquery'
import VueI18n from 'vue-i18n'
import globalMixin from './mixins/globalMixin'
import AssetBinding from './includes/AssetBinding'
import APIHandler from './services/api-handler/APIHandler'
import checkURLsToMount from './includes/CheckURLsToMount'
import ActivityTracker from './services/ActivityTracker'
import ActivityTrackerMixin from './mixins/activityTrackerMixin'
import DefineInclude from './DefineInclude'
import appLayoutConfig from './config/app-layout-config'
import customConfig from '../config/custom-config'
import { pwaInstallEvent } from './includes/PWAEvents'
import AuthService from './services/AuthService'
import { i18n } from './helpers/localization/i18n'
import '@splidejs/splide/dist/css/splide.min.css';
import {
  checkBrowserSupport,
  checkTemplateConfig,
  createScriptInHeader, listenServiceWorker,
  setBodyClasses,
  setSentry,
  showLegacyApp
} from './includes/AppMountHelpers'
import ComponentLibrary from './components/root/icons'
import './includes/NLAFGlobal'
import OneSignalVue from 'onesignal-vue'

Vue.use(OneSignalVue);
if (process.env.NODE_ENV === 'production') {
  const authService = new AuthService()
  if (!authService.isValidToken()) {
    // localStorage.removeItem('authToken')
  }
}

if (!checkBrowserSupport()) {
  showLegacyApp('browser not supported')
}
Vue.mixin(ActivityTrackerMixin)
Vue.mixin(globalMixin)

function getAuthTokenFromURL() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('token');
}

function setAuthToken() {
  const authToken = getAuthTokenFromURL();
  if (authToken && window.location.pathname.includes('/login')) {
    localStorage.setItem('authToken', authToken);
    console.log('Auth token set successfully');
  } else {
    console.log('No auth token found in URL');
  }
}

if(window.location.pathname.includes('/login')){
  setAuthToken();
}
/** Install BootstrapVue */
// Install BootstrapVue
Vue.use(ModalPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)
Vue.use(ListGroupPlugin)
Vue.use(LayoutPlugin)
/** Optionally install the BootstrapVue icon components plugin */
/** vue localization plugin */
Vue.use(VueI18n)
/** config axios config **/
APIHandler.initAxiosConfig()

// Register the component library globally
Vue.use(ComponentLibrary);

/**
 * load components config
 * @type {{type: string, name: string, properties: {background_default: {type: string, desc: string, default: string}, background_medium: {type: string, desc: string, default: string}, logo: {type: string, desc: string, default: string}, bg2: {type: string, desc: string, default: string}}, page-components: *}}
 */
let trainerID = 0
let resellerID = 0

if (document && document.querySelector('meta[name=ma-application]')) {
  let maAppMeta = document
    .querySelector('meta[name=ma-application]')
    .getAttribute('content') || ''
  maAppMeta = maAppMeta.split('.')
  trainerID = maAppMeta[1] || 0
  resellerID = maAppMeta[0] || 0
  Vue.prototype.$trainer = maAppMeta.join('.')
  Vue.prototype.$trainerID = maAppMeta[1] || 0
  Vue.prototype.$resellerID = maAppMeta[0] || 0
}

Vue.prototype.$layoutConfig = appLayoutConfig
Vue.prototype.$appConfig = DefineInclude

if (localStorage.getItem('logout')) {
  localStorage.clear()
}
// mount App
jQuery(document).ready(function () {
  if (customConfig.is_toning_enabled) {
    let toningConfigURL =
      customConfig.toning_url +
      'template-config/trainers/' +
      trainerID +
      '/resellers/' +
      resellerID
    if (customConfig.toning_url === 'https://toning.macroactivemvp.com/api/') {
      toningConfigURL =
        customConfig.toning_url +
        'template-config/trainer/' +
        trainerID +
        '/reseller/' +
        resellerID
    }
    jQuery.ajax(toningConfigURL, {
      // eslint-disable-next-line
      success: function (data, status, xhr) { // eslint-disable-line
        createScriptInHeader(data)
        mountMainVueApp()
      },
      error: function () {
        showLegacyApp('toning config error')
      }
    })
  } else {
    mountMainVueApp()
  }
})

/**
 * vue App mount
 * @returns {{SubscriptionOptionService: SubscriptionOption, activityTracker: {customerID: number, logging: boolean, addEvent(*=, *=): void, _log(): void, setUserProperties(*=, *=, *): void, sendToAPI()}}}
 */
function mountMainVueApp () {

  checkURLsToMount()
    .then((response) => {

      if (response) {
        const bodySelector = $('body')
        bodySelector.html('')
        const event = new CustomEvent('app-not-ready', { detail: {} })
        document.dispatchEvent(event)

        const appDiv = document.createElement('div')
        appDiv.id = 'app'
        bodySelector.append(appDiv)

        // set body class
        setBodyClasses()
        // add and remove js and css forms the site
        AssetBinding()
        setSentry()
        checkTemplateConfig()
        pwaInstallEvent()

        const activityTracker = new ActivityTracker()
        Vue.prototype.$activityTracker = activityTracker
        /* eslint-disable no-new */
        window.confApp = new Vue({
          router,
          i18n,
          render: h => h(App),
          provide() {
            return {
              activityTracker: activityTracker
            }
          }
        }).$mount('#app')
      } else {
        showLegacyApp('url not found')
        $('#app-css').remove()
      }
    })
    .catch((e) => {
      showLegacyApp('url not found')
    })
    .finally(() => {
      $('body').css('display', 'block')
      if(document.body.classList.contains('page-sign-up-registration')){
        document.body.classList.add('no-animation')
      }
      printPage()
    })

    window.templateConfig = templateConfig
}

listenServiceWorker()

function printPage () {
  const url = new URL(window.location.href)
  if (url.searchParams.has('print-page')) {
    let existCondition = setInterval(function () {
      let element = $('.link-print-all')
      if (element.length) {
        clearInterval(existCondition)
        $('.cust-style-tag').remove()
        $('<style class="cust-style-tag">@media print { .panel-default { display:block; } .panel-default .collapse { display: block;  } }</style>').appendTo('head')
        window.print()
      }
    }, 300)
  }
}

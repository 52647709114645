<template>
  <page :header="header" class="gc-page-meal-plan-satisfaction">
    <page-container>
      <validation-observer ref="form" v-slot="{ handleSubmit }">
        <div class="gc-page-meal-plan-satisfaction__header-wrapper">
          <text-heading-4
            class="gc-page-meal-plan-satisfaction__title text-center"
            weight="extra-bold"
            lineHeight="multi"
          >{{ mainTitle }}
          </text-heading-4>
          <div class="gc-page-meal-plan-satisfaction__sub-title text-center">
            <text-body-regular
              class="pc-progress-body-measurements__sub-title"
              weight="medium"
              line-height="multi"
            >{{
                optionalText
              }}
            </text-body-regular>
          </div>
        </div>
        <div class="gc-page-meal-plan-satisfaction__content">
          <form
            id="form-input-meal-plan-satisfaction"
            ref="formContent"
            class="gc-page-meal-plan-satisfaction__form"
            @submit.prevent="handleSubmit(submitData)"
          >
            <text-body-small class="gc-page-meal-plan-satisfaction__label">
              {{ $t('message[\'form.title.meal-plan-checkin-positive-question\']') }}
            </text-body-small>
            <text-area
              v-model.trim="formData.likes"
              :placeholder="$t('message[\'general.answer-placeholder\']')"
              :rows="3"
            />
            <text-body-small class="gc-page-meal-plan-satisfaction__label">
              {{ $t('message[\'form.title.meal-plan-checkin-negative-question\']') }}
            </text-body-small>
            <text-area
              v-model.trim="formData.improvements"
              :placeholder="$t('message[\'general.answer-placeholder\']')"
              :rows="3"
            />
          </form>
        </div>
      </validation-observer>
    </page-container>
    <page-footer
      ref="footer"
      :hide-return-button="true"
      :form="'form-input-meal-plan-satisfaction'"
    ></page-footer>
  </page>
</template>

<script>
import FormBaseComponent from "../../layout/template-1/global/widgets/forms/FormBaseComponent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ButtonPrimary from "../buttons/ButtonPrimary";
import TextBodyExtraSmall from "../typography/TextBodyExtraSmall";
import TextHeading4 from "../typography/TextHeading4";
import ButtonChoice from "../buttons/ButtonChoice";
import IconInfo from "../../root/icons/IconInfo";
import Page from "@/components/global/pages/PageForm.vue";
import PageContainer from "@/components/global/pages/page-sub-components/PageContainer.vue";
import PageFooter from "@/components/global/pages/page-sub-components/PageFormFooter.vue";
import InputCheckBoxSquared from "@/components/global/inputs/InputCheckBoxSquared.vue";
import TextBodyRegular from "@/components/global/typography/TextBodyRegular.vue";
import TextBodySmall from "@/components/global/typography/TextBodySmall.vue";
import ButtonLink from "@/components/global/buttons/ButtonLink.vue";
import * as Sentry from "@sentry/browser";
import DashBoardService from "@/services/DashboardService";
import WorkoutLogService from "@/services/WorkoutLogService";
import TextArea from "@/components/global/inputs/TextArea.vue";
import {mapGetters} from "vuex";

export default {
  name: "FormMealPlanSatisfaction",
  extends: FormBaseComponent,
  components: {
    TextArea,
    ButtonLink,
    TextBodySmall,
    TextBodyRegular,
    InputCheckBoxSquared,
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    ButtonChoice,
    PageFooter,
    IconInfo,
  },
  props: ["infoText"],
  data: function () {
    return {
      formData: {
        likes: '',
        improvements: '',
      },
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: "",
        right: "",
        rightBlink: false,
        modifiers: [],
        mainTitle: "",
        subTitle: "",
      },
    };
  },

  computed: {
    optionalText: function () {
      if(this.$t('message[\'general.form.optional-with-brackets\']') !== '(Optional)'){
        return this.$t('message[\'general.form.optional-with-brackets\']')
      }

      return '('+this.$t('message[\'general.form.optional\']')+')'
    },
    mainTitle: function () {
      return (
        this.title.main ||
        this.$i18n.t("message['form.title.share-your-feedback']")
      );
    },

  },
  mounted() {

  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getProgressFormService'
    }),
    async submitData() {
      this.showLoading();
      try {
        const dashboardService = new DashBoardService();
        const progressService = this.getService();
        const category = progressService.getMealPlanCategoryName(progressService.dietary.category)
        const userData = await dashboardService.getUserData()
        const payload = {
          trainerID: userData.trainer_id,
          resellerID: userData.reseller_id,
          customer: userData.id,
          url: window.location.href
        }
        const service = new WorkoutLogService()

        if (this.formData.likes) {
          const likes = this.formData.likes
          await service.sendFeedBack({
            ...payload,
            flag: 'meal-plan-checkin-positive',
            feedback: `Feedback: ${likes}\n\nMeal Category: ${category}`
          })
        }
        if (this.formData.improvements) {
          const improvements = this.formData.improvements
          await service.sendFeedBack({
            ...payload,
            flag: 'meal-plan-checkin-negative',
            feedback: `Feedback: ${improvements}\n\nMeal Category: ${category}`
          })
        }
      } catch (e) {
        console.log(e)
        Sentry.captureException(e);
        this.hideLoading();
      }

      this.$emit("next", {replace: true});
    },
    nextComponent() {
      const inputObj = {
        key: this.inputKey,
        value: this.selectedHabits,
      };
      this.$emit("submit", inputObj);
    },
  },
};
</script>

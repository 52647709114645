<template>
  <div class="pc-container-dine-out">
    <transition name="fade">
      <page-container v-if="!selectedRestaurant">
        <input-search-bar
          :key="'restaurantSearch'"
          v-model="restaurantSearchString"
          class="pc-container-dine-out__search"
          :placeholder="$t('message[\'food-diary.dine-out.search-restaurants\']')"
          name="restaurantSearch"
        />
        <div class="pc-container-dine-out__input-options-wrap" v-if="caloriesInput||showMacroSplitInput">

          <div class="pc-container-dine-out__input-options" v-if="caloriesInput">
            <input-check-box-squared
              id="remainingCalories"
              v-model="macroSplitInput"
            /> <text-body-extra-small>{{ $t('message[\'food-diary.closer-to-remaining-daily-calories\']') }}</text-body-extra-small>
          </div>
          <div class="pc-container-dine-out__input-options" v-if="showMacroSplitInput">
            <input-check-box-squared
              id="macroSplit"
              v-model="macroSplitInput"
            />  <text-body-extra-small>{{ $t('message[\'food-diary.closer-to-macro-split\']') }}</text-body-extra-small>
          </div>

        </div>

        <div
          v-for="restaurant in restaurants"
          :key="restaurant.name"
        >
          <button-dining-out-restaurant
            v-show="restaurant.mealCount> 0 && restaurant.name.toLowerCase().includes(restaurantSearchString.toLowerCase())"
            :key="'button'+restaurant.name"
            class="pc-container-dine-out__restaurant"
            :text="restaurant.name"
            :sub-text="$tc('message[\'food-diary.dine-out.meal-items\']',restaurant.mealCount ,{count:restaurant.mealCount})"
            @click="showRestaurant(restaurant)"
          >
            <div class="pc-container-dine-out__restaurant-icon">
              <icon-restaurant />
            </div>
          </button-dining-out-restaurant>
        </div>
      </page-container>
    </transition>
    <transition name="fade">
      <page-container
        v-if="selectedRestaurant"
        class="pc-container-dine-out__restaurant-area"
      >
        <div
          class="pc-container-dine-out__back"
          @click="backToList"
        >
          <icon-up-arrow-filled size="sm2" />
          {{ $t('message[\'food-diary.dine-out.back-to-restaurant\']') }}
        </div>

        <button-primary-forward
          v-show="selectedRestaurant.name.toLowerCase()"
          :key="'button'+selectedRestaurant.name"
          class="pc-container-dine-out__restaurant "
          :text="selectedRestaurant.name"
          :sub-text="$tc('message[\'food-diary.dine-out.meal-items\']',selectedRestaurant.mealCount ,{count:selectedRestaurant.mealCount})"
        >
          <div class="pc-container-dine-out__restaurant-icon">
            <icon-restaurant />
          </div>
        </button-primary-forward>
        <input-search-bar
          v-if="selectedRestaurant"
          :key="'mealSearch'"
          v-model="mealSearchString"
          :name="'mealSearch'"

          :placeholder="$t('message[\'food-diary.dine-out.search-food\']')"
          class="pc-container-dine-out__search-food"
        />

        <div
          v-if="!hasAnyMealResult"
          class="pc-container-dine-out__no-result"
        >
          <text-body-extra-small>{{ $i18n.t('message[\'food-diary.no-results\']') }}</text-body-extra-small>
        </div>
        <div
          v-for="group in selectedRestaurant.groups"
          :key="group.name"
        >
          <button-sub-category
            v-show="hasSearchItemInGroup(group)"
            class="pc-container-dine-out__meal-group"
            :selected="selectedCategory.name===group.name"
            :text="setCategoryText(group)"
            @click="setSelectedCategoryName(group)"
          />
          <grid-row
            v-if="hasSearchItemInGroup(group) && selectedCategory.name===group.name"
            class="pc-container-dine-out__meal-group__row"
          >
            <text-body-small
              v-if="getCatPageCount(group)>1"
              class="pc-container-dine-out__pagination-category-text--top"
            >
              {{ $t('message[\'food-diary.dine-out.items-to-show\']',{from:selectedCategory.from, to:selectedCategory.to, count: selectedCategory.total }) }}
            </text-body-small>

            <pagination
              class="pc-container-dine-out__pagination-category--top"
              :current-page="selectedCategory.page"
              :page-count="getCatPageCount(group)"
              @go-to="goToSelectedCategoryPage"
            />

            <card-dine-out-meal
              v-for="meal in getGroupMeals(group)"
              v-show="meal.show"
              :key="meal.name"
              class="pc-container-dine-out__card-meal"
              :calories="meal.calories"
              :macro-nutrients="meal.recipes[0].macronutrients"
              :name="meal.name"
              :show-saved-state="showMealSavedState"
              :selected="isSelected(meal)"
              :saved="isSavedMeal(meal)"
              @add="addMeal(meal)"
              @remove="removeMeal(meal)"
              @save="saveMeal(meal)"
              @erase="eraseMeal(meal)"
            />

            <text-body-small
              v-if="getCatPageCount(group)>1"
              class="pc-container-dine-out__pagination-category-text--top"
            >
              {{ $t('message[\'food-diary.dine-out.items-to-show\']',{from:selectedCategory.from, to:selectedCategory.to, count: selectedCategory.total }) }}
            </text-body-small>
            <pagination
              class="pc-container-dine-out__pagination-category--bottom"
              :current-page="selectedCategory.page"
              :page-count="getCatPageCount(group)"
              @go-to="goToSelectedCategoryPage($event,group.name)"
            />
          </grid-row>
        </div>
        <page-fixed-footer>
          <button-primary
            :disabled="(count===0 && mealsToRemove.length===0)"
            :text="state === 'submitting' ? '' : $tc('message[\'food-diary.log-meal-submit\']', count, { count: count })"
            @click="submitData()"
          >
            <loading-buffer-custom v-if="state === 'submitting'" />
          </button-primary>
        </page-fixed-footer>
      </page-container>
    </transition>
  </div>
</template>

<script>
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import NutritionDetailsBar from '../../../../global/bars/BarNutritionDetails'
import NavigationMealsAdd from './NavigationMealsAdd'
import PageContainerFluid from '../../../../global/pages/page-sub-components/PageContainerFluid'
import ButtonLinkForward from '../../../../global/buttons/ButtonLinkForward'
import ButtonPrimaryForward from '../../../../global/buttons/ButtonPrimaryForward'
import InputSearchBar from '../../workout/page-components/workout-log-sub-components/InputSearchBar'
import ButtonSecondaryForward from '../../../../global/buttons/ButtonSecondaryForward'
import CardMealLog from './CardMealLog'
import CardDineOutMeal from './CardDineOutMeal'
import LoadingBufferCustom from '../../global/widgets/LoadingBufferCustom'
import PageFixedFooter from '../../../../global/pages/PageFixedFooter'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import GridCol from '../../../../global/grid/GridCol'
import GridRow from '../../../../global/grid/GridRow'
import IconRestaurant from '../../../../root/icons/IconRestaurant'
import IconUpArrowFilled from '../../../../root/icons/IconUpArrowFilled'
import ButtonSubCategory from '../../../../global/buttons/ButtonSubCategory'
import ButtonDiningOutRestaurant from '../../../../global/buttons/ButtonDiningOutRestaurant'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import logDineOutMealMixin from '@/mixins/component-mixins/page/food-diary/logDineOutMealMixin'
import InputCheckBoxSquared from '@/components/global/inputs/InputCheckBoxSquared.vue'
import IconInfo from '@/components/root/icons/IconInfo.vue'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'
import ButtonLink from '@/components/global/buttons/ButtonLink.vue'
import Pagination from '@/components/global/pagination/pagination.vue'
import { getVisibilityOfCaloriesInNutrition } from '@/includes/TemplateSettings'

export default {
  name: 'ContainerDineOutMealLog',
  components: {
    Pagination,
    ButtonLink,
    TextBodySmall,
    IconInfo,
    InputCheckBoxSquared,
    TextBodyExtraSmall,
    ButtonDiningOutRestaurant,
    ButtonSubCategory,
    IconUpArrowFilled,
    IconRestaurant,
    GridRow,
    GridCol,
    CardDineOutMeal,
    CardMealLog,
    LoadingBufferCustom,
    PageFixedFooter,
    ButtonPrimary,
    ButtonSecondaryForward,
    InputSearchBar,
    ButtonPrimaryForward,
    ButtonLinkForward,
    PageContainerFluid,
    NavigationMealsAdd,
    NutritionDetailsBar,
    PageContainer
  },
  mixins:[logDineOutMealMixin],

  mounted () {
    this.init()
    if(getVisibilityOfCaloriesInNutrition()){
      this.caloriesInput =false
      this.macroSplitInput =[]
    }

  },

}
</script>

<style scoped>

</style>

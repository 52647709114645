<template>
  <div>
  </div>
</template>

<script>

export default {
  name: 'logout',
  mounted () {

     this.$OneSignal.logout();
    this.$OneSignal.User.PushSubscription.optOut();



    localStorage.removeItem('lastSessionRoute')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('authToken')

    setTimeout(() => {
      window.location.replace('/login')
    }, 3000)

  }
}
</script>

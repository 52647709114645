import headerDashboardStyle1
  from "@/components/layout/template-1/dashboard/page-components/headers/HeaderDashboardStyle1.vue";
import headerDashboardStyle2
  from "@/components/layout/template-1/dashboard/page-components/headers/HeaderDashboardStyle2.vue";
import headerDashboardStyle3
  from "@/components/layout/template-1/dashboard/page-components/headers/HeaderDashboardStyle3.vue";
import DashboardLayoutService from "@/services/creator/toning/layout/DashboardLayoutService";
import {mapMutations} from "vuex";
import SectionDashboardIntroStyle1
  from "@/components/layout/template-1/dashboard/page-components/sections/sectionDashboardIntroStyle1.vue";
import SectionDashboardIntroStyle2
  from "@/components/layout/template-1/dashboard/page-components/sections/sectionDashboardIntroStyle2.vue";
import sectionDashboardProgressUpdateStyle2
  from "@/components/layout/template-1/dashboard/page-components/sections/sectionDashboardProgressUpdateStyle2.vue";
import sectionDashboardProgressUpdateStyle1
  from "@/components/layout/template-1/dashboard/page-components/sections/sectionDashboardProgressUpdateStyle1.vue";


export default {
  watch:{
    selectedWidget: function(){
      if(this.selectedWidget!=='header'){
        localStorage.removeItem('showNewLogoPopupShown')
      }
    },
    selectedHeaderVariationId: function(newVal,oldVal){
      console.log('selectedHeaderVariationId', newVal, oldVal,this.editor.state)
      if(this.editor.state==='edit' && newVal==='style2'&& oldVal==='style1'){
        this.showNewLogoPopup()
      }
      this.layoutConfig.header.variation = newVal;
      if(this.editor.state==='edit'){
        this.logs.header = () =>{
          this.logEvent('DashboardVariations.ChangedStyle', {
            header_style : newVal,
            header_style_using_logo: !!this.getStoreImage('dashboard.header.logo')
          })
        }
      }

    },

    selectedDescriptionSelectionVariationId: function(newVal){

      const descriptionSectionIndex= this.layoutConfig.widgets.findIndex(w=>w.code==='descriptionSelection');

      this.layoutConfig.widgets[descriptionSectionIndex].variation = newVal;
      if(this.editor.state==='edit'){
        this.logs.intro = () =>{
          this.logEvent('DashboardVariations.ChangedStyle', {
            intro_text_style : newVal
          })
        }

      }

    },
    selectedProgressUpdateVariationId: function(newVal){

      const progressUpdateIndex= this.layoutConfig.widgets.findIndex(w=>w.code==='progressUpdateSection');

      this.layoutConfig.widgets[progressUpdateIndex].variation = newVal;
    },

  },
  data:function (){
    return {
      logs:{
        header : ()=>{},
        intro: ()=>{}
      },
      descriptionSelectionVariationEditable: false,
      selectedWidget: null,
      layoutConfig :{
        ver: '1.0',
        header: {
          variation: 'style1'
        },
        widgets: [
          {
            code : 'descriptionSelection',
            variation: 'style1'
          },
          {
            code : 'progressUpdateSection',
            variation: 'style1'
          }
        ],
        widgetOrder:[]
      },
      toningConfig: {},
      layoutService: new DashboardLayoutService(),
      editor:{
        state: 'preview', // preview, edit,
        disabled: false
      },
      selectedHeaderVariationId: 'style1',
      selectedDescriptionSelectionVariationId: 'style1',
      selectedProgressUpdateVariationId: 'style1',
      descriptionSelectionVariations: [
        {
          text: 'Style 1',
          value: 'style1',
          component: SectionDashboardIntroStyle1,
        },
        {
          text: 'Style 2',
          value: 'style2',
          component: SectionDashboardIntroStyle2,
        },
      ],
      progressUpdateVariations: [
        {
          text: 'Style 1',
          value: 'style1',
          component: sectionDashboardProgressUpdateStyle1,
        },
        {
          text: 'Style 2',
          value: 'style2',
          component: sectionDashboardProgressUpdateStyle2,
        },
      ],
      headerVariations: [
        {
          text: 'Style 1',
          value: 'style1',
          component: headerDashboardStyle1,
        },
        {
          text: 'Style 2',
          value: 'style2',
          component: headerDashboardStyle2,
        },
        {
          text: 'Style 3',
          value: 'style3',
          component: headerDashboardStyle3,
        }
      ]

    }
  },
  computed:{
    selectedHeaderVariation: function(){
      return this.headerVariations.find(variation => variation.value === this.selectedHeaderVariationId)
    },
    selectedDescriptionSelectionVariation: function(){
      return this.descriptionSelectionVariations.find(variation => variation.value === this.selectedDescriptionSelectionVariationId)
    },
    selectedProgressUpdateVariation: function(){
      return this.progressUpdateVariations.find(variation => variation.value === this.selectedProgressUpdateVariationId)
    },
  },
  methods:{
    ...mapMutations({
      setPageTitle: 'pageStore/setPageTitle'
    }),
    showNewLogoPopup(){
      if(localStorage.getItem('showNewLogoPopupShown')|| this.getStoreImage('dashboard.header.logo')){
        return
      }
      this.$store.commit('featurePopupStore/setContent', {
        title: `Now you can display your logo`,
        feature: 'new-dashboard-logo',
        featureInfo: [
          {
            video: '',
            navigate: {
              url: '',
              text:'Understood'
            },
            image: 'https://app-redesign-production-builds.s3.ap-southeast-2.amazonaws.com/common/dashboard-variation-logo.png',
            content: 'Great news, everyone! With the new Header Styles 2 and 3, you can now display your logo.' +
              '</br>' +
              '</br>' +
              'Simply go to <strong>Toning > Common > Dashboard Header Logo</strong>, or select <strong>\'Page Background & Headers\'</strong> from the dropdown. The Dashboard Header Logo configuration will be available.'
          }
        ]
      })
      localStorage.setItem('showNewLogoPopupShown', true)
      this.$store.commit('featurePopupStore/showPopup')


    },
    hasVariations(id) {
      const widget = this.widgets.find(w => w.id === id)
      return widget && widget.variations && widget.variations.length > 0
    },
    toggleProgressNotifications() {
      this.toggle.progressNotifications = !this.toggle.progressNotifications
    },
    changeVariation(id) {
      this.widgets = this.widgets.map(widget => {
        const {id: widgetId, variations, currentVariation} = widget;
        if (widgetId === id && variations.length > 0) {
          let currentIndex = variations.indexOf(currentVariation);
          widget.currentVariation = variations[(currentIndex + 1) % variations.length];
        }
        return widget;
      });
    },
    moveDown() {
      console.log(this.widgets)
      // find the index of the selected widget an move it down
      let index = this.widgets.findIndex(w => w.id === this.selectedWidgetArea.id)
      console.log(index)
      if (index < this.widgets.length - 1) {
        const widgets = this.widgets
        const widget = this.widgets[index]
        widgets.splice(index, 1); // Remove the item from the current position
        widgets.splice(index + 1, 0, widget); // Insert the item below

        this.widgets = widgets
      }
      console.log(this.widgets)
    },
    moveUp() {
      console.log(this.widgets, this.selectedWidgetArea.id)

      // find the index of the selected widget an move it up
      let index = this.widgets.findIndex(w => w.id === this.selectedWidgetArea.id)
      console.log(index)
      if (index > 0) {
        const widgets = this.widgets
        const widget = this.widgets[index]
        widgets.splice(index, 1); // Remove the item from the current position
        widgets.splice(index - 1, 0, widget); // Insert the item above
        this.widgets = widgets
      }
      console.log(this.widgets)
    },
    setSelectedWidget(id) {
      this.selectedWidgetArea.id = id
      this.selectedWidgetArea.show = true
    }
  }


}

<template>
  <div class="pc-skeleton-meal-plan-log">
    <div class="pc-container-meal-plan-meal-log">
      <div
        class="pc-skeleton_nutrition-wrap pc-food-dairy-home pc-food-dairy-home__calendar-slider"
      >
        <div
          class="pc-skeleton-calender pc-skeleton-calender--deasktop"
          v-for="index in 10"
          :key="index"
        >
          <div class="pc-skeleton-nutrients">
            <AnimatedPlaceholder width="40px" height="75px" />
          </div>
        </div>
        <div class="pc-skeleton-calender" v-for="index in 7" :key="index">
          <AnimatedPlaceholder width="40px" height="75px" />
        </div>
        <!-- <div class="pc-skeleton-nutrients">
          <AnimatedPlaceholder width="40px" height="75px" />
        </div>
        <div class="pc-skeleton-nutrients">
          <AnimatedPlaceholder width="40px" height="75px" />
        </div>
        <div class="pc-skeleton-nutrients">
          <AnimatedPlaceholder width="40px" height="75px" />
        </div>
        <div class="pc-skeleton-nutrients">
          <AnimatedPlaceholder width="40px" height="75px" />
        </div>
        <div class="pc-skeleton-nutrients">
          <AnimatedPlaceholder width="40px" height="75px" />
        </div> -->
      </div>
      <!-- <div class="gc-page__container pc-food-dairy-home__container">
        <div class="pc-skeleton-card-list">
          <div
            class="pc-skeleton-card gc-card-meal"
            v-for="index in 3"
            :key="index"
          >
            <div class="pc-skeleton-card-head">
              <AnimatedPlaceholder width="100px" />
              <AnimatedPlaceholder width="200px" />
            </div>
            <div class="pc-skeleton-card-bottom">
              <div class="pc-skeleton_nutrition-wrap">
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder";

export default {
  components: {
    AnimatedPlaceholder,
  },
};
</script>

<style lang="scss" scoped></style>

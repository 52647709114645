<template>
    <div class="pc-habits-tracker-skeleton">
        <div class="pc-habits-tracker-skeleton__calender">
            <div
            class="pc-skeleton-calender pc-skeleton-calender--deasktop"
            v-for="index in 2"
            :key="index"
          >
            <div class="pc-skeleton-nutrients">
              <AnimatedPlaceholder width="40px" height="75px" />
            </div>
          </div>
          <div class="pc-skeleton-calender" v-for="index in 7" :key="index">
            <AnimatedPlaceholder width="40px" height="75px" />
          </div>
        </div>
        <div class="container pc-habits-tracker-skeleton__habits-list gc-habits-tracker__habits-list">
          <div class="pc-habits-tracker-skeleton__habits-list-item" v-for="index in 7" :key="index">
              <div><AnimatedPlaceholder width="150px" height="20px" /></div>
              <div class="pc-habits-tracker-skeleton__habits-list-item-icons"><AnimatedPlaceholder width="24px" height="24px" border-radius="50%"/><AnimatedPlaceholder width="24px" height="24px" border-radius="50%"/></div>
          </div>
        </div>
    </div>
       

</template>
<script>
import AnimatedPlaceholder from '@/components/global/animators/AnimatedPlaceholder'

export default {
    components: {
        AnimatedPlaceholder
    }
}
</script>
<style lang="scss">


</style>
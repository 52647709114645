import { mapGetters, mapMutations } from 'vuex'
import {isMobileNativeApp} from "@/includes/NativeAppCommon";

export default {
  computed: {
    ...mapGetters({
      isNativeAppUpdatePopupShown: 'nativeAppUpdateStore/isPopupShown',
      nativeAppUpdatePopupVisibility: 'nativeAppUpdateStore/visible',
      nativeAppUpdatePopupContent: 'nativeAppUpdateStore/getInfo'
    }),
    showNativeAppUpdatesPopup () {
      return this.checkNativeAppUpdatePopupToShow()
    }

  },
  methods: {
    ...mapMutations({
      setNativeAppUpdateContent: 'nativeAppUpdateStore/setContent'

    }),
    checkNativeAppUpdatePopupToShow () {
      return (!this.isNativeAppUpdatePopupShown)  && !this.$store.state.loading
    },
    addEventListenerNativeAppUpdateEvent () {
      if(!isMobileNativeApp()){
        return
      }
      window.addEventListener('checkUpdatesResponse', this.setNativeAppUpdateEvent)
    },
    removeEventListenerNativeAppUpdateEvent () {
      if(!isMobileNativeApp()){
        return
      }
      window.removeEventListener('checkUpdatesResponse', this.setNativeAppUpdateEvent)
    },
    setNativeAppUpdateEvent (event) {
      this.setNativeAppUpdateContent(event.detail || {})
    }
  }

}

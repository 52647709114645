let isInitialized = false;

export function initNotifications(appId){

  return new Promise((resolve, reject) => {
    if (isInitialized) {
      resolve();
      return;
    }
    const script = document.createElement("script");
    script.src = "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.js";
    script.defer = true;

    script.onload = function () {
      window.OneSignal = window.OneSignal || [];
      window.OneSignal.push(function () {
        window.OneSignal.init({
          appId: appId,
        });
      });

      isInitialized = true;
      resolve();
    };

    document.head.appendChild(script);
  });

}

export function registerUser(uuid){

  OneSignal.push(async (OneSignal) => {
    await OneSignal.login(uuid);
  });
}


export function subscribeToNotifications(){

  if(!OneSignal.Notifications.permission){
    OneSignal.Notifications.requestPermission()
      .then(() => {
        OneSignal.push(async () => {
          await OneSignal.Slidedown.promptPush();
          await OneSignal.User.PushSubscription.optIn();

        });

      })

  } else {
    OneSignal.push(async () => {
      await OneSignal.Slidedown.promptPush();
      await OneSignal.User.PushSubscription.optIn();
    });

  }
}


export function unsubscribeToNotifications(){
   OneSignal.User.PushSubscription.optOut();
}



export function isPWA() {
  return window.matchMedia('(display-mode: standalone)').matches || (navigator.standalone || (screen.height-document.documentElement.clientHeight<40))
}

console.log("Is PWA:", isPWA());


import {env} from '../../config/custom-config'
import moment from 'moment'
import store from '@/store'

/**
 * get template config flag value
 * @param {string} flag - Key of the config.
 * @param {string|integer|float|boolean|Object|Array} testingValue - Value to be assigned on testing env
 * @returns {string|integer|float|boolean|Object|Array}
 */
const enabledFlags = ['show_fitness_diary']

export function flagValue (flag, testingValue = true) {
  if (
    templateConfig.instance_config &&
    flag in templateConfig.instance_config
  ) {
    // config value assigned from toning
    return templateConfig.instance_config[flag]
  } else if (isAEnabledFlag(flag)) {
    return true
  } else if (
    env === 'feature-testing' ||
    env === 'development' ||
    env === 'alpha'
  ) {
    // expected value for testing
    return testingValue
  } else {
    // if no config value has been assigned
    return null
  }
}

export function flagEnabledDate (flag) {
  const defaultEnabledDate = new Date('2020-01-01')
  let enabledDate = defaultEnabledDate
  if (!flagValue(flag)) {
    throw new Error('flag not enabled - ' + flag)
  } else if (
    templateConfig.instance_config &&
    flag in templateConfig.instance_config_timestamps
  ) {
    enabledDate =
      templateConfig.instance_config_timestamps[flag].updated_at ||
      defaultEnabledDate
  }

  return moment(enabledDate)
}

export function periodTrackerEnabledDate () {
  return flagEnabledDate('show_period_tracker')
}

export function showNutritionInformationFlag () {
  return flagValueDefaultEnabled('show_nutrition_information')
}

export function showMealSwapDislikePopupFlag () {
  return flagValueDefaultEnabled('meal_swap_dislike_popup')
}

export function enableIngredientsGroupedFlag () {
  return flagValueDefaultEnabled('is_ingredients_grouped')
}

export function showFoodDairy () {
  return flagValueDefaultEnabled('food_diary_enabled')
}

export function isFoodDiaryEnabled () {
  return showFoodDairy()
}

function isAEnabledFlag (flag) {
  return enabledFlags.some((flagStr) => {
    return flagStr === flag
  })
}

function flagValueDefaultEnabled (flag) {
  const enabled = flagValue(flag)
  if (enabled === null) {
    return true
  }
  return enabled
}

function flagValueDefaultDisabled (flag) {
  const enabled = flagValue(flag, false)
  if (enabled === null) {
    return false
  }
  return enabled
}

export function isDesktopModeEnabled () {
  return true
}

export function isPhotoUploadEnabled () {
  return flagValueDefaultEnabled('show_photo_upload')
}

export function isMeasurementHeightEnabled () {
  return flagValueDefaultEnabled('measurements.show_height')
}

export function isMeasurementWeightEnabled () {
  return flagValueDefaultEnabled('measurements.show_weight')
}

export function isMeasurementShouldersEnabled () {
  return flagValueDefaultEnabled('measurements.show_shoulders')
}

export function isMeasurementArmsEnabled () {
  return flagValueDefaultEnabled('measurements.show_arms')
}

export function isMeasurementChestEnabled () {
  return flagValueDefaultEnabled('measurements.show_chest')
}

export function isMeasurementWaistEnabled () {
  return flagValueDefaultEnabled('measurements.show_waist')
}

export function isMeasurementHipEnabled () {
  return flagValueDefaultEnabled('measurements.show_hip')
}

export function isMeasurementCalvesEnabled () {
  return flagValueDefaultEnabled('measurements.show_calves')
}

export function isMeasurementLegsEnabled () {
  return flagValueDefaultEnabled('measurements.show_legs')
}

export function isMeasurementFatEnabled () {
  return flagValueDefaultEnabled('measurements.show_fat')
}

export function isMeasurementsEnabled () {
  let arr = [
    isMeasurementShouldersEnabled(),
    isMeasurementArmsEnabled(),
    isMeasurementChestEnabled(),
    isMeasurementWaistEnabled(),
    isMeasurementHipEnabled(),
    isMeasurementLegsEnabled(),
    isMeasurementCalvesEnabled(),
    isMeasurementHeightEnabled(),
  ]

  return arr.some((item) => item === true)
}

export function showWorkoutWeekSlider () {
  return flagValueDefaultEnabled('show_dashboard_week_slider')
}

export function isMyJourneyEnabled () {
  let arr = [
    isMeasurementShouldersEnabled(),
    isMeasurementArmsEnabled(),
    isMeasurementChestEnabled(),
    isMeasurementWaistEnabled(),
    isMeasurementHipEnabled(),
    isMeasurementLegsEnabled(),
    isMeasurementCalvesEnabled(),
    isMeasurementHeightEnabled(),
    isMeasurementFatEnabled(),
    isMeasurementWeightEnabled(),
  ]

  return arr.some((item) => item === true)
}

export function showMyJourneyWidget () {
  return (
    flagValueDefaultDisabled('show_my_journey_widget') &&
    isPhotoUploadEnabled() &&
    isMeasurementWeightEnabled()
  )
}

export function showSubscriptionForm () {
  return flagValueDefaultEnabled('show_subscription_form')
}

export function enableGroupedIngredients () {
  return enableIngredientsGroupedFlag()
}

export function enableShoppingListIngredientsFlag () {
  return flagValueDefaultEnabled('is_shopping_list_ingredients_grouped')
}

export function enableMealDislikesFlag () {
  return flagValueDefaultDisabled('enable_meal_dislikes')
}

export function showMealPlanDays () {
  return flagValueDefaultDisabled('enable_meal_plan_days_selection')
}

export function showFoodDiaryFutureDays () {
  return flagValueDefaultEnabled('show_food_diary_future_dates')
}

export function showBottomNavigationBar () {
  return flagValueDefaultDisabled('show_bottom_navigation_bar')
}

export function enableSmartCheckingForActivityLvl () {
  return flagValueDefaultDisabled('enable_smart_checking_for_activity_lvl')
}

export function getCreatorAnalyticsConfig () {
  return flagValueDefaultDisabled('creator_analytics_config')
}

export function getVisibilityOfCaloriesInNutrition () {
  return flagValueDefaultDisabled('hide_calories_in_nutrition')
}


export function showSignUpFlowForAllTypes(){
  return flagValueDefaultEnabled('show_sign_up_forms')
}

export function showSignUpFlow () {

  return flagValueDefaultEnabled('show_sign_up_forms')

}

export function enableFlexibleWorkoutLog () {
  return flagValueDefaultDisabled('enable_flexible_workout_log')
}

export function newDashboardLayout () {
  return !(store.getters.getAppMode === 'mobile' && showBottomNavigationBar());
}

export function showNewDashboardLayout () {
  if (
    localStorage.getItem('newDashboardTheme') === null ||
    localStorage.getItem('newDashboardTheme') === 'true'
  ) {
    return newDashboardLayout()
  } else {
    return false
  }
}

export function enableMealFavourites () {
  return true //enable for all //flagValueDefaultDisabled('enable_meal_favorites')
}

export function enableSmartAdd () {
  return flagValueDefaultDisabled('enable_smart_add')
}

export function enableStepTracker () {
  return flagValueDefaultDisabled('enable_step_tracker')
}

export function enableMealPlanQuestionnaire () {
  return flagValueDefaultDisabled('enable_meal_plan_questionnaire')
}

/**
 * only for testing should be false in production
 * @returns {boolean|*}
 */
export function progressCheckInDataSaveBypass () {
  return flagValueDefaultDisabled('progress_save_bypass')
}
export function enablePayPal () {
  return true
}

/**
 * Check if the creator enabled  the new meal swap v2 feature
 * if not enabled, the old meal swap feature will be used
 * @returns {boolean|*}
 */
export function enableSuggestedMealSwap () {
  return flagValueDefaultEnabled('enable_suggested_meal_swap')
}

export function enableMealPlanSatisfaction() {
  return flagValueDefaultDisabled('enable_meal_plan_satisfaction')
}
export function enableDashboardVariations() {
  return flagValueDefaultDisabled('enable_dashboard_variations')
}
export function enableDashboardVariationsDescription() {
  return true
}

export function enableDashboardVariationsProgressUpdate() {
  return true
}

export function enablePushNotificationCustomization() {
  return flagValueDefaultDisabled('enable_push_notification_customization')
}


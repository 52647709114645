<template>
  <div class="pc-my-journey-skeleton">
    <div class="container">
      <!-- image card -->
      <!-- <div
        class="pc-my-journey-skeleton__card-photo gc-card-photo-journey-compare"
      >
        <div class="pc-my-journey-skeleton-card-header">
          <AnimatedPlaceholder width="150px" height="20px" />
          <AnimatedPlaceholder width="15px" height="20px" />
        </div>
        <div
          class="pc-my-journey-skeleton__card-content gc-card-photo-journey-compare__before"
        >
          <div class="gc-card-photo-journey-compare__second-title">
            <AnimatedPlaceholder width="150px" height="10px" />
          </div>
          <div
            class="pc-my-journey-skeleton__card-content-images-wrap gc-card-photo-journey-compare__image-wrapper"
          >
            <AnimatedPlaceholder
              width="100%"
              height="150px"
              border-radius="10px"
            />
            <AnimatedPlaceholder
              width="100%"
              height="150px"
              border-radius="10px"
            />
            <AnimatedPlaceholder
              width="100%"
              height="150px"
              border-radius="10px"
            />
          </div>

          <div
            class="gc-card-photo-journey-compare__second-title pc-my-journey-skeleton__card-photo-text"
          >
            <AnimatedPlaceholder width="250px" height="10px" />
          </div>
        </div>
        <div
          class="pc-my-journey-skeleton__card-content-button gc-card-photo-journey-compare__more-button"
        >
          <AnimatedPlaceholder width="80px" height="16px" />
        </div>
      </div> -->
      <!-- image card end -->

      <!-- empty card -->
      <div class="pc-my-journey-skeleton__card gc-card-photo-journey-compare">
        <div class="pc-my-journey-skeleton-card-header">
          <AnimatedPlaceholder width="150px" height="20px" />
          <AnimatedPlaceholder width="15px" height="20px" />
        </div>
        <div
          class="pc-my-journey-skeleton__card-content pc-my-journey-skeleton__card-content--empty gc-card-photo-journey-compare__empty"
        >
          <AnimatedPlaceholder width="100%" height="16px" />
          <AnimatedPlaceholder width="80px" height="16px" />
        </div>
        <div
          class="pc-my-journey-skeleton__card-content-button gc-card-photo-journey-compare__more-button"
        >
          <AnimatedPlaceholder width="80px" height="16px" />
        </div>
      </div>
      <!-- empty card end -->
      <!-- other cards -->
      <div
        class="pc-my-journey-skeleton__card gc-card-photo-journey-compare"
        v-for="index in 4"
        :key="index"
      >
        <div class="pc-my-journey-skeleton-card-header">
          <AnimatedPlaceholder width="150px" height="20px" />
          <AnimatedPlaceholder width="15px" height="20px" />
        </div>
        <div
          class="pc-my-journey-skeleton__card-content gc-card-photo-journey-compare__empty"
        >
          <AnimatedPlaceholder width="100%" height="16px" />
          <AnimatedPlaceholder width="120px" height="30px" />
          <AnimatedPlaceholder width="60px" height="16px" />
        </div>
        <div
          class="pc-my-journey-skeleton__card-content-button gc-card-photo-journey-compare__more-button"
        >
          <AnimatedPlaceholder width="80px" height="16px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "../../../global/animators/AnimatedPlaceholder";

export default {
  name: "page-my-journey-skeleton",
  components: { AnimatedPlaceholder },
};
</script>

<style lang="scss" scoped>
.pc-my-journey-skeleton {
  .pc-my-journey-skeleton-card-header {
    display: flex;
    justify-content: space-between;
  }
  .pc-my-journey-skeleton__card-content {
    .gc-placeholder {
      display: block;
      margin: auto;
      margin-top: 8px;
    }
  }
  .pc-my-journey-skeleton__card-content-button {
    padding: 0;
    .gc-placeholder {
      display: inline-block;
    }
  }
  .pc-my-journey-skeleton__card-content-images-wrap {
    display: flex;
    gap: 8px;
  }
  .pc-my-journey-skeleton__card-photo-text {
    margin-bottom: 8px;
  }
}
</style>

<template>
  <div class="pc-skeleton-meal-plan-log">
    <div class="pc-container-meal-plan-meal-log">
      <div
        class="pc-skeleton_nutrition-wrap pc-food-dairy-home pc-food-dairy-home__calendar-slider"
      >
        <div
          class="pc-skeleton-calender pc-skeleton-calender--deasktop"
          v-for="index in 2"
          :key="index"
        >
          <div class="pc-skeleton-nutrients">
            <AnimatedPlaceholder width="40px" height="75px" />
          </div>
        </div>
        <div class="pc-skeleton-calender" v-for="index in 7" :key="index">
          <AnimatedPlaceholder width="40px" height="75px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder";

export default {
  components: {
    AnimatedPlaceholder,
  },
};
</script>

<style></style>

<template>
  <page-secondary :header="headerOptions" class="pc-meal-plan" :type="'primary'">

    <transition name="fade">
      <page-workout-program-skeleton v-if="pageLoading"/>
      <div v-else>
        <page-container class="pc-meal-plan__container-header">
          <card-image-cover
            class="pc-meal-plan__cover-image"
            :image-src="mealPlan.getCoverImage()"
            :default-image-src="getStoreImage('workout.week1.background')"
          >
            <div class="pc-meal-plan__cover-content-area">
              <div class="pc-meal-plan__cover-text">

                <text-content
                  class="pc-meal-plan__image-cover-text"
                  :size="'md3'"
                  :weight="'extra-bold'"
                  :lineHeight="'multi'">
                  <p>{{ mealPlan.getName() }}
                  </p>
                </text-content>
                <icon-info-bulb
                  v-if="showInstructionsPopupIcon"
                  @click="viewInstructionsPopup"
                  :size="'md1'" />

              </div>
            </div>
          </card-image-cover>
          <div class="pc-meal-plan__sliders">
            <slider-navigation class='pc-meal-plan__slider-navigation-days'
                               :slider-id="mealPlanID.toString()"
                               v-if="dayNavigationLinks && dayNavigationLinks.length>0"
                               :active-link-i-d="currentDayID"
                               :links="dayNavigationLinks"
                               :isWeekSlider="true"
                               @click-link="setActiveDay($event)"
            />
          </div>
        </page-container>
        <page-container class="pc-meal-plan__container-nutrition">

          <bar-nutrition-details
            class="pc-meal-plan__nutrition-details-bar"
            v-if="showNutritionCard"
            :macroNutrients="macroNutrients"
            :calories="calories"
          />
        </page-container>

        <page-container class="pc-meal-plan-day__container">

            <PageDaySkeleton v-if="skeltonLoad" />
          <transition v-else-if="mealPlanDay" name="card-fade">
            <div>
              <div class="pc-meal-plan__section-info">
                <div>
                  <text-body-small line-height="lg-lg">{{$tc('message["meal-plan.meal-count"]',mealBoxes.length,{count: mealBoxes.length})}}</text-body-small>

                </div>
                <div class="pc-meal-plan__section-info-right">

                  <icon-send @click="dayRecipeEmailPopup.show= true" size="sm4"></icon-send>
                  <icon-cart  v-if="showCart" @click="navigateToSingleDayShoppingCart" size="sm4"></icon-cart>

                </div>

              </div>
              <grid-row class="pc-meal-plan-day__card-meal-row">
                <grid-col
                  class="pc-meal-plan-day__card-meal-col"
                  lg="6"
                  v-for="(meal, key) in mealBoxes"
                  :key="'meal-box' +dayIndex+ key"
                >
                  <transition name="card-fade">
                    <meal-card
                      :day-i-d="currentDayID"
                      :show-swap="isSwapEnabled"
                      :day-title="numberWordToNumeric(headerOptions.mainTitle)"
                      :meal-options="meal"
                      @meal-swap-success="swapSuccess"
                    />
                  </transition>

                </grid-col>
              </grid-row>
            </div>



       </transition>
          <popup-day-recipe-email
            v-if="dayRecipeEmailPopup.show"
            :calories="nutritionOptions.calories"
            :day-i-d="mealPlanDay.getId()"
            :day-name="dayName"
            :macronutrients="nutritionOptions.macronutrients"
            :meal-plan-i-d="$route.params.mid"
            :meal-plan-day="mealPlanDay"
            :visible="dayRecipeEmailPopup.show"
            @close="hideDayRecipeEmailPopup()"
          />
        </page-container>
        <div v-if="!skeltonLoad" class="pc-meal-plan__meal-change-button">
          <button-meal-change-request
            v-if="mealChangeRequestFlagEnabled"
            :mealId="mealPlanID"
          />
        </div>
        <container-meal-plan-email-summary
          ref="mealPlanEmailSummary"
          class="pc-meal-plan_container-meal-plan-email-summary"
          :meal-plan-id="mealPlanID"/>

      </div>
    </transition>

  </page-secondary>
</template>

<script>
import MealCard from "./page-components/CardMeal";
import BaseComponent from "../global/base/BaseComponent";
import CartButton from "./widgets/CartButton";
import { mapGetters, mapMutations } from "vuex";
import pageConfigMixin from "../../../../mixins/pageConfigMixin";
import UserEventsService from "../../../../services/UserEventsService";
import BarNutritionDetails from "./../../../global/bars/BarNutritionDetails";
import PageSecondary from "../../../global/pages/PageSecondary";
import PageContainer from "../../../global/pages/page-sub-components/PageContainer";
import {
  enableMealPlanQuestionnaire,
  flagValue, showBottomNavigationBar,
  showNutritionInformationFlag,
} from "../../../../includes/TemplateSettings";
import headerEventBus from "../../../../event-buses/headerEventBus";
import PopupDayRecipeEmail from "./page-components/PopupDayRecipeEmail";
import GridRow from "../../../global/grid/GridRow";
import GridCol from "../../../global/grid/GridCol";
import NutritionService from "../../../../services/nutrition/NutritionService";
import { pageReadyEvent } from "@/helpers/dom/events/customEvents";
import ButtonMealChangeRequest from "@/components/layout/template-1/meal-plan/page-components/ButtonMealChangeRequest.vue";
import PageDaySkeleton from "./PageDaySkeleton.vue";
import pageMealPlanMixin from "@/mixins/component-mixins/page/meal-plan/pageMealPlanMixin";
import PageWorkoutProgramSkeleton from "@/components/layout/template-1/workout/PageWorkoutProgramSkeleton.vue";
import TextContent from "@/components/root/TextContent.vue";
import CardImageCover from "@/components/global/cards/CardImageCover.vue";
import SliderNavigation from "@/components/global/sliders/SliderNavigationV2.vue";
import IconCart from "@/components/root/icons/IconCart.vue";
import IconSend from "@/components/root/icons/IconSend.vue";
import ContainerMealPlanEmailSummary
  from "@/components/layout/template-1/meal-plan/page-components/ContainerMealPlanEmailSummary.vue";
import DefineInclude from "@/DefineInclude";
import TextBodySmall from "@/components/global/typography/TextBodySmall.vue";
import IconInfoBulb from "@/components/root/icons/IconInfoBulb.vue";

export default {
  name: "PageMealPlan",
  components: {
    IconInfoBulb,
    TextBodySmall,
    ContainerMealPlanEmailSummary,
    IconSend,
    IconCart,
    SliderNavigation,
    CardImageCover, TextContent,
    PageWorkoutProgramSkeleton,
    ButtonMealChangeRequest,
    GridCol,
    GridRow,
    MealCard,
    CartButton,
    BarNutritionDetails,
    PageSecondary,
    PageContainer,
    PopupDayRecipeEmail,
    PageDaySkeleton,
  },
  extends: BaseComponent,
  mixins: [pageConfigMixin,pageMealPlanMixin],
  data: function () {
    return {
      showCart: false,
      dayIndex: -1,
      nutritionOptions: {
        calories: 0,
        macronutrients: {
          proteins: 0,
          fats: 0,
          carbohydrates: 0,
          net_carbohydrates: 0,
          show_net_carbs: false,
        },
      },
      dayRecipeEmailPopup: {
        show: false,
      },
      dayName: '',
      skeltonLoad: true,
      calories: [],
      macroNutrients: [],
      showNutritionCard: false,
      currentDayID: this.$route.query.day,
      pageLoading: true,
      mealPlanDay: null,
      isSwapEnabled: false,
      mealBoxes: [],
      headerOptions: {
        show: true,
        isTransparent: false,
        left: "menu",
        right: "message",
        rightDisabled: true,
        helpContent: "",
        modifiers: ["small-title"],
        formData: {
          image: "",
        },
        header: {
          mainTitle: "",
          subTitle: "",
        },
        mainTitle: "",
        subTitle: "",
      },
    }
  },watch:{
    isDesktop: function (val) {
      this.setHeaderIcons()
    },
  },
  computed: {
    showInstructionsPopupIcon: function () {
      if (!this.mealPlan) {
        return false;
      }
      return this.checkIsNotEmptyTextArray(this.instructionsPopup.instructions) || this.checkIsNotEmptyTextArray(this.instructionsPopup.notes);
    },
    mealChangeRequestFlagEnabled: function () {
      return enableMealPlanQuestionnaire();
    },
    dayNavigationLinks: function () {
      if (!this.mealPlan) {
        return [];
      }
      return this.mealPlan.getDays().map((day) => {
        return {
          text: this.numberWordToNumeric(day.getName(), true),
          id: day.getId(),
          info: "",
          completed: false,
        };
      });
    },

  },
  mounted() {
    this.setHeaderIcons()
    this.logEvent('Viewed Meal Plan', {meal_plan_id: this.mealPlanID}, false)
    UserEventsService.sendMealPlanFeedBackPopupView()
    this.hideLoading()

    headerEventBus.$on('cart-button-click', this.shoppingCartButtonClicked)
    headerEventBus.$on('send-button-click', this.summaryButtonClicked)


  },

  methods: {
    setHeaderIcons(){
      if(this.isDesktop){
        this.headerOptions.left = 'message'
        this.headerOptions.right = 'cart'
      } else if(!showBottomNavigationBar()){
        this.headerOptions.left = 'menu'
        this.headerOptions.right = 'cart|message'
      } else {
        this.headerOptions.left = 'message'
        this.headerOptions.right = 'cart'
      }
    },
    hideDayRecipeEmailPopup() {
      this.dayRecipeEmailPopup.show = false;
    },
    setShoppingListData(dayIndex) {
      this.dayIndex = dayIndex + 1;
      this.$store.commit("shoppingStore/people", { count: 1 });
      this.$store.commit("shoppingStore/days", { days: [this.dayIndex] });

      this.showCart = true;
    },
    setActiveDay(e){
        this.currentDayID = e
        this.setMealPlanDayData(this.mealPlan.getDay(e))
        this.saveSliderActiveDataInStorage()

    },
    addMacroNutrients() {
      this.macroNutrients = [];
      const Protein = this.nutritionOptions.macronutrients.proteins;
      this.macroNutrients.push({
        unit: this.$i18n.t("message['general.g']"),
        name: this.$i18n.t("message['general.proteins']"),
        qut: Protein,
      });
      const fat = this.nutritionOptions.macronutrients.fats;
      this.macroNutrients.push({
        unit: this.$i18n.t("message['general.g']"),
        name: this.$i18n.t("message['general.fats']"),
        qut: fat,
      });
      const Carb = this.nutritionOptions.macronutrients.carbohydrates;
      this.macroNutrients.push({
        unit: this.$i18n.t("message['general.g']"),
        name: this.$i18n.t("message['general.carbs']"),
        qut: Carb,
      });
      if (this.nutritionOptions.macronutrients.show_net_carbs === true) {
        const NetCarb = this.nutritionOptions.macronutrients.net_carbohydrates;
        this.macroNutrients.push({
          unit: this.$i18n.t("message['general.g']"),
          name: this.$i18n.t("message['general.carbs-net']"),
          qut: NetCarb,
        });
      }
    },
    addCalories() {
      this.calories = [];
      const calories = this.nutritionOptions.calories;
      this.calories.push({
        unit: "",
        name: this.$i18n.tc("message['general.calories']", calories),
        qut: calories,
      });
    },

    setMealPlanDayData(day) {
      const dayService = day

      this.dayName = this.numberWordToNumeric(day.getName());
      this.currentDayID = day.getId();
     this.mealBoxes = dayService.getMeals();
      this.mealPlanDay = dayService;
       this.nutritionOptions.calories = dayService.getCalories();
     this.nutritionOptions.macronutrients = dayService.getMacroNutrients();
    this.addMacroNutrients();
     this.addCalories();
      this.showNutritionCard = showNutritionInformationFlag();

      this.setShoppingListData(
        this.mealPlan.getDayIndex(day.getId())
      );
    },
    swapSuccess(){
      this.skeltonLoad= true
      console.log('swapSuccess')
      this.getMealPlanOptionData(this.mealPlanID, true)
    },
    setCurrentWorkoutSliderActiveData() {
      let storageData =
        localStorage.getItem("mealPlanSlider") ||
        '{ "dayID": null,"planId": null }';
      storageData = JSON.parse(storageData);
      if (
        !storageData.planId ||
        parseInt(storageData.planId) !== parseInt(this.mealPlan.getId())
      ) {
        return;
      }

      this.currentDayID = storageData.dayID || null;

    },
    saveSliderActiveDataInStorage() {
      let storageData = {
        dayID: this.currentDayID,
        planId: this.mealPlan.getId(),
      };
      storageData = JSON.stringify(storageData);

      localStorage.setItem("mealPlanSlider", storageData);
    },
    afterGetMealPlanOptionData () {
      this.setCurrentWorkoutSliderActiveData()
      try{
        if(!this.currentDayID){
          this.currentDayID = this.mealPlan.getDays()[0].getId()
        }

        this.setActiveDay(this.currentDayID)
      } catch (e) {
        this.currentDayID = this.mealPlan.getDays()[0].getId()
        this.setActiveDay(this.currentDayID)
      }

      this.skeltonLoad= false
      this.isSwapEnabled = this.mealPlan.isSwapEnabled();
      this.pageLoading= false
      pageReadyEvent()
    },
    shoppingCartButtonClicked(){
      this.$store.commit('shoppingStore/days', {days: [1, 2, 3, 4, 5, 6, 7]})

      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.shoppingList.replace(
          ':mid',
          this.mealPlanID
        ),
        query: {loading: 0}
      })
    },
    navigateToSingleDayShoppingCart () {
        this.$router.push({
          path: DefineInclude.appUrlList.shoppingList.replace(
            ':mid',
            this.$route.params.mid
          ),
          query: { page: 'shop',loading: 0 }
        })
        this.logEvent('MealPlan.SingleDay.ShoppingListViewed', {day: 'Day ' + this.dayid}, false)
    },

    summaryButtonClicked(){
        this.$refs.mealPlanEmailSummary.showPopup()
    },
  },
  beforeDestroy () {
    headerEventBus.$off('cart-button-click', this.shoppingCartButtonClicked)
    headerEventBus.$off('send-button-click', this.summaryButtonClicked)
  }
}
</script>

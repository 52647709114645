<template>
  <grid-row class="pc-dashboard__action-buttons pc-dashboard__action-buttons-style-2">
    <grid-col>
      <div class="pc-section-dashboard-progress-update-style-2__card">
        <div>
          <text-body-small class="pc-section-dashboard-progress-update-style-2__title" :weight="'extra-bold'">{{$t('message["progress-update-section.header"]')}}</text-body-small>
          <text-body-extra-small class="pc-section-dashboard-progress-update-style-2__sub-title" >{{$t('message["progress-update-section.sub-title"]')}}</text-body-extra-small>
        </div>
        <div class="pc-section-dashboard-progress-update-style-2__button-wrapper">
          <div v-for="(notification, id) in notifications"
               v-bind:key="'dashboard-notification' + id"
               :lg="notifications.length === 1 ? 12 : 6">
               <button-custom
                class="pc-section-dashboard-progress-update-style-2__button"
                :text="buttonText(notification.code)"
                @click="doAction(notification.action)"
                v-if="!notification.action.url.includes(
                    'api/notifications/subscription/'
                  )"
                >
                <template v-slot:right>
                  <icon-up-arrow-filled :size="'sm1'" class="pc-section-dashboard-progress-update-style-2__icon" />
                </template>

              </button-custom>
          </div>
        </div>
      </div>
    </grid-col>
  </grid-row>
</template>

<script>
import GridCol from "../../../../../global/grid/GridCol.vue";
import ButtonProgressUpdate from "./../ButtonProgressUpdate.vue";
import GridRow from "@/components/global/grid/GridRow.vue";
import TextBodySmall from "@/components/global/typography/TextBodySmall.vue";
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall.vue";
import IconUpArrowFilled from "@/components/root/icons/IconUpArrowFilled";
import ButtonCustom from "@/components/global/buttons/ButtonCustom.vue";

export default {
  name: 'sectionDashboardProgressUpdateStyle2',
  components: {
    GridRow,
    GridCol,
    ButtonProgressUpdate,
    TextBodySmall,
    TextBodyExtraSmall,
    IconUpArrowFilled,
    ButtonCustom
  },
  data: function () {
    return {
    }
  },
  props: {
    notifications: {
      default: ''
    }
  },
  mounted() {
  },
  methods: {
    buttonText (code) {
      if (code.includes('meal-plan')) {
        return this.$t('message["progress-update-section.meal-plan-button-text"]');
      } else if (code.includes('workout-program')) {
        return this.$t('message["progress-update-section.workout-plan-button-text"]');
      }
    },
    doAction(action) {
      this.showLoading()
      if (action.url.includes('meal-plans/update')) {
        // delaying route push to prevent UI breakage visibility
        this.delayedRouterPush({
          path: this.$appConfig.appUrlList.mealPlanUpdate
        })
      } else if (action.url.includes('workout-programs/update')) {
        // delaying route push to prevent UI breakage visibility
        this.delayedRouterPush({
          path: this.$appConfig.appUrlList.workoutUpdate
        })
      } else if (action.url.includes(window.location.hostname)) {
        // delaying route push to prevent UI breakage visibility
        this.delayedRouterPush({
          path: action.url.replace(
            window.location.protocol + '//' + window.location.hostname,
            ''
          )
        })
      } else {
        this.hideLoading()
      }
    }
  }
}
</script>

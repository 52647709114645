<template>
  <div class="pc-skeleton-meal-plan-log">
    <div class="pc-container-meal-plan-meal-log pc-skeleton-meal-plan-log">
      <div class="pc-food-dairy-home__container">
        <div class="pc-skeleton-card gc-card-meal pc-skeleton-card-first">
          <div class="pc-skeleton-card-head">
            <AnimatedPlaceholder width="100px" />
          </div>
          <div class="pc-skeleton-card-bottom">
            <div class="pc-skeleton_nutrition-wrap">
              <div class="pc-skeleton-nutrients">
                <AnimatedPlaceholder width="40px" height="50px" />
              </div>
              <div class="pc-skeleton-nutrients">
                <AnimatedPlaceholder width="40px" height="50px" />
              </div>
              <div class="pc-skeleton-nutrients">
                <AnimatedPlaceholder width="40px" height="50px" />
              </div>
              <div class="pc-skeleton-nutrients">
                <AnimatedPlaceholder width="40px" height="50px" />
              </div>
              <div class="pc-skeleton-nutrients">
                <AnimatedPlaceholder width="40px" height="50px" />
              </div>
              <div class="pc-skeleton-nutrients">
                <AnimatedPlaceholder width="40px" height="50px" />
              </div>
            </div>
            <div class="pc-skeleton-card-bottom-prograss">
              <AnimatedPlaceholder width="70px" height="12px" />
              <AnimatedPlaceholder
                width="100%"
                height="10px"
                border-radius="0"
              />
            </div>
          </div>
        </div>
        <div class="pc-skeleton-card-list">
          <div
            class="pc-skeleton-card gc-card-meal"
            v-for="index in 3"
            :key="index"
          >
            <div class="pc-skeleton-card-head">
              <AnimatedPlaceholder width="100px" />
              <AnimatedPlaceholder width="200px" />
            </div>
            <div class="pc-skeleton-card-bottom">
              <div class="pc-skeleton_nutrition-wrap">
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
                <div class="pc-skeleton-nutrients">
                  <AnimatedPlaceholder width="40px" height="50px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder";

export default {
  components: {
    AnimatedPlaceholder,
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="pc-step-tracker-skeleton">
    <!--  header -->
    <div class="pc-step-tracker-skeleton__section-one">
      <div class="pc-step-tracker-skeleton-connection">
        <AnimatedPlaceholder width="100%" height="12px" borderRadius="0" />
      </div>

      <div class="pc-step-tracker-skeleton__date">
        <AnimatedPlaceholder width="100%" height="32px" borderRadius="0" />
        <AnimatedPlaceholder width="100%" height="32px" borderRadius="0" />
        <AnimatedPlaceholder width="100%" height="32px" borderRadius="0" />
        <AnimatedPlaceholder width="100%" height="32px" borderRadius="0" />
      </div>
      <div class="container">
        <div class="pc-step-tracker-skeleton-nutrition">
          <AnimatedPlaceholder width="60px" height="12px" />
          <AnimatedPlaceholder width="130px" height="32px" />
          <AnimatedPlaceholder width="130px" height="12px" />
        </div>
        <div class="pc-step-tracker-skeleton__section-graph">
          <AnimatedPlaceholder width="40px" height="250px" borderRadius="0" />
          <AnimatedPlaceholder width="40px" height="50px" borderRadius="0" />
          <AnimatedPlaceholder width="40px" height="150px" borderRadius="0" />
          <AnimatedPlaceholder width="40px" height="80px" borderRadius="0" />
          <AnimatedPlaceholder width="40px" height="150px" borderRadius="0" />
          <AnimatedPlaceholder width="40px" height="50px" borderRadius="0" />
        </div>
      </div>
    </div>

    <div
      class="container pc-step-tracker-skeleton__section-second"
      v-for="index in 4"
      :key="index"
    >
      <AnimatedPlaceholder width="200px" height="12px" />
      <div class="pc-step-tracker-skeleton__step">
        <AnimatedPlaceholder width="50px" height="50px" />
        <AnimatedPlaceholder width="100px" height="16px" />
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "../../../global/animators/AnimatedPlaceholder";

export default {
  name: "page-step-tracker-skeleton",
  components: { AnimatedPlaceholder },
};
</script>

<style scoped></style>

import AuthAPI from './api/AuthAPI'
import ActivityTracker from './ActivityTracker'
import jwtDecode from 'jwt-decode'
import { env } from '../../config/custom-config'
import DefineInclude from '../DefineInclude'

export default class AuthService {
  _token = null
  _refreshToken = null
  _decodedToken = null
  _customerID = 0
  _isSendingRefreshToken = false
  _isLoggingOut = false

  constructor () {
    this.setToken()
    if (!AuthService.instance) {
      AuthService.instance = this
    }
    this.setToken()
    return AuthService.instance
  }

  isLoggedIn(){
    return !!this._token || !!this._refreshToken
  }

  signIn (data) {
    const api = new AuthAPI()
    return new Promise((resolve, reject) => {
      api.signIn(data).then(res => {
        this._token = res.data.token
        this._refreshToken = res.data.refresh_token
        this.storeInStorage()
        this._decodedToken = jwtDecode(this._token)
        this.setTokens(res.data.token, res.data.refresh_token)
        resolve(res)
      }).catch(err => {
        this.removeFromStorage()
        reject(err)
      })
    })
  }

  mobileLogout () {
    const api = new AuthAPI()
    return new Promise((resolve, reject) => {
      api.mobileSignOut().then(() => {
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  removeTokens(){
    this._refreshToken = null
    return new Promise((resolve) => {
      if (!this._isLoggingOut) {
        this._token = null
        this._refreshToken = null
        this._isLoggingOut = true
        this.removeFromStorage()
        setTimeout(() => {
          this._isLoggingOut = false
          localStorage.clear()
          localStorage.setItem('logout', '1')
        }, 2000)
      }
      resolve()
    })
  }

  logOut () {
    this._refreshToken = null
    return new Promise((resolve) => {
      if (!this._isLoggingOut) {
        this._token = null
        this._refreshToken = null
        this._isLoggingOut = true
        this.removeFromStorage()

        window.location.href = DefineInclude.appUrlList.logout
        setTimeout(() => {
          this._isLoggingOut = false
          localStorage.clear()
          localStorage.setItem('logout', '1')
        }, 2000)
      }
      resolve()
    })
  }

  hasAuthTokens () {
    return !!this._refreshToken
  }

  setToken () {
    this._getTokenFromStorage()
    return this._token
  }
  isValidToken () {
    if (this._token === null) {
      return false
    }
    // eslint-disable-next-line no-undef
    if ((process.env.NODE_ENV !== 'production') || (env !== 'production')) {
      return true
    }
    return this._token && this._validateToken()
  }

  _validateToken () {
    if (!this._decodedToken) {
      this._decodedToken = jwtDecode(this._token)
    }
    return !!(this._decodedToken.exp && Date.now() < this._decodedToken.exp * 1000)
  }

  getRoles () {
    if (!this._decodedToken) {
      try {
        this._decodedToken = jwtDecode(this._token)
      } catch (e) {
        return []
      }
    }

    return this._decodedToken.roles || []
  }

  isCreator () {
    return this.getRoles().includes('platform-trainer')
  }
  isCoach () {
    return this.getRoles().includes('platform-coach')
  }

  isMember () {
    return this.getRoles().includes('platform-customer') || this.getRoles().includes('ROLE_ATHLETE')
  }

  hasRoles () {
    return this.getRoles().length > 0
  }

  getCustomerID () {
    if (this._customerID) {
      return this._customerID
    }
    const mAApp = document.querySelector('meta[name="ma-application"]')
    if (mAApp && mAApp.getAttribute('data-customer-id')) {
      this._customerID = parseInt(mAApp.getAttribute('data-customer-id'))
      return this._customerID
    }
    return 0
  }

  setTokenFormRefreshToken () {
    let api = new AuthAPI()

    return new Promise((resolve, reject) => {
      this.setToken()
      if(!this._refreshToken || this._refreshToken==='null'){

         this.getTokenFromAPI().then(() => {
           resolve({
              data: {
                token: this._token,
                refresh_token: ''
              }
           })
         }).catch((err)=>{
           if(!this._refreshToken || this._refreshToken!=='null'){
             reject(err)
           }

         })

        return
      }

      api.getTokenFromRefresh(this._refreshToken).then(res => {
        this.setTokens(res.data.token, res.data.refresh_token)
        resolve(res)
      }).catch(err => {
        this.removeFromStorage()
        reject(err)
      })
    })
  }  getTokenFromSession () {
    let api = new AuthAPI()

    return new Promise((resolve, reject) => {
      this.setToken()
      api.getTokenFromSession().then(res => {
        this.setTokens(res.data.token, null)
        resolve(res)
      }).catch(err => {
        this.removeFromStorage()
        reject(err)
      })
    })
  }

  setTokens (token, refreshToken) {
    this._token = token
    this._refreshToken = refreshToken
    this.storeInStorage()
  }

  getTokenFormRefreshToken () {
    return new Promise((resolve) => {
      if (this._isSendingRefreshToken) {
        setTimeout(() => {
          resolve(this._token)
        }, 2000)
      } else {
        this._isSendingRefreshToken = true
        const tokenCallBack= (this._refreshToken)? this.setTokenFormRefreshToken(): this.getTokenFromSession()

        tokenCallBack.then(() => {
          this._isSendingRefreshToken = false
          resolve(this._token)
        }).catch(() => {
          this.logOut()
          // not rejecting because of logout page redirect
        }).finally(() => {
          this._isSendingRefreshToken = false
        })
      }
    })
  }

  getTokenFromAPI () {
    const api = new AuthAPI()
    return new Promise((resolve, reject) => {
      api.getToken().then(res => {
        this._token = res.data.token
        const activity = new ActivityTracker()
        if (typeof res.data['user-id'] !== 'undefined') {
          sessionStorage.setItem('app-user-id', res.data['user-id'])
          activity.setUserProperties('id', res.data['user-id'], false)
        }
        try {
          this._decodedToken = jwtDecode(this._token)
        } catch (e) {
          console.log('token format invalid')
        }
        this.storeInStorage()
        resolve(true)
      }).catch(err => {
        this.removeFromStorage()
        reject(err)
      })
    })
  }

  _getTokenFromStorage () {
    let token = localStorage.getItem('authToken')
    this._refreshToken = localStorage.getItem('refreshToken') || null

    if (typeof token !== 'undefined' && token !== null) {
      this._token = token
      try {
        this._decodedToken = jwtDecode(this._token)
      } catch (e) {
        return false
      }
      return true
    }
    return false
  }

  getToken () {
    return this._token
  }

  setTokenInStorage (token) {
    this._token = token
    this._decodedToken = jwtDecode(this._token)
    localStorage.setItem('authToken', this._token)
  }

  storeInStorage () {
    localStorage.setItem('authToken', this._token)
    if(this._refreshToken){
      localStorage.setItem('refreshToken', this._refreshToken)
    }

  }

  removeAccessToken () {
    this._token = null
    localStorage.removeItem('authToken')
  }

  logout () {
    this.removeFromStorage()
  }
  removeFromStorage () {
    this._resetToken()
    localStorage.removeItem('authToken')
    localStorage.removeItem('refreshToken')
  }

  _resetToken () {
    this._token = null
    this._decodedToken = null
    this._customerID = 0
  }
}

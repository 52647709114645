import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import WorkoutProgramsService from '../../../../services/WorkoutProgramsService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  extends: BaseComponent,
  data: function () {
    return {
      pageClass:
        ['macroapp--page-common', 'app-background-color', 'macroapp--page-week-set'],
      weeks: {},
      program: null,
      headerOptions: {
        show: true,
        left: 'menu',
        modifiers: ['app-nav--with-bg-color'],
        mainTitle: '',
        subTitle: ''
      }
    }
  },
  beforeMount () {
    this.loadPageData()
  },
  mounted () {
    this.setPageClass()
    this.setHeader(this.headerOptions)
  },
  methods: {
    setProgramData (program) {
      this.weeks = program.getWeeks()
      this.program = program
      this.setPageTitle(program.getName())
      this.hideLoading()
    },
    onWorkoutProgramLoadError () {
      this.program = null
    },
    loadPageData () {
      let workoutProgramID = this.params.workout
      workoutProgramID = parseInt(workoutProgramID)
      let workoutProgramsService = new WorkoutProgramsService()
      workoutProgramsService.setWorkoutProgramList().then(() => {
        let program = workoutProgramsService.getWorkoutProgram(workoutProgramID)

        if (program.checkDetailsLoaded()) {
          this.setProgramData(program)
        } else {
          program.setProgramDetails().then(() => {
            this.setProgramData(program)
          }).catch(err => {
            this.onWorkoutProgramLoadError(err)
          })
        }
        pageReadyEvent()
      }).catch((e) => {
        this.hideLoading()
      })
    },
    navigateNext (week) {
      this.$router.push({
        path: this.$appConfig.appUrlList.workout,
        query: {
          workout: this.params.workout,
          week: week
        }
      })
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}

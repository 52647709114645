<template>
  <div :class="componentClasses" v-on="$listeners">
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_35091_10661)">
        <path d="M40.0999 25.0046V38.3493C40.0999 39.0402 39.5397 39.6003 38.8488 39.6003H2.15096C1.46 39.6003 0.899902 39.0402 0.899902 38.3493V25.0046C0.899902 24.0415 1.94245 23.4396 2.7765 23.9211C3.16357 24.1446 3.40203 24.5576 3.40203 25.0046V37.0982H37.5978V25.0046C37.5978 24.0415 38.6403 23.4396 39.4744 23.9211C39.8614 24.1446 40.0999 24.5576 40.0999 25.0046ZM19.6158 25.8887C20.1043 26.3765 20.8955 26.3765 21.384 25.8887L29.7244 17.5482C30.3809 16.8436 30.0285 15.6925 29.0901 15.4762C28.6851 15.3829 28.2603 15.4968 27.9562 15.7801L21.751 21.9832V1.65137C21.751 0.688303 20.7084 0.086381 19.8744 0.567912C19.4873 0.791397 19.2488 1.20441 19.2488 1.65137V21.9832L13.0436 15.7801C12.339 15.1235 11.1879 15.4759 10.9716 16.4144C10.8783 16.8193 10.9921 17.2442 11.2754 17.5482L19.6158 25.8887Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_35091_10661">
          <rect width="40" height="40" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDownload',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-download': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>

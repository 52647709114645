<template>
  <div class="pc-period-tracker-manage-widget">
    <div class="pc-period-tracker-manage-widget__heder">
      <TextBodySmall2 :weight="'extra-bold'">
        {{
          $t('message["period-tracker.manage-widget-title"]')
        }}</TextBodySmall2
      >
      <inputSwitch :value="enableStatus" @input="handleSwitchChange" :name="toggleName"/>
    </div>
    <div class="pc-period-tracker-manage-widget__body">
      <TextBodyExtraSmall>
        {{
          $t('message["period-tracker.manage-widget-description"]')
        }}</TextBodyExtraSmall
      >
    </div>
  </div>
</template>

<script>
import TextBodySmall2 from "@/components/global/typography/TextBodySmall2";
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall";
import inputSwitch from "@/components/global/inputs/InputSwitch";

export default {
  name: "CardPeriodTrackerManageWidget",
  components: {
    TextBodySmall2,
    TextBodyExtraSmall,
    inputSwitch,
  },
  props: {
    toggleName: {
      type: String,
      default: ''
    },
    enableStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSwitchChange (status) {
      this.$emit('toggleStatus', status)
    }
  }
};
</script>

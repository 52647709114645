<template>
  <div class="pc-dashboard__text pc-section-dashboard-intro-style-2">
      <text-body-extra-small class="pc-dashboard__text-description " v-html="currentText"></text-body-extra-small>
  </div>
</template>

<script>
import TextContent from "@/components/root/TextContent.vue";
import IconDownArrow from "@/components/root/icons/IconDownArrow.vue";
import IconUpArrow from "@/components/root/icons/IconUpArrow.vue";
import Vue from 'vue';
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall.vue";

export default {
  name: 'sectionDashboardIntroStyle2',
  components: {
    TextBodyExtraSmall,
    TextContent,
    IconDownArrow,
    IconUpArrow,
  },
  data: function () {
    return {
      collapsed: true,
      maxLength: 60,
      currentText: this.elements
    }
  },
  mounted() {

    window.addEventListener('resize', this.updateMaxLength);
    this.$nextTick(() => {
      this.updateText();
      this.updateMaxLength()
      this.updateParagraphClasses();
      this.injectIconsAfterParagraphs();

    });
  },
  updated() {
    this.$nextTick(() => {
      this.updateText();
      this.updateMaxLength()
      this.updateParagraphClasses();
      this.injectIconsAfterParagraphs();
    });
  },
  computed: {
    text: function () {
      if(!this.collapsed){
        return this.elements
      }
      let maxLength = 60
      if (window.innerWidth <= 768) {
        maxLength = 120;
      } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        maxLength = 170;
      } else if (window.innerWidth > 1024) {
        maxLength = 240;
      }
      return this.truncateHTML(this.elements, maxLength);
    },
    pharagraphClass() {
      return {
        'pc-section-dashboard-intro-style-2__text-collapsed': this.collapsed
      }
    }
  },
  props: {
    elements: {
      default: ''
    }
  },
  watch: {
    collapsed() {
      this.updateParagraphClasses();
      this.updateText();
    },
    maxLength() {
      this.updateText();
    },
    elements() {
      this.currentText = this.elements;
      this.updateMaxLength();
    }
  },
  methods: {
    updateText() {
      if (!this.collapsed) {
        this.currentText = this.elements;
      } else {
        this.currentText = this.truncateHTML(this.elements, this.maxLength);
      }
    },
    updateMaxLength() {
      this.$nextTick(() => {
        const width = window.innerWidth;
        if (width <= 768) {
          this.maxLength = 120;
        } else if (width  > 768 && width <= 1024) {
          this.maxLength = 170;
        } else if (width > 1024) {
          this.maxLength = 240;
        }
      });
    },
    truncateHTML(html, maxLength) {
      const div = document.createElement("div");
      div.innerHTML = html;

      let truncated = "";
      let length = 0;

      function traverse(node) {
        if (length >= maxLength) return;

        if (node.nodeType === Node.TEXT_NODE) {
          const remaining = maxLength - length;
          truncated += node.textContent.slice(0, remaining);
          length += node.textContent.length;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const clonedNode = node.cloneNode(false);
          truncated += `<${clonedNode.tagName.toLowerCase()}`;
          Array.from(node.attributes).forEach(attr => {
            truncated += ` ${attr.name}="${attr.value}"`;
          });
          truncated += ">";

          Array.from(node.childNodes).forEach(child => traverse(child));

          truncated += `</${clonedNode.tagName.toLowerCase()}>`;
        }
      }

      Array.from(div.childNodes).forEach(child => traverse(child));
      const newDiv = document.createElement("div");
      newDiv.innerHTML = truncated;
      if(div.innerHTML.length > truncated.length){
        const htmLpTags = newDiv.querySelectorAll('p')
        htmLpTags.forEach((p,index) => {
          if(index === htmLpTags.length - 1){
            p.innerText = p.innerText + '...'
          }
        });
       }

      return newDiv.innerHTML
    },

    updateParagraphClasses() {
      this.$nextTick(() => {
        const paragraphs = this.$el.querySelectorAll("p");

        paragraphs.forEach((paragraph) => {
          if (this.collapsed) {
            paragraph.classList.add("pc-section-dashboard-intro-style-2__text-collapsed");
          } else {
            paragraph.classList.remove("pc-section-dashboard-intro-style-2__text-collapsed");
          }
        });
      });
    },
    injectIconsAfterParagraphs() {
      // create div element and append it to the paragraph
      let totalLetters = 0
      const div = document.createElement("div");
      div.innerHTML = this.currentText;
      const paragraphs = div.querySelectorAll("p");
      let iconAdded = false;
      paragraphs.forEach((paragraph) => {
        totalLetters += paragraph.innerText.length
        if (totalLetters>10) {
          if (!iconAdded) {
            const IconConstructor = Vue.extend({
              render: (h) => {
                return h(this.collapsed ? 'IconDownArrow' : 'IconUpArrow', {
                  on: {
                    click: this.toggleCollapsed
                  }
                });
              },
              components: {
                IconDownArrow,
                IconUpArrow,
              },
            });

            const mountedIcon = new IconConstructor().$mount();
            mountedIcon.$el.classList.add('icon-component');
            const renderedP = this.$el.querySelector("p");
            renderedP.append( mountedIcon.$el);
            renderedP.style.display = 'flex'
            renderedP.style.justifyContent = 'space-between'
            renderedP.style.alignItems = 'flex-end'
            renderedP.style.fontSize = '12px'
            iconAdded =true
          }
        }
      });
    },

    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.updateText();
    },
  }
}
</script>

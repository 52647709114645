let lastHref = null
let hasSignupThankYouPageLoaded= false
export function signUpPageLoadedEvent (payload = {}) {
  const event = new CustomEvent('signupPageLoaded', {
    detail: payload
  })

  // Dispatch the custom event
  window.dispatchEvent(event)
}

export function registrationPageLoadedEvent (payload = {}) {
  const event = new CustomEvent('signupRegistrationPageLoaded', {
    detail: payload
  })

  // Dispatch the custom event
  window.dispatchEvent(event)
  signUpPageLoadedEvent({ page: 'registration', ...payload })
  pageReadyEvent(payload)
}

export function paymentPageLoadedEvent (payload = {}) {
  const event = new CustomEvent('signupPaymentPageLoaded', {
    detail: payload
  })

  // Dispatch the custom event
  window.dispatchEvent(event)
  signUpPageLoadedEvent({ page: 'payment', ...payload })
  pageReadyEvent(payload)
}

export function thankYouPageLoadedEvent (payload = {}) {
  if(hasSignupThankYouPageLoaded){
    return
  }

  hasSignupThankYouPageLoaded = true
  const event = new CustomEvent('signupThankYouPageLoaded', {
    detail: payload
  })

  // Dispatch the custom event
  window.dispatchEvent(event)
  signUpPageLoadedEvent({ page: 'thank-you', ...payload })
  pageReadyEvent(payload)
}

export function subscriptionSettingsPageLoadedEvent (payload = {}) {
  const event = new CustomEvent('signupSubscriptionSettingsPageLoaded', {
    detail: payload
  })

  // Dispatch the custom event
  window.dispatchEvent(event)
  signUpPageLoadedEvent({ page: 'subscription-settings', ...payload })
  pageReadyEvent(payload)
}

export function pageReadyEvent (payload = {}) {
  if (lastHref === window.location.href) {
    return

  }
  const location = {
    href: window.location.href,
    search: window.location.search,
    pathname: window.location.pathname,
    hash: window.location.hash,
  }
  const event = new CustomEvent('memberAppPageReady', {
    detail: { ...payload, ...location }
  })

  // Dispatch the custom event
  window.dispatchEvent(event)
  lastHref = window.location.href
}



<template>
  <popup
    v-if="visible"
    ref="wearableModal"
    :visible="visible"
    @hide="closePopup"
    :modal-class="'gc-popup-confirm-wearable-connection'">
    <popup-title>
      <popup-button-close @hide="closePopup()"/>
    </popup-title>
    <popup-body class="gc-popup-confirm-wearable-connection__body text-center">
      <popup-head class="text-center"  :class="{ 'gc-popup-confirm-wearable-connection__error-title': errorMessage }">
        <popup-text-heading v-if="status==='init'">{{ title }}</popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body
          v-if="!(source.connected) && errorMessage"
          class="text-center info-modal__subtitle mb-2"
        >{{ errorMessage }}
        </popup-text-body>
        <popup-text-body
          v-if="!(source.connected) && status==='init' && !errorMessage"
          class="text-center info-modal__subtitle mb-2"
        >{{ $t('message["connect-wearable-popup.description-connect"]') }}
        </popup-text-body>
        <popup-text-body
          v-else-if="source.connected && status==='init'"
          class="text-center info-modal__subtitle mb-2"
        >{{ $t('message["connect-wearable-popup.description-disconnect"]') }}
        </popup-text-body>
        <icon-complete-filled
          v-if="status==='success'"
          :size="'lg3'"
        />
        <loading-buffer v-if="status==='loading'" :height="'75'"/>
        <div v-if="status==='error'">
          <div class="text-center"/>
          <p class="text-center info-modal__subtitle mb-2">{{ errorMessage }}</p>
        </div>
      </popup-content>
      <popup-footer v-if="status === 'init'">
        <button-primary v-if="!(source.connected) && !(error === 'rook-not-available')" @click="proceed()" :text="confirmButtonText"></button-primary>
        <button-primary v-if="!(source.connected) && error === 'rook-not-available'" @click="closePopup()" :text="understoodButtonText"></button-primary>
        <button-secondary v-if="source.connected" @click="disconnect()"
                          :text="$t('message[\'member-connect.disconnect\']')"></button-secondary>
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import Popup from './Popup.vue'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFloatingFooter from './popup-sub-components/PopupFloatingFooter'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import StepTrackerService from '@/services/StepTrackerService'
import NativeAppService from "@/services/NativeAppService";
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
import DashBoardService from '@/services/DashboardService'

export default {
  name: 'PopupConfirmWearableConnection',
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFloatingFooter,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary,
    IconCompleteFilled
  },
  mixins: [popupAnimationMixin],
  extends: BaseComponent,
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    source: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      status: 'loading',
      loading: true,
      confirmButtonText: this.$i18n.t('message["general.proceed"]'),
      understoodButtonText: this.$i18n.t('message["general.understood"]'),
      title: this.source.connected ? this.source.name : this.$t('message["connect-wearable-popup.title-connect"]', {name: this.source.name}),
      errorMessage: '',
      error: ''
    }
  },
  created () {
    this.status = 'init'
  },
  mounted () {
    window.addEventListener('wearablePermissionsResponse',this.afterWearablePermissionsResponse)
  },
  beforeDestroy () {
    window.removeEventListener('wearablePermissionsResponse',this.afterWearablePermissionsResponse)
  },
  methods: {
    afterWearablePermissionsResponse(event){
    
      const data = event.detail
      console.log(9999999, data)
      this.hideLoading()
      if(data.error){
        if (data.error === 'rook-not-available' || data.error === 'ROOK_NOT_INSTALLED') {
          this.error = 'rook-not-available'
          this.title =  this.$i18n.t('message["connect-wearables.title-fail"]')
          this.errorMessage = this.$i18n.t('message["connect-wearables.native-app.warning"]')
        } else {
          this.title =  this.$i18n.t('message["connect-wearables.title-fail"]')
          this.errorMessage = this.$i18n.t('message["connect-wearables.description-error"]')
          this.confirmButtonText =  this.$i18n.t('message["general.reconnect"]')
        }
      } else {
        this.status = 'success'
        const nativeAppService = new NativeAppService()
        const dashboard = new DashBoardService()
        nativeAppService.startRookSessionRequest(dashboard.userData)

        setTimeout(()=>{
          nativeAppService.sendWearableEnableSyncRequest()
          let service = new StepTrackerService()
          service.setNewConnectedDataSource(this.source.name)
        },1500)
        setTimeout(()=>{
          this.$emit('success')
          this.closePopup()
        },3000)
      }
    },
    closePopup (e) {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    proceed () {
      this.logEvent('StepTracker.DailyActiveUsers', {
        'connected_timestamp': (new Date()).toISOString(),
        'engagement_type': 'connected a wearable'
      })
      if (this.source.name === 'Apple Health' || this.source.name === 'Health Connect') {
        this.showLoading()
        const nativeAppService = new NativeAppService()
        setTimeout(()=>{
          nativeAppService.sendWearablePermissionsRequest()
          this.logEvent('StepTracker.FeatureAdoptionRate', {
            'connected_timestamp': (new Date()).toISOString(),
            'connected_wearable': this.source.name
          })
        },1000)
      } else {
        this.status = 'loading'
        sessionStorage.setItem('latestWearableConnectionAttempt', this.source.name)
        window.location.href = (this.source.authorizationUrl)
        setTimeout(() => {
          this.closePopup()
        }, 2000)
      }
    },
    async disconnect () {
      this.status = 'loading'
      let service = new StepTrackerService()
      await service.disconnectDataSource(this.source.name).then(async (response) => {
        this.logEvent('StepTracker.DailyActiveUsers', {
          'connected_timestamp': (new Date()).toISOString(),
          'engagement_type': 'disconnected a wearable'
        })
        this.status = 'success'
        if (isMobileNativeApp() && (this.source.name === 'Apple Health' || this.source.name === 'Health Connect')) {
          const nativeAppService = new NativeAppService()
          nativeAppService.endRookSessionRequest()
        }

      }).finally(() => {
        setTimeout(() => {
          this.$emit('success')
          this.closePopup()
        }, 1000)
      })
    }
  }
}
</script>

<template>
  <div
    v-bind="$attrs"
    class="gc-header-page"
    ref="header"
    :class="headerClasses"
  >
    <div class="gc-header__section-left">
      <icon-backward
        v-if="options.left === 'previous'"
        class="gc-header__left-icon gc-header__left-icon-backward"
        @click="previous()"
      />
      <icon-backward
        v-else-if="options.left === 'previous-emit'"
        class="gc-header__left-icon gc-header__left-icon-backward"
        @click="previous('emit')"
      />
      <icon-paper-plane
        v-else-if="options.left === 'message'"
        class="gc-header__right-icon gc-header__right-icon-send"
        @click="sendButtonClick"
      />
      <transition v-else-if="options.left === 'menu' && !isDesktop&&showSideMenu" name="fade">
        <side-bar-v2 />
      </transition>


      <div v-else class="gc-header__left-empty-area"></div>
    </div>
    <div class="gc-header__section-center">
      <div :class="titleClasses">
        <text-heading3
          v-if="mainTitle && !modifiers.includes('small-title')"
          weight="extra-bold"
          :line-height="'multi'"
        >{{ mainTitle }}
        </text-heading3>
        <text-body-regular
          v-if="mainTitle && modifiers.includes('small-title')"
          weight="extra-bold"
          :line-height="'multi'"
        >{{ mainTitle }}
        </text-body-regular>
      </div>
      <div class="gc-header__sub-title">
        <text-body-extra-small v-if="subTitle" :line-height="'lh-xl'"
        >{{ subTitle }}
        </text-body-extra-small>
      </div>
    </div>
    <div
      class="gc-header__section-right gc-header__section-right--without-bottom-bar"
      :class="{'gc-header__section-right--two-icons':rightIcons.length===2}"
    >
      <icon-cart
        v-if="rightIcons.includes('cart')"
        :size="'md1'"
        class="gc-header__right-icon gc-header__right-icon-send"
        @click="actionButtonClick('cart')"
      />
      <icon-info
        v-if="rightIcons.includes('info')"
        class="gc-header__right-icon gc-header__right-icon-info"
        @click="infoButtonClicked"
      />
      <icon-info-bulb
        v-else-if="showHelpBulb() && !rightIconActive"
        class="gc-header__right-icon gc-header__right-icon-info-bulb"
        @click="showHelpPopup()"
      />
      <icon-info-bulb-filled
        v-else-if="showHelpBulb() && rightIconActive"
        @click="showHelpPopup()"
        class="gc-header__right-icon gc-header__right-icon-info-bulb gc-header__right-icon-info-bulb-filled"
      />
      <icon-add
        v-if="rightIcons.includes('add')"
        class="gc-header__right-icon gc-header__right-icon-add"
        @click="addButtonClicked"
      />
      <icon-close
        v-if="rightIcons.includes('close')"
        :size="'sm4'"
        class="gc-header__right-icon gc-header__right-icon-close"
        @click="closeButtonClicked"
      />
      <icon-paper-plane
        v-if="rightIcons.includes('message')"
        class="gc-header__right-icon gc-header__right-icon-send"
        @click="sendButtonClick"
      />
      <icon-edit
        v-if="rightIcons.includes('edit')"
        :size="'md1'"
        class="gc-header__right-icon gc-header__right-icon-send"
        @click="editButtonClick"
      />

      <icon-mark-read
        v-if="rightIcons.includes('mark-read')"
        :state="
          options.iconStates && options.iconStates['mark-read']
            ? options.iconStates['mark-read']
            : 'active'
        "
        :size="'md1'"
        class="gc-header__right-icon gc-header__right-icon-mark-read"
        @click="markReadButtonClick"
      />
      <button
        v-if="rightIcons.includes('pwa') && showPwaPopup()"
        class="gc-header__right-button gc-header__right-gc-header__right-button-pwa"
      >
        <img :src="imagesUrl + 'icons/iosdownloadnew.gif'" alt="PWA"/>
        <span/>
      </button>
      <div v-else class="gc-header__right-empty-area"></div>
    </div>
  </div>
</template>

<script>
import IconBackward from '../../root/icons/IconBackward'
import IconInfo from '../../root/icons/IconInfo'
import SideBarV2 from '../navigators/SideBar'
import IconAdd from '../../root/icons/IconAdd'
import IconPaperPlane from '../../root/icons/IconPaperPlane'
import IconInfoBulb from '../../root/icons/IconInfoBulb'
import appHeaderMixin from '../../../mixins/appHeaderMixin'
import TextHeading3 from '../typography/TextHeading3'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextBodyRegular from '../typography/TextBodyRegular'
import IconInfoBulbFilled from '../../root/icons/IconInfoBulbFilled'
import {flagValue, showBottomNavigationBar} from '../../../includes/TemplateSettings'
import IconClose from '../../root/icons/IconClose'
import IconEdit from '../../root/icons/IconEdit'
import IconMarkRead from '../../root/icons/IconMarkRead'
import {checkIsMember} from "@/helpers/user";
import IconCart from "@/components/root/icons/IconCart.vue";

export default {
  name: 'HeaderPage',
  mixins: [appHeaderMixin],
  components: {
    IconCart,
    IconClose,
    IconInfoBulbFilled,
    TextBodyRegular,
    IconInfoBulb,
    IconPaperPlane,
    IconAdd,
    SideBarV2,
    IconInfo,
    IconBackward,
    TextHeading3,
    TextBodyExtraSmall,
    IconEdit,
    IconMarkRead,
  },
  data:function (){
    return{
      showSideMenu: false
    }
  },
  computed: {
    headerClasses: function () {
      return {
        'gc-header-page--with-title': !!this.mainTitle,
        'gc-header-page--transparent':
          (!!this.isTransparent && !this.isDesktop) ||
          this.modifiers.includes('transparent-desktop'),
        'gc-header-page--top-fixed': !!this.isFixed,
        'gc-header-page--with-sub-title': !!this.subTitle,
        'gc-header-page--without-sub-title': !this.subTitle,
        'gc-header-page__title-sm': this.modifiers.includes('small-title'),
        'gc-header-page__with-bottom-nav': showBottomNavigationBar() && checkIsMember(),
        'gc-header-page__without-bottom-nav': !showBottomNavigationBar()
      }
    },
    titleClasses: function () {
      return {
        'gc-header__title': true,
        'gc-header__title--small': this.modifiers.includes('small-title'),
      }
    },
    showNewSideMenu () {
      return !!flagValue('show_sidebar_v2', true)
    },
  },
  mounted () {
    setTimeout(()=>{
      this.showSideMenu = true
    },100)
    this.setRightIcons()
    this.emitHeaderHeight()
    this.onScreenResize()
  },
  updated () {
    this.emitHeaderHeight()
    this.$emit('updated')
  },
  methods: {
    onScreenResize () {
      window.addEventListener('resize', this.emitHeaderHeight)
    },
    offScreenResize () {
      window.removeEventListener('resize', this.emitHeaderHeight)
    },
    emitHeaderHeight () {
      let header = this.$refs.header
      if (header) {
        this.$nextTick(() => {
          let height =
            header.offsetHeight +
            parseFloat(
              window
                .getComputedStyle(header, null)
                .getPropertyValue('margin-top')
            )
          this.$emit('height-updated', height)
        })
      }
    },
  },
  beforeDestroy () {
    this.offScreenResize()
  },
}
</script>

<style scoped></style>

<template>
  <div class="macroapp macroapp--page-common">
      <component
        :page-config="dynamicComponentConfig"
        :is="dynamicComponent"
        v-if="dynamicComponent"
        v-once/>
  </div>

</template>

<script>

import Shopping from './shopping/Main'
import PageHome from './PageHome'
import BaseComponent from '../global/base/BaseComponent'
import PageMeal from './PageMeal'
import List from './List'
import MainPageMixin from '../../../../mixins/mainPageMixin'
import FoodDiaryPageHome from '../food-diary/PageHome'
import DashBoardService from '../../../../services/DashboardService'
import PageLogMeals from '../food-diary/PageLogMeals'
import NutritionService from '../../../../services/nutrition/NutritionService'
import CountryService from '@/services/CountryService'
import FoodDiaryDineOutService from '@/services/FoodDiaryDineOutService'
import store from "@/store";
import PageMealPlan from "@/components/layout/template-1/meal-plan/PageMealPlan.vue";

export default {
  name: 'MealPlanMain',
  extends: BaseComponent,
  mixins: [MainPageMixin],
  components: {},
  data: function () {
    return {
      components: {
        'meal-plan-day': {design1: PageMealPlan},
        'meal-plan-list': {design1: List},
        'meal-plan-shopping': {design1: Shopping},
        'meal-plan-home': {design1: PageMealPlan, design2: PageHome},
        'meal-plan-meal': {design1: PageMeal},
        'food-diary-home': {'design1': FoodDiaryPageHome},
        'food-diary-log-meal': {'design1': PageLogMeals},
        'food-diary-day': {'design1': FoodDiaryPageHome}
      }
    }
  },
  mounted () {
    this.setPageName(this.page)
  },
  computed: {
    page () {
      const queryParams = this.$route.query
      let pageName = ''

      if ('meal_plan' in queryParams && 'shopping' in queryParams) {
        pageName = 'meal-plan-shopping'
      } else if ('day' in queryParams && 'meal' in queryParams) {
        pageName = 'meal-plan-meal'
      } else if ('day' in queryParams) {
        pageName = 'meal-plan-day'
      } else if ('page' in queryParams && 'id' in queryParams && queryParams.page === 'food-diary') {
        pageName = 'food-diary-day'
      } else if ('page' in queryParams && queryParams.page === 'food-diary') {
        pageName = 'food-diary-home'
      } else if ('page' in queryParams && queryParams.page === 'food-diary-log-meal') {
        pageName = 'food-diary-log-meal'
      } else if (typeof this.$route.params.mid !== 'undefined') {
        pageName = 'meal-plan-home'
      } else {
        pageName = 'meal-plan-list'
      }
      return pageName
    }
  },
  beforeRouteEnter (to, from, next) {
    store.commit('showLoggingLoading')
    let mealPlanService = new NutritionService()
    let dashboard = new DashBoardService()
    const promises = [mealPlanService.init(), dashboard.getPageData()]
    const queryParams = to.query
    if (queryParams.page && (queryParams.page === 'food-diary-log-meal')) {

      const cs = new CountryService()
      if(cs.getCount() === 0){
        store.commit('showLoading')
      }
      promises.push(cs.load())
      const foodDiaryDineOutService = new FoodDiaryDineOutService()
      promises.push(foodDiaryDineOutService.init())
    }
    Promise.all(promises)
      .then(() => {
        let latestMealPlan = mealPlanService.getLatestMealPlan()
        if (latestMealPlan) {
          if(latestMealPlan.isLoaded() === false){
            store.commit('showLoading')
          }
          latestMealPlan.getData().finally(() => {
            store.commit('hideLoggingLoading')
            next()
          })
        } else {
          store.commit('hideLoggingLoading')
          next()
        }
      }).catch(() => {
      store.commit('hideLoggingLoading')
      next()
    })
  }
}
</script>

<template>
  <div class="pc-food-dairy-image-input">
    <image-uploader
      v-if="state === 'init' || state === 'error'|| state === 'error'"
      :id="imageName"
      ref="image-uploader"
      :auto-rotate="false"
      :max-width="1000"
      :preview="false"
      :quality="0.7"
      accept="image/*"
      output-format="verbose"
      @input="setUploadData($event)"
    >
      <label
        slot="upload-label"
        class="pc-food-dairy-image-input__label"
        :for="imageName"
        style="display: block"
      >
        <div class="pc-food-dairy-image-input__add-image-box">
          <!-- "add-image-box-uploaded" class to above div to control uploaded image -->
          <div class="pc-food-dairy-image-input__icon">
            <icon-camera :size="'sm4'" />
          </div>
          <text-body-extra-small
            class="pc-food-dairy-image-input__text"
          >
            {{ $t('message["food-diary.add-image"]') }}
          </text-body-extra-small>
        </div>
      </label>
    </image-uploader>

    <div
      v-if="image&&state === 'uploaded'"
      class="pc-food-dairy-image-input__add-image-box pc-food-dairy-image-input__add-image-box-uploaded"
    >
      <img
        :src="image.dataUrl"
        alt=""
        class="pc-food-dairy-image-input__add-image-box-uploaded-img"
        @error="handleImageError"
      >
      <icon-close
        class="pc-food-dairy-image-input__add-image-box-cross-icon"
        :size="'sm4'"
        @click="removeImage()"
      />
    </div>
  </div>
</template>
<script>
import ImageUploader from 'vue-image-upload-resize'
import IconCamera from './../../../../root/icons/IconCamera'
import IconClose from './../../../../root/icons/IconClose'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'

export default {
  name: 'FoodDiaryImageInput',
  components: {
    TextBodyExtraSmall,
    ImageUploader,
    IconCamera,
    IconClose
  },
  props: {
    imageUrl: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if (this.imageUrl) {
      this.image = { dataUrl: this.imageUrl }
      this.state = 'uploaded'
    }
  },
  data: function () {
    return {
      state: 'init',
      imageName: 'init',
      image: null
    }
  },
  watch: {
    imageUrl(newImageUrl) {
      // Update image data property when imageUrl prop changes
      if (newImageUrl) {
        this.image = { dataUrl: newImageUrl };
        this.state = 'uploaded'; // Update state to reflect the new image
      } else {
        this.image = null;
        this.state = 'init'; // Reset state if imageUrl is cleared
      }
    }
  },
  methods: {
    setUploadData (imageData) {
      this.image = imageData
      if (!this.image.dataUrl) {
        this.state = 'error'
        this.$emit('error', [ this.$i18n.t('message.validation.messages.image').replace('{_field_}', this.$i18n.t('message["food-diary.image"]')) ])
      } else {
        this.state = 'uploaded'
        this.$emit('uploaded', this.image.dataUrl)
      }
    },
    handleImageError () {
      this.image = null;
      this.state = 'init';
    },
    removeImage () {
      this.image = null
      this.state = 'init'
      this.$emit('removed', null)
    }
  }
}
</script>

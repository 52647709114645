<template>
  <div :class="componentClasses" @click="viewMore('home_card')"
       style="-webkit-transform: translateZ(0); -moz-transform: translateZ(0);  -ms-transform: translateZ(0);-o-transform: translateZ(0);transform: translateZ(0);" >
      <div class="gc-card-measurement-detailed__header">
        <text-content :size="'sm2'" :weight="'extra-bold'" :lineHeight="'multi'">{{name}}</text-content>
        <icon-add v-if="isEditable" :size="'sm3'" @click.stop="showPopup()" />
        <icon-forward v-else size="sm3" />
      </div>
      <div class="gc-card-measurement-detailed__main-wrapper--empty-card" v-if="JSON.stringify(latestValue) === 'null' && JSON.stringify(firstTimeValue) === 'null'" >
        <text-content :size="'sm1'" :lineHeight="'multi'">{{$i18n.t('message["fitness-diary.empty-measurement"]', [name])}}</text-content>
      </div>
      <div class="gc-card-measurement-detailed__main-wrapper--first-card" v-if="(JSON.stringify(firstTimeValue) != 'null' && JSON.stringify(latestValue) !== 'null') && JSON.stringify(firstTimeValue) === JSON.stringify(latestValue)">
        <text-content :size="'sm1'" :lineHeight="'multi'">{{$t('message["fitness-diary.request-log-measurement"]')}}</text-content>
        <div class="gc-card-measurement-detailed__measure-wrapper">
          <text-content :size="'md2'" :weight="'extra-bold'" :lineHeight="'multi'">{{Number.parseFloat(latestValue).toFixed(1)}} <span> {{measurementUnit}} </span></text-content>
          <div class="gc-card-measurement-detailed__first-card--day">
              <text-content :size="'sm1'" :weight="'medium'" :lineHeight="'multi'">{{getLatestDate()}}</text-content>
          </div>
        </div>
      </div>
      <div class="gc-card-measurement-detailed__difference--first-section" v-if="(JSON.stringify(latestValue) !== 'null') && JSON.stringify(firstTimeValue) !== JSON.stringify(latestValue)">
            <text-content :size="'lg4'" :weight="'extra-bold'" :lineHeight="'multi'">{{progressDiff()}} <span> {{measurementUnit}}</span></text-content>
        </div>
      <div class="gc-card-measurement-detailed__main-wrapper" v-if="(JSON.stringify(firstTimeValue) != 'null' && JSON.stringify(latestValue) != 'null') && JSON.stringify(firstTimeValue) !== JSON.stringify(latestValue)">
          <div class="gc-card-measurement-detailed__measure-left--wrapper">
            <div class="gc-card-measurement-detailed__measure">
              <text-content :size="'md2'" :weight="'extra-bold'" :lineHeight="'multi'">{{Number.parseFloat(firstTimeValue).toFixed(1)}} <span> {{measurementUnit}} </span></text-content>
            </div>
            <div class="gc-card-measurement-detailed__day">
              <text-content :size="'sm1'" :weight="'medium'" :lineHeight="'multi'">{{getFirstTimeDate()}}</text-content>
            </div>
          </div>
          <div class="gc-card-measurement-detailed__measure-middle--wrapper">
            <div class="gc-card-measurement-detailed__measure">
              <!-- <icon-right-arrow :size="'lg3'" /> -->
              <div class="gc-card-measurement-detailed__arrow-top-measure">
                <div class="gc-card-measurement-detailed__difference">
                  <text-content :size="'sm1'" :weight="'medium'" :lineHeight="'multi'">{{getprogressType()}}</text-content>
                </div>
              </div>
              <right-arrow-svg />
            </div>
          </div>
          <div class="gc-card-measurement-detailed__measure-right--wrapper">
            <div class="gc-card-measurement-detailed__measure">
              <text-content :size="'md2'" :weight="'extra-bold'" :lineHeight="'multi'">{{Number.parseFloat(latestValue).toFixed(1)}} <span>{{measurementUnit}}</span></text-content>
            </div>
            <div class="gc-card-measurement-detailed__day">
              <text-content :size="'sm1'" :weight="'medium'" :lineHeight="'multi'">{{getLatestDate()}}</text-content>
            </div>
          </div>
      </div>
      <div v-if="isEditable" :class="{'gc-card-measurement__more-button--one-entry': (JSON.stringify(firstTimeValue) === 'null' && JSON.stringify(latestValue) !== 'null') || (JSON.stringify(latestValue) !== 'null' && (JSON.stringify(latestValue) === JSON.stringify(firstTimeValue)))}" class="gc-card-measurement-detailed__more-button" v-on:click.stop.prevent="viewMore('home_more')">
        <text-content :size="'sm1'" :lineHeight="'multi'">{{ $t('message["general.more"]') }} ></text-content>
      </div>
  </div>
</template>
<script>
import TextContent from '../../root/TextContent'
import HeaderSimple from '../headers/HeaderSimple'
import RightArrowSvg from '../../layout/template-1/fitness-diary/widgets/svgs/RightArrowSvg'
import IconAdd from '../../root/icons/IconAdd'
import moment from 'moment'
import IconForward from '../../root/icons/IconForward'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'card-measurement-detailed',
  props: {
    name: {
      default: '',
      required: true
    },
    isEditable: {
      default: true
    },
    type: {
      default: '',
      required: true
    },
    measurementUnit: {
      default: ''
    },
    firstTimeDate: {
      default: ''
    },
    latestDate: {
      default: ''
    },
    firstTimeValue: {
      default: ''
    },
    latestValue: {
      default: ''
    }
  },
  data () {
    return {
      isClicked: false
    }
  },
  components: {
    IconForward,
    HeaderSimple,
    TextContent,
    RightArrowSvg,
    IconAdd
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    }),
    componentClasses: function () {
      let classes = {
        'gc-card-measurement-detailed': true,
        'gc-card-measurement-detailed-on-tap': this.isClicked
      }
      classes['gc-card-measurement-detailed__' + this.type] = true
      return classes
    }
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    getprogressType: function () {
      if (parseFloat(this.firstTimeValue) > parseFloat(this.latestValue)) {
        return this.$i18n.t('message["fitness-diary.loss"]')
      } else {
        return this.$i18n.t('message["fitness-diary.gain"]')
      }
    },
    progressDiff () {
      let diff = parseFloat(this.firstTimeValue) - parseFloat(this.latestValue)
      return Math.abs(diff.toFixed(1))
    },
    getFirstTimeDate () {
      return this.formatDate(this.firstTimeDate)
    },
    getLatestDate () {
      return this.formatDate(this.latestDate)
    },
    formatDate (dateTime) {
      if (!dateTime) {
        return ''
      }
      const latestDate = new Date(this.latestDate)
      const firstTimeDate = new Date(this.firstTimeDate)
      if (!this.latestDate || !this.firstTimeDate ||
        this.type === 'none' ||
        moment(latestDate).diff(moment(firstTimeDate), 'months') === 0) {
        return moment(dateTime).format('ll')
      } else {
        return moment(dateTime).format('MMM YYYY')
      }
    },
    showPopup () {
      this.$emit('showProgressPopup', this.type)
    },
    viewMore (context) {
      this.onClicked()
      // this.showLoading()

      this.logEvent('Journey.ViewedMeasurement', { 'measurement ': this.type, context: context }, false)
      this.$router.push({
        path: this.$route.path,
        query: {
          page: 'chart',
          type: this.type
        }
      })
    },
    onClicked () {
      this.activeOnClick()
      setTimeout(() => {
        this.deactiveOnClick()
      }, 300)
    },

    activeOnClick () {
      this.isClicked = true
    },

    deactiveOnClick () {
      this.isClicked = false
    }
  }
}
</script>

<template>
  <card-dashboard-step-tracker-skeleton v-if="dataLoading"/>
  <card-content v-else :class="componentClasses">
    <header-simple v-if="showHeader" :headerText="$t('message[\'general.step-tracker\']')" :icon="'forward-small'"
                   @click="navigateToStepTrackerHome()"></header-simple>
    <loading-buffer v-if="dataLoading" :height="'47'"/>
    <div v-if="(isNewUser || connectedWearables.length === 0) && !dataLoading && !errorMessage" class="pc-dashboard__card-step-tracker__first-time">
      <text-body-extra-small class="pc-dashboard__card-step-tracker__description">
        {{ $t('message["step-tracker.first-time-card-text"]') }}
      </text-body-extra-small>
      <button-link @click="navigateToConnectWearablesScreen()" class="pc-dashboard__card-step-tracker__connect-button"
                   :text="$t('message[\'step-tracker.first-time-card-button-text\']')"></button-link>
    </div>
    <div v-if="connectedWearables.length > 0 && !dataLoading && !errorMessage">
      <div class="pc-dashboard__card-step-tracker__with-steps">
        <icon-steps :size="'md2'"/>
        <div class="pc-dashboard__card-step-tracker__step-count">
          <text-heading2 :weight="'extra-bold'">{{stepCount}}</text-heading2>
        </div>
      </div>
      <div class="pc-dashboard__card-step-tracker__sync-date">
        <icon-refresh :size="'sm2'"/>
        <text-body-extra-small>{{syncedDate}}</text-body-extra-small>
      </div>
    </div>
    <div>
      <text-body-extra-small class="pc-dashboard__card-step-tracker__error">
        {{errorMessage}}
      </text-body-extra-small>
    </div>
    <div style="text-align: left">Today's step count - {{ stepCount }}</div>  <!-- to be removed -->
    <div style="text-align: left">Today's Native step count - {{ todayNativeStepCount }}</div>  <!-- to be removed -->
    <button-link @click="refreshTodayStepCount" :text="'refresh today step count'"/> <!-- to be removed -->

  </card-content>
</template>
<script>
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import TextHeading2 from '@/components/global/typography/TextHeading2.vue'
import HeaderSimple from '@/components/global/headers/HeaderSimple.vue'
import CardContent from '@/components/global/cards/CardContent.vue'
import {mapGetters} from 'vuex'
import ButtonLink from '@/components/global/buttons/ButtonLink.vue'
import IconSteps from '@/components/root/icons/IconSteps'
import IconRefresh from '@/components/root/icons/IconRefresh.vue'
import StepTrackerService from "@/services/StepTrackerService";
import LoadingBuffer from "@/components/layout/template-1/global/widgets/LoadingBuffer.vue";
import NativeAppService from '@/services/NativeAppService'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
import { isIOS } from 'mobile-device-detect'
import moment from "moment";
import DashBoardService from '@/services/DashboardService'
import CardDashboardStepTrackerSkeleton from './CardDashboardStepTrackerSkeleton.vue'

export default {
  name: 'card-dashboard-step-tracker',
  components: {LoadingBuffer, ButtonLink, CardContent, HeaderSimple, TextBodyExtraSmall,TextHeading2, IconSteps, IconRefresh, CardDashboardStepTrackerSkeleton},
  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      connectedWearables: [],
      stepCount: 'N/A',
      dataLoading: true,
      syncedDate: '',
      errorMessage: '',
      isNewUser: false,
      todayNativeStepCount: 'n/a',
      lastUpdatedAt: null
    }
  },
  beforeMount () {
    this.getData()
  },
  computed: {
    ...mapGetters({}),
    componentClasses: function () {
      return {
        'pc-dashboard__card-step-tracker': true
      }
    },
    isNative: function () {
      return isMobileNativeApp() && (this.connectedWearables.includes('Health Connect')|| this.connectedWearables.includes('Apple Health') || this.connectedWearables.includes('healthConnect'))
    }
  },
  mounted () {
    window.addEventListener('startRookSessionResponse',this.afterStartRookSessionResponse)
    window.addEventListener('wearablePermissionsResponse',this.afterWearablePermissionsResponse)
    window.addEventListener('todayStepCountResponse', this.handleTodayStepCount);
  },
  beforeDestroy () {
    window.removeEventListener('startRookSessionResponse',this.afterStartRookSessionResponse)
    window.removeEventListener('wearablePermissionsResponse',this.afterWearablePermissionsResponse)
    window.removeEventListener('todayStepCountResponse', this.handleTodayStepCount);
  },
  methods: {
    refreshTodayStepCount () {
      this.setTodayStepCount()
      if (this.isNative) {
        const nativeApp = new NativeAppService()
        nativeApp.getTodayStepCountRequest()
      }
    },
    afterStartRookSessionResponse(data){
      console.log(data)
    },
    afterEnableBackgroundAndroidStepsResponse(data){
      console.log('afterEnableBackgroundAndroidStepsResponse',data)
    },
    afterRequestAndroidStepsPermissionsResponse(data){
      console.log('afterRequestAndroidStepsPermissionsResponse',data)
    },
    afterHasAndroidStepsPermissionsResponse(data){
      console.log('afterHasAndroidStepsPermissionsResponse',data)
    },
    handleTodayStepCount (data) {
      console.log('Received step count response event:', data);
      if (data.steps) {
        this.todayNativeStepCount = data.steps;
      }
    },
    async setTodayStepCount() {
      const today = new Date().toISOString().split('T')[0];
      const stepService = new StepTrackerService();
      const response = await stepService.getStepCount(today);
      this.stepCount = response.stepCount
      this.lastUpdatedAt = response.lastUpdatedAt
    },
    async afterWearablePermissionsResponse(event) {
      const data = event.detail
      if (data.error && isMobileNativeApp()) {
        let service = new StepTrackerService()
        const sourceName = !isIOS ? 'Health Connect' : isIOS ? 'Apple Health' : '';
        await service.disconnectDataSource(sourceName)
      }
    },
    navigateToStepTrackerHome () {
      this.showLoading()
      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: '/dashboard',
        query: {page: 'step-tracker'}
      })
    },
    navigateToConnectWearablesScreen () {
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.connectWearable
      })
    },
    async getData() {
      try {
        this.dataLoading = true;
        const service = new StepTrackerService();
        const connectedDataSources = await service.getConnectedDataSources(); // checking if any wearable connected
        this.connectedWearables = Object.entries(connectedDataSources)
          .filter(([key, value]) => value.enabled)
          .map(([key, value]) => value.id);
        if (this.isNative) {
          const nativeAppService = new NativeAppService();
          const dashboard = new DashBoardService();
          if(!isIOS){
            if(sessionStorage.getItem('wearablePermissionsRequestSent')){
              await nativeAppService.sendWearablePermissionsRequest()
              sessionStorage.getItem('wearablePermissionsRequestSent','1')
            }

            setTimeout(async ()=>{
              await nativeAppService.sendHasAndroidStepsPermissionsRequest()
            },1000)

          } else if(localStorage.getItem('isNativeWearableConnected')===null){
            if(sessionStorage.getItem('wearablePermissionsRequestSent')){
              await nativeAppService.sendWearablePermissionsRequest()
              sessionStorage.getItem('wearablePermissionsRequestSent','1')
            }


          }
          setTimeout(async ()=>{
            await nativeAppService.startRookSessionRequest(dashboard.userData)

            setTimeout(()=>{
              nativeAppService.sendWearableEnableSyncRequest()
            },2000)
          },2000)

        }
        this.refreshTodayStepCount()
        let lastSyncedAt = null;
        if (this.lastUpdatedAt != null) {
          lastSyncedAt = moment(this.lastUpdatedAt);
        } else {
          const stats = await service.getStats(); // getting last synced date
          lastSyncedAt = (stats.lastSyncedAt === null) ? null : moment(stats.lastSyncedAt);
        }

        const now = moment().startOf('day');
        const yesterday = moment().subtract(1, 'day').startOf('day');

        const yesterdayLang = this.$i18n.t('message[\'general.yesterday\']');
        const todayLang = this.$i18n.t('message[\'general.today\']');

        if (lastSyncedAt === null) {
          this.syncedDate =  this.$i18n.t('message[\'general.fail-last-synced\']');
        } else {
          this.syncedDate = lastSyncedAt.isSameOrAfter(yesterday)
            ? `${this.$i18n.t('message[\'general.last-synced\']')} - ${lastSyncedAt.format('hh:mm A')}, ${lastSyncedAt.isSame(now,'day') ? todayLang : yesterdayLang}`
            : `${this.$i18n.t('message[\'general.last-synced\']')} - ${lastSyncedAt.format('hh:mm A')}, ${lastSyncedAt.format('DD MMMM YYYY')}`;
        }


      } catch (error) {
        if(error && error.response.status===404){
          this.isNewUser= true
        } else {
          this.errorMessage = this.$i18n.t('message[\'general.error-getting-data\']');
        }

      } finally {
        this.dataLoading = false;
      }
    }
  }
}
</script>

<template>
  <div class="pc-navigation-meals-add__wrapper ">
    <navigation-bar ref="navbar" :class="componentClass">
      <div @click="slideLeft" v-if="shouldEnableSlider && showLeftSlider" class="pc-navigation-meals-add__nav-button pc-navigation-meals-add__nav-button-left">
        <icon-arrow-circular-linear  :size="'sm4'"/>
      </div>
      <navigation-bar-tab v-if="enableSmartAdd" :active="currentTab==='smart-add'" @click="changeTab('smart-add')">
        {{ $t('message["food-diary.smart-add"]') }}
      </navigation-bar-tab>
      <navigation-bar-tab :active="currentTab==='meal-plan'" @click="changeTab('meal-plan')">
        {{ $t('message["food-diary.navigation-log-meal.meal-plan-meals"]') }}
      </navigation-bar-tab>
      <navigation-bar-tab v-show="getDineOutEnabled" :active="currentTab==='dine-out'" @click="changeTab('dine-out')">
        {{ $t('message["food-diary.navigation-log-meal.dine-out"]') }}
      </navigation-bar-tab>
      <navigation-bar-tab :active="currentTab==='saved-meals'" @click="changeTab('saved-meals')">
        {{ $t('message["food-diary.navigation-log-meal.saved-meals"]') }}
      </navigation-bar-tab>
      <navigation-bar-tab :active="currentTab==='new-meal'" @click="changeTab('new-meal')">
        {{ $t('message["food-diary.navigation-log-meal.add-new-meal"]') }}
      </navigation-bar-tab>
      <div @click="slideRight" v-if="shouldEnableSlider && showRightSlider" class="pc-navigation-meals-add__nav-button pc-navigation-meals-add__nav-button-right">
        <icon-arrow-circular-linear  :size="'sm4'"/>
      </div>
    </navigation-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationBar from '../../../../global/navigators/NavigationBar'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import IconArrowCircularLinear from '../../../../root/icons/IconArrowCircularLinear.vue'
import NavigationBarTab from '../../../../global/navigators/navigation-bar-sub-components/NavigationBarTab'
import {enableSmartAdd} from "@/includes/TemplateSettings";

export default {
  name: 'NavigationMealsAdd',
  components: {
    NavigationBar,
    IconArrowCircularLinear,
    TextBodyExtraSmall,
    NavigationBarTab
  },
  props: {
    activeTab: {
      required: true
    },
  },
  data() {
    return {
      enableSmartAdd: false,
      selectedTab: null,
      shouldEnableSlider: false,
      showRightSlider: false,
      showLeftSlider: false,
      scrollLeftPosition: 0
    };
  },
  computed: {
    ...mapGetters({
      getDineOutEnabled: 'foodDiaryStore/getDineOutEnabled'
    }),
    currentTab() {
      return this.selectedTab || this.activeTab;
    },
    componentClass() {
      return {
        'container': !this.shouldEnableSlider,
        'gc-navigation-bar__slider': this.shouldEnableSlider
      };
    }
  },
  watch: {
    getDineOutEnabled(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          console.log('navbar-reloaded');
          this.initializeNavbar();
        });
      }
    }
  },
  mounted() {
    this.enableSmartAdd = enableSmartAdd()
    this.initializeNavbar();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScroll);
    this.$refs.navbar.$el.removeEventListener("scroll", this.updateScrollPosition);
  },
  methods: {
    changeTab(name) {
      this.$emit('change-tab', name);
    },
    initializeNavbar() {
      this.$nextTick(() => {
        this.checkScroll();
        window.addEventListener('resize', this.checkScroll);
        this.$refs.navbar.$el.addEventListener("scroll", this.updateScrollPosition);
      });
      this.$nextTick(() => {
        let navBar = this.$refs.navbar.$el;
        let minScrollLeft = navBar.scrollLeft;
        navBar.scrollLeft = 1;
        setTimeout(() => {
          navBar.scrollTo({ left: minScrollLeft, behavior: "instant" });
        }, 100);
      });
    },

    checkScroll() {
      let navBar = this.$refs.navbar.$el;
        let showSlider = navBar.scrollWidth > window.innerWidth; this.updateScrollPosition();
        this.shouldEnableSlider = showSlider;
        this.showRightSlider = showSlider;
        this.showLeftSlider = false;
    },
    slideRight () {
      let navBar = this.$refs.navbar.$el
      let navBarFullWidth = navBar.scrollWidth
      navBar.scrollTo({ left: navBarFullWidth, behavior: "smooth" });
    },
    slideLeft () {
      let navBar = this.$refs.navbar.$el
      navBar.scrollTo({ left: 0, behavior: "smooth" });
    },
    updateScrollPosition() {
      let navBar = this.$refs.navbar.$el;
      this.scrollLeftPosition = Math.max(0, navBar.scrollLeft); // Prevent negatives
      let maxScroll = navBar.scrollWidth - navBar.clientWidth;
      if (this.scrollLeftPosition <= 0) {
        this.showRightSlider = true;
        this.showLeftSlider = false;
      } else if (this.scrollLeftPosition >= maxScroll) {
        this.showRightSlider = false;
        this.showLeftSlider = true;
      } else {
        this.showRightSlider = true;
        this.showLeftSlider = true;
      }
    }

  }
};
</script>

<style scoped>

</style>

<template>
  <div :class="componentClasses">
    <div class="pc-meal-plan-meal__nav__back-button" v-on:click="navigatePreviousExerciseCircuit">
      <icon-backward-arrow-rounded :state="(showCircuitBackButton)? '':'disabled'" :size="'md1'"/>
    </div>
    <div class="pc-meal-plan-meal__nav__middle-text">
      <text-body-extra-small>
        {{ currentMealName }}
      </text-body-extra-small>
    </div>
    <div class="pc-meal-plan-meal__nav__forward-button">
      <icon-forward-arrow-rounded
        :state="(showCircuitNextButton)? '':'disabled'"
        :size="'md1'"
        @click="navigateNextExerciseCircuit"
      />
    </div>
  </div>

</template>
<script>
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import IconForwardArrowRounded from '../../../../root/icons/IconForwardArrowRounded'
import IconBackwardArrowRounded from '../../../../root/icons/IconBackwardArrowRounded'


export default {
  name: 'NavMeals',
  components: {
    TextBodyExtraSmall,
    IconForwardArrowRounded,
    IconBackwardArrowRounded,
  },
  props: {
    mealPlanId: '',
    mealPlanDayId: '',
    meals: {
      default: []
    },
    currentMeal: '',
  },
  computed: {
    currentMealName: function () {
      return this.currentMeal.getType().name
    },
    showCircuitBackButton: function () {
      return (this.meals.length > 0 && this.meals[0].getId() !== this.currentMeal.getId())
    },
    showCircuitNextButton: function () {
      return (this.meals.length > 0 && this.meals[this.meals.length - 1].getId() !== this.currentMeal.getId())
    },
    componentClasses: function () {
      return {
        'pc-meal-plan-meal__nav': true
      }
    }
  },
  methods: {
    navigatePreviousExerciseCircuit() {
      this.$router.replace({
        path: this.$appConfig.appUrlList.mealPlanBase + '/' + this.mealPlanId,
        query: {
          day: this.mealPlanDayId,
          meal: this.meals[this.meals.findIndex((meal) => meal.getId() === this.currentMeal.getId()) - 1].getId(),
          loading: 0
        }
      })
    },
    navigateNextExerciseCircuit() {
      this.$router.replace({
        path: this.$appConfig.appUrlList.mealPlanBase + '/' + this.mealPlanId,
        query: {
          day: this.mealPlanDayId,
          meal: this.meals[this.meals.findIndex((meal) => meal.getId() === this.currentMeal.getId()) + 1].getId(),
          loading: 0
        }
      })
    }
  }
}
</script>

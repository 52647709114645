<template>
  <div class="gc-sidebar gc-navigator-side-menu">
      <nav id="menu" v-on:scroll.passive="setScroll">
        <div  class="gc-sidebar__menu">
          <div class="gc-sidebar__user-details-wrap"
               @click="navigate('/account-details?page=account-settings')">
            <div class="gc-sidebar__image-wrap">
              <img :src="profileImage" @onerror="imageLoadOnError" alt="profile-image"/>
            </div>
            <text-content
              class="gc-sidebar__profile-name"
              :weight="'extra-bold'"
              :size="'sm4'"
              :lineHeight="'multi'">
              {{$t('message["general.sidebar-title"]', { name: charLimitedUserName }) }}
            </text-content>

          </div>
          <div v-for="link in navLinks"
               :class="['link_'+link.code]"
               :key="'link-'+link.code">
            <side-bar-button-primary
              @click="navigateLink(link)"
              v-bind:class="{'gc-side-bar__button-primary__selected':isCurrentRoute(link)}">
              {{showLinkText(link.label)}}<icon-ellipse  size="sm1" class="gc-sidebar__link-icon-new" v-if="link.isNew"/>
            </side-bar-button-primary>
          </div>

          <side-bar-button-primary
            v-if="isMember&&showWorkoutLogLink&&workoutPlans.length>0"
            :class="{'gc-side-bar__button-primary__selected':($router.currentRoute.fullPath.includes('workout-log'))}"
            @click="navigate('/workout-programs/?workout-log=1')"
          >
            {{$t('message["workout-log.title"]')}}
          </side-bar-button-primary>
          <side-bar-button-primary
            v-if="isMember&&stepTrackerEnabled"
            :class="{'gc-side-bar__button-primary__selected':($router.currentRoute.fullPath.includes('wearable'))}"
            @click="navigateToStepTracker()"
          >
            {{$t('message["general.step-tracker"]')}}
          </side-bar-button-primary>

          <side-bar-button-primary
            v-if="isMember"
            @click="navigate('/account-details')"
            v-bind:class="{'gc-side-bar__button-primary__selected':(curruntRoute === '/account-details')}">
            {{$t('message["profile.title"]')}}
          </side-bar-button-primary>
          <side-bar-button-collapse v-if="!isMember && showDashboardEditorLink" :selected="$route.fullPath.includes('/dashboard?page=edit&edit=1')">
            <template >
              {{ $t('message["creator.layout-editor.look-and-feel"]') }}
            </template>
            <template v-slot:content>
              <side-bar-button-primary :sub="true" :active="$route.fullPath.includes('/dashboard?page=edit&edit=1')" @click="navigateLink({url: '/dashboard?page=edit&edit=1'})">
                {{ $t('message["creator.layout-editor.dashboard"]') }}
              </side-bar-button-primary>
            </template>
          </side-bar-button-collapse>



          <side-bar-button-primary
            :href="void(0)"
            @click="showLogoutPopup">
            {{$t('message[\'menu.logout\']')}}
          </side-bar-button-primary>

          <ul class="side-menu-bottom-wrap">
            <li>
              <button-link @click="openTermsAndConditionsPopup()" :text="$t('message[\'settings.terms.and.conditions\']')"></button-link>
            </li>
            <li>
              <button-link @click="openPrivacyPolicyPopup()" :text="$t('message[\'settings.privacy-policy\']')"></button-link>
            </li>
            <li>
            <li>
              <button-link :text="getTrainerIDInfo() + ' | ' + appVersion"></button-link>
            </li>
          </ul>
        </div>
      </nav>

    <content-page-popup :page="'privacy-policy'" :visible="showPrivacyPolicyPopup" v-bind:key="'privacy-policy-popup'" v-if="showPrivacyPolicyPopup"
                        v-on:close="closePrivacyPolicyPopup()"></content-page-popup>
    <content-page-popup :page="'legal-disclaimer'" :visible="showTermsCondPopup" v-bind:key="'legal-disclaimer-popup'" v-if="showTermsCondPopup"
                        v-on:close="closeTermsAndConditionsPopup()"></content-page-popup>
    <popup-logout-confirmation :visible="showLogoutConfirmationPopup"
                               v-on:close="closeLogoutConfirmationPopup()"
                               v-on:confirm="logout" />
  </div>
</template>
<script>

import sideBarMixin from '../../../mixins/component-mixins/global/sideBarMixin'
import IconMenu from '../../root/icons/IconMenu'
import SideBarButtonPrimary from '../../global/navigators/side-bar-sub-components/SideBarButtonPrimary'
import SideBarButtonCollapse from '../../global/navigators/side-bar-sub-components/SideBarButtonCollapse'
import ButtonLink from '../../global/buttons/ButtonLink'
import ButtonLinkSecondary from '../../global/buttons/ButtonLinkSecondary'
import TextContent from '../../root/TextContent'
import DashboardService from '../../../services/DashboardService'
import IconEllipse from '../../root/icons/IconEllipse'
import PopupLogoutConfirmation from '../../global/popups/PopupLogoutConfirmation'
import {enableDashboardVariations} from "@/includes/TemplateSettings";

export default {
  name: 'NavigatorSideMenu',
  components: {
    PopupLogoutConfirmation,
    IconMenu,
    SideBarButtonPrimary,
    ButtonLink,
    ButtonLinkSecondary,
    TextContent,
    SideBarButtonCollapse,
    IconEllipse
  },
  data: function () {
    return {
      showDashboardEditorLink: false,
      isContentPage: false,
      charLimitedUserName: this.userName,
      showLogoutConfirmationPopup: false
    }
  },
  mixins: [sideBarMixin],
  beforeMount () {
    this.showDashboardEditorLink = enableDashboardVariations()
    this.loadCommunities()
    this.settingCurruntRoute()
    this.isContentPage = this.curruntRoute.startsWith('/page')
    let date = new Date()
    this.year = date.getFullYear()

    let service = new DashboardService()
    service.getNavigation().then(data => {
      this.navLinksList = data.links
    })
    this.showWorkoutLog()
    this.checkNewFeatures()
  },
  mounted () {
    this.setUserNameCharLimit()
  },
  methods: {
    isCurrentRoute (link) {

      if (link.url.endsWith('/dashboard') && this.$route.path === link.url && Object.values(this.$route.query).length > 0) {
        return false
      }
      if (Object.keys(this.$route.query).length > 0) {
        if (Object.keys(this.$route.query)[0] === 'workout-log-history') {
          return false
        }
      }
      if (link.url.includes('community')) {
        console.log(this.$route.fullPath,link.url)
        return this.$route.fullPath === link.url
      }
      return this.curruntRoute === link.url
    },
    setUserNameCharLimit () {
      let username = this.userName
      if (username.length > 8) {
        username = username.substring(0, 8)
        this.charLimitedUserName = username + '...'
      } else {
        this.charLimitedUserName = username
      }
    },
    showLogoutPopup () {
      this.showLogoutConfirmationPopup = true
    },
    closeLogoutConfirmationPopup () {
      this.showLogoutConfirmationPopup = false
    }
  }
}

</script>

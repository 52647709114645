import LanguageService from '@/services/LanguageService'
import SignUpService from '@/services/sign-up/SignUpService'
import globalSignUpMixin from '@/mixins/component-mixins/page/sign-up/globalSignUpMixin'
import pageSignUpCompleteMixin from '@/mixins/component-mixins/page/sign-up/pageSignUpCompleteMixin'
import DashBoardService from '@/services/DashboardService'
import { error } from 'jquery'
import * as Sentry from "@sentry/browser";

export default {
  mixins:[globalSignUpMixin, pageSignUpCompleteMixin],
  data: function () {
    return {
      popupPaymentProcessingTimeout: null,
      checkSubscriptionStatusTimeout: null,
      numberOfTimesWithoutPaymentMethodsButSubscriptionReady:0,
      stripeKey: null,
      accountId: null,
      paymentState: '',
      contactSupport: {
        popupShow: false
      }
    }
  },
  methods: {
    restartPayment () {
      this.$router.replace({ path: this.$appConfig.appUrlList.signUpPay.replace(':plan', this.$route.params.plan) })
    },
    showPopupContactSupport () {
      this.contactSupport.popupShow = true
    },
    retrievePaymentIntent (clientSecret) {
      let lang = new LanguageService()
      const stripe = Stripe(this.stripeKey, {
        locale: lang.getLangForStripe(),
        stripeAccount: this.accountId,
        betas: ['netbanking_beta_1', 'netbanking_bank_beta_1'],
      })

      stripe.retrievePaymentIntent(clientSecret).then(paymentIndent => {
        console.log(paymentIndent)
      })
    },
    checkSubscriptionStatus (subscriptionId = null) {
      console.log('checkSubscriptionStatus')
      if (!subscriptionId && !this.$route.query.subscription) {
        return
      }
      subscriptionId = subscriptionId || this.$route.query.subscription
      this.hideLoading()
      const planID = this.$route.params.plan
      const service = new SignUpService(planID)

      service.checkSubscriptionStatus(subscriptionId).then(subscription => {
        console.info('check payment state')
        console.table(subscription)
        if (subscription.state !== 'pending-payment') {
          console.log('check 12')

          const hasUpsells = sessionStorage.getItem('has_upsells') === 'true'

          console.log('subscription.payment_method_id', subscription)
          if(subscription.ready && !subscription.payment_method.id && hasUpsells){
            console.info('subscription ready but no payment method')

            if (this.numberOfTimesWithoutPaymentMethodsButSubscriptionReady<20) {
              this.numberOfTimesWithoutPaymentMethodsButSubscriptionReady++
              this.popupPaymentProcessingTimeout = setTimeout(() => {
                this.popupPaymentProcessing.show = true
                this.checkSubscriptionStatus(subscriptionId)
              }, 1000)

              return;
            }

            console.error('Max attempts reached. Payment method ID still missing.')
            Sentry.captureMessage('Upsell API Error: Payment method ID missing after few attempts. planID' + planID, "debug")
            return
          } else if (subscription.ready) {
            console.log('check 13')
            this.goToThankYouPage()
            return
          } else {
            console.log('check 14')
            this.checkSubscriptionStatusTimeout = setTimeout(() => {
              this.checkSubscriptionStatus(subscriptionId)
            }, 3000)
          }
        }
        this.popupPaymentProcessing.show = true
        if (subscription.ready === false) {
          console.log('check 11')
          setTimeout(() => {
            this.checkSubscriptionStatus(subscriptionId)
          }, 3000)
        }
      }).catch((error) => {
        console.log('errorerror', error)
      })
    },
    initService (planID = null) {
      if (!planID) {
        planID = this.$route.params.plan
      }
      if (this.getService && this.getService.getPlanUUID() === planID) {
        // found service for relevant plan UUID
        this.service = this.getService
      } else {
        // create new sign up object
        this.service = new SignUpService(planID)
      }
    },
    getPaymentGatewayDetails () {
      return this.service.getPaymentGatewayDetails()
    },
    delay (time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    confirmCheckoutPaymentBySessionID () {
      if (this.$route.query.sessionId) {
        this.popupPaymentProcessing.show = true
        this.service.confirmCheckoutPaymentBySessionID(this.$route.query.sessionId).then(async data => {
          if (data.subscription_id && data.paid) {
            this.subscriptionID = data.subscription_id
            this.checkSubscriptionStatus(data.subscription_id)
          } else {
            await this.delay(3000)
            this.confirmCheckoutPaymentBySessionID()
          }
        }).catch(async()=>{
          await this.delay(3000)
          this.confirmCheckoutPaymentBySessionID()
        })
      }
    },
    async goToThankYouPage () {
      this.showLoading()
      await this.delay(1000)
      let dashBoardService = new DashBoardService()
      await dashBoardService.getUserData(true)
      await this.goToNextPage()
    },
    async goToNextPage () {
      if (this.redirected) {
        return
      }
      this.$router.replace({ path: this.$appConfig.appUrlList.signUpThankYou.replace(':subscription', this.subscriptionID) })
    }
  }
}

<template>
    <div>
        <page-secondary :header="headerOptions">
            <page-container>
                <card-period-tracker-manage-widget :key="widgetKey"
                                                   :toggle-name="'widgetKey'"
                                                   v-if="isPeriodTrackerEnableByConsole && isFemaleAccount"
                                                   :enableStatus="isPeriodTrackerEnableByUser" @toggleStatus="changeSwitch"/>
            </page-container>
            <popup-disable-period-tracker v-if="showDisablePopup" :popupStatus="status" :visible="showDisablePopup" @disable-period-tracker="disableTracker" @close="closeDisable()"/>
            <popup-enable-period-tracker v-if="showEanblePopup" :popupStatus="status" :visible="showEanblePopup" @close="closeEnable()" @enable-period-tracker="enableTracker"/>
        </page-secondary>
    </div>
</template>
<script>
import BaseComponent from '../../global/base/BaseComponent'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import CardPeriodTrackerManageWidget from '../../period-tracker/CardPeriodTrackerManageWidget'
import PopupDisablePeriodTracker from './page-components/PopupDisablePeriodTracker'
import PopupEnablePeriodTracker from './page-components/PopupEnablePeriodTracker'
import DashBoardService from '@/services/DashboardService'
import PeriodTrackerApi from '@/services/api/period-tracker/PeriodTrackerApi'
export default {
    name: 'PageManageWidgets',
    extends: BaseComponent,
    components: {
        PageSecondary,
        PageContainer,
        CardPeriodTrackerManageWidget,
        PopupDisablePeriodTracker,
        PopupEnablePeriodTracker
    },
    data () {
     return  {
        widgetKey: null,
        status: 'init',
        isPeriodTrackerEnableByUser: false,
        isPeriodTrackerEnableByConsole: false,
        isFemaleAccount: false,
        showEanblePopup: false,
        showDisablePopup: false,
        headerOptions: {
            isTransparent: false,
            isFixed: false,
            show: true,
            left: 'previous',
            right: '',
            rightBlink: false,
            modifiers: ['small-title', 'with-bg-color'],
            mainTitle: this.$i18n.t("message['profile.manage-widgets']"),
            subTitle: ''
      },
     }
    },
    mounted () {
        this.hideLoading()
        this.loadConfigs()
    },
    methods: {
        async loadConfigs () {
            const dashBoardService = new DashBoardService()
            const response = await dashBoardService.getUserConfig(true)
            await dashBoardService.getUserData()

            //male female accounts check
            this.isFemaleAccount = dashBoardService.userData.gender && dashBoardService.userData.gender === "f"
            //trainer console feature flag test
            this.isPeriodTrackerEnableByConsole = response['feature_flags']['period_tracker_enabled']
            //set Other widgets configs as below
            this.isPeriodTrackerEnableByUser = response['user_features']['period_tracker'] !== null
            ? response['user_features']['period_tracker']
            : true
        },
        async enableTracker (data) {
            try {
                this.status = 'processing'
                let payload = {
                    'period_tracker': true
                }

                const dashBoardService = new DashBoardService()
                const response = await dashBoardService.togglePeriodTrackerFeature(payload)
                if (data.enableRestore) {
                    this.enableRestoreStatus()
                }
                this.isPeriodTrackerEnableByUser = true
                this.status = 'success'

                this.logEvent('Profile.WidgetConfigrationUpdated', {
                'widget': 'period-tracker',
                'context': 'profile',
                'enabled': true
                })
                setTimeout (() => {
                    this.showEanblePopup = false
                    this.status = 'init'
                }, 2000)
            } catch (error) {
                console.log(error)
                this.status = 'error'
            }

        },

        async enableRestoreStatus () {
            try {
                const periodTrackerApi = new PeriodTrackerApi()
                const response = await periodTrackerApi.resetPeriodTrackerApi()

                console.log(response)
            } catch (error) {
                console.log(error)
            }

        },
        async disableTracker () {
            try {
                this.status = 'processing'
                let payload = {
                    'period_tracker': false
                }

                const dashBoardService = new DashBoardService()
                const response = await dashBoardService.togglePeriodTrackerFeature(payload)

                this.isPeriodTrackerEnableByUser = false
                this.status = 'success'

                this.logEvent('Profile.WidgetConfigrationUpdated', {
                'widget': 'period-tracker',
                'context': 'profile',
                'enabled': false
                })
                setTimeout (() => {
                    this.showDisablePopup = false
                    this.status = 'init'
                }, 2000)
            } catch (error) {
                console.log(error)
                this.status = 'error'
            }
        },
        changeSwitch (status) {
            if (status) {
                this.showEanblePopup = true
            } else {
                this.showDisablePopup = true
            }
        },

        closeEnable () {
            this.isPeriodTrackerEnableByUser = false
            this.widgetKey = Date.now()
            this.showEanblePopup=false
            this.status = 'init'

        },

        closeDisable () {
            this.isPeriodTrackerEnableByUser = true
            this.showDisablePopup=false
            this.status = 'init'
            this.widgetKey = Date.now()
        }
    }
}
</script>

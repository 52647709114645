import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    visible: false,
    needed:false,
    currentVersion:"",
    latestVersion:"",
    storeUrl:"",
    popupShown: false
  },
  mutations: {
    showPopup (state) {
      state.visible = true
    },
    hidePopup (state) {
      state.visible = false
      state.popupShown = true

    },
    setPopupShown (state) {
      state.popupShown = true
    },
    setContent (state, data) {

      state.needed = data.needed || false
      state.currentVersion = data.needed || ''
      state.latestVersion = data.latestVersion || ''
      state.storeUrl = data.storeUrl || null
      if(data.needed && state.storeUrl){
        state.visible = true
      }
    }
  },
  getters: {
    visible (state) {
      return state.visible
    },
    isPopupShown (state) {
      return state.popupShown
    },
    getInfo (state) {
      return {
        currentVersion: state.currentVersion,
        latestVersion: state.latestVersion,
        storeUrl: state.storeUrl
      }
    }
  }
}

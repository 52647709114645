<template>
  <div v-if="compareEnabled&&hasImages" ref="element" class="pc-slider-journey-photo-highlight">
    <!-- Compare -->
    <div class="pc-slider-journey-photo-highlight__slider">
      <slick ref="slick" :options="slick.slickOptions" @afterChange="handleAfterChange" @beforeChange="handleBeforeChange" @breakpoint="handleBreakpoint" @destroy="handleDestroy" @edge="handleEdge" @init="handleInit" @reInit="handleReInit" @setPosition="handleSetPosition" @swipe="handleSwipe" @lazyLoaded="handleLazyLoaded" @lazyLoadError="handleLazeLoadError">
        <div v-for="(item, key) in data" :key="key">
          <div class="pc-slider-journey-photo-highlight__slider-slide container">
            <div class="pc-slider-journey-photo-highlight__image-area">
              <text-body-extra-small
                class="pc-slider-journey-photo-highlight__slider-image-header"
                weight="extra-bold"
                line-height="multi">
                <div>{{$t('message["fitness-diary.before"]')}}</div>
                <div> &nbsp; - &nbsp; </div>
                <div>{{item.first.date}}</div>
              </text-body-extra-small>
              <image-tile class="pc-slider-journey-photo-highlight__image"
                          :src="item.first.image"
                          v-on:error="setImageOnError('before',key)"
                          v-on:click="showImagePopup(item.first.image)"

              />
            </div>
            <div class="pc-slider-journey-photo-highlight__image-area">
              <text-body-extra-small
                class="pc-slider-journey-photo-highlight__slider-image-header"
                weight="extra-bold"
                line-height="multi">
                <div>{{$t('message["fitness-diary.after"]')}}</div>
                <div> &nbsp; - &nbsp; </div>
                <div>{{item.after.date}}</div>
              </text-body-extra-small>

              <image-tile class="pc-slider-journey-photo-highlight__image"
                          :src="item.after.image"
                          v-on:error="setImageOnError('after',key)"
                          v-on:click="showImagePopup(item.after.image)"

              />
            </div>
          </div>
        </div>
      </slick>
    </div>
    <custom-popup-image :image="imagePopup.image" v-if="imagePopup.show" v-on:close="hideImagePopup()"></custom-popup-image>
  </div>
  <div  class="pc-photo-journey__slider-skeleton" v-else  >
    <ImagePhotoJourneyHistorySkeleton ></ImagePhotoJourneyHistorySkeleton>
    <ImagePhotoJourneyHistorySkeleton ></ImagePhotoJourneyHistorySkeleton>
  </div>
</template>
<script>
import Slick from 'vue-slick'
import { mapGetters } from 'vuex'
import moment from 'moment'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'

import CustomPopupImage from '../widgets/CustomPopupImage'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import ImageBase from '../../../../root/Image'
import ImageTile from './ImageTile'
import ImagePhotoJourneyHistorySkeleton
  from "@/components/layout/template-1/fitness-diary/page-components/ImagePhotoJourneyHistorySkeleton.vue";
export default {
  name: 'SliderJourneyPhotoHighlight',
  components: {
    ImagePhotoJourneyHistorySkeleton,
    ImageTile,
    ImageBase,
    TextBodyExtraSmall,
    CustomPopupImage,
    Slick,
    LoadingBuffer
  },
  data () {
    return {
      imagePopup: {
        show: false,
        image: ''
      },
      navData: {
        default: 0,
        options: [{
          id: 0,
          label: 'Front',
          value: 'front-image'
        },
        {
          id: 1,
          label: 'Side',
          value: 'side-image'
        },
        {
          id: 2,
          label: 'Back',
          value: 'back-image'
        }
        ]
      },
      selectedType: 'front-image',
      compareEnabled: false,
      dateFormat: 'MMM D, Y',
      hasImages: false,
      slick: {
        slickOptions: {
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          nextArrow: false,
          prevArrow: false,
          rtl: this.isRTL(),
          responsive: [{
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]

        }
      },
      data: []
    }
  },
  mounted () {
    this.setPageData()
  },
  beforeDestroy () {
    if (this.$refs.slick) {
      this.$refs.slick.$el.style.visibility = 'hidden'
    }
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    showImagePopup (image) {
      this.imagePopup.image = image
      this.imagePopup.show = true
      this.logEvent('Journey.EnlargedPhoto', { type: this.selectedType.replace('-image', '') }, false)
    },
    hideImagePopup () {
      this.imagePopup.image = ''
      this.imagePopup.show = false
    },
    setCompareStatus (firstDate) {
      const firstDateObj = moment(firstDate)
      const nowObj = moment()

      // if greater than week
      // TODO -bypass for 1 day  previous 7 days
      if (nowObj.diff(firstDateObj, 'days') > 7) {
        this.compareEnabled = true
      }
    },
    setDateFormat (firstDate) {
      const firstDateObj = moment(firstDate)
      const nowObj = moment()

      // if greater than month
      if (nowObj.diff(firstDateObj, 'days') > 30 && nowObj.format('YYYY') === firstDateObj.format('YYYY')) {
        this.dateFormat = 'MMM D'
      } else {
        this.dateFormat = 'll'
      }
    },
    setPageData () {
      Promise.all([
        this.service().getDataFromAPI(true)
      ]).then(() => {
        this.setImages()
      })
        .finally(() => {
          this.hideLoading()
        })
    },
    setImageOnError (type, key) {
      if (type === 'before') {
        this.data[key].first.show = false
      } else if (type === 'after') {
        this.data[key].after.show = false
      }
    },
    setImages () {
      const images = this.service().images
      this.hasImages = Object.values(images).every(image => {
        return image.firstTime.image && image.currentImage && (image.firstTime.image !== image.currentImage)
      })
      if (!this.hasImages) {
        this.$emit('has-no-image')
      }

      const types = ['front-image', 'side-image', 'back-image']
      types.forEach((item, key) => {
        const first = {
          date: '',
          image: '',
          show: false
        }
        const after = {
          date: '',
          image: '',
          show: false
        }

        const beforeDate = images[item].firstTime.date
        if (beforeDate) {
          // set date format according to first log
          this.setDateFormat(beforeDate)

          // set compare visibility according to first log
          this.setCompareStatus(beforeDate)
          const beforeDateObj = moment(beforeDate)
          first.date = beforeDateObj.format(this.dateFormat)
        }
        if (images[item].firstTime.image) {
          first.image = images[item].firstTime.image
          first.show = true
        }

        if (images[item].currentImage) {
          after.image = images[item].currentImage
          after.show = true
        }

        // if user get same image for the before and after hide the before image
        first.image = first.image === after.image ? '' : first.image

        const afterDate = images[item].latestDate
        if (afterDate) {
          const afterDateObj = moment(afterDate)
          after.date = afterDateObj.format(this.dateFormat)
        }

        this.data.push({ first, after })
      })
    },

    next () {
      this.$refs.slick.next()
    },

    slickGoTo (slideIndex) {
      this.$refs.slick.goTo(slideIndex)
    },

    prev () {
      this.$refs.slick.prev()
    },

    reInit () {
      this.$refs.slick.destroy()
      this.$nextTick(() => {
        this.$refs.slick.create()
      })
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick()
      })
    },

    // Events listeners
    handleAfterChange (event, slick, currentSlide) {},
    handleBeforeChange (event, slick, currentSlide, nextSlide) {
      // console.log('handleBeforeChange', event, slick, currentSlide, nextSlide)
    },
    handleBreakpoint (event, slick, breakpoint) {
      // console.log('handleBreakpoint', event, slick, breakpoint)
    },
    handleDestroy (event, slick) {
      // console.log('handleDestroy', event, slick)
    },
    handleEdge (event, slick, direction) {
      // console.log('handleEdge', event, slick, direction)
    },
    handleInit (event, slick) {
      // console.log('handleInit', event, slick)
    },
    handleReInit (event, slick) {
      // console.log('handleReInit', event, slick)
    },
    handleSetPosition (event, slick) {
      // console.log('handleSetPosition', event, slick)
    },
    handleSwipe (event, slick, direction) {
      // console.log('handleSwipe', event, slick, direction)
    },
    handleLazyLoaded (event, slick, image, imageSource) {
      // console.log('handleLazyLoaded', event, slick, image, imageSource)
    },
    handleLazeLoadError (event, slick, image, imageSource) {
      // console.log('handleLazeLoadError', event, slick, image, imageSource)
    }
  }
}

</script>
<style scoped>
  .emptyImage {
    border: 1px solid #ccc;
  }

</style>

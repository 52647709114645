<template>
  <page-secondary :header="headerOptions" class="pc-content">
    <page-content-image v-if="mainImage" :contentImage="mainImage" class="pc-content__video-cover-image"/>
    <page-container class="pc-content__container-top pc-content__video" :class="{'pc-content__no-main-image': !mainImage}">
      <page-content-text :description="description" v-if="description"/>
      <page-video-container  :tags="tags" :video-list="videoList"/>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import ContentPageFactoryService from '../../../../services/ContentPageFactoryService'
import ContentContainer from './widgets/ContentContainer'
import {mapMutations} from 'vuex'
import PageContentImage from '../../../global/pages/page-sub-components/PageContentImage'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageContentText from './page-components/PageContentText'
import PageVideoContainer from './page-components/PageVideoContainer'
import PageSecondary from '../../../global/pages/PageSecondary'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
export default {
  name: 'PageVideo',
  extends: BaseComponent,
  components: {
    PageSecondary,
    PageVideoContainer,
    ContentContainer,
    PageContentImage,
    PageContainer,
    PageContentText
  },
  data: function () {
    return {
      tags: [],
      videoList: [],
      selectedTag: '',
      pageType: this.$route.params.contentPage,
      itemList: {},
      mainImage: '',
      description: '',
      headerOptions: {
        isTopFixed: true,
        isTransparent: false,
        isFixed: true,
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: '',
        subTitle: ''
      }
    }
  },
  mounted () {
    const service = new ContentPageFactoryService()
    service.getPageList().then(data => {
      const link = service.findPageInList(this.pageType, data)
      this.setPageName(link.code)
      this.loadPageData(link.code)
      pageReadyEvent()
    })
  },
  methods: {
    ...mapMutations({
      setPageName: 'pageStore/setPageName',
      unsetPageName: 'pageStore/unsetPageName'
    }),
    loadPageData (pageType) {
      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(pageType)
      page.setPageData().then(() => {
        this.headerOptions.mainTitle = page.pageData.page.title
        this.setPageTitle(page.pageData.page.title)
        this.description = page.pageData.page.description
        this.mainImage = page.pageData.page.header_image || ''
        const tags = []
        for (const [key, value] of Object.entries(page.pageData.tags)) {
          tags.push({ id: key, text: value })
        }

        this.tags = tags

        this.videoList = page.pageData.items
        window.scrollTo({
          top: 500,  // vertical scroll position
          left: 0,   // horizontal scroll position
          behavior: 'smooth'  // smooth scrolling animation
        });
        setTimeout(() => {

          this.$nextTick(() => {
            this.hideLoading()
          })
        }, 500)

      })
    }
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>

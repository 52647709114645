<template>
  <accordion-recipe-tile
    :text="recipe.getName()"
    :sub-text="time"
    :image-src="recipe.getImageMainImage()"
    :show-arrow="showArrow"
    @click="toggleContent()"
  >
    <div v-if="showContent">
      <loading-buffer v-if="loading"/>
      <div v-else>
        <text-body-extra-small
          v-if="cleanString(recipe.getDescription())"
          class="meal-description"
          v-html="recipe.getDescription()"
          :lineHeight="'multi'"
        ></text-body-extra-small>

      <grid-row
        v-if="recipe.getVideos().length > 0"
        class="pc-button-meal-recipe-video__wrapper"
      >
        <grid-col
          cols="6"
          v-for="(video, id) in recipe.getVideos()"
          v-bind:key="'Item' + id"
          @click="showVideoPopup(video.url)"
        >
        <button-meal-recipe-video
          :text="$t('message[\'recipe.video\']', [id + 1])"
        />
        </grid-col>
      </grid-row>

        <card-text>
          <card-text-header>{{
            $tc('message["general.ingredients"]', 2)
            }}</card-text-header>
          <card-text-list v-if="recipe.isLoaded()">
            <card-text-list-item
              v-for="(ingredient, id) in recipe.getIngredients()"
              v-bind:key="'item-ingredients-' + id"
              :text="showIngredient(ingredient)"
            />
          </card-text-list>
          <accordion-recipe-skeleton v-else/>
          <!-- <loading-buffer v-else :height="200"></loading-buffer> -->
        </card-text>
        <card-text>
          <card-text-header>{{
            $t('message["general.instructions"]')
            }}</card-text-header>
          <card-text-list v-if="recipe.isLoaded()">
            <card-text-list-item
              v-for="(Item, id) in recipe.getInstructionSteps()"
              v-bind:key="'item-instructions-' + id"
              :item-number="id + 1 + '.'"
              :text="Item"
            />
          </card-text-list>
          <accordion-recipe-skeleton v-else/>
          <!-- <loading-buffer v-else :height="200"></loading-buffer> -->
        </card-text>
      </div>

    </div>
    <popup-custom-media
      :url="videoPopup.url"
      :type="'video'"
      v-if="videoPopup.visible"
      v-show="videoPopup.visible"
      @close="hideVideoPopup()"
    />
  </accordion-recipe-tile>
</template>
<script>
import AccordionRecipeTile from '../../../layout/template-1/meal-plan/page-components/AccordionRecipeTile'
import CardText from '../../../global/cards/CardText.vue'
import CardTextHeader from '../../../global/cards/card-text-sub-components/CardTextHeader'
import CardTextList from '../../../global/cards/card-text-sub-components/CardTextList'
import CardTextListItem from '../../../global/cards/card-text-sub-components/CardTextListItem'
import TextContent from '../../../root/TextContent.vue'
import CustomIframePopup from '../global/widgets/CustomIframePopup.vue'
import ButtonMealRecipeVideo from './page-components/ButtonMealRecipeVideo.vue'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall.vue'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import PopupCustomMedia from '../../../global/popups/PopupCustomMedia.vue'
import LoadingBuffer from '../global/widgets/LoadingBuffer'
import AccordionRecipeSkeleton from './AccordionRecipeSkeleton'

export default {
  name: 'AccordionRecipe',
  components: {
    LoadingBuffer,
    GridRow,
    GridCol,
    AccordionRecipeTile,
    CardText,
    CardTextHeader,
    CardTextList,
    CardTextListItem,
    TextContent,
    ButtonMealRecipeVideo,
    CustomIframePopup,
    TextBodyExtraSmall,
    PopupCustomMedia,
    AccordionRecipeSkeleton
  },
  props: {
    recipe: {
      required: true
    },

    showArrow: {
      default: false
    }
  },
  data: function () {
    return {
      showContent: false,
      videos: [],
      videoPopup: {
        visible: false,
        url: ''
      },
      timeUnit: '',
      description: '',
      instructions: [],
      ingredients: [],
      loading: false
    }
  },
  computed: {
    time: function () {
     return  this.$i18n.t('message["meal-plan.meal-preparation-time"]', {time:this.recipe.getPreparationTime() + ' ' + this.timeUnit})
    }
  },
  mounted () {
    this.showContent = !this.showArrow
    if (!this.recipe.isLoaded()) {
      this.recipe.getDataFromAPI().then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    } else {
      this.loading = false
    }

    if (this.recipe.getPreparationTime() === 1) {
      this.timeUnit = this.$i18n.tc('message["general.minute"]', 1)
    } else {
      this.timeUnit = this.$i18n.tc('message["general.minutes"]', 2)
    }
  },
  methods: {
    toggleContent () {
      this.showContent = !this.showContent
      if (!this.showArrow) {
        this.showContent = true
      }
    },
    cleanString (string) {
      return string.replace(/<\/?[^>]+(>|$)/g, '').trim()
    },
    showIngredient (item) {
      if (!item) {
        return ''
      }
      const textArr = []
      let text = '&nbsp;'
      if (item.quantity) {
        text = text + item.quantity + ' '
      }

      if (item.unit) {
        text = text + item.unit + ' '
      }
      text = text + item.name

      textArr.push(text)
      if (item.substitutions) {
        item.substitutions.forEach((substitution) => {
          textArr.push(this.showSubstitution(substitution))
        })
      }
      return textArr.join(
        '<br><i>' + this.$i18n.t('message["general.or"]') + '</i> '
      )
    },
    showSubstitution (substitution) {
      if (typeof substitution === 'string') {
        return `<i>${substitution}</i>`
      }
      return (
        '<i>' +
        ((substitution.quantity) ? substitution.quantity + ' ' : '') +
        ((substitution.unit) ? substitution.unit + ' ' : '') +
        substitution.name +
        '</i> '
      )
    },
    showVideoPopup (url) {
      this.videoPopup.url = url
      this.videoPopup.visible = true
    },
    hideVideoPopup () {
      this.videoPopup.url = null
      this.videoPopup.visible = false
    }
  }
}
</script>

import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import AuthService from '../../AuthService'
import SignUpExternalPurchaseService from '@/services/sign-up/SignUpExternalPurchaseService'

export default class extends BaseAPI {
  _resource ='sign-up'

  postUserDetails (payload) {
    const urlArr = ['sign-up', 'register-user-details']
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  postPaymentDetails (payload) {
    const auth = new AuthService()
    this._token = auth.setToken()
    const urlArr = ['sign-up', 'payment-details']
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  confirmPaymentDetails (payload) {
    const auth = new AuthService()
    this._token = auth.setToken()
    const urlArr = ['sign-up', 'is-paid']
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  getPaymentDetails () {
    const urlArr = ['sign-up', 'payment-gateway']
    const auth = new AuthService()
    this._token = auth.setToken()
    return axios.get(this._baseURL + urlArr.join('/'))
  }
  getPaymentGateways (planUUID) {
    const urlArr = ['sign-up', 'product-details', planUUID, 'payment-gateways']
    const auth = new AuthService()
    this._token = auth.setToken()
    return axios.get(this._baseURL + urlArr.join('/'))
  }

  confirmSetupIndent (planUUID, payload) {
    const urlArr = ['sign-up', planUUID, 'confirm-setup-intent']
    const auth = new AuthService()
    this._token = auth.setToken()
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  getProductDetails (planUUID) {
    const urlArr = ['sign-up', 'product-details', planUUID]
    const auth = new AuthService()
    this._token = auth.setToken()
    return axios.get(this._baseURL + urlArr.join('/'))
  }

  getExternalPurchaseData (code) {
    const urlArr = ['sign-up','subscription',code,'activation']
    return axios.get(this._baseURL + urlArr.join('/'))
  }
  createSetupIntent (planUUID) {
    const urlArr = ['sign-up', planUUID, 'create-setup-intent']
    const auth = new AuthService()
    this._token = auth.setToken()
    return axios.post(this._baseURL + urlArr.join('/'), {})
  }

  getStripeCheckout (planUUID) {
    const urlArr = [ 'stripe','checkout-url', planUUID]
    const auth = new AuthService()
    this._token = auth.setToken()
    return axios.get(this._baseURL + urlArr.join('/'))
  }

  getStripePaymentToken (planUUID, payload = {}) {
    const urlArr = [ 'stripe', planUUID,'payment-init' ]
    const auth = new AuthService()
    this._token = auth.setToken()
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  checkSubscriptionStatus (subscriptionID, payload = {}) {
    const urlArr = [ 'sign-up', 'subscription', subscriptionID, 'ready' ]
    const auth = new AuthService()
    this._token = auth.setToken()
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  getIPGDetails () {
    const urlArr = ['sign-up', 'payment-gateway']
    return axios.get(this._baseURL + urlArr.join('/'))
  }

  validateCoupon (UUID,payload) {
    const auth = new AuthService()
    this._token = auth.setToken()
    const urlArr = ['sign-up',UUID, 'validate-coupon']
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  createPaymentIndent (uuid, payload) {
    const auth = new AuthService()
    this._token = auth.setToken()
    const urlArr = ['sign-up', uuid, 'create-payment-intent']
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  confirmPaymentSession (uuid, payload) {
    const auth = new AuthService()
    this._token = auth.setToken()
    const urlArr = ['stripe', uuid, 'confirm-payment-session']
    return axios.get(this._baseURL + urlArr.join('/'), { params: payload })
  }
  processPaymentAction (uuid, payload) {
    const auth = new AuthService()
    this._token = auth.setToken()
    const urlArr = ['sign-up', uuid, 'process-action']
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  checkActivationCode (uuid, payload) {
    const auth = new AuthService()
    this._token = auth.setToken()
    const urlArr = ['sign-up', 'product-details', uuid, 'activate']
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }

  getUpsellDetails (planUUID) {
    const urlArr = ['sign-up', 'product-details', planUUID]
    const auth = new AuthService()
    this._token = auth.setToken()
    return axios.get(this._baseURL + urlArr.join('/'))
  }

  payUpsellProduct (uuid, payload) {
    const auth = new AuthService()
    this._token = auth.setToken()
    const urlArr = ['stripe', uuid, 'purchase']
    return axios.post(this._baseURL + urlArr.join('/'), payload)
  }
}

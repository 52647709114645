import StepTrackerApi from './api/step-tracker/StepTrackerApi';
import APIErrorHandler from "@/services/api-handler/APIErrorHandler";
import ActivityTracker from "@/services/ActivityTracker";
import DashBoardService from "@/services/DashboardService";

export default class StepTrackerApiService {

  constructor () {
    this.user = null;
  }

  getAvailableDataSources (redirectUrl) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getDataSources(redirectUrl)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  getUserExistence (memberId) {
    if(!memberId) {
      const dashboard = new DashBoardService();
      memberId = `${dashboard.userData.reseller_id}-${dashboard.userData.trainer_id}-${dashboard.userData.id}`;

    }
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getUserExistence(memberId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          this.handleServerError(error)
          reject(error);
        });
    });
  }
  getConnectedDataSources () {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getConnectedDataSources()
        .then(response => {
          resolve(response.data);
        })
        .catch(async () => {
          await stepTrackerApi.getConnectedDataSources()
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {

              reject(error);
            });
        });
    });
  }

  getStats () {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getStats()
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  getStepCount (date) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getStepCount(date)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          this.handleServerError(error)
          reject(error);
        });
    });
  }

  setNewConnectedDataSource (sourceName) {
    return new Promise(async (resolve, reject) => {
      console.log(111111111)
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.setNewConnectedDataSource(sourceName)
        .then(response => {
          console.log(3333333333, response)
          try{
            const at = new ActivityTracker()
            at.addEvent('StepTracker.FeatureAdoptionRate', {
              'connected_timestamp': (new Date()).toISOString(),
              'connected_wearable': sourceName
            })
          } catch (e) {
            console.log('Error in activity tracker', e)
          }

          resolve(response.data);
        })
        .catch(error => {
          console.log(44444444444, error)
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  setNewRevokedDataSource (sourceName) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.setNewRevokedDataSource(sourceName)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  disconnectDataSource (sourceName) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.disconnectDataSource(sourceName)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  getGraphData (from, to, type) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getGraphData(from, to, type)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  handleServerError (error) {
    let sentryEventID = APIErrorHandler.logSentry(error)
    const apiUrl = error.config.url
    let isValidState = error.status >= 500 && error.status < 600
    if (error && apiUrl.includes('wearable/2024-07-03/plot') && isValidState) {
      APIErrorHandler.showErrorPopup(sentryEventID, 'step-tracker')
    } else if (error && apiUrl.includes('wearable/2024-07-03/datasources') && isValidState) {
      APIErrorHandler.showErrorPopup(sentryEventID, 'wearable')
    }
  }
}

<template>
  <section class="splide" role="group" :id="uUID">
    <div class="splide__track">
      <ul class="splide__list">
        <slot></slot>
      </ul>
    </div>
  </section>
</template>


<script>
import Splide from '@splidejs/splide';

export default {
  name: "Slider",
  props: {
    sliderID: "",
    /**
     * ref -  https://splidejs.com/guides/options/
     * Options for the Splide slider
     */
    options: {
      type: Object,
      default: () => ({
        arrows: false,
        pagination: false,
      })
    },
    uUID: {
      type: String,
      default: () => `splide-${Math.random().toString(36).slice(2, 9)}`,
    },
  },
  data() {
    return {
      splide: null
    }
  },
  mounted() {
    Splide.defaults = {
      arrows: false,
      drag: 'free',
      lazyLoad: 'sequential',
      pagination: false,
      autoWidth: true,
      trimSpace: true,
    }
    const defaults = {
      start : this.getLastIndex(),
    }
    this.splide = new Splide('#' + this.uUID, {...defaults, ...this.options})
    this.listenEvents();
    this.splide.mount();
  },
  methods: {

    listenEvents() {
      /**
       * List of Splide events to listen to
       * https://splidejs.com/guides/events/#event-list
       */
      const events = [
        'mounted', 'updated', 'move', 'moved', 'active', 'inactive', 'click',
        'drag', 'dragged', 'visible', 'hidden', 'autoplay:play', 'autoplay:pause',
        'autoplay:playing', 'destroy'
      ];
      // Listen to each event and emit it
      events.forEach(event => {
        this.splide.on(event, (...args) => {
          this.$emit(event, ...args);
          console.log(event, ...args);
          if(event==='active'){
            console.log('active', args);
            this.setLastIndex(args[0].index);
          }
        });
      });
    },
    setLastIndex(index){
      localStorage.setItem('lastIndexOfSlider'+this.sliderID, index);
    },
    getLastIndex(){
      return parseInt(localStorage.getItem('lastIndexOfSlider'+this.sliderID)) ;
    },
    go(index) {
      this.splide.go(index);
    },
    destroy(completely = true) {
      this.splide.destroy(completely);
    },
    refresh() {
      this.splide.go(1);
    }
  },
  beforeDestroy() {
    this.destroy(true);
  }
}
</script>

<template>
  <page :header="header" class="gc-page-gender">
    <validation-observer ref="form" v-slot="{ handleSubmit, invalid }">
      <page-container>
        <div class="gc-page-gender__header">
          <text-heading-4
            class="gc-page-gender__title text-center"
            weight="extra-bold"
            lineHeight="multi"
            >{{ title.main }}</text-heading-4
          >
          <div
            v-if="showInfoIcon"
            class="gc-page-gender__header-wrap-info-button"
            @click="popupGenderInfoShow()"
          >
            <!-- <div
            v-if="true"
            class="gc-page-gender__header-wrap-info-button"
            @click="popupGenderInfoShow()"
          > -->
            <icon-info size="sm1" class="gc-page-gender__header-info-button" />
          </div>
        </div>
        <div class="gc-page-gender__content">
          <form
            id="form-input-gender"
            class="gc-page-gender__form"
            @submit.prevent="handleSubmit(nextComponent)"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              vid="genderInput"
              :name="'gender'"
            >
              <button-choice
                type="button"
                :class="[
                  'gc-page-gender__input',
                  'gc-page-gender__input-' + option.value,
                ]"
                v-for="option in options"
                :key="'gender-' + option.value"
                :text="option.name"
                v-model="gender"
                :selected="gender === option.value"
                @click="setGender(option.value)"
              ></button-choice>
              <text-body-extra-small
                class="gc-page-progress-form__input-error text-center"
                lineHeight="multi"
                >{{ errors[0] }}</text-body-extra-small
              >
            </validation-provider>
          </form>
        </div>
      </page-container>
      <page-footer
        :form="'form-input-gender'"
        :validated="invalid"
      ></page-footer>
    </validation-observer>
    <popup-gender-info
      :visible="popupGenderInfoVisibility"
      @close="popupGenderInfoShow()"
    />
    <popup-registration-successful :visible="registrationCompletePopupShow" @close="registrationCompletePopupShow=false"/>
  </page>
</template>

<script>
import FormBaseComponent from "../../layout/template-1/global/widgets/forms/FormBaseComponent";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ButtonPrimary from "../buttons/ButtonPrimary";
import TextBodyExtraSmall from "../typography/TextBodyExtraSmall";
import TextHeading4 from "../typography/TextHeading4";
import PageContainer from "./page-sub-components/PageContainer";
import Page from "./PageForm";
import ButtonChoice from "../buttons/ButtonChoice";
import TextContent from "../../root/TextContent";
import PageFooter from "./page-sub-components/PageFormFooter";
import IconInfo from "@/components/root/icons/IconInfo";
import PopupGenderInfo from "../popups/PopupGenderInfo";
import PopupRegistrationSuccessful from '../../global/popups/PopupRegistrationSuccessful'

export default {
  name: "PageGender",
  extends: FormBaseComponent,
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    ButtonChoice,
    TextContent,
    PageFooter,
    IconInfo,
    PopupGenderInfo,
    PopupRegistrationSuccessful
  },
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: "",
        right: "",
        rightBlink: false,
        modifiers: [],
        mainTitle: "",
        subTitle: "",
      },
      gender: "",
      popupGenderInfoVisibility: false,
      showInfoIcon: false,
      registrationCompletePopupShow: false
    };
  },
  mounted() {
    const subscriptionID = this.$route.params.subscription;

    const thankYouRoute = this.$appConfig.appUrlList.signUpThankYou.replace(':subscription', subscriptionID);

    if (this.$route.query.from === 'thank-you') {
        this.registrationCompletePopupShow = true
        
        const url = new URL(window.location.href)
        url.searchParams.delete('from')
        window.history.replaceState({}, '', url.toString())
    }

    this.gender = this.inputValue;
    this.hideLoading();
    this.showInfoIcon =
      !!templateConfig.localization[
        "subscriptions.gender-info-title"
      ]?.trim() &&
      !!templateConfig.localization[
        "subscriptions.gender-info-description"
      ]?.trim() &&
      templateConfig.localization["subscriptions.gender-info-title"].trim() !==
        "-" &&
      templateConfig.localization[
        "subscriptions.gender-info-description"
      ].trim() !== "-";
  },
  methods: {
    setGender(selectedGender) {
      this.gender = selectedGender;
    },
    popupGenderInfoShow() {
      this.popupGenderInfoVisibility = !this.popupGenderInfoVisibility;
    },
    nextComponent() {
      const inputObj = { key: this.inputKey, value: this.gender };
      this.$emit("submit", inputObj);
    },
  },
};
</script>

<template>
  <page-secondary :header="headerOptions" class="pc-food-dairy-home">
    <!-- <div><page-food-diary-skeleton /></div> -->
    <page-container-fluid breakpoint="sm">
      <grid-row no-gutters>
        <grid-col>
          <slider
            v-if="dates.length > 0 && showSlider"
            :dates="dates"
            :has-more="hasMoreLogs"
            :last-selected-day="currentSelectedDay"
            class="pc-food-dairy-home__calendar-slider"
            :class="isTablet || isDesktop ? 'container' : ''"
            @load-more="addMoreDays()"
            @selected="selectedDate($event)"
          />
          <page-food-diary-skeleton v-else />
          <!-- <loading-buffer v-else height="150" /> -->
        </grid-col>
      </grid-row>
    </page-container-fluid>

    <page-container v-if="underMaintenance">
      <text-body-extra-small class="pc-food-dairy-home__text-maintenance-mode">
        {{
          $t('message["feature.maintenance-mode"]', {
            featureName: $t('message["food-diary.title"]'),
          })
        }}
      </text-body-extra-small>
    </page-container>
    <page-container class="pc-food-dairy-home__container">
      <transition name="fast-fade">
        <food-diary-day
          v-if="currentDate && mealPlanDays && showDay"
          :food-diary-day-i-d="dayID"
          :date="currentDate"
          :days="mealPlanDays"
          class="pc-food-dairy-home__day"
          @header-text="setHeaderText($event)"
          @updated="setLogUpdated($event)"
        />
      </transition>
    </page-container>
  </page-secondary>
</template>

<script>
import FoodDiaryService from "../../../../services/FoodDiaryService";
import FoodDiaryDay from "./page-components/FoodDiaryDay";
import lodash from "lodash";
import moment from "moment";
import Slider from "../../../global/sliders/CalendarSliderV2";
import PageContainer from "../../../global/pages/page-sub-components/PageContainer";
import { mapGetters, mapMutations } from "vuex";
import DashboardService from "../../../../services/DashboardService";
import PageSecondary from "../../../global/pages/PageSecondary";
import TextBodyExtraSmall from "../../../global/typography/TextBodyExtraSmall";
import LanguageService from "../../../../services/LanguageService";
import headerEventBus from "../../../../event-buses/headerEventBus";
import PageContainerFluid from "../../../global/pages/page-sub-components/PageContainerFluid";
import GridRow from "../../../global/grid/GridRow";
import GridCol from "../../../global/grid/GridCol";
import NutritionService from "../../../../services/nutrition/NutritionService";
import LoadingBuffer from "../global/widgets/LoadingBuffer";
import { showFoodDiaryFutureDays } from "@/includes/TemplateSettings";
import { pageReadyEvent } from "@/helpers/dom/events/customEvents";
import APIErrorHandler from "@/services/api-handler/APIErrorHandler";
import UserEventsService from "@/services/UserEventsService";
import DashBoardService from "../../../../services/DashboardService";
import UserEventService from "@/services/UserEventsService";
import PageFoodDiarySkeleton from "./PageFoodDiarySkeleton";

export default {
  name: "PageHome",
  components: {
    LoadingBuffer,
    GridCol,
    GridRow,
    Slider,
    PageContainerFluid,
    PageSecondary,
    FoodDiaryDay,
    PageContainer,
    TextBodyExtraSmall,
    PageFoodDiarySkeleton,
  },

  data: function () {
    return {
      dayID: 0,
      showSlider: false,
      mealPlanDays: 0,
      dates: [],
      service: null,
      logs: [],
      hasMoreLogs: true,
      currentPage: 0,
      subscriptionStartDate: null,
      currentDate: null,
      registeredDate: null,
      showDay: true,
      underMaintenance: false,
      currentSelectedDay: {
        id: null,
        date: null,
        day: null,
        month: null,
        index: 0,
      },
      headerOptions: {
        show: true,
        left: "previous-emit",
        right: "add",
        rightBlink: false,
        modifiers: ["with-bg-color", "small-title"],
        mainTitle: this.$i18n.t('message["food-diary.title"]'),
        header: {
          mainTitle: this.$i18n.t('message["food-diary.title"]'),
          subTitle: "",
        },
      },
      totalCount: 0,
    };
  },
  created() {
    this.setMealPlanDays();
    let dashboard = new DashboardService();
    this.registeredDate = dashboard.getRegisteredDate();
    this.setPageTitle(this.$t('message["food-diary.title"]'));
  },
  beforeMount() {
    this.currentPage = this.getPaginationCurrentPage();
    this.dates = this.getCurrentDays();

    this.logs = this.getCurrentLogs();
    this.hasMoreLogs = this.getHasMoreLogs();
    this.currentSelectedDay = this.getLastSelectedDate();
  },
  mounted() {
    this.service = new FoodDiaryService();
    if (this.getAddPopupShown) {
      this.logPopup.visible = true;
    }
    this.dayID = 0;
    if (this.logs.length === 0 && this.hasMoreLogs) {
      this.getLatestData();
    } else {
      this.showSlider = true;
      this.hideLoading();
    }
    headerEventBus.$on("back-button-click", this.goBack);
  },
  methods: {
    ...mapMutations({
      setTodayLog: "foodDiaryStore/setTodayLog",
      setPaginationData: "foodDiaryStore/setPaginationData",
      setRouteTransition: "setRouteTransition",
      setLastSelectedDay: "foodDiaryStore/setLastSelectedDay",
      showFoodDiaryInfoPopup: "showFoodDiaryInfoPopup",
    }),
    ...mapGetters({
      getPaginationCurrentPage: "foodDiaryStore/getPaginationCurrentPage",
      getCurrentDays: "foodDiaryStore/getCurrentDays",
      getCurrentLogs: "foodDiaryStore/getCurrentLogs",
      getHasMoreLogs: "foodDiaryStore/getHasMoreLogs",
      getLastSelectedDate: "foodDiaryStore/getLastSelectedDate",
    }),
    showInfoPopupForFirstTimeVisitor() {
      UserEventService.foodDiaryInfoPopupView();
    },
    goBack() {
      this.showLoading();
      this.setRouteTransition({ name: "fade", mode: "in-out" });
      this.$router.push({ path: this.$appConfig.appUrlList.dashboard });
    },
    setHeaderText(headerText) {
      this.headerOptions.header = headerText;
      this.setHeader(this.headerOptions);
    },
    addMoreDays() {
      this.getLatestData();
    },
    setMealPlanDays() {
      const service = new NutritionService();

      let plans = service.getActiveMealPlans();
      if (plans.length > 0) {
        let latestMealPlan = lodash.last(lodash.orderBy(plans, "_id"));
        latestMealPlan.getData().then(() => {
          this.mealPlanDays = latestMealPlan.getDays();
        });
      }
    },
    showFutureDates() {
      return showFoodDiaryFutureDays();
    },
    getLatestData() {
      if (this.hasMoreLogs && this.state !== "loading") {
        this.$nextTick(() => {
          this.state = "loading";
          let paginateData = {
            page: this.currentPage + 1,
          };
          this.service
            .getPaginateData(paginateData)
            .then((data) => {
              if (!data.data.logs) {
                this.logs = [];
              } else {
                this.hasMoreLogs = data.data.has_more_pages || false;
                this.currentPage = data.data.current_page || this.currentPage;
                let lastLog = lodash.last(data.data.logs);
                let lastLogDate = moment().locale("en").format("YYYY-MM-DD");
                if (lastLog) {
                  lastLogDate = lastLog.logged_date;
                }
                if (!this.hasMoreLogs) {
                  lastLogDate = this.registeredDate
                    .locale("en")
                    .format("YYYY-MM-DD");
                  if (
                    this.currentPage === 1 &&
                    moment()
                      .subtract(2, "months")
                      .diff(this.registeredDate, "days") > 0
                  ) {
                    lastLogDate = moment()
                      .subtract(2, "months")
                      .locale("en")
                      .format("YYYY-MM-DD");
                  }
                }
                this.totalCount = data.data.total;
                this.logs = lodash.concat(this.logs, data.data.logs);
                let lastDate;
                if (this.dates.length > 0) {
                  lastDate = lodash.first(this.dates).date;
                } else if (!this.showFutureDates()) {
                  if (this.logs.length === 0) {
                    lastDate = moment().locale("en").format("YYYY-MM-DD");
                  } else if (
                    moment(lodash.first(this.logs).logged_date)
                      .locale("en")
                      .diff(moment().locale("en"), "days") > 0
                  ) {
                    lastDate = moment(lodash.first(this.logs).logged_date)
                      .locale("en")
                      .format("YYYY-MM-DD");
                  } else {
                    lastDate = moment().locale("en").format("YYYY-MM-DD");
                  }
                } else {
                  lastDate = moment()
                    .add(7 * 6, "days")
                    .locale("en")
                    .format("YYYY-MM-DD");
                }
                let dates = [];
                for (
                  let i = 0;
                  moment(lastLogDate)
                    .locale("en")
                    .add(i, "days")
                    .diff(moment(lastDate).locale("en"), "days") <= 0;
                  ++i
                ) {
                  let cDay = moment(lastLogDate).locale("en").add(i, "days");
                  let currentFormattedDate = cDay
                    .locale("en")
                    .format("YYYY-MM-DD");
                  let currentLog = this.logs.find((log) => {
                    return log.logged_date === currentFormattedDate;
                  });
                  let languageService = new LanguageService();
                  dates.push({
                    month: cDay
                      .locale(languageService.setAppLangCode())
                      .format("MMM"),
                    day: cDay
                      .locale(languageService.setAppLangCode())
                      .format("DD"),
                    date: currentFormattedDate,
                    id: currentLog ? currentLog._id : "",
                    logged: !!(
                      currentLog &&
                      currentLog.logged_meals &&
                      currentLog.logged_meals.length > 0
                    ),
                  });
                }

                dates = lodash.concat(dates, this.dates);
                this.dates = lodash.uniqBy(dates, "date");
                this.setPaginationData({
                  paginationCurrentPage: this.currentPage,
                  currentDays: this.dates,
                  currentLogs: this.logs,
                  hasMoreLogs: this.hasMoreLogs,
                });
              }
            })
            .catch((err) => {
              let sentryEventID = APIErrorHandler.logSentry(err);
              APIErrorHandler.handleInternalServerError(
                err.response,
                sentryEventID
              );
              this.underMaintenance =
                err.response.status === 504 || err.response.status === 503;
              if (this.underMaintenance) {
                this.headerOptions.right = "";
              }
            })
            .finally(() => {
              if (this.totalCount < 1) {
                this.showInfoPopupForFirstTimeVisitor();
              }
              pageReadyEvent();
              this.hideLoading();
              this.state = "loaded";
              if (
                this.hasMoreLogs &&
                moment()
                  .subtract(2, "weeks")
                  .diff(
                    moment(lodash.first(this.dates).date).locale("en"),
                    "days"
                  ) < 14
              ) {
                this.addMoreDays();
              } else {
                this.showSlider = true;
                this.hideLoading();
                this.state = "loaded";
              }
            });
        });
      }
    },
    selectedDate(event) {
      this.showDay = false;
      this.$nextTick(() => {
        this.dayID = event.id;
        this.currentDate = event.date;
        this.currentSelectedDay = event;
        this.setLastSelectedDay(this.currentSelectedDay);
        this.showDay = true;
      });
    },
    setLogUpdated(log) {
      if (log.logged_date === moment().locale("en").format("YYYY-MM-DD")) {
        this.setTodayLog(log);
      }
      this.showDay = false;
      this.showLoading();
      this.$nextTick(() => {
        this.dayID = log._id;
        this.currentSelectedDay.id = this.dayID;
        this.setLastSelectedDay(this.currentSelectedDay);
        this.currentDate = log.logged_date;
        this.dates = this.dates.map((date) => {
          if (date.date === log.logged_date) {
            date.logged = !!(log.logged_meals && log.logged_meals.length > 0);
            date.id = log._id;
          }
          return date;
        });
        this.showDay = true;
        this.hideLoading();
      });
    },
  },
  beforeDestroy() {
    headerEventBus.$off("back-button-click", this.goBack);
  },
};
</script>

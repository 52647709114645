<template>
  <div class="pc-page-my-journey-measument-chart-skeleton">
    <div class="container">
          <AnimatedPlaceholder width="90px" height="45px" />
          <AnimatedPlaceholder width="60px" height="15px" />
    </div>
    <PageMyJourneyMeasumentChartBoxSkeleton />
  </div>
</template>

<script>
import AnimatedPlaceholder from "../../../global/animators/AnimatedPlaceholder";
import PageMyJourneyMeasumentChartBoxSkeleton from "./PageMyJourneyMeasumentChartBoxSkeleton";

export default {
  name: "pc-page-my-journey-measument-chart-skeleton",
  components: { AnimatedPlaceholder, PageMyJourneyMeasumentChartBoxSkeleton },
};
</script>

<style>

</style>
